import type { CSSRulesFunction, ResponsiveValue, SpaceScale } from "../theme";

export enum ToasterPosition {
  TopRight = "top-right",
  BottomLeft = "bottom-left",
}

interface PositionStyle {
  container: CSSRulesFunction<{ transitionDuration: number }>;
  entering?: CSSRulesFunction<{ transitionDuration: number }>;
  entered?: CSSRulesFunction<{ transitionDuration: number }>;
  exiting?: CSSRulesFunction<{ transitionDuration: number }>;
  exited?: CSSRulesFunction<{ transitionDuration: number }>;
  unmounted?: CSSRulesFunction<{ transitionDuration: number }>;
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const toasterPositionStyles: Record<ToasterPosition, PositionStyle> = {
  [ToasterPosition.TopRight]: {
    container: (theme) => {
      const offset: ResponsiveValue<SpaceScale> = 2;
      return [
        { left: "auto", bottom: "auto" },
        theme.responsive(offset, (value) => ({
          top: theme.spacing(value),
        })),
        theme.responsive(offset, (value) => ({
          right: theme.spacing(value),
        })),
      ];
    },
    entering: () => {
      return {
        transform: "translate3d(100%, 0, 0)",
        opacity: 0,
      };
    },
    entered: (_, { transitionDuration: d }) => {
      return {
        transition: `transform ${d}ms ease-out, opacity ${d}ms ease-out`,
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
      };
    },
    exiting: (_, { transitionDuration: d }) => {
      return {
        transition: `transform ${d}ms ease-out, opacity ${d}ms ease-out`,
        transform: "translate3d(100%, 0, 0)",
        opacity: 0,
      };
    },
  },

  [ToasterPosition.BottomLeft]: {
    container: (theme) => {
      const offset: ResponsiveValue<SpaceScale> = 2;
      return [
        { right: "auto", top: "auto" },
        theme.responsive(offset, (value) => ({
          bottom: theme.spacing(value),
        })),
        theme.responsive(offset, (value) => ({
          left: theme.spacing(value),
        })),
      ];
    },
    entering: () => {
      return {
        transform: "translate3d(-100%, 0, 0)",
        opacity: 0,
      };
    },
    entered: (_, { transitionDuration: d }) => {
      return {
        transition: `transform ${d}ms ease-out, opacity ${d}ms ease-out`,
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
      };
    },
    exiting: (_, { transitionDuration: d }) => {
      return {
        transition: `transform ${d}ms ease-out, opacity ${d}ms ease-out`,
        transform: "translate3d(-100%, 0, 0)",
        opacity: 0,
      };
    },
  },
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
