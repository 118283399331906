import type * as React from "react";

import { ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { Box, Visibility } from "../layout";
import { useTheme } from "../theme";

import type { RowCollapsibleProps } from "./componentTypes";

interface ActionsProp {
  collapsible?: Omit<RowCollapsibleProps, "alwaysVisibleAmount">;
  actions?: React.ReactNode[];
  collapsed: boolean;
  toggleCollapse: () => void;
  rowKey: string;
}
function Actions({
  collapsible,
  actions,
  collapsed,
  toggleCollapse,
  rowKey,
}: ActionsProp) {
  const { theme } = useTheme();
  return (
    <Box layout="flex" flexGrow={0}>
      {!!actions && (
        <Box layout="flex" flexWrap="wrap" css={{ gap: theme.spacing(0.5) }}>
          {actions.map((action, i) => (
            <span key={`${rowKey}_action_${i}`}>{action}</span>
          ))}
        </Box>
      )}
      {!!collapsible && (
        <Visibility visible={["block", null, "none"]}>
          <IconButton
            variant={
              collapsible.variant
                ? collapsible.variant
                : ButtonVariant.TextOnLight
            }
            icon={collapsed ? Glyph.ChevronDown : Glyph.ChevronUp}
            size={collapsible.size}
            label={
              collapsed
                ? collapsible.labelTranslations.expand
                : collapsible.labelTranslations.collapse
            }
            onClick={() => toggleCollapse()}
          />
        </Visibility>
      )}
    </Box>
  );
}

export default Actions;
