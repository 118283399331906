import * as React from "react";

import { useTheme } from "../theme";
import { omitTypographyStyleProps } from "../typography/appearance";

import { dlAccessoryStyle, dtStyle } from "./dlStyle";
import type { DTStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof DTStyleProps
>;

export interface DTProps extends HTMLAttributes, DTStyleProps {
  children: React.ReactNode;
}

const DT: React.FC<DTProps> = (props) => {
  const { theme } = useTheme();
  const { leftAccessory, children, ...rest } = omitTypographyStyleProps(props);

  return (
    <dt css={dtStyle(theme, props)} {...rest}>
      {leftAccessory && React.Children.count(leftAccessory) > 0 ? (
        <span css={dlAccessoryStyle(theme, "left")}>{leftAccessory}</span>
      ) : null}
      <span>{children}</span>
    </dt>
  );
};

export default DT;
