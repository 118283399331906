import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { ColorScheme, Interpolation, useTheme } from "../../theme";

import ToggleCheck from "./ToggleCheck";
import ToggleRadio from "./ToggleRadio";
import {
  toggleBodyStyle,
  toggleDescriptionStyle,
  toggleInputStyle,
  toggleLabelStyle,
  toggleStyle,
} from "./toggleStyles";
import ToggleSwitch from "./ToggleSwitch";
import {
  ToggleControl,
  ToggleControlPosition,
  ToggleVariant,
  ToggleStyleProps,
} from "./toggleTypes";

const controlArea: Interpolation = { gridArea: "control" };
const mediaArea: Interpolation = { gridArea: "media" };
const contentArea: Interpolation = { gridArea: "content", alignSelf: "center" };

const Control: React.FC<{ type: ToggleControl }> = ({ type }) => {
  switch (type) {
    case ToggleControl.Check:
      return <ToggleCheck />;
    case ToggleControl.Radio:
      return <ToggleRadio />;
    case ToggleControl.Switch:
      return <ToggleSwitch />;
  }
};

type HTMLAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  keyof ToggleStyleProps | "children" | "className" | "aria-checked"
>;

export interface ToggleProps extends HTMLAttributes, Partial<ToggleStyleProps> {
  /**
   * In controlled mode, sets the checked state of this component.
   */
  checked?: boolean;
  /**
   * In uncontrolled mode, sets the initial checked state of this component.
   */
  defaultChecked?: HTMLAttributes["defaultChecked"];
  /**
   * A callback that is triggered when the state of the component changes.
   */
  onChange?: HTMLAttributes["onChange"];
  /**
   * The underlying type of this component.
   */
  type: "radio" | "checkbox";
  /**
   * Optional media content such as an image or icon to display within this
   * component.
   */
  media?: React.ReactNode;
  /** An optional description to display alongside the label. */
  description?: React.ReactNode;
  /** The label to set for this component. */
  children: React.ReactNode;
}

const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
  const {
    children,
    description,
    media,
    control = ToggleControl.Check,
    colorScheme = ColorScheme.OnLight,
    controlPosition = ToggleControlPosition.Start,
    variant = ToggleVariant.Emphasized,
    ...rest
  } = props;

  const { theme } = useTheme();

  const styleProps = {
    control,
    controlPosition,
    colorScheme,
    variant,
  };

  let contents = (
    <>
      {media ? <div css={mediaArea}>{media}</div> : null}
      <div css={contentArea}>
        <div css={toggleLabelStyle}>{children}</div>
        {description ? (
          <div css={toggleDescriptionStyle}>{description}</div>
        ) : null}
      </div>
    </>
  );

  if (styleProps.variant === ToggleVariant.ControlOnly) {
    contents = <VisuallyHidden>{contents}</VisuallyHidden>;
  }

  return (
    <label css={toggleStyle(theme, styleProps)} data-field-control>
      <input css={toggleInputStyle} {...rest} ref={ref} />
      <div css={toggleBodyStyle(theme, { ...styleProps, hasMedia: !!media })}>
        <div css={controlArea} role="presentation">
          <Control type={control} />
        </div>
        {contents}
      </div>
    </label>
  );
});

export default Toggle;
