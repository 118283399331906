import * as React from "react";

import {
  useTheme,
  CSSRulesFunction,
  ResponsiveValue,
  CSSValue,
  SpaceScale,
  TypeScale,
  ColorProp,
} from "../theme";

import {
  TypographyStyleProps,
  typographyStyle,
  omitTypographyStyleProps,
} from "./appearance";

type HTMLProps = Omit<
  React.BlockquoteHTMLAttributes<HTMLQuoteElement>,
  "color"
>;

export interface BlockQuoteProps extends HTMLProps, TypographyStyleProps {
  /**
   * Customizes the width of the leading accent on the blockquote
   */
  borderWidth?: ResponsiveValue<number>;

  /**
   * Customizes the color of the leading accent on the blockquote
   */
  borderColor?: ResponsiveValue<ColorProp>;

  /**
   * Customizes the distance between the accent and content of the blockquote
   */
  borderSpacing?: ResponsiveValue<CSSValue<SpaceScale>>;

  /**
   * Changes the alignment of the blockquote to either left-align the accent OR
   * content with adjacent elements
   */
  accentPosition?: ResponsiveValue<"outside" | "inside">;
}

const blockQuoteStyle: CSSRulesFunction<BlockQuoteProps> = (theme, props) => {
  return [
    typographyStyle(theme, props),
    {
      "--blockquote-border-width": 0,
      "--blockquote-border-spacing": 0,
      borderLeftStyle: "solid",
      "p:last-child": {
        marginBottom: 0,
      },
    },
    theme.responsive(props.accentPosition, (v) => {
      switch (v) {
        case "inside":
          return { marginLeft: 0 };
        case "outside":
          return {
            marginLeft:
              "calc( -1 * (var(--blockquote-border-width) + var(--blockquote-border-spacing)) )",
          };
      }
    }),
    theme.responsive(props.borderWidth, (borderWidth) => ({
      borderWidth: borderWidth,
      "--blockquote-border-width": `${borderWidth}px`,
    })),
    theme.responsive(props.borderColor, (color) => ({
      borderColor: theme.color(color),
    })),
    theme.responsive(props.borderSpacing, (spacing) => {
      const value = theme.spacing(spacing);
      return {
        paddingLeft: value,
        "--blockquote-border-spacing": value,
      };
    }),
  ];
};

const BlockQuote = React.forwardRef<HTMLQuoteElement, BlockQuoteProps>(
  function BlockQuote(props, ref) {
    const { theme } = useTheme();
    const {
      preset,
      size: inSize,
      borderWidth = 5,
      borderSpacing = 1,
      accentPosition = "inside",
      layout = "block",
    } = props;
    // Set a default size if size or preset props are set
    const size = inSize || (preset ? undefined : TypeScale.Size_07);
    const { borderColor, ...rest } = omitTypographyStyleProps(props);

    return (
      <blockquote
        css={blockQuoteStyle(theme, {
          borderWidth,
          borderSpacing,
          accentPosition,
          layout,
          ...props,
          ...(size == null ? {} : { size }),
        })}
        {...rest}
        ref={ref}
      />
    );
  }
);

export default BlockQuote;
