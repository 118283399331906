import { ClassNames } from "@emotion/react";
import * as React from "react";

import { useTheme } from "../theme";

import MenuLabel from "./MenuLabel";
import MenuLI from "./MenuLI";
import {
  menuKeyProp,
  MenuRole,
  useMenuRole,
  useMenuSelectionState,
} from "./menuState";
import { menuButtonStyle, MenuButtonStyleProps } from "./menuStyle";
import type { MenuLinkRenderProps, MenuLabelProps } from "./menuTypes";

type HTMLAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export interface MenuLinkProps
  extends HTMLAttributes,
    Partial<MenuButtonStyleProps>,
    MenuLabelProps {
  /**
   * Customizes the rendering of the underlying link. This render prop is useful
   * when trying to integrating with custom link components from React
   * frameworks and routing libraries.
   */
  render?: (props: HTMLAttributes & MenuLinkRenderProps) => React.ReactNode;

  children: React.ReactNode;
}

const MenuLink = React.forwardRef<HTMLAnchorElement, MenuLinkProps>(
  (props, ref) => {
    const {
      description,
      children,
      leftIcon,
      rightIcon,
      accent = "hidden",
      render,
      ...rest
    } = props;
    const { key, tabIndex } = useMenuSelectionState();
    const { theme } = useTheme();
    const menuRole = useMenuRole();
    const isMenuRole = menuRole === MenuRole.Menu;
    const role = isMenuRole ? "menuitem" : undefined;

    const label = (
      <MenuLabel
        title={children}
        description={description}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
      />
    );

    return (
      <MenuLI>
        <ClassNames>
          {({ css, cx }) => {
            const className = cx(
              css(menuButtonStyle(theme, { accent })),
              props.className
            );

            const forwardedProps = {
              ...rest,
              className,
              children: label,
              role,
              tabIndex: isMenuRole ? tabIndex : undefined,
              [menuKeyProp]: isMenuRole ? key : undefined,
            };

            return render ? (
              render(forwardedProps)
            ) : (
              <a ref={ref} {...forwardedProps}>
                {forwardedProps.children}
              </a>
            );
          }}
        </ClassNames>
      </MenuLI>
    );
  }
);

export default MenuLink;
