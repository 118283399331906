import {
  CreditorSelfResponseBody,
  CreditorsVerificationStatus,
} from "@gocardless/api/dashboard/types";

import { useGetPrimaryCreditorResponse } from "src/libraries/creditor/hooks";

interface UseVerificationStatus {
  loaded: boolean;
  status: CreditorsVerificationStatus;
  collectionsPermitted: boolean;
  mutate: () => Promise<CreditorSelfResponseBody | undefined>;
}

export const useVerificationStatus = (): UseVerificationStatus => {
  const { data: creditorResponse, mutate } = useGetPrimaryCreditorResponse();

  const creditor = creditorResponse?.creditors;

  const status =
    creditor?.gc_sas_verifications_enabled &&
    creditor.gc_sas_remediation_outstanding_verifications
      ? CreditorsVerificationStatus.ActionRequired
      : creditor?.verification_status ?? CreditorsVerificationStatus.InReview;

  const loaded = !!creditor;
  return {
    loaded,
    status,
    collectionsPermitted: creditor?.collections_permitted ?? false,
    mutate,
  };
};
