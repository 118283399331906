import * as React from "react";

import { FormFieldStatus } from "../status";
import Input from "../Input";
import Hint from "../Hint";
import { ButtonVariant, IconButton, ButtonGutter } from "../../buttons";
import Button from "../../buttons/Button";
import { Glyph } from "../../icons";
import Box from "../../layout/Box";
import {
  AlignItems,
  ButtonSize,
  ColorPreset,
  ColorScheme,
  TextAlign,
  useTheme,
} from "../../theme";
import { P } from "../../typography";
import Space from "../../layout/Space";
import type { IconButtonStyleProps } from "../../buttons/types";

import { wrapperStyle, uploadStyle } from "./styles";
import { UploadSelectionMode, UploadStatus } from "./types";

import type { UploadProps } from ".";

const Upload = React.forwardRef<HTMLDivElement, UploadProps>((props, ref) => {
  const { theme } = useTheme();
  const {
    colorScheme,
    buttonLayout,
    accept,
    description,
    selectionMode,
    status,
    errors,
    translations,
    onSelectFiles,
    uploadDescribeby,
    ...rest
  } = props;

  const emptyFile = new File([], "");
  const [selectedFiles, setSelectedFiles] = React.useState<Array<File>>([
    emptyFile,
  ]);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files =
      event.currentTarget.files && event.currentTarget.files[0]
        ? Array.from(event.currentTarget.files)
        : [];

    onChangeFile(files);
  };

  const removeFile = (file: File) => {
    const files = selectedFiles.filter((x) => x !== file);
    setSelectedFiles(files);
    onSelectFiles(files);
  };

  const onChangeFile = (files: Array<File>) => {
    const newFiles = files.filter(
      ({ name, lastModified }) =>
        !selectedFiles.some(
          ({ name: selectedName, lastModified: selectedLastModified }) =>
            name === selectedName && lastModified === selectedLastModified
        )
    );

    let updatedFiles: File[];
    if (isMultiple) {
      updatedFiles = isFileSelected ? selectedFiles.concat(newFiles) : newFiles;
    } else {
      updatedFiles = files;
    }

    setSelectedFiles(updatedFiles);
    onSelectFiles(updatedFiles);
  };

  const isFileSelected =
    selectedFiles.length > 0 &&
    selectedFiles[0]?.size != null &&
    selectedFiles[0]?.size > 0;

  React.useEffect(() => {
    if (status === UploadStatus.Initial) {
      setSelectedFiles([emptyFile]);
    }
  }, [status]);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  let color: ColorPreset;
  let descriptionColor: ColorPreset;
  let uploadFileButtonVariant: ButtonVariant;
  let removeFileButtonVariant: IconButtonStyleProps["variant"];
  const isDisabledStatus = status === UploadStatus.Disabled;
  const isUploading = status === UploadStatus.Uploading;
  const isDisabledColours =
    status === UploadStatus.Disabled || status === UploadStatus.Uploading;
  const isMultiple = selectionMode === UploadSelectionMode.Multiple;

  switch (colorScheme) {
    case ColorScheme.OnLight:
      color = isDisabledColours
        ? ColorPreset.TextOnLight_03
        : ColorPreset.TextOnLight_01;
      descriptionColor = ColorPreset.TextOnLight_03;
      uploadFileButtonVariant = ButtonVariant.TextOnLight;
      removeFileButtonVariant = ButtonVariant.TextOnLight;
      break;
    case ColorScheme.OnDark:
      color = isDisabledColours
        ? ColorPreset.TextOnDark_01
        : ColorPreset.TextOnDark_01;
      descriptionColor = ColorPreset.TextOnDark_01;
      uploadFileButtonVariant = ButtonVariant.TextOnDark;
      removeFileButtonVariant = ButtonVariant.TextOnDark;
      break;
    default:
      color = ColorPreset.TextOnDark_01;
      descriptionColor = ColorPreset.TextOnDark_01;
      uploadFileButtonVariant = ButtonVariant.TextAuto;
      removeFileButtonVariant = ButtonVariant.TextOnLight;
  }

  const chooseFileLabel = `${
    translations?.chooseFile || (isMultiple ? "Choose files" : "Choose file")
  }`;

  const chooseButtonLabel = `${
    isUploading ? translations?.uploadingLabel || "Uploading" : chooseFileLabel
  }`;

  return (
    <div ref={ref}>
      <Box
        layout="flex"
        flexDirection="column"
        alignItems={AlignItems.Center}
        css={uploadStyle(theme, props)}
        gutterV={1.5}
        gutterH={2}
        spaceBelow={1}
      >
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Button
            disabled={isDisabledStatus || isUploading}
            rightIcon={
              status === UploadStatus.Uploading
                ? Glyph.Spinner
                : Glyph.PaperClip
            }
            variant={uploadFileButtonVariant}
            onClick={onClick}
            size={ButtonSize.Sm}
            aria-label={chooseButtonLabel}
            aria-describedby={uploadDescribeby}
          >
            {chooseButtonLabel}
          </Button>
        </Box>
        {description && (
          <Box spaceAbove={0.75}>
            <P color={descriptionColor} size={2} textAlign={TextAlign.Center}>
              {description}
            </P>
          </Box>
        )}
        <Box css={wrapperStyle}>
          <Input
            type="file"
            multiple={isMultiple}
            onChange={onChangeInput}
            {...rest}
            ref={inputRef}
            accept={accept}
            tabIndex={-1}
          />
        </Box>
      </Box>
      {isFileSelected && (
        <Box spaceAbove={1.5}>
          {selectedFiles.map((file, index) => (
            <Box
              key={`${index}_${file.name}`}
              layout="flex"
              flexDirection="row"
              spaceBelow={index === selectedFiles.length - 1 ? 0 : 0.5}
            >
              <IconButton
                label={
                  translations?.removeFile
                    ? `${translations?.removeFile} ${file.name}`
                    : "Remove file"
                }
                size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
                variant={removeFileButtonVariant}
                icon={Glyph.Close}
                onClick={() => removeFile(file)}
              />
              <Space h={0.75} layout="inline" />
              <P color={color} size={2}>
                {file.name}
              </P>
            </Box>
          ))}
        </Box>
      )}
      {errors &&
        errors.map((error) => (
          <Box spaceBelow={1} key={error}>
            <Hint status={FormFieldStatus.Danger}>{error}</Hint>
          </Box>
        ))}
    </div>
  );
});

export default Upload;
