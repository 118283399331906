import { CSSRulesFunction, useTheme, TypeScale } from "../theme";
import { tableCellStyle } from "../tables/TableCell";
import { tableHeadCellStyle } from "../tables/TableHeadCell";
import { tableBodyStyle } from "../tables/TableBody";
import { tableHeadStyle } from "../tables/TableHead";
import { tableContainerStyle } from "../tables/TableContainer";
import { plainButtonStyle } from "../buttons/plain";

import { typographyStyle } from "./appearance";

type HTMLAttributes = Omit<React.HTMLAttributes<HTMLElement>, "children">;

type ArbitraryHTMLProps = {
  /**
   * An HTML string that will be injected using dangerouslySetInnerHTML
   */
  html: string;
  children?: never;
};

type ReactHTMLProps = {
  /**
   * An HTML string that will be injected using dangerouslySetInnerHTML
   */
  html?: never;
  children: React.ReactNode;
};

export type HTMLContentProps = HTMLAttributes &
  (ArbitraryHTMLProps | ReactHTMLProps);

const style: CSSRulesFunction = (theme) => [
  {
    p: typographyStyle(theme, {
      size: [TypeScale.Size_04, null, TypeScale.Size_05],
      spaceBelow: [1.5, null, 2],
      fontFamily: "serif",
    }),
    h1: typographyStyle(theme, {
      spaceAbove: 1,
      spaceBelow: 1.5,
    }),
    h2: typographyStyle(theme, {
      size: [TypeScale.Size_06, null, TypeScale.Size_08],
      spaceAbove: 1,
      spaceBelow: 1.5,
    }),
    h3: typographyStyle(theme, {
      size: [TypeScale.Size_05, null, TypeScale.Size_07],
      spaceAbove: 1,
      spaceBelow: 1,
    }),
    h4: typographyStyle(theme, {
      size: [TypeScale.Size_04, null, TypeScale.Size_06],
      spaceAbove: 1,
      spaceBelow: 1,
    }),
    h5: typographyStyle(theme, {
      size: [TypeScale.Size_03, null, TypeScale.Size_05],
      spaceAbove: 1,
      spaceBelow: 1,
    }),
    h6: typographyStyle(theme, {
      size: [TypeScale.Size_02, null, TypeScale.Size_04],
      spaceAbove: 1,
      spaceBelow: 1,
    }),
    a: plainButtonStyle(theme, {
      textDecoration: "underline",
    }),
    ol: {
      paddingLeft: theme.spacing(1.5),
      margin: theme.spacing([1.5, 0, 2]),
      fontFamily: theme.tokens.fontFamilies["serif"],
      ...theme.tokens.fontSizes[TypeScale.Size_05],
    },
    ul: {
      paddingLeft: theme.spacing(1.5),
      margin: theme.spacing([1.5, 0, 2]),
      fontFamily: theme.tokens.fontFamilies["serif"],
      ...theme.tokens.fontSizes[TypeScale.Size_05],
    },
    img: {
      width: "100%",
    },
    table: tableContainerStyle(theme, { layout: "fullWidth" }),
    thead: tableHeadStyle,
    tbody: tableBodyStyle,
    th: tableHeadCellStyle(theme, {
      fontFamily: "serif",
      gutterV: 0.25,
      gutterH: 1,
    }),
    td: tableCellStyle(theme, {
      fontFamily: "serif",
      gutterV: 0.25,
      gutterH: 1,
    }),
    "th > :last-child, td > :last-child": { marginBottom: 0 },
    "th p, th ol, th ul, td p, td ol, td ul": {
      fontSize: "inherit",
      fontFamily: "inherit",
    },
    "> table img": {
      maxWidth: "100%",
    },

    ".gatsby-highlight": {
      marginBottom: theme.spacing(2),
    },
    ".wistia_responsive_padding": {
      marginBottom: theme.spacing(4),
      width: "100%",
    },
    ".youtube-embed-responsive-padding": {
      marginBottom: theme.spacing(4),
      paddingBottom: "56.25%",
      position: "relative",
    },
    ".youtube-embed-responsive-container": {
      height: "100%",
      position: "absolute",
      width: "100%",
    },
  },
];

/**
 * HTMLBody takes arbitrary HTML either as a raw string or React elements and
 * renders it using the Flux design system styles. This useful when working
 * with Markdown or rich text that is converted to an HTML string and then
 * passed to React using `dangerouslySetInnerHTML`.
 *
 * @example
 * // Using an arbitrary HTML string
 * function Demo() {
 *   return <HTMLContent html="<div><p>Hello World</p></div>" />;
 * }
 *
 * @example
 * // Using unstyled HTML elements
 * function Demo() {
 *   return (
 *     <HTMLContent>
 *       <div>
 *         <p>Hello World</p>
 *       </div>
 *     </HTMLContent>
 *   );
 * }
 */
const HTMLContent: React.FC<HTMLContentProps> = ({
  html,
  children,
  ...rest
}) => {
  const { theme } = useTheme();
  const sharedProps = {
    "data-reading-optimized": true,
    css: style(theme),
    ...rest,
  };

  return typeof html === "string" ? (
    <div {...sharedProps} dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <div {...sharedProps}>{children}</div>
  );
};

export default HTMLContent;
