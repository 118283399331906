import * as React from "react";

import {
  Theme,
  useTheme,
  CSSRulesFunction,
  Interpolation,
  ResponsiveValue,
  SpaceScale,
} from "../theme";

type HTMLProps = React.HTMLAttributes<HTMLDivElement>;

export interface ContainerProps extends HTMLProps {
  maxContentWidth?: ResponsiveValue<string>;
  contentGutters?: ResponsiveValue<SpaceScale>;
}

const maxWidthStyle: CSSRulesFunction<ContainerProps> = (
  theme: Theme,
  { maxContentWidth = theme.tokens.contentMaxWidth }
) =>
  theme.responsive(maxContentWidth, (value) => ({
    maxWidth: value,
  }));

const gutterStyle: CSSRulesFunction<ContainerProps> = (
  theme,
  { contentGutters = theme.tokens.contentGutters }
) =>
  theme.responsive(contentGutters, (value, themeValue) => ({
    padding: themeValue.spacing([0, value]),
  }));

const baseStyle: Interpolation = {
  width: "100%",
  marginRight: "auto",
  marginLeft: "auto",
};

export const containerStyle: CSSRulesFunction<ContainerProps> = (
  theme,
  props
) => [baseStyle, maxWidthStyle(theme, props), gutterStyle(theme, props)];

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  function Container(props, ref) {
    const { theme } = useTheme();
    const { maxContentWidth, contentGutters, ...rest } = props;
    return <div css={containerStyle(theme, props)} {...rest} ref={ref} />;
  }
);

export default Container;
