import {
  Color,
  ColorPreset,
  CSSRulesFunction,
  FontWeight,
  TypeScale,
  ColorScheme,
} from "../theme";

import { TagSize, TagStyleProps, TagColor, TagVariant } from "./types";

const vars = {
  bg: "--tag-bg",
  color: "--tag-color",
  fontSize: "--tag-font-size",
  lineHeight: "--tag-line-height",
  gutterH: "--tag-gutterh",
  gutterV: "--tag-gutterv",
};

type TagTheme = `${TagColor}-${ColorScheme}`;

const tagSolidThemeTokens: CSSRulesFunction<TagTheme> = (theme, tagTheme) => {
  const primaryOnLightBg = theme.color(ColorPreset.BackgroundDark_01);
  const primaryOnLightColor = theme.color(ColorPreset.TextOnDark_01);
  const primaryOnDarkBg = theme.color(ColorPreset.BackgroundLight_00);
  const primaryOnDarkColor = theme.color(ColorPreset.TextOnLight_01);

  const neutralOnLightBg = theme.color(Color.Greystone_1100);
  const neutralOnLightColor = theme.color(Color.White);
  const neutralOnDarkBg = theme.color(Color.Greystone_500);
  const neutralOnDarkColor = theme.color(Color.Greystone_DarkMatter);

  const successOnLightBg = theme.color(Color.Success_400);
  const successOnLightColor = theme.color(Color.White);
  const successOnDarkBg = theme.color(Color.Success_50);
  const successOnDarkColor = theme.color(Color.Success_500);

  const alertOnLightBg = theme.color(Color.Alert_400);
  const alertOnLightColor = theme.color(Color.White);
  const alertOnDarkBg = theme.color(Color.Alert_50);
  const alertOnDarkColor = theme.color(Color.Alert_500);

  const warningOnLightBg = theme.color(Color.Warning_400);
  const warningOnLightColor = theme.color(Color.White);
  const warningOnDarkBg = theme.color(Color.Warning_50);
  const warningOnDarkColor = theme.color(Color.Warning_500);

  const infoOnLightBg = theme.color(Color.Info_400);
  const infoOnLightColor = theme.color(Color.White);
  const infoOnDarkBg = theme.color(Color.Info_50);
  const infoOnDarkColor = theme.color(Color.Info_500);

  switch (tagTheme) {
    case "primary-on-light":
      return {
        [vars.bg]: primaryOnLightBg,
        [vars.color]: primaryOnLightColor,
      };
    case "primary-on-dark":
      return {
        [vars.bg]: primaryOnDarkBg,
        [vars.color]: primaryOnDarkColor,
      };
    case "primary-auto":
      return {
        [vars.bg]: primaryOnLightBg,
        [vars.color]: primaryOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: primaryOnDarkBg,
          [vars.color]: primaryOnDarkColor,
        },
      };

    case "neutral-on-light":
      return {
        [vars.bg]: neutralOnLightBg,
        [vars.color]: neutralOnLightColor,
      };
    case "neutral-on-dark":
      return {
        [vars.bg]: neutralOnDarkBg,
        [vars.color]: neutralOnDarkColor,
      };
    case "neutral-auto":
      return {
        [vars.bg]: neutralOnLightBg,
        [vars.color]: neutralOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: neutralOnDarkBg,
          [vars.color]: neutralOnDarkColor,
        },
      };

    case "success-on-light":
      return {
        [vars.bg]: successOnLightBg,
        [vars.color]: successOnLightColor,
      };
    case "success-on-dark":
      return {
        [vars.bg]: successOnDarkBg,
        [vars.color]: successOnDarkColor,
      };
    case "success-auto":
      return {
        [vars.bg]: successOnLightBg,
        [vars.color]: successOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: successOnDarkBg,
          [vars.color]: successOnDarkColor,
        },
      };

    case "alert-on-light":
      return {
        [vars.bg]: alertOnLightBg,
        [vars.color]: alertOnLightColor,
      };
    case "alert-on-dark":
      return {
        [vars.bg]: alertOnDarkBg,
        [vars.color]: alertOnDarkColor,
      };
    case "alert-auto":
      return {
        [vars.bg]: alertOnLightBg,
        [vars.color]: alertOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: alertOnDarkBg,
          [vars.color]: alertOnDarkColor,
        },
      };

    case "warning-on-light":
      return {
        [vars.bg]: warningOnLightBg,
        [vars.color]: warningOnLightColor,
      };
    case "warning-on-dark":
      return {
        [vars.bg]: warningOnDarkBg,
        [vars.color]: warningOnDarkColor,
      };
    case "warning-auto":
      return {
        [vars.bg]: warningOnLightBg,
        [vars.color]: warningOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: warningOnDarkBg,
          [vars.color]: warningOnDarkColor,
        },
      };

    case "info-on-light":
      return {
        [vars.bg]: infoOnLightBg,
        [vars.color]: infoOnLightColor,
      };
    case "info-on-dark":
      return {
        [vars.bg]: infoOnDarkBg,
        [vars.color]: infoOnDarkColor,
      };
    case "info-auto":
      return {
        [vars.bg]: infoOnLightBg,
        [vars.color]: infoOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: infoOnDarkBg,
          [vars.color]: infoOnDarkColor,
        },
      };
  }
};

const tagTintedThemeTokens: CSSRulesFunction<TagTheme> = (theme, tagTheme) => {
  const primaryOnLightBg = theme.color(Color.Brownstone_Base);
  const primaryOnLightColor = theme.color(Color.Greystone_DarkMatter);
  const primaryOnDarkBg = theme.color(Color.White_A25);
  const primaryOnDarkColor = theme.color(Color.Dawn_Base);

  const neutralOnLightBg = theme.color(Color.Greystone_500);
  const neutralOnLightColor = theme.color(Color.Greystone_1200);
  const neutralOnDarkBg = theme.color(Color.White_A25);
  const neutralOnDarkColor = theme.color(Color.Brownstone_Moonstone);

  const successOnLightBg = theme.color(Color.Success_50);
  const successOnLightColor = theme.color(Color.Success_500);
  const successOnDarkBg = theme.color(Color.Success_50_A25);
  const successOnDarkColor = theme.color(Color.Success_50);

  const alertOnLightBg = theme.color(Color.Alert_50);
  const alertOnLightColor = theme.color(Color.Alert_500);
  const alertOnDarkBg = theme.color(Color.Alert_50_A25);
  const alertOnDarkColor = theme.color(Color.Alert_50);

  const warningOnLightBg = theme.color(Color.Warning_50);
  const warningOnLightColor = theme.color(Color.Warning_500);
  const warningOnDarkBg = theme.color(Color.Warning_50_A25);
  const warningOnDarkColor = theme.color(Color.Warning_50);

  const infoOnLightBg = theme.color(Color.Info_50);
  const infoOnLightColor = theme.color(Color.Info_500);
  const infoOnDarkBg = theme.color(Color.Info_50_A25);
  const infoOnDarkColor = theme.color(Color.Info_50);

  switch (tagTheme) {
    case "primary-on-light":
      return {
        [vars.bg]: primaryOnLightBg,
        [vars.color]: primaryOnLightColor,
      };
    case "primary-on-dark":
      return {
        [vars.bg]: primaryOnDarkBg,
        [vars.color]: primaryOnDarkColor,
      };
    case "primary-auto":
      return {
        [vars.bg]: primaryOnLightBg,
        [vars.color]: primaryOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: primaryOnDarkBg,
          [vars.color]: primaryOnDarkColor,
        },
      };

    case "neutral-on-light":
      return {
        [vars.bg]: neutralOnLightBg,
        [vars.color]: neutralOnLightColor,
      };
    case "neutral-on-dark":
      return {
        [vars.bg]: neutralOnDarkBg,
        [vars.color]: neutralOnDarkColor,
      };
    case "neutral-auto":
      return {
        [vars.bg]: neutralOnLightBg,
        [vars.color]: neutralOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: neutralOnDarkBg,
          [vars.color]: neutralOnDarkColor,
        },
      };

    case "success-on-light":
      return {
        [vars.bg]: successOnLightBg,
        [vars.color]: successOnLightColor,
      };
    case "success-on-dark":
      return {
        [vars.bg]: successOnDarkBg,
        [vars.color]: successOnDarkColor,
      };
    case "success-auto":
      return {
        [vars.bg]: successOnLightBg,
        [vars.color]: successOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: successOnDarkBg,
          [vars.color]: successOnDarkColor,
        },
      };

    case "alert-on-light":
      return {
        [vars.bg]: alertOnLightBg,
        [vars.color]: alertOnLightColor,
      };
    case "alert-on-dark":
      return {
        [vars.bg]: alertOnDarkBg,
        [vars.color]: alertOnDarkColor,
      };
    case "alert-auto":
      return {
        [vars.bg]: alertOnLightBg,
        [vars.color]: alertOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: alertOnDarkBg,
          [vars.color]: alertOnDarkColor,
        },
      };

    case "warning-on-light":
      return {
        [vars.bg]: warningOnLightBg,
        [vars.color]: warningOnLightColor,
      };
    case "warning-on-dark":
      return {
        [vars.bg]: warningOnDarkBg,
        [vars.color]: warningOnDarkColor,
      };
    case "warning-auto":
      return {
        [vars.bg]: warningOnLightBg,
        [vars.color]: warningOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: warningOnDarkBg,
          [vars.color]: warningOnDarkColor,
        },
      };

    case "info-on-light":
      return {
        [vars.bg]: infoOnLightBg,
        [vars.color]: infoOnLightColor,
      };
    case "info-on-dark":
      return {
        [vars.bg]: infoOnDarkBg,
        [vars.color]: infoOnDarkColor,
      };
    case "info-auto":
      return {
        [vars.bg]: infoOnLightBg,
        [vars.color]: infoOnLightColor,
        "@media (prefers-color-scheme: dark)": {
          [vars.bg]: infoOnDarkBg,
          [vars.color]: infoOnDarkColor,
        },
      };
  }
};

const tagSizeTokens: CSSRulesFunction<TagSize> = (theme, size) => {
  const smSize = theme.tokens.fontSizes[TypeScale.Size_01];
  const mdSize = theme.tokens.fontSizes[TypeScale.Size_02];
  const lgSize = theme.tokens.fontSizes[TypeScale.Size_03];

  switch (size) {
    case TagSize.Xs:
      return {
        [vars.fontSize]: smSize.fontSize,
        [vars.lineHeight]: smSize.lineHeight,
        [vars.gutterH]: theme.spacing(0.25),
        [vars.gutterV]: theme.spacing(0),
      };
    case TagSize.Sm:
      return {
        [vars.fontSize]: smSize.fontSize,
        [vars.lineHeight]: smSize.lineHeight,
        [vars.gutterH]: theme.spacing(0.5),
        [vars.gutterV]: theme.spacing(0),
      };
    case TagSize.Md:
      return {
        [vars.fontSize]: mdSize.fontSize,
        [vars.lineHeight]: mdSize.lineHeight,
        [vars.gutterH]: theme.spacing(0.5),
        [vars.gutterV]: theme.spacing(0),
      };
    case TagSize.Lg:
      return {
        [vars.fontSize]: lgSize.fontSize,
        [vars.lineHeight]: lgSize.lineHeight,
        [vars.gutterH]: theme.spacing(1),
        [vars.gutterV]: theme.spacing(0.25),
      };
  }
};

export const tagStyle: CSSRulesFunction<TagStyleProps> = (theme, props) => {
  const tagTheme: TagTheme = `${props.color}-${props.colorScheme}`;
  return [
    props.variant === TagVariant.Tinted
      ? tagTintedThemeTokens(theme, tagTheme)
      : tagSolidThemeTokens(theme, tagTheme),
    theme.responsive(props.size, (size) => tagSizeTokens(theme, size)),
    {
      display: "inline-grid",
      justifyItems: "center",
      gridAutoColumns: "auto",
      gridAutoFlow: "column",
      gap: theme.spacing(0.25),
      padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
      alignItems: "center",
      color: `var(${vars.color})`,
      backgroundColor: `var(${vars.bg})`,
      borderRadius: theme.radius(0.25),
      fontSize: `var(${vars.fontSize})`,
      lineHeight: `var(${vars.lineHeight})`,
      fontFamily: theme.tokens.fontFamilies[props.fontFamily],
      fontWeight:
        props.fontFamily === "monospace"
          ? FontWeight.Bold
          : FontWeight.SemiBold,
    },
  ];
};
