import { useControlledState } from "@react-stately/utils";
import React from "react";

import { VisuallyHidden } from "../../a11y";
import { TypeScale, type Interpolation, useTheme } from "../../theme";

import { dateInputSegmentStyle } from "./dateInputStyle";

type DateInputSegmentMode = "day" | "month" | "year";

export interface DateInputSegmentProps {
  id?: string;
  mode: DateInputSegmentMode;
  value?: string;
  defaultValue?: string;
  onChange?: (newValue: string | undefined) => void;
  onFinished?: () => void;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  compact?: boolean;
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
  "aria-invalid"?: boolean;
}

const noop = () => {};

const ch4: Interpolation = { width: "4ch" };
const ch6: Interpolation = { width: "6ch" };

const dayRE = /^[0-9]{0,2}$/;
const monthRE = /^[0-9]{0,2}$/;
const yearRE = /^[0-9]{0,4}$/;

const reForMode = (mode: DateInputSegmentMode) => {
  switch (mode) {
    case "day":
      return dayRE;
    case "month":
      return monthRE;
    case "year":
      return yearRE;
  }
};

const DateInputSegment = React.forwardRef<
  HTMLInputElement,
  DateInputSegmentProps
>((props, ref) => {
  const { theme } = useTheme();
  const genid = React.useId();
  const id = props.id || genid;
  const { onFinished } = props;
  const inValue = props.value;
  const inDefault = props.defaultValue || "";
  const onChange = props.onChange || noop;

  const [value, setValue] = useControlledState(inValue, inDefault, onChange);

  const modeRE = reForMode(props.mode);
  const internalLabelId = `${genid}-label`;

  return (
    <div>
      <VisuallyHidden>
        <label id={internalLabelId} htmlFor={id}>
          {props.label}
        </label>
      </VisuallyHidden>
      <input
        id={id}
        ref={ref}
        css={[
          dateInputSegmentStyle,
          props.mode === "year" ? ch6 : ch4,
          props.compact
            ? { fontSize: theme.tokens.fontSizes[TypeScale.Size_02].fontSize }
            : {},
        ]}
        disabled={props.disabled}
        type="text"
        inputMode="numeric"
        data-name={props.mode}
        data-focustarget
        value={value}
        aria-labelledby={`${
          props["aria-labelledby"] || ""
        } ${internalLabelId} ${id}`}
        aria-invalid={props["aria-invalid"]}
        aria-describedby={props["aria-describedby"]}
        placeholder={props.placeholder}
        onChange={(e) => {
          const val = e.target.value;
          if (!modeRE.test(val)) {
            e.preventDefault();
            e.stopPropagation();
            onFinished?.();
            return;
          }

          setValue(val);

          // Check if we have entered the last character by adding another
          // character and seeing if it breaks the regex checks
          if (!modeRE.test(`${val}0`)) {
            onFinished?.();
          }
        }}
      />
    </div>
  );
});

export default DateInputSegment;
