import { CSSRulesFunction, Color } from "../theme";

import { ButtonVariant } from "./types";

export const secondaryTokens: CSSRulesFunction = (theme) => {
  const vl = ButtonVariant.SecondaryOnLight;
  const vd = ButtonVariant.SecondaryOnDark;
  const va = ButtonVariant.SecondaryAuto;

  return {
    [`--btn-${vl}-bg-default`]: theme.color(Color.Transparent),
    [`--btn-${vl}-fg-default`]: theme.color(Color.Greystone_DarkMatter),
    [`--btn-${vl}-border-default`]: theme.color(Color.Greystone_DarkMatter),
    [`--btn-${vl}-bg-hover`]: theme.color(Color.Transparent),
    [`--btn-${vl}-fg-hover`]: theme.color(Color.Greystone_1300),
    [`--btn-${vl}-border-hover`]: theme.color(Color.Greystone_1300),
    [`--btn-${vl}-bg-disabled`]: theme.color(Color.Transparent),
    [`--btn-${vl}-fg-disabled`]: theme.color(Color.Greystone_1400_A38),
    [`--btn-${vl}-border-disabled`]: theme.color(Color.Greystone_1400_A8),

    [`--btn-${vd}-bg-default`]: theme.color(Color.Transparent),
    [`--btn-${vd}-fg-default`]: theme.color(Color.Brownstone_50),
    [`--btn-${vd}-border-default`]: theme.color(Color.Brownstone_50),
    [`--btn-${vd}-bg-hover`]: theme.color(Color.Transparent),
    [`--btn-${vd}-fg-hover`]: theme.color(Color.Brownstone_400),
    [`--btn-${vd}-border-hover`]: theme.color(Color.Brownstone_400),
    [`--btn-${vd}-bg-disabled`]: theme.color(Color.Transparent),
    [`--btn-${vd}-fg-disabled`]: theme.color(Color.Greystone_700_A38),
    [`--btn-${vd}-border-disabled`]: theme.color(Color.Greystone_700_A16),

    [`--btn-${va}-bg-default`]: `var(--btn-${vl}-bg-default)`,
    [`--btn-${va}-fg-default`]: `var(--btn-${vl}-fg-default)`,
    [`--btn-${va}-border-default`]: `var(--btn-${vl}-border-default)`,
    [`--btn-${va}-bg-hover`]: `var(--btn-${vl}-bg-hover)`,
    [`--btn-${va}-fg-hover`]: `var(--btn-${vl}-fg-hover)`,
    [`--btn-${va}-border-hover`]: `var(--btn-${vl}-border-hover)`,
    [`--btn-${va}-bg-disabled`]: `var(--btn-${vl}-bg-disabled)`,
    [`--btn-${va}-fg-disabled`]: `var(--btn-${vl}-fg-disabled)`,
    [`--btn-${va}-border-disabled`]: `var(--btn-${vl}-border-disabled)`,

    "@media (prefers-color-scheme: dark)": {
      [`--btn-${va}-bg-default`]: `var(--btn-${vd}-bg-default)`,
      [`--btn-${va}-fg-default`]: `var(--btn-${vd}-fg-default)`,
      [`--btn-${va}-border-default`]: `var(--btn-${vd}-border-default)`,
      [`--btn-${va}-bg-hover`]: `var(--btn-${vd}-bg-hover)`,
      [`--btn-${va}-fg-hover`]: `var(--btn-${vd}-fg-hover)`,
      [`--btn-${va}-border-hover`]: `var(--btn-${vd}-border-hover)`,
      [`--btn-${va}-bg-disabled`]: `var(--btn-${vd}-bg-disabled)`,
      [`--btn-${va}-fg-disabled`]: `var(--btn-${vd}-fg-disabled)`,
      [`--btn-${va}-border-disabled`]: `var(--btn-${vd}-border-disabled)`,
    },
  };
};
