import { Button, ButtonLayout, ButtonVariant } from "../../buttons";
import { ButtonSize, useTheme } from "../../theme";

import { calendarFooterButtonStyle } from "./calendarRangeStyles";
import type { CalendarDate } from "./dateHelpers";
import type { DateRangeI18n } from "./i18n";

interface CalendarRangeFooterProps {
  onChange?(d: CalendarDate[] | undefined): void;
  setFromDate: React.Dispatch<
    React.SetStateAction<Partial<CalendarDate> | undefined>
  >;
  setToDate: React.Dispatch<
    React.SetStateAction<Partial<CalendarDate> | undefined>
  >;
  validFromDate: CalendarDate;
  validToDate: CalendarDate;
  i18n: DateRangeI18n;
  canApply: boolean | null;
  onClose?(): void;
}

const CalendarRangeFooter = ({
  onChange,
  setFromDate,
  setToDate,
  validFromDate,
  validToDate,
  i18n,
  canApply,
  onClose,
}: CalendarRangeFooterProps) => {
  const { theme } = useTheme();
  return (
    <div css={calendarFooterButtonStyle(theme)}>
      <Button
        variant={ButtonVariant.TextOnLight}
        layout={ButtonLayout.Inline}
        onClick={() => {
          onChange && onChange([]);
          setFromDate(undefined);
          setToDate(undefined);
        }}
        size={ButtonSize.Sm}
        css={{ marginRight: theme.spacing(0.5) }}
      >
        {i18n.clearButtonLabel || "Clear"}
      </Button>
      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={ButtonLayout.Inline}
        disabled={!canApply}
        onClick={() => {
          if (onChange && canApply) {
            onChange([validFromDate, validToDate]);
            onClose && onClose();
          }
        }}
        size={ButtonSize.Sm}
      >
        {i18n.applyButtonLabel || "Apply"}
      </Button>
    </div>
  );
};

export default CalendarRangeFooter;
