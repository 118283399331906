import type {
  ButtonProps,
  IconButtonProps,
  IconLinkProps,
  LinkProps,
  PlainButtonProps,
  PlainLinkProps,
} from "../buttons";
import type { ColorProp } from "../theme";

export enum CardLayout {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum CardVariant {
  Default = "default",
  Gradient = "gradient",
  Outlined = "outlined",
}

export enum CardType {
  Plain = "plain",
  Button = "button",
}

export enum CardButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Text = "text",
}

export enum CardActionType {
  Button = "button",
  IconButton = "iconButton",
  IconLink = "iconLink",
  Link = "link",
  PlainButton = "plainButton",
  PlainLink = "plainLink",
}

export enum CardGradient {
  Gradient01 = "gradient-01",
  Gradient02 = "gradient-02",
}

export interface GradientConfig {
  layout: CardLayout;
  degree: number;
  colors: Array<GradientStop>;
}

export interface GradientStop {
  color: ColorProp;
  stop: number;
}

type BaseActionProps<T, V extends CardButtonVariant = CardButtonVariant> = Omit<
  T,
  "variant"
> & {
  buttonVariant?: V;
};

type ButtonAction<V extends CardButtonVariant = CardButtonVariant> = {
  control: CardActionType.Button;
} & BaseActionProps<ButtonProps, V>;

type IconButtonAction<V extends CardButtonVariant = CardButtonVariant> = {
  control: CardActionType.IconButton;
} & BaseActionProps<IconButtonProps, V>;

type IconLinkAction<V extends CardButtonVariant = CardButtonVariant> = {
  control: CardActionType.IconLink;
} & BaseActionProps<IconLinkProps, V>;

type LinkAction<V extends CardButtonVariant = CardButtonVariant> = {
  control: CardActionType.Link;
} & BaseActionProps<LinkProps, V>;

type PlainLinkAction = {
  control: CardActionType.PlainLink;
} & PlainLinkProps;

type PlainButtonAction = {
  control: CardActionType.PlainButton;
} & PlainButtonProps;

export type CardHeaderActionProps =
  | IconButtonAction<CardButtonVariant>
  | IconLinkAction<CardButtonVariant>;

export type CardActionProps =
  | ButtonAction<CardButtonVariant.Primary | CardButtonVariant.Secondary>
  | LinkAction<CardButtonVariant.Primary | CardButtonVariant.Secondary>
  | IconButtonAction<CardButtonVariant.Primary | CardButtonVariant.Secondary>
  | IconLinkAction<CardButtonVariant.Primary | CardButtonVariant.Secondary>
  | PlainButtonAction
  | PlainLinkAction;

export enum CardArea {
  Media = "media",
  Header = "header",
  HeaderAction = "headerAction",
  MainContent = "mainContent",
  FooterActions = "footerActions",
  Empty = ".",
}

// Rules for removing content for layouts:
// - Row: Deletes the entire row.
// - Area: Deletes only the specified area.
export interface AreaRemovalRules {
  row: Array<string>;
  area: Array<string>;
}
