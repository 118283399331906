import type React from "react";

import { ButtonGutter, ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { ButtonSize, ColorScheme } from "../theme";

import type { TooltipTriggerProps } from "./types";

interface ToolTriggerProps extends TooltipTriggerProps {
  colorScheme: ColorScheme;
  children: React.ReactNode;
}
const TooltipTrigger: React.FC<ToolTriggerProps> = (props) => {
  const { colorScheme, children, ...rest } = props;
  let buttonVariant = ButtonVariant.TextOnLight;
  switch (colorScheme) {
    case ColorScheme.OnLight:
      buttonVariant = ButtonVariant.TextOnLight;
      break;
    case ColorScheme.OnDark:
      buttonVariant = ButtonVariant.TextOnDark;
      break;
    case ColorScheme.Auto:
      buttonVariant = ButtonVariant.TextAuto;
      break;
  }

  return (
    <IconButton
      icon={Glyph.Tooltip}
      variant={buttonVariant}
      label={children}
      size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
      {...rest}
    />
  );
};

export default TooltipTrigger;
