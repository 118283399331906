import type * as React from "react";

import { ColorScheme } from "../theme";
import { Tag, TagColor, TagSize } from "../accessories";

import { BannerVariant } from "./types";

export interface BannerTagProps {
  variant: BannerVariant;
  text: string;
}

const BannerTag: React.FC<BannerTagProps> = (props) => {
  const { text, variant } = props;
  return (
    <Tag
      size={TagSize.Md}
      color={TagColor.Primary}
      colorScheme={
        variant === BannerVariant.Dark
          ? ColorScheme.OnDark
          : ColorScheme.OnLight
      }
    >
      {text}
    </Tag>
  );
};

export default BannerTag;
