import * as React from "react";

import {
  useTheme,
  ResponsiveValue,
  CSSRulesFunction,
  Interpolation,
  ColumnSize,
  ColorProp,
} from "../theme";

import { GridContext, GridSemantics } from "./Grid";

type HTMLProps = React.HTMLAttributes<HTMLDivElement>;

export interface ColumnProps extends HTMLProps {
  size?: ResponsiveValue<"auto" | ColumnSize>;
  offset?: ResponsiveValue<ColumnSize>;
  bg?: ResponsiveValue<ColorProp>;
}

const bgStyle: CSSRulesFunction<ColumnProps> = (theme, props) =>
  theme.responsive(props.bg, (bg) => ({
    backgroundColor: theme.color(bg),
  }));

const widthStyle: CSSRulesFunction<ResponsiveValue<"auto" | ColumnSize>> = (
  theme,
  size
) =>
  theme.responsive(size, (value) => {
    if (value === "auto") {
      return { flexGrow: 1, width: "auto", flexBasis: 0 };
    }
    if (value === 0) {
      return { display: "none" };
    }
    const width = `${(100 * value) / 12}%`;
    return {
      flexGrow: 0,
      minWidth: 0,
      display: "block",
      width: width,
      flexBasis: width,
    };
  });

const offsetStyle: CSSRulesFunction<ResponsiveValue<ColumnSize>> = (
  theme,
  size
) =>
  theme.responsive(size, (value) => {
    const width = `${(100 * value) / 12}%`;
    return {
      marginLeft: width,
    };
  });

const baseStyle: Interpolation = {
  flex: "0 1 0",
};

const columnStyle: CSSRulesFunction<ColumnProps> = (theme, props) => [
  baseStyle,
  widthStyle(theme, props.size || "auto"),
  props.offset ? offsetStyle(theme, props.offset) : null,
  bgStyle(theme, props),
];

const tags: Record<GridSemantics, React.ElementType> = {
  [GridSemantics.Default]: "div",
  [GridSemantics.OrderedList]: "li",
  [GridSemantics.UnorderedList]: "li",
};

const Column = React.forwardRef<HTMLElement, ColumnProps>(function Column(
  props,
  ref
) {
  const { theme } = useTheme();
  const { size, offset, bg, ...rest } = props;
  const { semantics } = React.useContext(GridContext);
  const Tag = tags[semantics];

  return (
    <Tag css={columnStyle(theme, props)} {...rest} ref={ref}>
      {props.children}
    </Tag>
  );
});

export default Column;
