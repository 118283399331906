/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { CONSENT_TYPE, ExecutableScript } from "./Scripts";
import { useTrackPath } from "./useTrackPath";

import { getConfig } from "src/common/config";
import { TranscendPurposes } from "src/technical-integrations/transcend";

const getRedditScript = (redditId: string) => `
!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${redditId}');
`;

export enum REDDIT_TRACKING_EVENT {
  PAGE_VIEW = "PageView",
  SIGNUP = "SignUp",
  BANK_ACCOUNT_ADDED = "AddToCart",
}

const REDDIT_NOT_LOADED = "window.rdt not available";

declare global {
  interface Window {
    rdt?: (eventType: string, eventName: string) => void;
  }
}

interface Props {
  consent: TranscendPurposes;
}

export const RedditScript = ({ consent }: Props) => {
  const reddit = getConfig().client?.externalMarketing?.reddit;

  const [hasInitialised, setHasInitialised] = useState(false);
  const { shouldTrackPath } = useTrackPath();

  const trackingAllowed =
    reddit?.id && consent.Advertising === true && shouldTrackPath();

  useEffect(() => {
    if (trackingAllowed && !hasInitialised) {
      setHasInitialised(true);
    }
  }, [trackingAllowed, hasInitialised]);

  return trackingAllowed && !hasInitialised ? (
    <ExecutableScript
      consentType={CONSENT_TYPE.ADVERTISING}
      executableScript={getRedditScript(reddit?.id)}
    />
  ) : null;
};

export const trackReddit = () => {
  const trackRedditPageView = () => {
    if (!window.rdt) {
      console.log(REDDIT_NOT_LOADED);
      return;
    }
    window.rdt("track", "PageVisit");
  };

  const trackRedditEvent = (event: REDDIT_TRACKING_EVENT) => {
    if (!window.rdt) {
      console.log(REDDIT_NOT_LOADED);
      return;
    }
    window.rdt("track", event);
  };

  return {
    trackRedditPageView,
    trackRedditEvent,
  };
};
