import { useEffect, useState } from "react";

import { ButtonSize } from "../theme";
import { ButtonVariant } from "../buttons";

import { AreaRemovalRules, CardArea, CardButtonVariant } from "./cardTypes";

const initialTemplateColumns = "1fr";
const NARROW_CARD_BREAKPOINT = 425;

export const defaultVerticalTemplateAreas = [
  `'${CardArea.Media} ${CardArea.Media}'
  '${CardArea.Header} ${CardArea.Header}'
  '${CardArea.MainContent} ${CardArea.MainContent}'
  '${CardArea.FooterActions} ${CardArea.FooterActions}'`,
];

export const defaultHorizontalTemplateAreas = [
  `'${CardArea.Media} ${CardArea.Header} ${CardArea.HeaderAction}'
  '${CardArea.Media} ${CardArea.MainContent} ${CardArea.Empty}'
  '${CardArea.Media} ${CardArea.MainContent} ${CardArea.Empty}'
  '${CardArea.Media} ${CardArea.FooterActions} ${CardArea.Empty}'`,
];

// Rules for removing content for layouts:
// - Row: Deletes the entire row.
// - Area: Deletes only the specified area.
export const verticalAreaRemovalRules: AreaRemovalRules = {
  row: [
    CardArea.Media,
    CardArea.Header,
    CardArea.MainContent,
    CardArea.FooterActions,
  ],
  area: [CardArea.HeaderAction],
};

export const horizontalAreaRemovalRules: AreaRemovalRules = {
  row: [CardArea.Header, CardArea.MainContent, CardArea.FooterActions],
  area: [CardArea.Media, CardArea.HeaderAction, CardArea.Empty],
};

export const generateVerticalTemplateColumns = (
  removedAreas: Array<string>
) => {
  if (!removedAreas.includes(CardArea.HeaderAction)) {
    return `minmax(48px, 1fr) auto`;
  } else {
    return initialTemplateColumns;
  }
};

export const generateHorizontalTemplateColumns = (
  removedAreas: Array<string>
): string => {
  let templateColumns = initialTemplateColumns;

  if (!removedAreas.includes(CardArea.Media)) {
    templateColumns = `minmax(48px, auto) ${templateColumns}`;
  }
  if (!removedAreas.includes(CardArea.HeaderAction)) {
    templateColumns = `${templateColumns} auto`;
  }

  return templateColumns;
};

/* Logic for generating grid areas:
   1. Include entire rows if they contain any included areas.
   2. Exclude specific areas which are not provided.
   3. Place the header action in the top-right area if it is provided.
*/
export const generateVerticalGridAreas = (removedAreas: Array<string>) => {
  const newAreas = createRows(
    defaultVerticalTemplateAreas,
    verticalAreaRemovalRules.row,
    removedAreas
  );
  removeAreas(newAreas, verticalAreaRemovalRules.area, removedAreas);
  addHeaderAction(newAreas, removedAreas);
  return newAreas.join("\n");
};

export const generateHorizontalGridAreas = (removedAreas: Array<string>) => {
  const newAreas = createRows(
    defaultHorizontalTemplateAreas,
    horizontalAreaRemovalRules.row,
    removedAreas
  );
  removeAreas(newAreas, horizontalAreaRemovalRules.area, removedAreas);
  addHeaderAction(newAreas, removedAreas);
  return newAreas.join("\n");
};

const createRows = (
  defaultAreas: Array<string>,
  rowRules: Array<string>,
  removedAreas: Array<string>
) => {
  const newRows: Array<string> = [];
  defaultAreas.forEach((area) => {
    area.split("\n").map((row) => {
      // Don't include the row if the rules require you to remove the whole row, based on the removedAreas
      if (
        !row
          .replace("'", "")
          .split(" ")
          .some((a) =>
            // Only consider removed areas that are included in the row rules
            removedAreas.filter((ra) => rowRules.includes(ra)).includes(a)
          )
      ) {
        newRows.push(row.trim());
      }
    });
  });

  return newRows;
};

export const removeAreas = (
  newAreas: Array<string>,
  defaultAreas: Array<string>,
  removedAreas: Array<string>
) => {
  newAreas.forEach((_item, index, array) => {
    removedAreas
      .filter((ra) => defaultAreas.includes(ra))
      .forEach((ar) => {
        const regex = new RegExp(`\\${ar}`, "g");

        //Removes the area and deletes the spaces in quotes
        const newItem = array[index]!.replace(regex, "")
          .replace(/'\s*(.*?)\s*'/, "'$1'")
          .trim();
        array[index] = newItem;
      });
  });
};

const addHeaderAction = (
  newAreas: Array<string>,
  removedAreas: Array<string>
) => {
  if (!removedAreas.includes(CardArea.HeaderAction) && newAreas.length > 0) {
    const row = newAreas[0]!;
    if (!row.includes(CardArea.HeaderAction)) {
      newAreas[0] = row
        .split(" ")
        .map((area, index, arr) =>
          index === arr.length - 1 ? `${CardArea.HeaderAction}'` : area
        )
        .join(" ");
    }
  }
};

export const useIsNarrowCard = (
  ref: React.RefObject<HTMLDivElement | HTMLButtonElement>
) => {
  const [isNarrow, setIsNarrow] = useState<boolean>(false);
  useEffect(() => {
    const observable = new ResizeObserver(([entries]) => {
      const observedWidth = (entries?.target as HTMLElement).offsetWidth;

      setIsNarrow(observedWidth < NARROW_CARD_BREAKPOINT);
    });
    if (ref.current) {
      observable.observe(ref.current);
    }
    return () => {
      observable.disconnect();
    };
  }, [ref, isNarrow]);
  return isNarrow;
};

export const sharedButtonProps: {
  size: ButtonSize;
} = {
  size: ButtonSize.Sm,
};

export const convertButtonVariant = (
  variant?: CardButtonVariant
): Exclude<
  ButtonVariant,
  ButtonVariant.Inline | ButtonVariant.InlineUnderlined
> => {
  switch (variant) {
    case CardButtonVariant.Primary:
      return ButtonVariant.PrimaryOnLight;

    case CardButtonVariant.Secondary:
      return ButtonVariant.SecondaryOnLight;

    case CardButtonVariant.Text:
      return ButtonVariant.TextOnLight;

    default:
      return ButtonVariant.PrimaryOnLight;
  }
};
