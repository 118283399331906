import { useState } from "react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import {
  defaultExport,
  ExportsLocalStorageCache,
} from "src/components/ui/export-centre/ExportCentreConnect";

export const STORAGE_EXPORT_KEY = (userId: string) => `gc.${userId}.exports`;

export const useExportsLocalStorage = (): [
  ExportsLocalStorageCache,
  (exportsCache: ExportsLocalStorageCache) => void,
] => {
  const { data: user } = useUserShowSelf();
  const storageKey = STORAGE_EXPORT_KEY(user?.users?.id ?? "");
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(storageKey);
    try {
      if (item) {
        const parsedStorage = JSON.parse(item);
        const exportCache: ExportsLocalStorageCache = {
          pendingExports: parsedStorage.pendingExports || [],
          failedExports: parsedStorage.failedExports || [],
          completedExports: parsedStorage.completedExports || [],
          isCentreOpen: parsedStorage.isCentreOpen || true,
          isShowingCentre: false,
        };
        return exportCache;
      }
      return defaultExport;
    } catch (err) {
      return defaultExport;
    }
  });

  const setValue = (value: ExportsLocalStorageCache) => {
    setStoredValue(value);
    localStorage.setItem(storageKey, JSON.stringify(value));
  };

  return [storedValue, setValue];
};
