import {
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FocusRing,
  Interpolation,
} from "../../theme";
import { focusRingVar } from "../../theme/cssvariables";
import { focusRingStyle } from "../../theme/focusRings";

import type { DynamicListProps } from "./types";

export const dynamicListItemStyle: CSSRulesFunction = (theme) => ({
  width: "100%",
  padding: `${theme.spacing(1)} 0px`,
  borderTop: "1px solid",
  borderColor: theme.color(ColorPreset.BorderOnLight_05),
  ":first-of-type": {
    borderTop: "unset",
  },
});

export const plainActionStyle: Interpolation = {
  borderRadius: "4px",
  boxShadow: `var(${focusRingVar})`,
  maxWidth: "100%",
};

type StyleProp = Pick<DynamicListProps, "border" | "colorScheme">;
export const dynamicListStyle: CSSRulesFunction<StyleProp> = (theme, props) => {
  const borderWidth = props.border === "top-bottom" ? "1px" : "0px";
  return [
    {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      borderColor: theme.color(ColorPreset.BorderOnLight_05),
      borderStyle: "solid",
      borderWidth: `${borderWidth} 0px`,
    },
    focusRingStyle(theme, {
      colorScheme: props.colorScheme || ColorScheme.OnLight,
      variant: FocusRing.Heavy,
      focusSelector: "& li [data-dynamiclist-item-primary]:focus-visible",
    }),
  ];
};
