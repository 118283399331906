import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { FocusScope } from "@react-aria/focus";

import { useTheme } from "../theme";
import { Glyph } from "../icons";
import BodyPortal from "../BodyPortal";
import { ButtonVariant, IconButton } from "../buttons";
import { useTapOut } from "../hooks/pointers";

import Overlay from "./Overlay";
import {
  closeStyle,
  dialogBodyStyle,
  dialogFooterStyle,
  dialogHeaderStyle,
  dialogHostStyle,
  dialogMainWrapperStyle,
  dialogStyle,
  contentScrollShadows,
  bodyContentStyle,
} from "./styles";
import type {
  DialogCloseActionProps,
  DialogHeaderFooterProps,
  DialogProps,
} from "./types";

const CloseTrigger: React.FC<DialogCloseActionProps & { onHeader: boolean }> = (
  props
) => {
  const { onClose, label, ...rest } = props;
  return (
    <div css={closeStyle(rest)}>
      <IconButton
        icon={Glyph.Close}
        label={label}
        variant={ButtonVariant.TextOnLight}
        onClick={onClose}
      />
    </div>
  );
};

const overFlowScrollShadow = (wrapper: HTMLDivElement) => {
  const child = wrapper.children[0];
  if (!child || child.children.length < 1) return { top: false, bottom: false };
  const top = wrapper.scrollTop > 4;
  const heightDiff = child.clientHeight - wrapper.clientHeight;
  const bottom = heightDiff > 0 && wrapper.scrollTop < heightDiff;
  return {
    top,
    bottom,
  };
};

const destructureHeaderFooter = (
  item?: React.ReactNode | DialogHeaderFooterProps
) => {
  const itemIsObject = !!item && typeof item === "object" && "content" in item;
  const enableItem = itemIsObject || (!!item && React.Children.count(item) > 0);
  const { content, ...styles } = itemIsObject ? item : { content: item };

  return { enableItem, content, styles };
};

const Dialog: React.FC<DialogProps> = ({
  open,
  closeAction,
  header,
  footer,
  children,
  bodyGutterH = [2, null, 3],
  bodyGutterV = [1.5, null, 2],
  hostGutterH = 1,
  hostGutterV = [1, null, null, 2, 3],
  width = ["100%", null, "600px"],
  height = undefined,
  borderRadius = 1.5,
  ...rest
}) => {
  const { theme } = useTheme();

  const [scrollShadows, setScrollShadows] = React.useState({
    top: false,
    bottom: false,
  });
  const mainDialogRef = React.useRef<HTMLDivElement>(null);
  const contentDivRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      setScrollShadows(overFlowScrollShadow(node));
    },
    [children]
  );
  useTapOut(mainDialogRef, () => {
    if (!open || !closeAction) return;
    closeAction.onClose();
  });
  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "revert";
    }

    return () => {
      document.body.style.overflow = "revert";
    };
  }, [open]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key !== "Escape" || !closeAction?.onClose) return;

    // We don't want the event to propagate up in case anyone is listening to
    // the Escape key on the body.
    event.stopPropagation();

    closeAction.onClose();
  };

  const {
    enableItem: enableHeader,
    content: headerContent,
    styles: headerStyles,
  } = destructureHeaderFooter(header);
  const {
    enableItem: enableFooter,
    content: footerContent,
    styles: footerStyles,
  } = destructureHeaderFooter(footer);

  const sharedProps = {
    as: undefined,
  } as const;

  const content = (
    <>
      {" "}
      {closeAction ? (
        <CloseTrigger {...closeAction} onHeader={!!enableHeader} />
      ) : null}
      {enableHeader ? (
        <div
          css={dialogHeaderStyle(theme, {
            ...headerStyles,
            closePadding: !!closeAction && !closeAction.position,
          })}
        >
          {headerContent}
        </div>
      ) : null}
      <div
        ref={contentDivRef}
        onScroll={(event) => {
          const node = event.currentTarget;
          const { top, bottom } = overFlowScrollShadow(node);
          if (top === scrollShadows.top && bottom === scrollShadows.bottom)
            return;
          setScrollShadows({ top, bottom });
        }}
        style={{ ...contentScrollShadows(scrollShadows) }}
        css={dialogBodyStyle(theme)}
      >
        <div css={bodyContentStyle}>{children}</div>
      </div>
      {enableFooter ? (
        <div css={dialogFooterStyle(theme, footerStyles)}>{footerContent}</div>
      ) : null}
    </>
  );

  return (
    <BodyPortal>
      <CSSTransition
        in={open}
        timeout={250}
        classNames="slide"
        mountOnEnter
        unmountOnExit
        appear
      >
        <div
          css={{ pointerEvents: "auto" }}
          onKeyDown={handleKeyDown}
          role="presentation"
          tabIndex={-1}
        >
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <FocusScope contain autoFocus restoreFocus>
            <Overlay />
            <div css={dialogHostStyle(theme, { hostGutterH, hostGutterV })}>
              <div
                ref={mainDialogRef}
                css={dialogStyle(theme, {
                  header: headerStyles,
                  footer: footerStyles,
                  bodyGutterH,
                  bodyGutterV,
                  width,
                  height,
                  borderRadius,
                })}
                role="dialog"
                aria-modal
                tabIndex={-1}
              >
                {rest.as === "form" ? (
                  <form css={dialogMainWrapperStyle} {...rest} {...sharedProps}>
                    {content}
                  </form>
                ) : (
                  <div css={dialogMainWrapperStyle} {...rest} {...sharedProps}>
                    {content}
                  </div>
                )}
              </div>
            </div>
          </FocusScope>
        </div>
      </CSSTransition>
    </BodyPortal>
  );
};

export default Dialog;
