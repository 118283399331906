import type * as React from "react";

import { Box } from "../../layout";

import type { TimelineProps } from "./types";

const Timeline: React.FC<TimelineProps> = ({ maxWidth, children }) => (
  <Box layout="flex" flexDirection="column" maxWidth={maxWidth}>
    {children}
  </Box>
);

export default Timeline;
