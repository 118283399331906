import { ColorScheme, CSSRulesFunction, FocusRing, FontWeight } from "../theme";
import { focusRingVal } from "../theme/cssvariables";
import { focusRingStyle } from "../theme/focusRings";

import { type ButtonStyleProps, ButtonLayout } from "./types";

export const roundedButtonSizeTokens: CSSRulesFunction = (theme) => {
  return {
    "--btn-gutter-inline-sm": theme.spacing(2),
    "--btn-gutter-block-sm": theme.spacing(0.5),
    "--btn-gutter-intra-sm": theme.spacing(0.5),
    "--btn-font-size-sm": theme.tokens.fontSizes[2].fontSize,
    "--btn-line-height-sm": theme.tokens.fontSizes[2].lineHeight,
    "--btn-border-radius-sm": theme.radius(2),

    "--btn-gutter-inline-md": theme.spacing(3),
    "--btn-gutter-block-md": theme.spacing(0.75),
    "--btn-gutter-intra-md": theme.spacing(0.5),
    "--btn-font-size-md": theme.tokens.fontSizes[3].fontSize,
    "--btn-line-height-md": theme.tokens.fontSizes[3].lineHeight,
    "--btn-border-radius-md": theme.radius(2),

    "--btn-gutter-inline-lg": theme.spacing(4),
    "--btn-gutter-block-lg": theme.spacing(1),
    "--btn-gutter-intra-lg": theme.spacing(0.5),
    "--btn-font-size-lg": theme.tokens.fontSizes[3].fontSize,
    "--btn-line-height-lg": theme.tokens.fontSizes[3].lineHeight,
    "--btn-border-radius-lg": theme.radius(2),
  };
};

const roundedButtonGeometryStyle: CSSRulesFunction<ButtonStyleProps> = (
  theme,
  props
) => {
  const { size, layout } = props;

  return [
    roundedButtonSizeTokens(theme),
    theme.responsive(size, (v) => {
      return {
        padding: `var(--btn-gutter-block-${v}) var(--btn-gutter-inline-${v})`,
        columnGap: `var(--btn-gutter-intra-${v})`,
        fontSize: `var(--btn-font-size-${v})`,
        lineHeight: `var(--btn-line-height-${v})`,
        borderRadius: `var(--btn-border-radius-${v})`,
      };
    }),
    theme.responsive(layout, (v) => {
      return {
        width: v === ButtonLayout.Full ? "100%" : "auto",
        display: v === ButtonLayout.Full ? "grid" : "inline-grid",
      };
    }),
  ];
};

export const roundedButtonColorStyle: CSSRulesFunction<
  Pick<ButtonStyleProps, "variant">
> = (_, props) => {
  return [
    {
      backgroundColor: `var(--btn-${props.variant}-bg-default)`,
      color: `var(--btn-${props.variant}-fg-default)`,
      boxShadow: `inset 0 0 0 2px var(--btn-${props.variant}-border-default), ${focusRingVal}`,
      "&:hover, &:active": {
        backgroundColor: `var(--btn-${props.variant}-bg-hover)`,
        color: `var(--btn-${props.variant}-fg-hover)`,
        boxShadow: `inset 0 0 0 2px var(--btn-${props.variant}-border-hover), ${focusRingVal}`,
      },
      "&:disabled": {
        backgroundColor: `var(--btn-${props.variant}-bg-disabled)`,
        color: `var(--btn-${props.variant}-fg-disabled)`,
        boxShadow: `inset 0 0 0 2px var(--btn-${props.variant}-border-disabled)`,
      },
    },
  ];
};

interface TempStyleProps extends ButtonStyleProps {
  colorScheme: ColorScheme;
}

export const roundedButtonStyle: CSSRulesFunction<TempStyleProps> = (
  theme,
  props
) => {
  return [
    {
      width: "auto",
      textAlign: "center",
      alignItems: "center",
      border: "none",
      appearance: "none",
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
      fontWeight: FontWeight.SemiBold,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        "&:disabled": {
          textDecoration: "none",
        },
      },
      "&:disabled": {
        cursor: "not-allowed",
      },
      transition: "color 100ms, background 100ms, box-shadow 100ms",
    },
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme: props.colorScheme,
      focusSelector: "&:focus-visible",
    }),
    roundedButtonGeometryStyle(theme, props),
    roundedButtonColorStyle(theme, props),
  ];
};
