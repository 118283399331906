import * as React from "react";

import { useTheme } from "../theme";

import {
  TypographyStyleProps,
  typographyStyle,
  omitTypographyStyleProps,
} from "./appearance";

type HTMLProps = Omit<React.HTMLAttributes<HTMLParagraphElement>, "color">;

export interface PProps extends HTMLProps, TypographyStyleProps {}

const P = React.forwardRef<HTMLParagraphElement, PProps>(function Paragraph(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitTypographyStyleProps(props);

  return <p css={typographyStyle(theme, props)} {...rest} ref={ref} />;
});

export default P;
