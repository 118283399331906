import {
  CSSRulesFunction,
  Color,
  ColorPreset,
  Interpolation,
  ResponsiveValue,
  SpaceScale,
  Theme,
  ZIndex,
} from "../theme";

import type {
  DialogCloseActionProps,
  DialogHeaderFooterProps,
  DialogProps,
} from "./types";

export const styleVars = {
  // the top and right absolute position of the close button
  closePull: "--dialog-close-pull",
  // the amount of right padding to add to the header so it clears the close
  // button
  closeClearing: "--dialog-close-clearing",

  // dialogs padding
  headerGutterV: "--dialog-header-gutterv",
  headerGutterH: "--dialog-header-gutterh",
  headerGridGap: "--dialog-header-grid-gap",
  footerGutterV: "--dialog-footer-gutterv",
  footerGutterH: "--dialog-footer-gutterh",
  bodyGutterV: "--dialog-body-gutterv",
  bodyGutterH: "--dialog-body-gutterh",

  // dialogs main content scroll shadow
  topShadowOpacity: "--dialog-top-shadow-opacity",
  bottomShadowOpacity: "--dialog-bottom-shadow-opacity",

  width: "--dialog-width",
  height: "--dialog-height",
  borderRadius: "--dialog-border-radius",
};

type HeaderFooterStyle = Omit<DialogHeaderFooterProps, "content">;
interface StyleProps
  extends Pick<
    DialogProps,
    "bodyGutterH" | "bodyGutterV" | "width" | "height" | "borderRadius"
  > {
  header?: HeaderFooterStyle;
  footer?: HeaderFooterStyle;
}

const dialogSizeTokens: CSSRulesFunction<StyleProps> = (
  theme,
  { header, footer, bodyGutterH, bodyGutterV }
) => {
  const headerGutterV: ResponsiveValue<SpaceScale> =
    header?.gutterV !== undefined ? header.gutterV : [1.5];
  const headerGutterH: ResponsiveValue<SpaceScale> | undefined =
    header?.gutterH !== undefined ? header.gutterH : bodyGutterH;

  const footerGutterV: ResponsiveValue<SpaceScale> | undefined =
    footer?.gutterV !== undefined ? footer.gutterV : [1, null, 1.5];
  const footerGutterH: ResponsiveValue<SpaceScale> | undefined =
    footer?.gutterH !== undefined ? footer.gutterH : bodyGutterH;

  return [
    {
      [styleVars.headerGridGap]: theme.spacing(1),
      [styleVars.closePull]: theme.spacing(0.5),
      [styleVars.closeClearing]: theme.spacing(4),
    },

    theme.responsive(headerGutterV, (v) => {
      return { [styleVars.headerGutterV]: theme.spacing(v) };
    }),
    theme.responsive(headerGutterH, (h) => {
      return { [styleVars.headerGutterH]: theme.spacing(h) };
    }),
    theme.responsive(bodyGutterV, (v) => {
      return { [styleVars.bodyGutterV]: theme.spacing(v) };
    }),
    theme.responsive(bodyGutterH, (h) => {
      return { [styleVars.bodyGutterH]: theme.spacing(h) };
    }),
    theme.responsive(footerGutterV, (v) => {
      return { [styleVars.footerGutterV]: theme.spacing(v) };
    }),
    theme.responsive(footerGutterH, (h) => {
      return { [styleVars.footerGutterH]: theme.spacing(h) };
    }),
  ];
};

export const dialogHostStyle: CSSRulesFunction<
  Pick<DialogProps, "hostGutterH" | "hostGutterV">
> = (theme, { hostGutterH, hostGutterV }) => {
  return [
    theme.responsive(hostGutterV, (pad) => ({
      paddingTop: theme.spacing(pad),
      paddingBottom: theme.spacing(pad),
    })),
    theme.responsive(hostGutterH, (pad) => ({
      paddingLeft: theme.spacing(pad),
      paddingRight: theme.spacing(pad),
    })),
    {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: ZIndex.Dialog,
      display: "flex",
      flexDirection: "column",
      pointerEvents: "auto",
    },
  ];
};

export const dialogStyle: CSSRulesFunction<StyleProps> = (theme, props) => {
  const tv = "--dialog-transform";
  const tvar = `var(${tv})`;
  const ov = "--dialog-opacity";
  const ovar = `var(${ov})`;

  return [
    dialogSizeTokens(theme, props),
    {
      margin: "auto",
      position: "relative",
      backgroundColor: theme.color(Color.Brownstone_100),
      webkitOverflowScrolling: "touch",
      maxHeight: "100%",
      outline: "none",
      overflow: "hidden",
    },
    theme.responsive(props.width, (w) => {
      return { width: w };
    }),
    theme.responsive(props.height, (h) => {
      return { height: h };
    }),
    theme.responsive(props.borderRadius, (r, th) => ({
      borderRadius: th.radius(r),
    })),

    {
      [tv]: "translate3d(0, 48px, 0)",
      [ov]: 0,

      transition: "transform 250ms, opacity 250ms",
      transform: tvar,
      opacity: ovar,

      ".slide-enter &": {
        [tv]: "translate3d(0, 48px, 0)",
        [ov]: 0,
      },
      ".slide-enter-active &": {
        [tv]: "translate3d(0, 0, 0)",
        [ov]: 1,
      },
      ".slide-enter-done &": {
        [tv]: "translate3d(0, 0, 0)",
        [ov]: 1,
      },

      ".slide-exit &": {
        [tv]: "translate3d(0, 0, 0)",
        [ov]: 1,
      },
      ".slide-exit-active &": {
        [tv]: "translate3d(0, -48px, 0)",
        [ov]: 0,
      },
      ".slide-exit-done &": {
        [tv]: "translate3d(0, -48px, 0)",
        [ov]: 0,
      },

      "@media (prefers-reduced-motion: reduce)": {
        ".slide-enter &": {
          [tv]: "translate3d(0, 8px, 0)",
          [ov]: 0,
        },
        ".slide-enter-active &": {
          [tv]: "translate3d(0, 0, 0)",
          [ov]: 1,
        },
        ".slide-enter-done &": {
          [tv]: "translate3d(0, 0, 0)",
          [ov]: 1,
        },

        ".slide-exit &": {
          [tv]: "translate3d(0, 0, 0)",
          [ov]: 1,
        },
        ".slide-exit-active &": {
          [tv]: "translate3d(0, -8px, 0)",
          [ov]: 0,
        },
        ".slide-exit-done &": {
          [tv]: "translate3d(0, -8px, 0)",
          [ov]: 0,
        },
      },
    },
  ];
};

export const dialogMainWrapperStyle: Interpolation = {
  height: "100%",
  maxHeight: "100%",
  display: "grid",
};

const headerFooterSeparator = (theme: Theme) =>
  `1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`;

export const dialogHeaderStyle: CSSRulesFunction<
  HeaderFooterStyle & { closePadding: boolean }
> = (theme, { closePadding, separator = true }) => ({
  paddingTop: `var(${styleVars.headerGutterV})`,
  paddingBottom: `var(${styleVars.headerGutterV})`,
  paddingRight: closePadding
    ? `max(var(${styleVars.closeClearing}), var(${styleVars.headerGutterH}))`
    : `var(${styleVars.headerGutterH})`,
  paddingLeft: `var(${styleVars.headerGutterH})`,
  borderBottom: separator ? headerFooterSeparator(theme) : undefined,
  display: "grid",
  gap: `var(${styleVars.headerGridGap})`,
});

export const dialogFooterStyle: CSSRulesFunction<HeaderFooterStyle> = (
  theme,
  { separator = true }
) => ({
  borderTop: separator ? headerFooterSeparator(theme) : undefined,
  padding: `var(${styleVars.footerGutterV}) var(${styleVars.footerGutterH})`,
});

const scrollShadowVal = (theme: Theme, pos: "top" | "bottom") =>
  `0 ${pos === "top" ? "-" : ""}8px 18px 1px ${theme.color(
    Color.Brownstone_1400
  )}`;

export const contentScrollShadows = ({
  top,
  bottom,
}: {
  top: boolean;
  bottom: boolean;
}) => {
  return {
    [styleVars.topShadowOpacity]: top ? "1" : "0",
    [styleVars.bottomShadowOpacity]: bottom ? "1" : "0",
  };
};

export const dialogBodyStyle: CSSRulesFunction = (theme) => ({
  position: "relative",
  overflowY: "auto",
  "::before": {
    content: '""',
    display: "block",
    position: "sticky",
    top: "-1px",
    left: 0,
    right: 0,
    height: "1px",
    opacity: `var(${styleVars.topShadowOpacity})`,
    boxShadow: `${scrollShadowVal(theme, "top")}`,
  },
  "::after": {
    content: '""',
    display: "block",
    position: "sticky",
    bottom: "-1px",
    left: 0,
    right: 0,
    height: "1px",
    opacity: `var(${styleVars.bottomShadowOpacity})`,
    boxShadow: `${scrollShadowVal(theme, "bottom")}`,
  },
  "::before, ::after": {
    transition: "opacity 0.25s",
  },
});

export const bodyContentStyle: Interpolation = {
  padding: `var(${styleVars.bodyGutterV}) var(${styleVars.bodyGutterH})`,
};

type CloseStyleProps = Pick<DialogCloseActionProps, "position"> & {
  onHeader: boolean;
};
export const closeStyle = ({
  position,
  onHeader,
}: CloseStyleProps): Interpolation => {
  const closePosition = position ? position : onHeader ? "absolute" : "static";
  return {
    position: closePosition,
    top: `var(${styleVars.closePull})`,
    right: `var(${styleVars.closePull})`,
    marginBottom: `calc(-1 * var(${styleVars.closePull}))`,
    button:
      closePosition === "static"
        ? {
            float: "right",
            margin: `var(${styleVars.closePull}) var(${styleVars.closePull}) 0 0`,
          }
        : undefined,
  };
};
