import { ClassNames } from "@emotion/react";
import * as React from "react";

import { Icon } from "../icons";
import { ButtonSize, useTheme } from "../theme";

import { buttonAccessoryStyle, buttonStyle } from "./styles";
import {
  ButtonLayout,
  ButtonRenderProps,
  ButtonStyleProps,
  omitButtonStyleProps,
} from "./types";

type HTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof ButtonStyleProps
>;

export interface ButtonProps extends HTMLAttributes, ButtonStyleProps {
  children: React.ReactNode;

  render?: (props: ButtonRenderProps) => React.ReactNode;
}

const contentStyle = { gridArea: "content" };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { theme } = useTheme();

    const {
      leftIcon,
      rightIcon,
      variant,
      size = ButtonSize.Md,
      layout = ButtonLayout.Inline,
      type = "button",
    } = props;

    const { render, ...rest } = omitButtonStyleProps(props);
    const wrappedChildren = (
      <>
        {leftIcon ? (
          <span
            css={buttonAccessoryStyle(theme, { position: "start", variant })}
          >
            <Icon name={leftIcon} />
          </span>
        ) : null}

        <span css={contentStyle}>{props.children}</span>
        {rightIcon ? (
          <span css={buttonAccessoryStyle(theme, { position: "end", variant })}>
            <Icon name={rightIcon} />
          </span>
        ) : null}
      </>
    );

    return (
      <ClassNames>
        {({ css, cx }) => {
          const className = cx(
            css(buttonStyle(theme, { ...props, size, layout })),
            props.className
          );
          const forwardedProps = {
            ...rest,
            type,
            className,
            children: wrappedChildren,
          };

          return render ? (
            render(forwardedProps)
          ) : (
            <button ref={ref} {...forwardedProps} />
          );
        }}
      </ClassNames>
    );
  }
);

export default Button;
