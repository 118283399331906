import { ButtonGutter, ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { AlignItems, ButtonSize, useTheme } from "../theme";

import { BannerVariant } from "./types";

type CloseActionProps = {
  label: React.ReactNode;
  onClose(): void;
  variant: BannerVariant;
  wrapperAlignItems: AlignItems[];
};

const CloseAction: React.FC<CloseActionProps> = (props) => {
  const { theme } = useTheme();
  const { variant, label, onClose: onClick, wrapperAlignItems } = props;
  const btnProps = {
    label,
    onClick,
    icon: Glyph.Close,
    size: { base: ButtonSize.Sm, gutters: ButtonGutter.Md },
  };

  const shiftMarginValue = `calc(-1 * var(--btn-gutter-${btnProps.size.base}-${btnProps.size.gutters}))`;
  const shiftMargin = `${shiftMarginValue} ${shiftMarginValue} 0 0`;

  // When the button is at the start of the box, make its content box flush with the banner's content box
  const marginStyles = theme.responsive(
    wrapperAlignItems.map((alignItems) =>
      alignItems === AlignItems.Start ? shiftMargin : 0
    ),
    (v) => ({
      margin: v,
    })
  );

  switch (variant) {
    case BannerVariant.Dark:
      return (
        <IconButton
          {...btnProps}
          css={marginStyles}
          variant={ButtonVariant.TextOnDark}
        />
      );
    default:
      return (
        <IconButton
          {...btnProps}
          css={marginStyles}
          variant={ButtonVariant.TextOnLight}
        />
      );
  }
};

export default CloseAction;
