import * as React from "react";

import { useTheme } from "../theme";

import {
  TypographyStyleProps,
  typographyStyle,
  omitTypographyStyleProps,
} from "./appearance";

type HTMLProps = Omit<React.HTMLAttributes<HTMLElement>, "color">;

export interface CiteProps extends HTMLProps, TypographyStyleProps {}

const Cite = React.forwardRef<HTMLElement, CiteProps>(function Cite(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitTypographyStyleProps(props);
  return <cite css={typographyStyle(theme, props)} {...rest} ref={ref} />;
});

export default Cite;
