import * as React from "react";

import { ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import {
  ButtonSize,
  CSSRulesFunction,
  Interpolation,
  ResponsiveValue,
  SpaceScale,
  useTheme,
} from "../theme";

interface CarouselStyleProps {
  /**
   * Sets the spacing between carousel items.
   */
  itemSpacing: ResponsiveValue<SpaceScale>;
}

type HTMLAttributes = React.HTMLAttributes<HTMLDivElement>;

export interface CarouselProps
  extends HTMLAttributes,
    Partial<CarouselStyleProps> {
  /** Sets the localized label for the previous button. */
  previousLabel: React.ReactNode;
  /** Sets the localized label for the next button. */
  nextLabel: React.ReactNode;
  /**
   * A callback that is invoked when the previous button is clicked.
   */
  onPreviousClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * A callback that is invoked when the next button is clicked.
   */
  onNextClick?: React.MouseEventHandler<HTMLButtonElement>;

  children: React.ReactNode;
}

const vars = {
  itemSpacing: "--carousel-item-spacing",
};

const visuallyHidden: Interpolation = {
  pointerEvents: "none",
  opacity: 0,
};

const visuallyVisible: Interpolation = {
  pointerEvents: "initial",
  opacity: 1,
};

const controlStyle: Interpolation = {
  position: "absolute",
  top: "50%",
  transform: "translate3d(0, -50%, 0)",
  zIndex: 1,

  transition: "opacity 200ms 50ms",
  "@media(prefers-reduced-motion: reduce)": {
    transition: "opacity 50ms",
  },
};

const previousButtonStyle: Interpolation = [controlStyle, { left: 0 }];
const nextButtonStyle: Interpolation = [controlStyle, { right: 0 }];

const carouselListStyle: Interpolation = {
  display: "grid",
  gap: `var(${vars.itemSpacing})`,
  gridAutoFlow: "column",
  gridAutoColumns: "max-content",
  width: "100%",
  overflow: "auto",
  padding: 0,
  margin: 0,
  listStyleType: "none",
  scrollSnapType: "x mandatory",
  overscrollBehaviorX: "contain",
  scrollPaddingLeft: 0,
  "> li": { scrollSnapAlign: "center" },
  "@media (min-width: 480px)": {
    scrollPaddingLeft: `calc(2 * var(${vars.itemSpacing}))`,
    "> li": { scrollSnapAlign: "start" },
  },

  scrollBehavior: "smooth",
  "@media (prefers-reduced-motion: reduce)": {
    scrollBehavior: "auto",
  },

  // Only hide the scroll bars if the browser supports the snapping
  // carousel scrolling
  "@supports (scroll-snap-type: x mandatory)": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },
};

const carouselStyle: CSSRulesFunction<CarouselStyleProps> = (theme, props) => {
  return [
    theme.responsive(props.itemSpacing, (v) => {
      return { [vars.itemSpacing]: theme.spacing(v) };
    }),

    {
      position: "relative",
      "> [data-carousel-control]": visuallyHidden,
      "&:hover > [data-carousel-control]": visuallyVisible,
      "> [data-carousel-control][data-disabled=true]": visuallyHidden,
    },
  ];
};

const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const {
      nextLabel,
      previousLabel,
      itemSpacing = [1, null, 3],
      onPreviousClick,
      onNextClick,
      children,
      ...rest
    } = props;
    const listRef = React.useRef<HTMLUListElement>(null);

    // We want to visually hide next/previous buttons when they are not
    // applicable. For example, when at the start of the carousel, we don't need
    // to show the previous button. Likewise when at the end, we don't need to
    // show the next button. We're also visually hiding them because we want to
    // avoid popping them in and out of the accessibility tree and disrupting
    // the user journey for assistive tech users.
    const [disabledControls, setDisabledControls] = React.useState({
      next: false,
      previous: false,
    });
    const updateDisabledControls = React.useCallback(() => {
      const listEl = listRef.current;
      if (!(listEl instanceof HTMLElement)) {
        return;
      }

      setDisabledControls({
        previous: listEl.scrollLeft === 0,
        next: listEl.scrollLeft >= listEl.scrollWidth - listEl.clientWidth,
      });
    }, [listRef, setDisabledControls]);

    // Update the disabled state of next/previous controls when browser resizes
    React.useEffect(() => {
      const el = listRef.current;
      if (!(el instanceof HTMLElement)) {
        return;
      }

      if (typeof window === "undefined") {
        return;
      }

      // If ResizeObserver is not available for a browser then use a traditional
      // window resize event listener
      if (typeof window.ResizeObserver === "undefined") {
        document.addEventListener("resize", updateDisabledControls, false);
        return () => {
          document.removeEventListener("resize", updateDisabledControls, false);
        };
      } else {
        const ro = new ResizeObserver((entries) => {
          if (!entries.length) {
            return;
          }

          updateDisabledControls();
        });
        ro.observe(el);

        return () => {
          if (!el) {
            return;
          }
          ro.unobserve(el);
        };
      }
    }, [listRef, updateDisabledControls]);

    // Update the disabled state of next/previous controls when the carousel
    // list is scrolled.
    React.useEffect(() => {
      const el = listRef.current;
      if (!(el instanceof HTMLElement)) {
        return;
      }

      el.addEventListener("scroll", updateDisabledControls, false);
      return () =>
        el.removeEventListener("scroll", updateDisabledControls, false);
    }, [listRef, updateDisabledControls]);

    const handlePrevious: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      onPreviousClick?.(e);
      if (e.isDefaultPrevented()) {
        return;
      }

      const listEl = listRef.current;
      if (!listEl) {
        return;
      }

      listEl.scrollBy(-1 * listEl.clientWidth, 0);
    };

    const handleNext: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      onNextClick?.(e);
      if (e.isDefaultPrevented()) {
        return;
      }

      const listEl = listRef.current;
      if (!listEl) {
        return;
      }

      listEl.scrollBy(listEl.clientWidth, 0);
    };

    const nodes = React.Children.map(children, (c) => {
      return <li>{c}</li>;
    });

    return (
      <div ref={ref} css={carouselStyle(theme, { itemSpacing })} {...rest}>
        <div
          css={previousButtonStyle}
          data-carousel-control
          data-disabled={disabledControls.previous}
        >
          <IconButton
            icon={Glyph.ArrowBack}
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Lg}
            label={previousLabel}
            onClick={handlePrevious}
            disabled={disabledControls.previous}
          />
        </div>
        <ul
          ref={listRef}
          css={carouselListStyle}
          // See note 1 above
          data-snap-disabled={disabledControls.next}
        >
          {nodes}
        </ul>
        <div
          css={nextButtonStyle}
          data-carousel-control
          data-disabled={disabledControls.next}
        >
          <IconButton
            icon={Glyph.ArrowForward}
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Lg}
            label={nextLabel}
            onClick={handleNext}
            disabled={disabledControls.next}
          />
        </div>
      </div>
    );
  }
);

export default Carousel;
