import type React from "react";

import type { ButtonProps, LinkProps } from "../buttons";
import type { IconProps } from "../icons";

type ButtonActionProps = Omit<ButtonProps, "variant" | "layout">;
type ButtonAction = {
  control: "button";
  buttonVariant?: BannerButtonVariant;
} & ButtonActionProps;

type LinkActionProps = Omit<LinkProps, "variant" | "layout">;
type LinkAction = {
  control: "link";
  buttonVariant?: BannerButtonVariant;
} & LinkActionProps;

type BannerStatusProps = {
  type: BannerLeftAccessoryType.Status;
  status: BannerStatus;
};

type BannerIconType = Omit<
  IconProps,
  "size" | "color" | "verticalAlign" | "className"
>;
type BannerIconProps = {
  type: BannerLeftAccessoryType.Icon;
} & BannerIconType;

type BannerTagProps = {
  type: BannerLeftAccessoryType.Tag;
  text: string;
};

export interface BannerCloseActionProps {
  /**
   * The label to set on the close button. It needs to be defined especially
   * to support assistive technologies like screen readers. This prop accepts
   * localized strings.
   */
  label: React.ReactNode;

  /**
   * A click handler that is triggered when the close button is clicked.
   */
  onClose: () => void;
}

export enum BannerLeftAccessoryType {
  Status = "bannerStatus",
  Icon = "bannerIcon",
  Tag = "bannerTag",
}

export type BannerActionProps = ButtonAction | LinkAction;

export type BannerLeftAccessoryProps =
  | BannerStatusProps
  | BannerIconProps
  | BannerTagProps;

export enum BannerVariant {
  Light = "light",
  Dark = "dark",
  FullBleed = "full-bleed",
}
export enum BannerStatus {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Alert = "alert",
  Loading = "loading",
}

export enum BannerLayout {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum BannerButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Text = "text",
  Plain = "plain",
}

export enum BannerActionType {
  Primary = "primary",
  Secondary = "secondary",
}
