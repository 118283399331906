import { addPrefixUrl, extendAPI } from "@gocardless/api/utils/api";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";

import { Route, routerPush } from "../../common/routing";
import {
  checkForCookieBasedToken,
  destroySession,
  shouldRedirectToSignIn,
  signInRouteParams,
} from "../authorisation/session";

import { syncOrganisationIdFromResponseHeaderHook } from "./organisation-header-sync";

import { Environment, getUrl, isEnvironment } from "src/common/config";

export {
  useOrganisationIdFromResponseHeader,
  removeOrganisationIdFromResponseHeader,
} from "./organisation-header-sync";

export type { FieldLevelError, FieldLevelErrorMap } from "./errors";

export const NO_REDIRECT_TO_404 = "noredirect";

// This hook is called for every API response, and used to detect if the user should be logged out when the response
// status code is 401.
// We try to recover a valid session to avoid disrupting the user, but if we can't, we log them out and redirect them
// back to the sign-in page.
const unAuthorisedAPIHook = async (
  request: Request,
  _options: object,
  response: Response
) => {
  if (response.status !== HTTPStatusCode.Unauthorized) return;

  if (
    !request.url.includes("gocardless.com") &&
    !isEnvironment(Environment.Development)
  )
    return;

  const endpointsToIgnore = [
    // These endpoints are used as part of the logout flow, so we ignore them to avoid infinite loops.
    Endpoint.TemporaryAccessTokenDisableSelf,
    Endpoint.CookieBasedTokenCreate,

    // Endpoint.TemporaryAccessTokenCreate returns `401` if you have multi-factor authentication enabled and now need
    // to be challenged, so we don't want to log the user out.
    Endpoint.TemporaryAccessTokenCreate,
  ];

  if (
    endpointsToIgnore.some((endpoint) =>
      request.url.includes(getEndpointURL(endpoint))
    )
  ) {
    return;
  }

  // Destroy the user's session; if the token they're using is producing 401s we need to start again.
  await destroySession();

  // Check to see if the user has a temporary access token in a cookie. This happens when this merchant signs in/up
  // via another service e.g. the connect app. If they do, we can use the token to create a new session, without
  // needing the user to log in again.
  const hasActiveToken = await checkForCookieBasedToken();

  if (!hasActiveToken && shouldRedirectToSignIn()) {
    routerPush(signInRouteParams());
  }
};

/*
 * By default, we redirect to the /404 page if an API returns a 404.
 * To override this behaviour, add the endpoint to the noRedirectRoutes.
 */
const noRedirectRoutes = [
  getEndpointURL(Endpoint.UserCreateOnfidoToken),
  getEndpointURL(Endpoint.OnfidoSdkTokenCreate),
  getEndpointURL(Endpoint.AvailableCreditorRefundAmountList),
  getEndpointURL(Endpoint.PipeAccessTokenSelf, {
    pipeAccessTokenId: "OR\\d+",
  }),
  getEndpointURL(Endpoint.TemporaryAccessTokenCreateForOrganisation),
  getEndpointURL(Endpoint.ExportSelf, {
    exportId: "EX\\d+",
  }),
];

const notFoundAPIHook = (
  _request: Request,
  _options: object,
  response: Response
) => {
  const noRedirect = _request.headers.get(NO_REDIRECT_TO_404);
  if (
    response.status === HTTPStatusCode.NotFound &&
    !noRedirect &&
    !noRedirectRoutes.some((endpoint) => _request.url.match(endpoint))
  ) {
    routerPush({ route: Route.NotFound });
  }
};

/*
 * By default, we redirect to the MFA Setup page if an API returns a 432, indicating the user needs to setup MFA
 * and cannot access pages/APIs until this is done.
 * Overrides are for hidden, unnecessary calls being made and need to be removed in future.
 */
const noRedirectMfaRoutes = [
  getEndpointURL(Endpoint.AppAuthorisationList),
  getEndpointURL(Endpoint.OrganisationSelf, { organisationId: ".*" }),
  getEndpointURL(Endpoint.FeeDiscountList),
  getEndpointURL(Endpoint.CreditorSelf, { creditorId: ".*" }),
  getEndpointURL(Endpoint.TemporaryAccessTokenCreate),
];
const cannotAccessUntilMfaSetup = (
  _request: Request,
  _options: object,
  response: Response
) => {
  if (
    response.status === 432 &&
    !noRedirectMfaRoutes.some((endpoint) => _request.url.match(endpoint))
  ) {
    routerPush({ route: Route.MFASetup });
  }
};

/*
 * We add the prefix url to the API only if storybook is not running.
   This is to allow MSW to mock storybook requests on our storybook
   version deployed to production as we set a different url for storybook:
   https://storybook.gocardless.io/merchant-dashboard/index.html

   See ./storybook/preview.js
 */

if (!process.env.STORYBOOK) {
  addPrefixUrl(getUrl("api"));
}

/*
 * We extend the API to add request middle-wares.
 */
extendAPI({
  hooks: {
    afterResponse: [
      cannotAccessUntilMfaSetup,
      unAuthorisedAPIHook,
      notFoundAPIHook,
      syncOrganisationIdFromResponseHeaderHook,
    ],
  },
});
