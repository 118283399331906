import type { ResponsiveValue } from "../theme";

export enum AccordionVariant {
  SolidOnLight = "solid-on-light",
  SolidOnDark = "solid-on-dark",
  ClearOnLight = "clear-on-light",
  ClearOnDark = "clear-on-dark",

  SolidOnLightDimmed = "solid-on-light-dimmed",
  SolidOnDarkDimmed = "solid-on-dark-dimmed",
  ClearOnLightDimmed = "clear-on-light-dimmed",
  ClearOnDarkDimmed = "clear-on-dark-dimmed",
}

export enum AccordionSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export enum AccordionOutline {
  None = "none",
  Solid = "solid",
}

export enum AccordionMode {
  Single = "single",
  Multi = "multi",
}

export interface AccordionStyleProps {
  /**
   * When true, the selected accordion header will be scrolled into view if it is not in view
   * @default true
   */
  autoScrollIntoView?: boolean;
  /**
   * Sets the desired appearance of the accordion.
   */
  variant: ResponsiveValue<AccordionVariant>;

  /**
   * Controls the size of the accordion.
   */
  size: ResponsiveValue<AccordionSize>;

  /**
   * Switches the selection mode for the accordion. In `"multi"` mode, several
   * accordion items can be open. In  `"single"`, only one accordion item can be
   * open - opening one closes the others.
   */
  mode: AccordionMode;

  /**
   * Controls the display of a solid border around the accordion.
   */
  outline?: AccordionOutline;

  /**
   * Specify an optional set of initially opened accordion items. If the
   * accordion is in `"single"` mode, then only the first item is opened.
   */
  initialSelected?: string[];

  /**
   * Specifies the minimum number of items to keep expanded. Sometimes, it is
   * necessary for accordions to display at least one expanded item. Set this to
   * 0 if users are allowed to collapse all items
   */
  minimumSelected?: 0 | 1;

  /**
   * Controls of the visibility of the toggle which indicates an accordion panel
   * is open or not.
   */
  toggleVisibility?: "visible" | "hidden";

  /**
   * A callback that is called when accordion items are opened. It receives a
   * list of ids for the open items.
   */
  onChange?: (items: string[]) => void;
}
