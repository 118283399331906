import * as React from "react";
import { CSSTransition } from "react-transition-group";

import { ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { ButtonSize, ColorScheme, useTheme } from "../theme";
import { useIsomorphicLayoutEffect } from "../hooks/useIsomorphicLayoutEffect";

import {
  SidebarAppearanceProps,
  sidebarStyle,
  sidebarContentStyle,
  SidebarLayout,
  sidebarCloseStyle,
  sidebarBackdropStyle,
} from "./sidebarStyles";

export interface SidebarProps extends Partial<SidebarAppearanceProps> {
  /**
   * When floating, controls the open/closed state of the sidebar
   */
  open?: boolean;

  /**
   * Configures the rendering of the floating sidebar's close button. If this is
   * is not set, the close button will not be rendered.
   */
  closeAction?: {
    /**
     * Sets the assistive label of the sidebar's close button. If this is not set,
     * the close button will not be rendered. Additionaly, the close button is not
     * rendered if the sidebar is not floating.
     */
    label: React.ReactNode;
    /**
     * A callback that is called when the open/closed state of the sidebar changes
     */
    onClose(): void;
  };

  children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const {
    open,
    colorScheme = ColorScheme.OnLight,
    closeAction,
    borderRadius,
    layout = [SidebarLayout.FloatingLeft, null, null, SidebarLayout.StaticLeft],
    contentWidth = [
      "248px", // intention is to use floating sidebars here
      "256px", // and here
      null, // and here
      "208px",
      "256px",
    ],
    ...rest
  } = props;

  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  // If the sidebar is changing state to become open then use that as a trigger
  // to focus it. `openRef` tracks the previous open state and the layout effect
  // compares it to the current open state
  const openRef = React.useRef(!!open);
  useIsomorphicLayoutEffect(() => {
    const wasOpen = openRef.current;
    const isOpen = !!open;
    openRef.current = isOpen;
    if (isOpen === wasOpen) {
      return;
    }

    if (isOpen) {
      sidebarRef.current?.focus();
    }
  }, [openRef, open]);

  let closeVariant = ButtonVariant.PrimaryAuto;
  switch (colorScheme) {
    case ColorScheme.OnLight:
      closeVariant = ButtonVariant.PrimaryOnLight;
      break;
    case ColorScheme.OnDark:
      closeVariant = ButtonVariant.PrimaryOnDark;
      break;
  }

  const handleClose = () => {
    closeAction?.onClose?.();
  };

  return (
    <CSSTransition in={open} timeout={200} classNames="slide" appear>
      <div
        css={sidebarStyle(theme, {
          layout,
          colorScheme,
          open,
          contentWidth,
          borderRadius,
        })}
        {...rest}
        ref={sidebarRef}
        tabIndex={-1}
        role="presentation"
      >
        <div
          css={sidebarBackdropStyle}
          role="presentation"
          onClick={handleClose}
        />
        <div css={sidebarContentStyle}>{props.children}</div>
        {closeAction ? (
          <div css={sidebarCloseStyle(layout)}>
            <IconButton
              icon={Glyph.Close}
              variant={closeVariant}
              size={ButtonSize.Sm}
              label={closeAction.label}
              onClick={handleClose}
            />
          </div>
        ) : null}
      </div>
    </CSSTransition>
  );
};

export default Sidebar;
