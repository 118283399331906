import React, { useRef } from "react";
import {
  arrow,
  autoPlacement,
  flip,
  Middleware,
  MiddlewareState,
  offset,
  size,
  Strategy,
  useFloating,
} from "@floating-ui/react-dom";

import { useResizeObserver, useScrollObserver } from "../hooks/geometry";

import type { TooltipPlacement, TooltipPlacementStrategy } from "./types";

const getDocumentBody = () => {
  if (typeof document === "undefined" || typeof document.body === "undefined") {
    return null;
  }
  return document.body;
};

interface UseTooltipOptions {
  open?: boolean;
  arrowRef?: React.RefObject<HTMLElement>;
  initialPlacement?: TooltipPlacement;
  placementStrategy: TooltipPlacementStrategy;
  positionStrategy?: Strategy;
  /**
   * How far in from the edge of the floating tooltip should the arrow be.
   * This option helps account for a border radius on the floating element.
   */
  offsetPadding?: number;
  /**
   * If true, the width of the tooltip will be the same as the width of the
   * reference element.
   */
  sameWidth?: boolean;
}

const placementMiddleware = (
  options: Pick<UseTooltipOptions, "initialPlacement" | "placementStrategy">
): Middleware[] => {
  const { placementStrategy, initialPlacement } = options;

  if (placementStrategy === "static") {
    return [];
  }

  if (initialPlacement || placementStrategy === "flip") {
    // When deciding the set of allowed placements for a tooltip using flip
    // strategy, we will bias this based on the initial placement. For example,
    // if the initial placement is "top-start" then we will assume we can
    // reposition the tooltip above and below its trigger. This is a sensible
    // default behaviour that tries to avoid having the tooltip cover content to
    // the left or right of its trigger. A similar assumption is made if the
    // initial placement is left-* or right-*.
    const majorEdge = initialPlacement ? initialPlacement.split("-")[0] : "top";
    const fallbackPlacements: TooltipPlacement[] = [];
    if (majorEdge === "left" || majorEdge === "right") {
      fallbackPlacements.push(
        "left",
        "left-start",
        "left-end",
        "right",
        "right-start",
        "right-end"
      );
    } else {
      fallbackPlacements.push(
        "top",
        "top-start",
        "top-end",
        "bottom",
        "bottom-start",
        "bottom-end"
      );
    }

    return [
      flip({
        fallbackStrategy: "bestFit",
        fallbackPlacements,
      }),
    ];
  }

  return [autoPlacement()];
};

export const useTooltip = (options: UseTooltipOptions) => {
  const {
    open = false,
    arrowRef,
    initialPlacement,
    placementStrategy,
    offsetPadding,
    positionStrategy = "fixed",
    sameWidth,
  } = options;

  const placement =
    placementStrategy === "auto" ? initialPlacement : initialPlacement || "top";

  const result = useFloating({
    strategy: positionStrategy,
    placement,
    middleware: [
      ...placementMiddleware({
        initialPlacement: placement,
        placementStrategy,
      }),
      ...(arrowRef
        ? [arrow({ element: arrowRef, padding: offsetPadding })]
        : []),
      // This is the pixel offset of the tooltip from its reference and needs to
      // be adjust to account for the arrow.
      offset(8),
      ...(sameWidth
        ? [
            size({
              apply({ rects, elements }: MiddlewareState) {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`,
                });
              },
            }),
          ]
        : []),
    ],
  });

  const { update } = result;
  // // We need to track the size of the document so we can reposition the tooltip
  // // if it changes.
  const documentRef = useRef<HTMLElement>(getDocumentBody());
  useResizeObserver(documentRef, update, [open]);
  useResizeObserver(result.refs.floating, update, [open]);
  // // We also need to track the browser scroll position to flip the tooltip
  // // reposition if needed.
  useScrollObserver({
    scrollReference: "document",
    callback: update,
    enabled: open && placementStrategy === "flip",
  });
  return result;
};
