import Router from "next/router";
import { parse } from "query-string";
import { addAccessToken, extendAPI } from "@gocardless/api/utils/api";
import {
  cookieBasedTokenCreate,
  CookieBasedTokenCreateResponseBody,
} from "@gocardless/api/dashboard/cookie-based-token";
import {
  TemporaryAccessTokenCreateResponseBody,
  temporaryAccessTokenDisableSelf,
} from "@gocardless/api/dashboard/temporary-access-token";
import { AdminAccessTokenCreateResponseBody } from "@gocardless/api/staff/admin-access-token";
import { UserResource } from "@gocardless/api/dashboard/types";
import { useEffect, useState } from "react";
import { mutate } from "swr";

import { removeOrganisationIdFromResponseHeader } from "../api";
import {
  externalNavigation,
  PublicOnlyRoutes,
  Route,
  routerPush,
  RouteURLParams,
} from "../routing";
import { getItem, removeItem, setItem } from "../local-storage/local-storage";
import {
  matchesDynamicRoute,
  PublicOnlyDynamicRoutes,
} from "../routing/routes";

import { AccessToken } from "./types";

import { getConfig } from "src/common/config";
import { sessionTracking } from "src/technical-integrations/segmentAbs/sessionTracking";
import { resetSegmentAnalytics } from "src/technical-integrations/segmentAbs";
import { MFA_DELAYED_ITEM_KEY } from "src/components/authentication/mfa/useMFAEnforcementData";
import { resetDrift } from "src/technical-integrations/drift/Drift";
import { logoutUser } from "src/technical-integrations/zendesk/client/zendesk";
import { isZendeskFormsInterceptorRoute } from "src/technical-integrations/zendesk/helpers";

const GC_ACCESS_TOKEN_KEY = "gc.access.token";

export function getAccessToken(): AccessToken {
  return getItem(GC_ACCESS_TOKEN_KEY);
}

/**
 * Destroy the user's session, effectively logging them out.
 * To redirect the user to the sign-in page afterwards, use routerPush(signInRouterParams()).
 */
export async function destroySession() {
  // Disable the current temporary access token
  try {
    await temporaryAccessTokenDisableSelf();
  } catch {
    /* empty */
  }

  // Remove the now disabled token from local storage
  removeItem(GC_ACCESS_TOKEN_KEY);

  // Remove the access token stored in memory by the @gocardless/api package
  addAccessToken("");

  removeItem(MFA_DELAYED_ITEM_KEY);

  removeOrganisationIdFromResponseHeader();
  sessionTracking.deleteSession();

  removeItem("gc.top-banner-closed");

  // Destroy third-party sessions
  resetDrift();
  logoutUser();
  if (!PublicOnlyRoutes.includes(window.location.pathname)) {
    resetSegmentAnalytics();
  }
}

/**
 * Create RouteURLParams to (re)direct a user to the sign-in page.
 *
 * @param appendRedirectURL - Redirect the user back to the current page after a successful login (default: true)
 */
export function signInRouteParams({
  appendRedirectURL = true,
}: {
  appendRedirectURL?: boolean;
} = {}): RouteURLParams {
  /*
   * Send the current pathname and search query param to `redirect`
   * query string to be picked up after successful authentication
   * and redirected back to original route.
   */
  const redirectURL = `${window.location.pathname}${window.location.search}`;
  const redirectURLQuery = appendRedirectURL
    ? {
        redirect: redirectURL,
      }
    : undefined;

  return {
    route: Route.SignIn,
    queryParams: redirectURLQuery,
  };
}

export function shouldRedirectToSignIn() {
  /**
   * Checks match for dynamic public routes with router params
   */
  const isDynamicPublicRoute: boolean = matchesDynamicRoute(
    window.location.pathname,
    PublicOnlyDynamicRoutes
  );

  if (
    PublicOnlyRoutes.includes(window.location.pathname) ||
    isDynamicPublicRoute
  ) {
    return false;
  }

  /*
   * We should not redirect the user to sign-in screen
   * when pathName matches Zendesk Forms Interceptor route.
   * The interceptor will handle the redirecting to external Zendesk url.
   */
  if (isZendeskFormsInterceptorRoute(window.location.pathname)) {
    return false;
  }
  return true;
}

/**
 * Destroy the current session and redirect the user to the sign-in page.
 * @param appendRedirectURL - Redirect the user back to the current page after a successful login (default: true)
 */
export async function logout({
  appendRedirectURL = true,
}: {
  appendRedirectURL?: boolean;
} = {}): Promise<void> {
  await destroySession();

  if (shouldRedirectToSignIn()) {
    routerPush(signInRouteParams({ appendRedirectURL }));
  }
}

type AccessTokenResponse =
  | TemporaryAccessTokenCreateResponseBody
  | AdminAccessTokenCreateResponseBody;

export function setAccessTokenFromApiResponse(apiResponse: {
  users?: UserResource;
  linked?: AccessTokenResponse;
}) {
  setAccessToken(apiResponse.linked);
}

export function setAccessToken(
  accessTokenResponse?: AccessTokenResponse,
  skipEventDispatch = false
) {
  if (accessTokenResponse === undefined) {
    return;
  }

  const accessToken =
    "temporary_access_tokens" in accessTokenResponse
      ? accessTokenResponse.temporary_access_tokens
      : "admin_access_tokens" in accessTokenResponse
        ? accessTokenResponse.admin_access_tokens
        : {};

  addAccessToken(accessToken?.token || "");
  setItem(GC_ACCESS_TOKEN_KEY, accessToken);
  if (!skipEventDispatch) {
    // Dispatch storage event manually as setItem doesn't trigger
    // it for the same window or tab
    window.dispatchEvent(
      new StorageEvent("storage", {
        key: GC_ACCESS_TOKEN_KEY,
        newValue: JSON.stringify(accessToken),
      })
    );
  }
}

export const navigateHome = (queryParams?: { [key: string]: string }) => {
  if (getConfig().shared.enableDevelopmentRoute) {
    Router.push({ pathname: "/" });
  } else {
    routerPush({ route: Route.Home, queryParams });
  }
};

export const checkForCookieBasedToken: () => Promise<boolean> = async () =>
  // During OAuth flows, payments-service stores an access token in a cookie -
  // see Middleware::Auth::SetCookies - in order to log you in to merchant-dashboard
  // from the server.
  // The browser can't read cookies though, so we make a request to the server,
  // which will check for the cookie, and if found will then return an access token
  // in the response body
  await cookieBasedTokenCreate()
    .then((data) => {
      if (!data) {
        return false;
      }
      const tokenResponse = data as CookieBasedTokenCreateResponseBody;
      const token = tokenResponse.cookie_based_tokens;
      const temporaryAccessTokenObject = {
        temporary_access_tokens: token,
      };
      return setAccessToken(temporaryAccessTokenObject);
    })
    .then(() => getAccessToken()?.token)
    .then((accessToken) => {
      if (accessToken) {
        // Instantly set the access token in the API cache.
        // This will prevent a potential race condition where API requests use the
        // old access token which can eventually sign out the user if that returns a 401.
        extendAPI({
          hooks: {
            beforeRequest: [
              (request: Request) => {
                request.headers.set("authorization", `Bearer ${accessToken}`);
              },
            ],
          },
        });
        // Clear cache and revalidate as new access token has been set
        mutate(/* match all keys */ () => true, undefined, true);
      }
      return !!accessToken;
    })
    .catch(() => false);

export const handleRedirects = () => {
  const { redirect } = parse(window.location.search);
  const redirectHref = `${window.location.origin}${redirect || ""}`;
  // In development, we want to redirect urls
  // locally as we don't know if they are external.
  if (redirectHref && getConfig().shared.enableDevelopmentRoute) {
    window.location.replace(redirectHref);
  } else {
    /*
      To handle redirects after sign in for both merchant dashboard and enterprise dashboard
      we do the following:

      When any unauthenticated user tries to access a page they get sent to the sign in page in MD.
      After logging in successfully we check for the presence of the redirect query param:

      > If present we know to redirect the user with that value internally using within MD.
      > If not present we navigate the user to the enterprise dashboard with a query param to instruct ED
        that this user needs to be redirected to the page they wanted to visit initially before having to sign in.
    */

    if (redirect) {
      externalNavigation(redirectHref, false);
    } else {
      navigateHome({ redirectWithRouterState: "true" });
    }
  }
};

export function login(
  accessTokenResponse?: TemporaryAccessTokenCreateResponseBody
) {
  setAccessToken(accessTokenResponse);
  removeItem("gc.top-banner-closed");
  handleRedirects();
}

export function useAccessToken(): [
  AccessToken,
  (accessTokenResponse?: AccessTokenResponse) => void,
] {
  const [accessToken, updateAccessToken] = useState<AccessToken>(
    // Get an access token from localStorage
    // Will be null if the user is not logged in
    getAccessToken()
  );

  // Update the @gocardless/api package synchronously to make sure the token is available immediately
  addAccessToken(accessToken?.token || "");

  // Subscribe to changes to the token in localStorage
  useEffect(() => {
    const updateAccessTokenListenerEvent = (event: StorageEvent) => {
      if (event.key === GC_ACCESS_TOKEN_KEY) {
        try {
          const accessTokenRequest = JSON.parse(event.newValue || "{}");
          updateAccessToken(accessTokenRequest);
        } catch {
          /* empty */
        }
      }
    };

    if (!window) return;

    window.addEventListener("storage", updateAccessTokenListenerEvent);

    return () => {
      window.removeEventListener("storage", updateAccessTokenListenerEvent);
    };
  }, []);

  return [accessToken, setAccessToken];
}
