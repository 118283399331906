import { useEffect, useState } from "react";
import {
  useTemporaryAccessTokenCreate,
  TemporaryAccessTokenCreateRequestBody,
} from "@gocardless/api/dashboard/temporary-access-token";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { FactorType } from "@gocardless/api/dashboard/types";
import { useClearSWR } from "@gocardless/api/utils/use-clear-cache";

import { SIGN_IN_RATE_LIMIT_EXCEEDED } from "./notifications";
import { AuthFactorMetaData, ForcedPasswordResetMetaData } from "./types";

import { login } from "src/common/authorisation";
import {
  errorsNotificationHandler,
  RATE_LIMIT_EXCEEDED_MSG,
} from "src/common/notifications/notificationErrorHandlers";
import { useToastNotification } from "src/hooks/useToastNotification";
import { GC_ORGANISATION } from "src/common/constants/storage";
import { removeItem, setItem } from "src/common/local-storage/local-storage";
import {
  sendGA4Event,
  GA4Events,
} from "src/technical-integrations/third-party-tracking/google-analytics";
import { IDENTITY_CHECK_STORAGE_KEY } from "src/components/authentication/identityCheck/useIdentityCheck";

export enum SignInApiErrors {
  Unauthorized = "unauthorized",
  AuthFactorRequired = "auth_factor_required",
  InvalidTOTPCode = "two_factor_auth_invalid_otp_totp_code",
  InvalidSMSCode = "two_factor_auth_invalid_otp_sms_code",
  RateLimitExceeded = "rate_limit_exceeded",
  InvalidRecoveryCode = "two_factor_auth_invalid_recovery_code",
  RequirePasswordReset = "password_reset_required",
  TooManyFailedAttempts = "too_many_failed_login_attempts",
  RequirePasswordResetForPasswordPolicy = "password_reset_required_for_password_policy",
  RequirePasswordResetForResetDelayExpiry = "password_reset_required_for_reset_delay_expiry",
  RequirePasswordResetForAccountUnlock = "password_reset_required_to_unlock_account",
  RequirePasswordResetForDormantAccount = "password_reset_required_for_dormant_account",
}

const REQUIRE_PASSWORD_RESET_ERRORS = [
  SignInApiErrors.RequirePasswordResetForPasswordPolicy,
  SignInApiErrors.RequirePasswordResetForResetDelayExpiry,
  SignInApiErrors.RequirePasswordResetForAccountUnlock,
  SignInApiErrors.RequirePasswordResetForDormantAccount,
];

export const PasswordResetDelayNotApplicableErrors = [
  SignInApiErrors.RequirePasswordResetForResetDelayExpiry,
  SignInApiErrors.RequirePasswordResetForAccountUnlock,
  SignInApiErrors.RequirePasswordResetForDormantAccount,
];

export const useSignIn = () => {
  const clearCache = useClearSWR();
  const [userInfo, setUserInfo] =
    useState<TemporaryAccessTokenCreateRequestBody>();
  const [authFactorMetaData, setAuthFactorMetaData] =
    useState<AuthFactorMetaData>();
  const [forcedPasswordResetMetaData, setForcedPasswordResetMetaData] =
    useState<ForcedPasswordResetMetaData>();
  const [requireResetPassword, setRequireResetPassword] = useState(false);
  const [tooManyFailedAttempts, setTooManyFailedAttempts] = useState(false);
  const { triggerErrorNotification } = useToastNotification();

  const [submit, { isMutating }] = useTemporaryAccessTokenCreate({
    onSuccess: async (response) => {
      const organisationId =
        response?.temporary_access_tokens?.links?.organisation;

      if (userInfo?.otp_recovery_code) {
        sendGA4Event(GA4Events.SignInRecoveryCodeSuccess, {
          userId: response?.temporary_access_tokens?.links?.user,
          organisation: organisationId,
        });
      }

      if (userInfo && !userInfo.otp_recovery_code) {
        const signInEventName = !userInfo.otp_code
          ? GA4Events.SignInSuccess
          : GA4Events.SignInMFASuccess;

        sendGA4Event(signInEventName, {
          userId: response?.temporary_access_tokens?.links?.user,
          organisation: organisationId,
        });
      }

      // We set the org id in local storage to assist with Optimizely targeting on unauthenticated pages.
      setItem(GC_ORGANISATION, organisationId);
      // Remove identity check skip from local storage
      removeItem(IDENTITY_CHECK_STORAGE_KEY);
      clearCache();
      login(response);
    },
    onError: async (error) => {
      const errors = await getErrorsFromErrorResponse(error);
      const errs = errors.filter((signInError) => {
        if (
          REQUIRE_PASSWORD_RESET_ERRORS.includes(
            signInError.reason as SignInApiErrors
          )
        ) {
          const meta: ForcedPasswordResetMetaData = {
            error_reason: signInError.reason as SignInApiErrors,
            days_until_required_password_reset:
              signInError.metadata?.days_left_for_required_password_reset,
            user_email: userInfo?.email,
          };
          setForcedPasswordResetMetaData(meta);
          return;
        }

        if (signInError.reason === SignInApiErrors.AuthFactorRequired) {
          const meta: AuthFactorMetaData = {
            factor_type: signInError.metadata["factor_type"] as FactorType,
            phone_number_ending: signInError.metadata["phone_number_ending"],
            delay_password_reset: signInError.metadata["delay_password_reset"],
          };
          setAuthFactorMetaData(meta);
          return;
        }
        if (signInError.reason === SignInApiErrors.RequirePasswordReset) {
          setRequireResetPassword(true);
          return;
        }
        if (signInError.reason === SignInApiErrors.TooManyFailedAttempts) {
          setTooManyFailedAttempts(true);
          return;
        }

        return signInError;
      });
      errorsNotificationHandler(errs, triggerErrorNotification, {
        match: RATE_LIMIT_EXCEEDED_MSG,
        content: SIGN_IN_RATE_LIMIT_EXCEEDED,
      });
    },
  });

  const submitSignIn = (requestBody: TemporaryAccessTokenCreateRequestBody) => {
    setUserInfo({
      ...userInfo,
      ...requestBody,
      // If on the recovery code part, otp_code should be undefined which
      // resets the value so we don't send both at the same time
      otp_code: requestBody.otp_code,
      // Same as above
      otp_recovery_code: requestBody.otp_recovery_code,
    });
  };

  useEffect(() => {
    if (userInfo && userInfo.password && userInfo.email) {
      submit(userInfo);
    }
  }, [userInfo, submit]);

  return {
    submitSignIn,
    forcedPasswordResetMetaData,
    authFactorMetaData,
    requireResetPassword,
    tooManyFailedAttempts,
    userInfo,
    isSubmitting: isMutating,
  };
};
