import { useControlledState } from "@react-stately/utils";
import * as React from "react";

import type {
  FloatingPlacement,
  PlacementStrategy,
} from "../../hooks/floating";

import type { DateInputProps } from "./DateInput";
import DateInput from "./DateInput";
import DatePickerAccessory from "./DatePickerAccessory";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLLabelElement>,
  keyof DateInputProps
>;

export interface PickerCalendarOptions {
  position?: "absolute" | "fixed";
  placementStrategy?: PlacementStrategy;
  placement?: FloatingPlacement;
}

export interface DatePickerProps
  extends HTMLAttributes,
    Omit<DateInputProps, "rightAccessory"> {
  /**
   * Controls whether or not the calendar trigger and dropdown are rendered.
   */
  calendar?: "enabled" | "disabled" | PickerCalendarOptions;
}

const noop = () => {};

const DatePicker = React.forwardRef<HTMLLabelElement, DatePickerProps>(
  (props, ref) => {
    const {
      calendar = "enabled",
      minDate,
      maxDate,
      highlightedDates,
      ...rest
    } = props;

    const [value, setValue] = useControlledState(
      props.value,
      props.defaultValue || {
        day: undefined,
        month: undefined,
        year: undefined,
      },
      props.onChange || noop
    );

    const calendarOptions = !!calendar && typeof calendar === "object";
    return (
      <DateInput
        {...rest}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={setValue}
        ref={ref}
        css={{
          transform:
            calendarOptions && calendar.position === "fixed"
              ? "initial"
              : undefined,
        }}
        rightAccessory={
          calendar === "enabled" || calendarOptions ? (
            <DatePickerAccessory
              disabled={props.disabled}
              i18n={props.i18n}
              setValue={setValue}
              highlightedDates={highlightedDates}
              value={value}
              position={calendarOptions ? calendar.position : undefined}
              minDate={minDate}
              maxDate={maxDate}
              placementStrategy={
                calendarOptions ? calendar.placementStrategy : undefined
              }
              initialPlacement={
                calendarOptions ? calendar.placement : undefined
              }
            />
          ) : null
        }
      />
    );
  }
);

export default DatePicker;
