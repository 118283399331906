import {
  Color,
  ColorPreset,
  CSSRulesFunction,
  Interpolation,
  ResponsiveValue,
} from "../../theme";
import { FormFieldStatus } from "../status";

import type { DateInputStyleProps } from "./dateInputTypes";

const inputSize: ResponsiveValue<"sm" | "md"> = ["sm", "md"];

export const vars = {
  bg: "--dateinput-bg",
  color: "--dateinput-color",
  borderColor: "--dateinput-border-color",
  borderWidth: "--dateinput-border-width",
  gutterH: "--dateinput-gutterh",
  gutterV: "--dateinput-gutterv",
  segmentFocusBg: "--dateinput-segment-focus-bg",
  segmentBorderRadius: "--dateinput-segment-border-radius",
  accessorySpacing: "--dateinput-accessory-spacing",
};

export const dateInputStyle: CSSRulesFunction<DateInputStyleProps> = (
  theme,
  props
) => {
  return [
    theme.responsive(inputSize, (s) => {
      switch (s) {
        case "sm":
          return {
            [vars.gutterV]: props.compact
              ? theme.spacing(0.5)
              : theme.spacing(1),
          };
        case "md":
          return {
            [vars.gutterV]: props.compact
              ? theme.spacing(0.75)
              : theme.spacing(1),
          };
      }
    }),
    {
      [vars.gutterH]: props.compact ? theme.spacing(0.25) : theme.spacing(1),
      [vars.color]: theme.color(ColorPreset.TextOnLight_01),
      [vars.bg]: theme.color(ColorPreset.BackgroundLight_01),
      [vars.borderColor]:
        props.status === FormFieldStatus.Danger
          ? theme.color(ColorPreset.AlertBorderOnLight)
          : theme.color(ColorPreset.BorderOnLight_02),
      [vars.borderWidth]: "1px",
      "&:focus-within": {
        [vars.borderWidth]: "2px",
        [vars.borderColor]:
          props.status === FormFieldStatus.Danger
            ? theme.color(ColorPreset.AlertBorderOnLight)
            : theme.color(ColorPreset.BorderOnLight_01),
      },

      [vars.segmentFocusBg]: theme.color(ColorPreset.BackgroundLight_03),
      [vars.segmentBorderRadius]: theme.radius(0.25),

      "&[aria-disabled=true]": {
        [vars.color]: theme.color(Color.Greystone_1400_A38),
        [vars.bg]: theme.color(Color.Greystone_1400_A8),
        [vars.borderColor]: theme.color(Color.Greystone_700_A38),
      },
    },

    {
      position: "relative",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      color: `var(${vars.color})`,
      backgroundColor: `var(${vars.bg})`,
      borderRadius: theme.radius(0.5),
      boxShadow: `inset 0 0 0 var(${vars.borderWidth}) var(${vars.borderColor})`,
      "&[aria-disabled=true]": {
        cursor: "not-allowed",
      },
    },
  ];
};

export const dateInputGroupStyle: Interpolation = {
  display: "inline-flex",
  flexWrap: "nowrap",
  alignItems: "baseline",
  padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
};

export const dateInputAccessoriesStyle: Interpolation = {
  display: "inline-flex",
  flexWrap: "nowrap",
  alignItems: "center",
  padding: `0 var(${vars.gutterH})`,
  "> * + *": {
    marginLeft: `var(${vars.accessorySpacing})`,
  },
};

export const dateInputSegmentStyle: Interpolation = {
  border: "none",
  padding: 0,
  background: "none",
  fontFamily: "inherit",
  fontSize: "inherit",
  lineHeight: "inherit",
  textAlign: "inherit",
  color: `var(${vars.color})`,
  borderRadius: `var(${vars.segmentBorderRadius})`,

  "&:focus": {
    outline: "none",
    background: `var(${vars.segmentFocusBg})`,
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
};
