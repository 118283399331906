import { useControlledState } from "@react-stately/utils";
import * as React from "react";

import {
  Color,
  ColorScheme,
  CSSRulesFunction,
  Interpolation,
  FocusRing,
  useTheme,
  ColorPreset,
  ResponsiveValue,
} from "../theme";
import { focusRingVal } from "../theme/cssvariables";
import { focusRingStyle } from "../theme/focusRings";

type HTMLAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type" | "children"
>;

interface SliderStyleProps {
  /**
   * Sets the color scheme for the slider control and labels.
   */
  colorScheme: ColorScheme;
  labelPlacement: ResponsiveValue<"block" | "inline">;
}

export interface SliderProps extends HTMLAttributes, Partial<SliderStyleProps> {
  /**
   * In controlled mode, sets the value of the slider.
   */
  value?: number;
  /**
   * In uncontrolled mode, sets the default value of the slider.
   */
  defaultValue?: number;
  /**
   * The minimum value that can be set using this slider.
   */
  min?: number;
  /**
   * The maximum value that can be set using this slider.
   */
  max?: number;
  /**
   * Optional label for the minimum allowed value to display at the start of the
   * slider.
   */
  minLabel?: React.ReactNode;
  /**
   * Optional label for the maximum allowed value to display at the start of the
   * slider.
   */
  maxLabel?: React.ReactNode;
}

const vars = {
  ratio: "--slider-ratio",
  zeroRatio: "--slider-zero-ratio",
  knobBaseSize: "--slider-knob-base-size",
  knobSize: "--slider-knob-size",
  knobScale: "--slider-knob-scale",
  knobActiveScale: "--slider-knob-active-scale",

  // This is the total possible fill for the track. We need to take into
  // account that the knob's center isn't at 0 when the input value is 0. This
  // is why the total range (100%) is augmented by the knob size. If we don't do
  // this then the knob and track fill will not be in phase.
  trackRange: "--slider-track-range",

  // Theming vars
  knobDefaultBg: "--slider-knob-default-bg",
  knobActiveBg: "--slider-knob-active-bg",
  knobDefaultOutline: "--slider-knob-default-outline",
  knobDisabledOutline: "--slider-knob-disabled-outline",
  trackActiveDefaultFill: "--slider-track-active-default-fill",
  trackInactiveDefaultFill: "--slider-track-inactive-default-fill",
  trackDisabledActiveFill: "--slider-track-disabled-active-fill",
  trackDisabledInactiveFill: "--slider-track-disabled-inactive-fill",
  labelDefaultColor: "--slider-label-default-color",
  labelDisabledColor: "--slider-label-disabled-color",

  // Derived theming vars
  knobBg: "--slider-knob-bg",
  knobOutline: "--slider-knob-outline",
  trackActiveFill: "--slider-track-active-fill",
  trackInactiveFill: "--slider-track-inactive-fill",
  labelColor: "--slider-label-color",
};

const sliderColorTokens: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  const light = {
    [vars.knobDefaultBg]: theme.color(Color.Transparent),
    [vars.knobActiveBg]: theme.color(Color.Greystone_DarkMatter),
    [vars.knobDefaultOutline]: `
      inset 0 0 0 4px ${theme.color(Color.Greystone_DarkMatter)}
    `,
    [vars.knobDisabledOutline]: `
      inset 0 0 0 4px ${theme.color(Color.Greystone_1400_A38)}
    `,

    [vars.trackActiveDefaultFill]: theme.color(Color.Greystone_1200),
    [vars.trackInactiveDefaultFill]: theme.color(Color.Greystone_1400_A16),
    [vars.trackDisabledActiveFill]: theme.color(Color.Greystone_1400_A38),
    [vars.trackDisabledInactiveFill]: theme.color(Color.Greystone_1400_A8),

    [vars.labelDefaultColor]: theme.color(ColorPreset.TextOnLight_03),
    [vars.labelDisabledColor]: theme.color(Color.Greystone_1400_A38),
  };

  const dark = {
    [vars.knobDefaultBg]: theme.color(Color.Transparent),
    [vars.knobActiveBg]: theme.color(Color.Brownstone_Moonstone),
    [vars.knobDefaultOutline]: `
      inset 0 0 0 4px ${theme.color(Color.Brownstone_Moonstone)}
    `,
    [vars.knobDisabledOutline]: `
      inset 0 0 0 4px ${theme.color(Color.Greystone_700_A38)}
    `,

    [vars.trackActiveDefaultFill]: theme.color(Color.Dawn_400),
    [vars.trackInactiveDefaultFill]: theme.color(Color.White_A25),
    [vars.trackDisabledActiveFill]: theme.color(Color.Greystone_700_A38),
    [vars.trackDisabledInactiveFill]: theme.color(Color.Greystone_700_A16),

    [vars.labelDefaultColor]: theme.color(ColorPreset.TextOnDark_03),
    [vars.labelDisabledColor]: theme.color(Color.Greystone_700_A38),
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

const sliderStyle: CSSRulesFunction<SliderStyleProps> = (theme, props) => {
  const { labelPlacement } = props;

  return [
    sliderColorTokens(theme, props.colorScheme),
    {
      display: "grid",
      alignItems: "center",
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
    theme.responsive(labelPlacement, (lp) => {
      switch (lp) {
        case "block":
          return {
            gridTemplateAreas: "'control control' 'minLabel maxLabel'",
            gridTemplateColumns: "1fr 1fr",
          };
        case "inline":
          return {
            gridTemplateAreas: "'minLabel control maxLabel'",
            gridTemplateColumns: "auto 1fr auto",
          };
      }
    }),
  ];
};

const sliderLabelStyle: Interpolation = {
  display: "flex",
  alignItems: "center",
  [vars.labelColor]: `var(${vars.labelDefaultColor})`,
  color: `var(${vars.labelColor})`,
  "input:disabled ~ &": {
    [vars.labelColor]: `var(${vars.labelDisabledColor})`,
  },
};

const sliderControlStyle: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  return [
    {
      // Whereas `vars.ratio` is from 0 to 1, zero ratio is from -0.5 to 0.5.
      // That is, it is centered at 0. This scale helps us perform later
      // calculations relating to the before and after track fills.
      [vars.zeroRatio]: `calc((var(${vars.ratio}) - 0.5))`,
      [vars.knobBaseSize]: "24px",
      [vars.knobSize]: `calc(var(${vars.knobBaseSize}) * var(${vars.knobScale}, 1))`,
      [vars.knobScale]: "1",
      [vars.knobActiveScale]: "calc(28/24)",
      // The maximum width of either parts of the track fill is 100 percent
      // minus the size of the knob.
      [vars.trackRange]: `calc(100% - var(${vars.knobSize}))`,

      [vars.knobBg]: `var(${vars.knobDefaultBg})`,
      [vars.knobOutline]: `var(${vars.knobDefaultOutline})`,
      [vars.trackActiveFill]: `var(${vars.trackActiveDefaultFill})`,
      [vars.trackInactiveFill]: `var(${vars.trackInactiveDefaultFill})`,
    },
    {
      "&[data-active], &[data-focused], &[data-hovered], &:active, &:hover, &:focus-visible":
        {
          [vars.knobBg]: `var(${vars.knobActiveBg})`,
        },
      "&[data-active], &:active": {
        [vars.knobScale]: `var(${vars.knobActiveScale})`,
      },
      "&:disabled": {
        [vars.knobScale]: "1",
        [vars.knobBg]: `var(${vars.knobDefaultBg})`,
        [vars.trackActiveFill]: `var(${vars.trackDisabledActiveFill})`,
        [vars.trackInactiveFill]: `var(${vars.trackDisabledInactiveFill})`,
        [vars.knobOutline]: `var(${vars.knobDisabledOutline})`,

        cursor: "not-allowed",
      },
    },
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme,
      focusSelector: "&:focus-visible, &[data-focused]",
    }),
    {
      appearance: "none",
      cursor: "pointer",
      display: "block",
      width: "100%",
      background: "none",
      margin: 0,
      padding: 0,
      gridArea: "control",

      "&::-webkit-slider-thumb": knobStyle,
      "&::-moz-range-thumb": knobStyle,

      "&::-webkit-slider-runnable-track": trackStyle,
      "&::-moz-range-track": trackStyle,
    },
  ];
};

const knobStyle: Interpolation = {
  padding: 0,
  margin: 0,
  boxSizing: "border-box",
  appearance: "none",
  width: `var(${vars.knobBaseSize})`,
  height: `var(${vars.knobBaseSize})`,
  borderRadius: `var(${vars.knobBaseSize})`,
  border: "none",
  backgroundColor: `var(${vars.knobBg})`,
  boxShadow: `var(${vars.knobOutline}), ${focusRingVal}`,
  transform: `scale(var(${vars.knobScale}, 1))`,
  transformOrigin: "50% 50%",
  transition: "transform 150ms, box-shadow 150ms, background-color 150ms",
};
const trackStyle: Interpolation = {
  // When the knob is scaled up, i.e. when it is actively pressed, we need to
  // adjust the track fill to account for its new size. Unfortunately, scaling
  // up the knob requires some finer calculations. This would not be needed if
  // we could have changed the width and height of the knob but that results in
  // an unpleasant interaction and layout shift. Scaling with a transform and
  // transform-origin mitigates this but requires this bit of maths.
  //
  // Regardless of scaling, the knob physically occupies its base size, that is
  // defined as its width and height, on the slider. The total we need to adjust
  // for is the "scaled size - base size" or "base size * (scale factor - 1)".
  // This adjustment then needs to be translated along the slider such that at
  // the 50% mark we need 0 adjustment and as we move to either ends of the
  // slider we apply adjustments linearly. This is where the zero ratio comes in
  // to help.
  //
  // Frankly, the best way to understand this maths is to set this variable to
  // 0, see the effect of that change and then work backwards from this code.
  "--adjustment": `calc(
    var(${vars.knobBaseSize})
    * (var(${vars.knobScale}) - 1)
    * var(${vars.zeroRatio})
  )`,

  // The following two variables determine the widths of the track fill before
  // and after the knob.
  //
  // The 1px adjustments in the following two rules will create a nice little
  // gap between the track fills and the knob.
  "--before": `calc(
    var(${vars.trackRange}) * var(${vars.ratio})
    + var(--adjustment)
    - 1px
  )`,
  "--after": `calc(
    var(${vars.trackRange}) * var(${vars.ratio})
    + var(${vars.knobSize})
    + var(--adjustment)
    + 1px
  )`,
  padding: 0,
  margin: 0,
  boxSizing: "border-box",
  appearance: "none",
  width: "100%",
  backgroundSize: "100% 4px",
  backgroundPosition: "left center",
  backgroundRepeat: "no-repeat",
  // The gradient represents the fill that is applied to the track. The middle
  // color stop is transparent and is effectively creating a space for the knob
  // to sit in.
  backgroundImage: `linear-gradient(
    to right,
    var(${vars.trackActiveFill}) 0% var(--before),
    transparent var(--before) var(--after),
    var(${vars.trackInactiveFill}) var(--after) 100%
  )`,
};

const noop = () => {};

const Slider = React.forwardRef<HTMLInputElement, SliderProps>((props, ref) => {
  const { theme } = useTheme();
  const {
    colorScheme = ColorScheme.OnLight,
    labelPlacement = "block",
    value: inValue,
    defaultValue: inDefaultValue,
    onChange,
    min = 0,
    max = 100,
    minLabel,
    maxLabel,
    ...rest
  } = props;
  const styleProps = { colorScheme, labelPlacement };

  const defaultValue =
    typeof inDefaultValue === "number"
      ? inDefaultValue
      : Math.trunc((max - min) / 2);

  const [value, setValue] = useControlledState(inValue, defaultValue, noop);
  const range = max - min;
  const ratio = range && value ? (value - min) / range : 0;
  const hasMinLabel = React.Children.count(minLabel) > 0;
  const hasMaxLabel = React.Children.count(maxLabel) > 0;

  return (
    <div css={sliderStyle(theme, styleProps)} data-field-control>
      <input
        css={sliderControlStyle(theme, colorScheme)}
        type="range"
        {...rest}
        min={min}
        max={max}
        value={value}
        onChange={(e) => {
          const parsed = parseInt(e.target.value);
          if (Number.isNaN(parsed)) {
            return;
          }

          onChange?.(e);

          setValue(parsed);
        }}
        ref={ref}
        style={{ [vars.ratio]: `${ratio}` }}
      />
      {hasMinLabel ? (
        <div css={[sliderLabelStyle, { gridArea: "minLabel" }]}>{minLabel}</div>
      ) : null}
      {hasMaxLabel ? (
        <div
          css={[
            sliderLabelStyle,
            { gridArea: "maxLabel", textAlign: "end", justifyContent: "end" },
          ]}
        >
          {maxLabel}
        </div>
      ) : null}
    </div>
  );
});

export default Slider;
