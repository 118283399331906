/* eslint-disable deprecation/deprecation */
import {
  Breakpoint,
  Color,
  DesignTokens,
  TypeScale,
  TypePreset,
  ColorPreset,
  FontWeight,
} from "./types";

const fontFamiliesConfig = {
  baseFontFamily: 'Haffer, "Helvetica Neue", Helvetica, Arial, sans-serif',
  serifFontFamily: "Para, Georgia, serif",
  monospaceFontFamily:
    '"DM Mono", Monaco, "Consolas", "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace',
  displayFontFamily: 'Nudge, "Helvetica Neue", Helvetica, Arial, sans-serif',
};

const colors: Record<Color, string> = {
  [Color.Dawn_1400]: "#252603",
  [Color.Dawn_1300]: "#4b4b06",
  [Color.Dawn_1200]: "#707109",
  [Color.Dawn_1100]: "#96970b",
  [Color.Dawn_1000]: "#bbbc0e",
  [Color.Dawn_900]: "#e1e211",
  [Color.Dawn_800]: "#eeef2d",
  [Color.Dawn_700]: "#f1f252",
  [Color.Dawn_600]: "#f3f468",
  [Color.Dawn_500]: "#f4f57d",
  [Color.Dawn_400]: "#f6f793",
  [Color.Dawn_300]: "#f8f9a9",
  [Color.Dawn_200]: "#fafabe",
  [Color.Dawn_100]: "#fbfcd4",
  [Color.Dawn_50]: "#fdfde9",
  [Color.Dawn_Base]: "#f1f252",

  [Color.Brownstone_1400]: "#1e1a14",
  [Color.Brownstone_1400_A08]: "rgba(30, 26, 20, 0.8)",
  [Color.Brownstone_1300]: "#3c3428",
  [Color.Brownstone_1200]: "#5b4e3c",
  [Color.Brownstone_1100]: "#796850",
  [Color.Brownstone_1000]: "#978264",
  [Color.Brownstone_900]: "#ac9b82",
  [Color.Brownstone_800]: "#c0b3a0",
  [Color.Brownstone_700]: "#d4cbbe",
  [Color.Brownstone_600]: "#d9d2c6",
  [Color.Brownstone_500]: "#dfd8ce",
  [Color.Brownstone_400]: "#e4dfd6",
  [Color.Brownstone_300]: "#eae5df",
  [Color.Brownstone_200]: "#efece7",
  [Color.Brownstone_100]: "#f4f2ef",
  [Color.Brownstone_50]: "#faf9f7",
  [Color.Brownstone_700_A12]: "rgba(212, 203, 190, 0.12)",
  [Color.Brownstone_700_A21]: "rgba(212, 203, 190, 0.21)",
  [Color.Brownstone_Base]: "#d4cbbe",
  [Color.Brownstone_Moonstone]: "#efece7",

  [Color.Greystone_1400]: "#1c1b18",
  [Color.Greystone_1300]: "#383530",
  [Color.Greystone_1200]: "#545048",
  [Color.Greystone_1100]: "#6e685e",
  [Color.Greystone_1000]: "#8c8579",
  [Color.Greystone_900]: "#a49e95",
  [Color.Greystone_900_A21]: "#a49e9536",
  [Color.Greystone_800]: "#bcb8b1",
  [Color.Greystone_700]: "#d4d1cd",
  [Color.Greystone_600]: "#d9d7d3",
  [Color.Greystone_500]: "#dfddda",
  [Color.Greystone_400]: "#e4e2e0",
  [Color.Greystone_300]: "#eae8e6",
  [Color.Greystone_200]: "#efeeec",
  [Color.Greystone_100]: "#f4f4f3",
  [Color.Greystone_50]: "#faf9f9",
  [Color.Greystone_1400_A90]: "rgba(28, 27, 24, 0.9)",
  [Color.Greystone_1400_A50]: "rgba(28, 27, 24, 0.5)",
  [Color.Greystone_1400_A38]: "rgba(28, 27, 24, 0.38)",
  [Color.Greystone_1400_A16]: "rgba(28, 27, 24, 0.16)",
  [Color.Greystone_1400_A8]: "rgba(28, 27, 24, 0.08)",
  [Color.Greystone_700_A38]: "rgba(212, 209, 205, 0.38)",
  [Color.Greystone_700_A16]: "rgba(212, 209, 205, 0.16)",
  [Color.Greystone_Base]: "#d4d1cd",
  [Color.Greystone_DarkMatter]: "#1c1b18",

  [Color.Sunset_1400]: "#230c00",
  [Color.Sunset_1300]: "#461700",
  [Color.Sunset_1200]: "#692300",
  [Color.Sunset_1100]: "#8c2f00",
  [Color.Sunset_1000]: "#af3a00",
  [Color.Sunset_900]: "#d24600",
  [Color.Sunset_800]: "#f55200",
  [Color.Sunset_700]: "#ff6619",
  [Color.Sunset_600]: "#ff7936",
  [Color.Sunset_500]: "#ff8c53",
  [Color.Sunset_400]: "#ff9f70",
  [Color.Sunset_300]: "#ffb38c",
  [Color.Sunset_200]: "#ffc6a9",
  [Color.Sunset_100]: "#ffd9c6",
  [Color.Sunset_50]: "#ffece2",
  [Color.Sunset_Base]: "#ff6619",

  [Color.Sunrise_1400]: "#251900",
  [Color.Sunrise_1300]: "#493100",
  [Color.Sunrise_1200]: "#6e4a00",
  [Color.Sunrise_1100]: "#936300",
  [Color.Sunrise_1000]: "#b77b00",
  [Color.Sunrise_900]: "#dc9400",
  [Color.Sunrise_800]: "#ffac01",
  [Color.Sunrise_700]: "#ffb826",
  [Color.Sunrise_600]: "#ffc141",
  [Color.Sunrise_500]: "#ffca5c",
  [Color.Sunrise_400]: "#ffd377",
  [Color.Sunrise_300]: "#ffdc93",
  [Color.Sunrise_200]: "#ffe4ae",
  [Color.Sunrise_100]: "#ffedc9",
  [Color.Sunrise_50]: "#fff6e4",
  [Color.Sunrise_Base]: "#ffb826",

  [Color.Slate_1400]: "#060807",
  [Color.Slate_1300]: "#0c100e",
  [Color.Slate_1200]: "#121815",
  [Color.Slate_1100]: "#18211c",
  [Color.Slate_1000]: "#1e2924",
  [Color.Slate_900]: "#25312b",
  [Color.Slate_800]: "#2b3932",
  [Color.Slate_700]: "#314139",
  [Color.Slate_600]: "#465d52",
  [Color.Slate_500]: "#5b7a6a",
  [Color.Slate_400]: "#719583",
  [Color.Slate_300]: "#8eaa9c",
  [Color.Slate_200]: "#aabfb5",
  [Color.Slate_100]: "#c6d5cd",
  [Color.Slate_50]: "#e3eae6",
  [Color.Slate_Base]: "#314139",

  [Color.Pebble_1400]: "#102019",
  [Color.Pebble_1300]: "#203f33",
  [Color.Pebble_1200]: "#305f4c",
  [Color.Pebble_1100]: "#407e66",
  [Color.Pebble_1000]: "#509e7f",
  [Color.Pebble_900]: "#6ab497",
  [Color.Pebble_800]: "#8ac4ad",
  [Color.Pebble_700]: "#a9d4c3",
  [Color.Pebble_600]: "#b4d9ca",
  [Color.Pebble_500]: "#bfded2",
  [Color.Pebble_400]: "#c9e4d9",
  [Color.Pebble_300]: "#d4e9e1",
  [Color.Pebble_200]: "#dfefe8",
  [Color.Pebble_100]: "#eaf4f0",
  [Color.Pebble_50]: "#f4faf7",
  [Color.Pebble_Base]: "#a9d4c3",

  [Color.Ocean_1400]: "#021a1d",
  [Color.Ocean_1300]: "#04343a",
  [Color.Ocean_1200]: "#064e57",
  [Color.Ocean_1100]: "#086774",
  [Color.Ocean_1000]: "#0a8191",
  [Color.Ocean_900]: "#0c9bae",
  [Color.Ocean_800]: "#0eb5cb",
  [Color.Ocean_700]: "#10cfe8",
  [Color.Ocean_600]: "#29d9f0",
  [Color.Ocean_500]: "#48def2",
  [Color.Ocean_400]: "#66e4f4",
  [Color.Ocean_300]: "#85e9f6",
  [Color.Ocean_200]: "#a3eff9",
  [Color.Ocean_100]: "#c2f4fb",
  [Color.Ocean_50]: "#e0fafd",
  [Color.Ocean_Base]: "#10cfe8",

  [Color.Ultraviolet_1400]: "#0a081b",
  [Color.Ultraviolet_1300]: "#140f36",
  [Color.Ultraviolet_1200]: "#1e1751",
  [Color.Ultraviolet_1100]: "#281f6b",
  [Color.Ultraviolet_1000]: "#322686",
  [Color.Ultraviolet_900]: "#3c2ea1",
  [Color.Ultraviolet_800]: "#4636bc",
  [Color.Ultraviolet_700]: "#5949cb",
  [Color.Ultraviolet_600]: "#6e60d2",
  [Color.Ultraviolet_500]: "#8377d8",
  [Color.Ultraviolet_400]: "#978ddf",
  [Color.Ultraviolet_300]: "#aca4e5",
  [Color.Ultraviolet_200]: "#c1bbec",
  [Color.Ultraviolet_100]: "#d6d2f2",
  [Color.Ultraviolet_50]: "#eae8f9",
  [Color.Ultraviolet_Base]: "#5949cb",

  [Color.Dusk_1400]: "#2a0928",
  [Color.Dusk_1300]: "#541250",
  [Color.Dusk_1200]: "#7e1c77",
  [Color.Dusk_1100]: "#a8259f",
  [Color.Dusk_1000]: "#d22fc6",
  [Color.Dusk_900]: "#db59d2",
  [Color.Dusk_800]: "#e483dd",
  [Color.Dusk_700]: "#edade9",
  [Color.Dusk_600]: "#efb7eb",
  [Color.Dusk_500]: "#f2c2ee",
  [Color.Dusk_400]: "#f4ccf1",
  [Color.Dusk_300]: "#f6d6f4",
  [Color.Dusk_200]: "#f8e0f7",
  [Color.Dusk_100]: "#fbebf9",
  [Color.Dusk_50]: "#fdf5fc",
  [Color.Dusk_Base]: "#edade9",

  [Color.Clay_1400]: "#130a07",
  [Color.Clay_1300]: "#25130f",
  [Color.Clay_1200]: "#381d16",
  [Color.Clay_1100]: "#4a261d",
  [Color.Clay_1000]: "#5d3024",
  [Color.Clay_900]: "#6f392c",
  [Color.Clay_800]: "#824333",
  [Color.Clay_700]: "#944c3a",
  [Color.Clay_600]: "#af5a45",
  [Color.Clay_500]: "#bf6f5b",
  [Color.Clay_400]: "#ca8776",
  [Color.Clay_300]: "#d49f92",
  [Color.Clay_200]: "#dfb7ad",
  [Color.Clay_100]: "#eacfc8",
  [Color.Clay_50]: "#f4e7e4",
  [Color.Clay_Base]: "#944c3a",

  [Color.Evergreen_1400]: "#0b1d0c",
  [Color.Evergreen_1300]: "#153918",
  [Color.Evergreen_1200]: "#205624",
  [Color.Evergreen_1100]: "#2b7230",
  [Color.Evergreen_1000]: "#368f3d",
  [Color.Evergreen_900]: "#40ab49",
  [Color.Evergreen_800]: "#54bf5c",
  [Color.Evergreen_700]: "#71c978",
  [Color.Evergreen_600]: "#83d089",
  [Color.Evergreen_500]: "#94d799",
  [Color.Evergreen_400]: "#a6deaa",
  [Color.Evergreen_300]: "#b8e4bb",
  [Color.Evergreen_200]: "#caebcc",
  [Color.Evergreen_100]: "#dbf2dd",
  [Color.Evergreen_50]: "#edf8ee",
  [Color.Evergreen_Base]: "#71c978",

  [Color.Alert_500]: "#b02a2a",
  [Color.Alert_400]: "#c52f2f",
  [Color.Alert_300]: "#d13f3f",
  [Color.Alert_200]: "#da6565",
  [Color.Alert_100]: "#df7878",
  [Color.Alert_50]: "#f4cfcf",
  [Color.Alert_Base]: "#d13f3f",
  [Color.Alert_50_A25]: "rgba(244, 207, 207, 0.25)",

  [Color.Warning_500]: "#a15000",
  [Color.Warning_400]: "#b65b00",
  [Color.Warning_300]: "#ca6500",
  [Color.Warning_200]: "#eb7908",
  [Color.Warning_100]: "#f08a25",
  [Color.Warning_50]: "#ffe0c1",
  [Color.Warning_Base]: "#ca6500",
  [Color.Warning_50_A25]: "rgba(255, 224, 193, 0.25)",

  [Color.Success_500]: "#0b6f4a",
  [Color.Success_400]: "#0c764f",
  [Color.Success_300]: "#0d8559",
  [Color.Success_200]: "#12b378",
  [Color.Success_100]: "#14c383",
  [Color.Success_50]: "#d0f5e7",
  [Color.Success_Base]: "#0d8559",
  [Color.Success_50_A25]: "rgba(192, 249, 228, 0.25)",

  [Color.Info_500]: "#2457be",
  [Color.Info_400]: "#2862d6",
  [Color.Info_300]: "#4074db",
  [Color.Info_200]: "#6690e2",
  [Color.Info_100]: "#799de6",
  [Color.Info_50]: "#d9e3f8",
  [Color.Info_Base]: "#4074db",
  [Color.Info_50_A25]: "rgba(217, 227, 248, 0.25)",

  [Color.White]: "#ffffff",
  [Color.White_A25]: "rgba(255, 255, 255, 0.25)",
  // TODO: support decimals in enum member names.
  // Currently A12 is representing an opacity of 0.125
  [Color.White_A12]: "rgba(255, 255, 255, 0.125)",

  [Color.Transparent]: "transparent",
};

const colorPresets: Record<ColorPreset, string> = {
  [ColorPreset.BackgroundLight_00]: colors[Color.Dawn_700],
  [ColorPreset.BackgroundLight_01]: colors[Color.White],
  [ColorPreset.BackgroundLight_02]: colors[Color.Brownstone_50],
  [ColorPreset.BackgroundLight_03]: colors[Color.Brownstone_Moonstone],
  [ColorPreset.BackgroundLight_04]: colors[Color.Brownstone_300],
  [ColorPreset.BackgroundDark_01]: colors[Color.Brownstone_1400],
  [ColorPreset.BackgroundDark_02]: colors[Color.Slate_1100],
  [ColorPreset.BackgroundDark_03]: colors[Color.Slate_900],
  [ColorPreset.BackgroundDark_04]: colors[Color.Slate_700],

  [ColorPreset.TextOnLight_01]: colors[Color.Greystone_DarkMatter],
  [ColorPreset.TextOnLight_02]: colors[Color.Greystone_1200],
  [ColorPreset.TextOnLight_03]: colors[Color.Greystone_1100],
  [ColorPreset.TextOnDark_00]: colors[Color.Dawn_Base],
  [ColorPreset.TextOnDark_01]: colors[Color.Brownstone_Moonstone],
  [ColorPreset.TextOnDark_02]: colors[Color.Greystone_700],
  [ColorPreset.TextOnDark_03]: colors[Color.Greystone_800],

  [ColorPreset.IconOnLight_01]: colors[Color.Greystone_DarkMatter],
  [ColorPreset.IconOnLight_02]: colors[Color.Greystone_1200],
  [ColorPreset.IconOnLight_03]: colors[Color.Greystone_1100],
  [ColorPreset.IconOnLight_04]: colors[Color.Greystone_1100],
  [ColorPreset.IconOnDark_00]: colors[Color.Dawn_Base],
  [ColorPreset.IconOnDark_01]: colors[Color.Brownstone_Moonstone],
  [ColorPreset.IconOnDark_02]: colors[Color.Greystone_700],
  [ColorPreset.IconOnDark_03]: colors[Color.Greystone_800],
  [ColorPreset.IconOnDark_04]: colors[Color.Greystone_900],

  [ColorPreset.BorderOnLight_01]: colors[Color.Greystone_DarkMatter],
  [ColorPreset.BorderOnLight_02]: colors[Color.Greystone_1000],
  [ColorPreset.BorderOnLight_03]: colors[Color.Greystone_800],
  [ColorPreset.BorderOnLight_04]: colors[Color.Greystone_500],
  [ColorPreset.BorderOnLight_05]: colors[Color.Greystone_1400_A16],
  [ColorPreset.BorderOnDark_01]: colors[Color.Brownstone_Moonstone],
  [ColorPreset.BorderOnDark_02]: colors[Color.Greystone_1000],
  [ColorPreset.BorderOnDark_03]: colors[Color.Greystone_1200],
  [ColorPreset.BorderOnDark_04]: colors[Color.Greystone_1300],
  [ColorPreset.BorderOnDark_05]: colors[Color.White_A25],

  [ColorPreset.AlertTextOnLight]: colors[Color.Alert_400],
  [ColorPreset.AlertIconOnLight]: colors[Color.Alert_300],
  [ColorPreset.AlertBorderOnLight]: colors[Color.Alert_300],
  [ColorPreset.AlertTextOnDark]: colors[Color.Alert_100],
  [ColorPreset.AlertIconOnDark]: colors[Color.Alert_200],
  [ColorPreset.AlertBorderOnDark]: colors[Color.Alert_200],

  [ColorPreset.DataOnLight_01]: colors[Color.Sunset_800],
  [ColorPreset.DataOnLight_02]: colors[Color.Ultraviolet_700],
  [ColorPreset.DataOnLight_03]: colors[Color.Pebble_1000],
  [ColorPreset.DataOnLight_04]: colors[Color.Dusk_900],
  [ColorPreset.DataOnLight_05]: colors[Color.Clay_700],
  [ColorPreset.DataOnLight_06]: colors[Color.Sunrise_1000],
  [ColorPreset.DataOnLight_07]: colors[Color.Evergreen_1000],
  [ColorPreset.DataOnLight_08]: colors[Color.Ocean_900],
  [ColorPreset.DataOnDark_01]: colors[Color.Sunset_600],
  [ColorPreset.DataOnDark_02]: colors[Color.Ultraviolet_400],
  [ColorPreset.DataOnDark_03]: colors[Color.Pebble_800],
  [ColorPreset.DataOnDark_04]: colors[Color.Dusk_800],
  [ColorPreset.DataOnDark_05]: colors[Color.Clay_400],
  [ColorPreset.DataOnDark_06]: colors[Color.Sunrise_600],
  [ColorPreset.DataOnDark_07]: colors[Color.Evergreen_700],
  [ColorPreset.DataOnDark_08]: colors[Color.Ocean_600],
};

export const defaultDesignTokens: DesignTokens = {
  fontFamilies: {
    "sans-serif": fontFamiliesConfig.baseFontFamily,
    display: fontFamiliesConfig.displayFontFamily,
    monospace: fontFamiliesConfig.monospaceFontFamily,
    serif: fontFamiliesConfig.serifFontFamily,
  },
  spacingBase: 16,
  borderRadiusBase: 16,
  breakpoints: {
    [Breakpoint.Sm]: "480px",
    [Breakpoint.Md]: "768px",
    [Breakpoint.Lg]: "1120px",
    [Breakpoint.Xl]: "1600px",
  },
  contentMaxWidth: ["100vw", "540px", "90vw", null, "1360px"],
  fontSizes: {
    [TypeScale.Size_01]: { fontSize: "12px", lineHeight: "16px" },
    [TypeScale.Size_02]: { fontSize: "14px", lineHeight: "20px" },
    [TypeScale.Size_03]: { fontSize: "16px", lineHeight: "22px" },
    [TypeScale.Size_04]: { fontSize: "18px", lineHeight: "24px" },
    [TypeScale.Size_05]: { fontSize: "20px", lineHeight: "28px" },
    [TypeScale.Size_06]: { fontSize: "24px", lineHeight: "32px" },
    [TypeScale.Size_07]: { fontSize: "28px", lineHeight: "34px" },
    [TypeScale.Size_08]: { fontSize: "32px", lineHeight: "38px" },
    [TypeScale.Size_09]: { fontSize: "36px", lineHeight: "44px" },
    [TypeScale.Size_10]: { fontSize: "42px", lineHeight: "50px" },
    [TypeScale.Size_11]: { fontSize: "48px", lineHeight: "58px" },
    [TypeScale.Size_12]: { fontSize: "54px", lineHeight: "65px" },
    [TypeScale.Size_13]: { fontSize: "60px", lineHeight: "72px" },
    [TypeScale.Size_14]: { fontSize: "68px", lineHeight: "82px" },
    [TypeScale.Size_15]: { fontSize: "76px", lineHeight: "90px" },
    [TypeScale.Size_16]: { fontSize: "84px", lineHeight: "100px" },
    [TypeScale.Size_17]: { fontSize: "92px", lineHeight: "110px" },
    [TypeScale.Size_18]: { fontSize: "102px", lineHeight: "122px" },
    [TypeScale.Size_19]: { fontSize: "112px", lineHeight: "134px" },
    [TypeScale.Size_20]: { fontSize: "122px", lineHeight: "146px" },
    [TypeScale.Size_21]: { fontSize: "132px", lineHeight: "158px" },
    [TypeScale.Size_22]: { fontSize: "144px", lineHeight: "172px" },
  },
  typePresets: {
    [TypePreset.Body_01]: { size: TypeScale.Size_02 },
    [TypePreset.Body_02]: { size: TypeScale.Size_03 },
    [TypePreset.Body_03]: { size: TypeScale.Size_04 },
    [TypePreset.Body_04]: { size: TypeScale.Size_05 },

    [TypePreset.LongForm_01]: {
      fontFamily: "serif",
      weight: FontWeight.Light,
      size: TypeScale.Size_02,
      lineHeight: TypeScale.Size_03,
    },
    [TypePreset.LongForm_02]: {
      fontFamily: "serif",
      weight: FontWeight.Light,
      size: TypeScale.Size_03,
      lineHeight: TypeScale.Size_04,
    },
    [TypePreset.LongForm_03]: {
      fontFamily: "serif",
      weight: FontWeight.Light,
      size: TypeScale.Size_04,
      lineHeight: TypeScale.Size_05,
    },
    [TypePreset.LongForm_04]: {
      fontFamily: "serif",
      weight: FontWeight.Light,
      size: TypeScale.Size_05,
      lineHeight: TypeScale.Size_06,
    },

    [TypePreset.Caption_01]: { size: TypeScale.Size_01 },

    [TypePreset.Code_01]: {
      size: TypeScale.Size_02,
      fontFamily: "monospace",
    },
    [TypePreset.Code_02]: {
      size: TypeScale.Size_03,
      fontFamily: "monospace",
    },
    [TypePreset.Code_03]: {
      size: TypeScale.Size_04,
      fontFamily: "monospace",
    },
    [TypePreset.Code_04]: {
      size: TypeScale.Size_01,
      fontFamily: "monospace",
    },

    [TypePreset.Subheading_01]: {
      size: TypeScale.Size_05,
      fontFamily: "serif",
      weight: FontWeight.Light,
    },
    [TypePreset.Subheading_02]: {
      size: TypeScale.Size_06,
      fontFamily: "serif",
      weight: FontWeight.Light,
    },

    [TypePreset.Heading_01]: {
      size: TypeScale.Size_02,
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_02]: {
      size: TypeScale.Size_03,
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_03]: {
      size: TypeScale.Size_04,
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_04]: {
      size: TypeScale.Size_05,
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_05]: {
      size: [TypeScale.Size_05, TypeScale.Size_06],
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_06]: {
      size: [TypeScale.Size_06, TypeScale.Size_07],
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_07]: {
      size: [TypeScale.Size_07, TypeScale.Size_08, null, TypeScale.Size_09],
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_08]: {
      size: [TypeScale.Size_08, TypeScale.Size_10, null, TypeScale.Size_11],
      weight: FontWeight.SemiBold,
    },
    [TypePreset.Heading_09]: {
      size: [TypeScale.Size_09, TypeScale.Size_11, null, TypeScale.Size_13],
      weight: FontWeight.SemiBold,
    },

    [TypePreset.Quote_01]: {
      size: [TypeScale.Size_08, TypeScale.Size_10, null, TypeScale.Size_11],
      fontFamily: "serif",
      weight: FontWeight.Light,
    },
    [TypePreset.Quote_02]: {
      size: [TypeScale.Size_05, null, TypeScale.Size_06],
      fontFamily: "serif",
      weight: FontWeight.Light,
    },

    [TypePreset.Display_01]: {
      size: [TypeScale.Size_12, TypeScale.Size_17, null, TypeScale.Size_21],
      fontFamily: "display",
      weight: FontWeight.Bold,
    },
    [TypePreset.Display_02]: {
      size: [TypeScale.Size_13, TypeScale.Size_18, null, TypeScale.Size_22],
      fontFamily: "display",
      weight: FontWeight.Bold,
    },
  },
  elevation: {
    0: "none",
    1: "drop-shadow(0 0px 1px rgba(0,0,0,0.10))",
    2: "drop-shadow(0 1px 4px rgba(0,0,0,0.10))",
    3: "drop-shadow(0 2px 6px rgba(0,0,0,0.10))",
    4: "drop-shadow(0 6px 8px rgba(0,0,0,0.10))",
    5: "drop-shadow(0 10px 12px rgba(0,0,0,0.10))",
    6: "drop-shadow(0 16px 24px rgba(0,0,0,0.10))",
  },
  contentGutters: 1,
  gridGutters: [0.25, null, 0.5, 1],
  colors: {
    ...colors,
    ...colorPresets,
  },
  colorPresets,
};
