import React from "react";

import type { MenuStyleProps } from "./menuStyle";

export enum MenuRole {
  /**
   * This role indicates that the menu is used for select from a list actions
   * or functions. It also sets up keyboard shortcuts that are expected when
   * navigating this type of menu: Up/Down/Home/End.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role ARIA: menu role}
   */
  Menu = "menu",

  /**
   * This role indicates that the menu is a plain list of navigation links and
   * supporting content. It is typically used for display lists of links in a
   * site's footer, navigation sidebar or other primary navigation elements.
   */
  List = "list",
}

export const menuKeyProp = "data-menu-key";

const MenuRoleContext = React.createContext<MenuRole>(MenuRole.List);

export const MenuRoleProvider: React.FC<{
  value: MenuRole;
  children: React.ReactNode;
}> = ({ value, children }) => {
  return (
    <MenuRoleContext.Provider value={value}>
      {children}
    </MenuRoleContext.Provider>
  );
};

export const useMenuRole = () => {
  return React.useContext(MenuRoleContext);
};

export interface MenuSelectionState {
  /**
   * Indicates the currently selected item in the focusable list of menu items.
   */
  selectedKey: string | null;
  /**
   * Indicates when the list is interactive so that custom tab indexes are
   * applied.
   */
  interactive: boolean;
}
const MenuSelectionContext = React.createContext<MenuSelectionState>({
  selectedKey: null,
  interactive: false,
});

export const MenuSelectionProvider: React.FC<{
  value: string | null;
  children: React.ReactNode;
}> = ({ children, value: selectedKey }) => {
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  const value = React.useMemo(
    () => ({ interactive: isClient, selectedKey }),
    [isClient, selectedKey]
  );

  return (
    <MenuSelectionContext.Provider value={value}>
      {children}
    </MenuSelectionContext.Provider>
  );
};

export const useMenuSelectionState = () => {
  const key = React.useId();
  const state = React.useContext(MenuSelectionContext);
  const selected = key === state.selectedKey;
  const tabIndex = selected ? 0 : -1;
  return { key, selected, tabIndex: state.interactive ? tabIndex : undefined };
};

const MenuLevelContext = React.createContext(-1);

export const MenuLevelProvider: React.FC<{
  value: number;
  children: React.ReactNode;
}> = ({ value, children }) => {
  return (
    <MenuLevelContext.Provider value={value}>
      {children}
    </MenuLevelContext.Provider>
  );
};

export const useMenuLevel = () => {
  return React.useContext(MenuLevelContext);
};

const MenuLayout = React.createContext<MenuStyleProps["groupLayout"]>("row");

export const MenuLayoutProvider: React.FC<{
  value: MenuStyleProps["groupLayout"];
  children: React.ReactNode;
}> = ({ value, children }) => {
  return <MenuLayout.Provider value={value}>{children}</MenuLayout.Provider>;
};

export const useMenuLayout = () => {
  return React.useContext(MenuLayout);
};

/**
 * Builds a keyboard event handler that allows navigating menus using
 * Up/Down/Home/End keys.
 */
export const useMenuKeyboardHandler = ({
  setSelected,
  inboundHandler,
}: {
  setSelected: (key: string | null) => void;
  inboundHandler?: React.KeyboardEventHandler<HTMLUListElement>;
}): React.KeyboardEventHandler<HTMLUListElement> => {
  return (e) => {
    inboundHandler?.(e);

    if (e.isDefaultPrevented()) {
      return;
    }

    const allowedKeys = ["Up", "ArrowUp", "Down", "ArrowDown", "Home", "End"];
    // Short-circuit so we don't do expensive work
    if (!allowedKeys.includes(e.key)) {
      return;
    }

    // If we did indeed catch an allowed key then prevent default behavior.
    // In some browsers, up/down will cause page scroll if not intercepted.
    e.preventDefault();

    const { currentTarget: root, target } = e;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    // Find all menu items under this menu
    const allItems = Array.from(
      root.querySelectorAll("[role=menuitem]:not(:disabled)")
    );
    // ... and the subset of menu items that are nested under hidden lists
    const hiddenItems = Array.from(
      root.querySelectorAll("ul[hidden] [role=menuitem]")
    );
    // ... and create a list of focusable menu items to traverse
    const fitleredItems: HTMLElement[] = [];
    allItems.forEach((item) => {
      const isHTMLElement = item instanceof HTMLElement;
      const isHidden = hiddenItems.indexOf(item) >= 0;
      if (!isHTMLElement || isHidden) {
        return;
      }
      fitleredItems.push(item);
    });

    const lastIndex = fitleredItems.length - 1;
    const currIndex = fitleredItems.indexOf(target);
    let nextIndex = currIndex;

    if (["Up", "ArrowUp"].includes(e.key)) {
      nextIndex = nextIndex > 0 ? nextIndex - 1 : lastIndex;
    } else if (["Down", "ArrowDown"].includes(e.key)) {
      nextIndex = nextIndex < lastIndex ? nextIndex + 1 : 0;
    } else if (e.key === "Home") {
      nextIndex = 0;
    } else if (e.key === "End") {
      nextIndex = lastIndex;
    }

    const nextEl = fitleredItems[nextIndex] || fitleredItems[0];
    if (!nextEl) {
      return;
    }

    const key = nextEl.getAttribute(menuKeyProp);
    setSelected(key);

    nextEl.focus();
  };
};
