import type React from "react";

export enum SortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export enum SortMode {
  Single = "single",
  Controlled = "controlled",
  /**
   * @ignore Undocumented feature.
   */
  Multi = "multi",
}

//#region Data Table API namespace
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace DataTableAPI {
  interface Heading<Item, Accessor extends keyof Item = keyof Item> {
    /**
     * The label to use for a given heading.
     */
    label: string;
    /**
     * The key in the data that this heading corresponds to.
     */
    accessor: Accessor;
    /**
     * Indicates whether or not sorting is enabled for this heading
     */
    sorting?: "disabled";
    /**
     * An optional function that customises the sorting logic for this heading's
     * column.
     */
    comparator?: SortFunc<Item, Accessor>;
  }

  interface TableProps {
    role: "grid";
    "aria-readonly"?: boolean;
  }

  interface RowProps {
    key: string;
    role: "row";
    onClick?: React.MouseEventHandler<HTMLTableRowElement | HTMLButtonElement>;
    tabIndex?: number;
    link?: string;
  }

  type ActionRender<Item> = (item: Item) => React.ReactNode[];

  type CompactActions<Item> = {
    rightActions?: ActionRender<Item>;
    bottomActions?: ActionRender<Item>;
  };

  interface CompactRowInstanceActions {
    rightActions?: React.ReactNode[];
    bottomActions?: React.ReactNode[];
  }

  interface RowInstance<Item> {
    /** The cells contained within the row. */
    cells: CellInstance<Item>[];
    /** The item, from the dataset, that is represented by the row. */
    item: Item | PlaceholderItem;
    /**
     * Returns a set of HTML attributes to apply on a data table's row element.
     */
    getRowProps(): RowProps;
    /**
     * Returns the placement and rendered actions for the compact view
     */
    compactActions?: CompactRowInstanceActions;
  }

  interface CellProps {
    key: string;
    role: "gridcell" | "rowheader";
    scope?: "row";
    onClick?: React.MouseEventHandler<HTMLTableCellElement | HTMLButtonElement>;
  }

  interface DataCellInstance<Item, Accessor extends keyof Item = keyof Item> {
    type: "data";
    /**
     * The key in the data table's dataset that this cell represents.
     */
    accessor: Accessor;
    /**
     * The value of the cell.
     */
    value: Item[Accessor];
    /**
     * The item, from the dataset, that is represented by the cell.
     */
    item: Item | PlaceholderItem;
    /**
     * Indicates whether or not this cell element contains the row link.
     */
    omitLink: boolean;
    /**
     * Returns a set of HTML attributes to apply on a data table's cell element.
     */
    getCellProps(): CellProps;
    /**
     * A function that returns the rendered content of the cell.
     */
    render(): React.ReactNode;
  }

  interface PlaceholderCellInstance<
    Item,
    Accessor extends keyof Item = keyof Item
  > {
    type: "loading";
    /** The key in the data table's dataset that this cell represents. */
    accessor: Accessor;
    /**
     * Indicates whether or not this cell element contains the row link.
     */
    omitLink: boolean;
    /**
     * Returns a set of HTML attributes to apply on a data table's cell element.
     */
    getCellProps(): CellProps;
  }

  type CellInstance<Item> =
    | DataCellInstance<Item>
    | PlaceholderCellInstance<Item>;

  interface HeadingProps {
    key: string;
    role: "columnheader";
    scope: "col";
    "aria-sort"?: "ascending" | "descending";
  }

  /**
   * The result of a custom heading render function. This function can return a
   * ReactNode or an object contain the label content and optional left and
   * right accessories.
   */
  type HeadingRendererResult =
    | React.ReactNode
    | {
        /**
         * The label content to display in the heading.
         */
        label: React.ReactNode;
        /**
         * An optional accessory such as an icon, tooltip or link to display
         * alongside the heading label.
         */
        leftAccessory?: React.ReactNode;
        /**
         * An optional accessory such as an icon, tooltip or link to display
         * alongside the heading label.
         */
        rightAccessory?: React.ReactNode;
      };

  interface HeadingInstance<Item> {
    /**
     * The key in a dataset which a heading represents.
     */
    accessor: keyof Item;
    /**
     * A flag representing whether or not this heading can be sorted.
     */
    canSort?: boolean;
    /**
     * The current sort direction of the column represented by a heading.
     */
    sorted?: SortDirection;
    /**
     * A function that is used to change the sort order for the column
     * represented by a heading.
     */
    toggleSort(): void;
    /**
     * A set of HTML attributes to apply to the heading cell of a data table.
     */
    getHeadingProps(): HeadingProps;
    /**
     * A function that renders the heading's content.
     */
    render(): HeadingRendererResult;
    /**
     * Return wether the heading should be omitted on the compact view
     */
    omittedWhenCompact?: boolean;
  }

  interface HeadingsRowInstance<Item> {
    cells: HeadingInstance<Item>[];
    getRowProps(): RowProps;
  }

  type SortFunc<Item, Accessor extends keyof Item = keyof Item> = (
    a: Item[Accessor],
    b: Item[Accessor]
  ) => number;

  type SortState<Item> = [keyof Item, SortDirection][];

  type HeadingRenderers<Item> = {
    [k in keyof Item]?: (result: {
      label: string;
    }) => React.ReactNode | HeadingRendererResult;
  };

  type CellRenderers<Item> = {
    [k in keyof Item]?: (result: {
      value: Item[k];
      item: Item;
    }) => React.ReactNode;
  };

  interface PlaceholderItem {
    id: string;
    loading: true;
  }

  type UseDataTableOptions<Item> = {
    /**
     * A list of headings to display in the table. This option also determines
     * the subset of columns to render for each row.
     */
    headings: Heading<Item, keyof Item>[];
    /**
     * The row data to display in the table. This can also include placeholders
     * that will be rendered while waiting on data asynchronously.
     */
    data: (Item | PlaceholderItem)[];
    /**
     * A function that is used to generate a unqiue id for a data table row.
     * @param item The item for which to generate a unique id.
     */
    getItemId(item: Item): string;
    /**
     * A function that is used to generate a unqiue link for a data table row.
     * @param item The item for which to generate a unique link with item id.
     */
    getRowLink?(item: Item): string;
    /**
     * Sets the sorting mechanism to use on a data table. This can be
     * uncontrolled, single-column sorting or controlled sorting which delegates
     * management of sort state to a parent component.
     */
    sorting?: SortMode;
    /**
     * When using `SortMode.Controlled`, this option sets the sorted columns to
     * reflect in the table header.
     */
    activeSort?: SortState<Item>;
    /**
     * When using `SortMode.Controlled`, this option defines a callback to call
     * when the user changes the sorting of a column in the table.
     */
    onControlledSort?(accessor: keyof Item): void;
    /**
     * A callback to call when a row in the table is clicked.
     *
     * @param item The item that is represented by a row.
     */
    onRowClick?(item: Item): void;
    /**
     * An optional mapping that can be used to customise the rendering of
     * headings in a table.
     */
    headingRenderers?: HeadingRenderers<Item>;
    /**
     * An optional mapping that can be used to customise the rendering of cells
     * in a table.
     */
    cellRenderers?: CellRenderers<Item>;
    /**
     * Add extra actions for each row on the compact view.
     */
    compactActions?: CompactActions<Item>;
    /**
     * Define headings, if any that should be omitted on the compact view.
     */
    omitCompactHeadings?: (keyof Item)[];

    /**
     * Define columns, if any that should be omitted from the row link.
     */
    omitColumnLinks?: (keyof Item)[];
  };

  interface Instance<Item> {
    /**
     * A list that describes the currently sorted column(s) in a table.
     */
    activeSort: SortState<Item>;
    /**
     * The list of rows to render in a data table component.
     */
    rows: RowInstance<Item>[];
    /**
     * The list of headings to render in a data table component.
     */
    headings: HeadingsRowInstance<Item>;
    /**
     * A set of HTML attributes to apply to a data table's root element.
     */
    getTableProps(): TableProps;
  }
}
//#endregion
