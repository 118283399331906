import * as React from "react";

import { listResetStyle } from "./menuStyle";

type HTMLAttributes = React.HTMLAttributes<HTMLUListElement>;

export interface MenuULProps extends HTMLAttributes {
  children: React.ReactNode;
}

const MenuUL = React.forwardRef<HTMLUListElement, MenuULProps>((props, ref) => {
  return <ul css={listResetStyle} {...props} ref={ref} />;
});

export default MenuUL;
