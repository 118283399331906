import type * as React from "react";

import { Interpolation, useTheme } from "../theme";
import { focusRingVar } from "../theme/cssvariables";

export interface TabPanelProps {
  id: string;
  labelId: string;
  selected?: boolean;
  children: React.ReactNode;
}

const tabPanelStyle: Interpolation = {
  flex: "1 1 100%",
  boxShadow: `var(${focusRingVar})`,
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { theme } = useTheme();
  return (
    <div
      css={[tabPanelStyle, { borderRadius: theme.radius(0.25) }]}
      id={props.id}
      role="tabpanel"
      aria-labelledby={props.labelId}
      tabIndex={0}
      hidden={!props.selected || undefined}
    >
      {props.children}
    </div>
  );
};

export default TabPanel;
