import { ResponsiveValue, CSSRulesFunction, useTheme } from "../theme";

export interface FlexItemProps {
  grow?: ResponsiveValue<number>;
  shrink?: ResponsiveValue<number>;
  basis?: ResponsiveValue<string>;
  children: React.ReactNode;
}

const flexItemStyle: CSSRulesFunction<FlexItemProps> = (theme, props) => {
  return [
    { display: "block" },
    theme.responsive(props.grow, (v) => ({ flexGrow: v })),
    theme.responsive(props.shrink, (v) => ({ flexShrink: v })),
    theme.responsive(props.basis, (v) => ({ flexBasis: v })),
  ];
};

const FlexItem: React.FC<FlexItemProps> = (props) => {
  const { theme } = useTheme();
  return <span css={flexItemStyle(theme, props)}>{props.children}</span>;
};

export default FlexItem;
