import Box from "../layout/Box";
import {
  BorderRadiusScale,
  CSSValue,
  ResponsiveValue,
  SpaceScale,
  useTheme,
} from "../theme";

import { shimmerStyle } from "./styles";
export interface ShimmerProps {
  width?: ResponsiveValue<number | string>;
  minWidth?: ResponsiveValue<number | string>;
  maxWidth?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<string | number>;
  minHeight?: ResponsiveValue<string | number>;
  spaceAbove?: ResponsiveValue<SpaceScale>;
  spaceBelow?: ResponsiveValue<SpaceScale>;
  spaceBefore?: ResponsiveValue<SpaceScale>;
  spaceAfter?: ResponsiveValue<SpaceScale>;
  layout?: "block" | "inline-block" | "flex" | "inline-flex";
  borderRadius?: ResponsiveValue<CSSValue<BorderRadiusScale>>;
}

const Shimmer: React.FC<ShimmerProps> = (props) => {
  const { theme } = useTheme();
  return <Box css={shimmerStyle(theme, props)} />;
};

export default Shimmer;
