import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";
import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { useAccessToken } from "src/common/authorisation";

/*
 * We fetch the organisation by the the link
 * in the access token to support staff user impersonating
 * merchant.
 */
export const useOrganisation = (): OrganisationResource | undefined => {
  const [accessToken] = useAccessToken();

  const { data: organisation } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );

  return organisation?.organisations;
};

export const useOrganisationId = (): string | undefined => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return organisation.id;
};

export const useDefaultCountryCode = (): string | undefined => {
  const organisation = useOrganisation();
  return organisation?.signup_country_code ?? undefined;
};

export const useDefaultCurrency = (): string | undefined => {
  const organisation = useOrganisation();
  return organisation?.domestic_currency ?? undefined;
};
