import React, { useState, createContext, useContext } from "react";

import { AccordionMode, AccordionStyleProps } from "./types";

type UseAccordionManagerResult = [string[], (itemId: string) => void];
interface UseAccordionManagerOptions {
  minimumSelected: 0 | 1;
}

export const useAccordionManager = (
  mode: "single" | "multi",
  initialSelected: string[] = [],
  options: UseAccordionManagerOptions
): UseAccordionManagerResult => {
  type ItemMap = Record<string, boolean>;

  const { minimumSelected } = options;
  const [selected, setSelected] = useState(() => {
    const source =
      mode === "single" ? initialSelected.slice(0, 1) : initialSelected;
    return source.reduce<ItemMap>((acc, v) => ({ ...acc, [v]: true }), {});
  });

  const toggleItem = (itemId: string) => {
    setSelected((current) => {
      let clone: ItemMap = { [itemId]: true };

      if (mode === "multi") {
        clone = { ...clone, ...current };
      }

      if (current[itemId]) {
        delete clone[itemId];
      }

      if (Object.keys(clone).length < minimumSelected) {
        return current;
      }

      return clone;
    });
  };

  const selectedIds = React.useMemo(() => Object.keys(selected), [selected]);

  return [selectedIds, toggleItem];
};

type AccordionConfig = Required<
  Pick<
    AccordionStyleProps,
    "mode" | "minimumSelected" | "toggleVisibility" | "autoScrollIntoView"
  >
>;

interface AccordionContextValue extends AccordionConfig {
  selectedItems: string[];
  toggleItem: (itemId: string) => void;
}

export const AccordionContext = createContext<AccordionContextValue>({
  autoScrollIntoView: true,
  selectedItems: [],
  toggleItem: () => {},
  mode: AccordionMode.Single,
  minimumSelected: 1,
  toggleVisibility: "visible",
});

export const useAccordion = (): AccordionContextValue => {
  return useContext(AccordionContext);
};
