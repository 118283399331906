import Icon, { IconProps } from "./Icon";
import { Glyph } from "./registry";

export interface ChevronToggleProps {
  mode?: "open" | "closed";
  size?: IconProps["size"];
}

const ChevronToggle: React.FC<ChevronToggleProps> = ({ mode, size }) => {
  return (
    <Icon
      name={Glyph.ChevronRight}
      size={size}
      css={{
        willChange: "transform",
        transition: `transform 250ms ease-in-out`,
        transform: mode === "open" ? "rotate(90deg)" : "rotate(0)",
      }}
    />
  );
};

export default ChevronToggle;
