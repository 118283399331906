import {
  CSSRulesFunction,
  CSSValue,
  InputGutter,
  ResponsiveValue,
} from "./types";

type CSSValueExpander<Arg, Value> = (args: CSSValue<Arg>) => string | Value;

export const makeCSSValueHelper = <Arg, Value>(
  f: (arg: Arg) => Value
): CSSValueExpander<Arg, Value> => {
  const helper = function calcCSSValue(args: CSSValue<Arg>) {
    if (!Array.isArray(args)) {
      return f(args);
    }
    const first = f(args[0]);
    const second = f(args[1]);
    const third = args[2] == null ? "" : f(args[2]);
    const fourth = args[3] == null ? "" : f(args[3]);
    return `${first} ${second} ${third} ${fourth}`;
  };

  return helper;
};

export const inputGutterStyle: CSSRulesFunction<{
  /**
   * The size of the gutter to apply.
   */
  size: ResponsiveValue<InputGutter> | undefined;
  /**
   * An optional CSS variable that can be used to further adjust the padding.
   * This is useful when we want input dimensions to account for inset shadows
   * set on a parent element for example.
   */
  adjustmentVar?: string;
}> = (theme, props) => {
  const { size = InputGutter.Md, adjustmentVar } = props;
  return theme.responsive(size, (val) => {
    switch (val) {
      case InputGutter.Sm:
        let v = theme.spacing(0.5);
        let h = theme.spacing(0.75);
        v = adjustmentVar ? `calc(${v} - var(${adjustmentVar}, 0))` : v;
        h = adjustmentVar ? `calc(${h} - var(${adjustmentVar}, 0))` : h;

        return { padding: `${v} ${h}` };
      default:
        let p = theme.spacing(1);
        p = adjustmentVar ? `calc(${p} - var(${adjustmentVar}, 0))` : p;

        return { padding: p };
    }
  });
};
