import * as React from "react";
import { toast as rhtToast } from "react-hot-toast";

type Toast = { id: string };
type ToastOptions = {
  duration?: number;
};

interface ToastAPI {
  /**
   * Pushes a toast notification to be displayed on the screen
   * @param node a React element that will be rendered as a toast notification
   * @param options configures the behaviour of toast notifications
   * @returns the id of the toast message
   */
  toast(node: React.ReactElement, options?: ToastOptions): string;
  /**
   * Pushes a toast notification to be displayed on the screen
   * @param render a render function that receives the toast state and returns a
   *    React element. The state is useful for building a dismiss/close button
   *    since it provides the toast message id.
   * @param options configures the behaviour of toast notifications
   * @returns the id of the toast message
   */
  toast(
    render: (state: Toast) => React.ReactElement,
    options?: ToastOptions
  ): string;

  /**
   * Dismisses a toast message
   * @param id the id of the toast message to dismiss
   */
  dismissToast(id: string): void;

  /**
   * Dismisses all toast messages
   */
  dismissAllToasts(): void;
}

const defaultHandlers: ToastAPI = {
  toast(
    elementOrRender:
      | React.ReactElement
      | ((state: Toast) => React.ReactElement),
    options?: ToastOptions
  ): string {
    const duration = options?.duration || 4000;

    return rhtToast(elementOrRender, { duration });
  },

  dismissToast(id) {
    rhtToast.dismiss(id);
  },

  dismissAllToasts() {
    rhtToast.dismiss();
  },
};

const ToastContext = React.createContext<ToastAPI>(defaultHandlers);

export const ToastProvider: React.FC<{
  handlers: Partial<ToastAPI>;
  children: React.ReactNode;
}> = (props) => {
  const handlers = React.useMemo(
    () => ({ ...defaultHandlers, ...props.handlers }),
    [props.handlers, defaultHandlers]
  );

  return (
    <ToastContext.Provider value={handlers}>
      {props.children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastAPI => {
  return React.useContext(ToastContext);
};
