import { forwardRef } from "react";

import { Box, BoxProps } from "../../layout";

interface ListItemsContainerProps extends BoxProps {
  children: React.ReactNode;
}
export const ListItemsContainer = forwardRef<
  HTMLDivElement,
  ListItemsContainerProps
>(({ children, ...rest }: ListItemsContainerProps, ref) => {
  return (
    <Box
      ref={ref}
      css={{
        "&:focus-visible": {
          outline: "none",
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});
