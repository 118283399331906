import { IconButton, ButtonVariant } from "../../buttons";
import { Glyph } from "../../icons";
import { ButtonSize, TextAlign, FontWeight } from "../../theme";
import { Text } from "../../typography";

import { dateInRange, type CalendarDate } from "./dateHelpers";
import { calendarHeaderStyle } from "./calendarStyles";
import type { DatesI18n } from "./i18n";

export interface CalendarHeaderProps {
  i18n: DatesI18n;
  onNext(): void;
  onPrevious(): void;
  focusedDate: CalendarDate;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  const { i18n, focusedDate, minDate, maxDate } = props;

  // Disable previous button of current date is less than min date
  const disablePrev = !dateInRange({
    min: minDate,
    dt: { ...focusedDate, day: 1 },
  });

  // Disable next button of current date is less than max date
  const disableNext = !dateInRange({
    max: maxDate,
    dt: { ...focusedDate, day: 31 },
  });

  return (
    <div css={calendarHeaderStyle}>
      <IconButton
        icon={Glyph.ChevronLeft}
        variant={ButtonVariant.TextOnLight}
        size={ButtonSize.Sm}
        label={i18n.previousMonthButtonLabel}
        onClick={props.onPrevious}
        disabled={disablePrev}
      />
      <Text
        textAlign={TextAlign.Center}
        weight={FontWeight.SemiBold}
        aria-live="polite"
      >
        {i18n.getMonthYearLabel(focusedDate)}
      </Text>
      <IconButton
        icon={Glyph.ChevronRight}
        variant={ButtonVariant.TextOnLight}
        size={ButtonSize.Sm}
        label={i18n.nextMonthButtonLabel}
        onClick={props.onNext}
        disabled={disableNext}
      />
    </div>
  );
};
