import type { Glyph } from "../../icons";
import type { ResponsiveValue } from "../../theme";

export enum TimelineItemStatus {
  Alert = "alert",
  Neutral = "neutral",
  Success = "success",
  Warning = "warning",
}

export interface TimelineItemStatusProps {
  status: TimelineItemStatus;
  icon: Glyph;
}

export interface TimelineItemDateTime {
  date: React.ReactNode;
  time?: React.ReactNode;
}

export interface TimelineItemProps {
  /**
   * The descriptive title of the timeline item
   */
  title: React.ReactNode;
  /**
   * The date and time of the timeline item
   */
  dateTime: TimelineItemDateTime;
  /**
   * The current status of the timeline item
   */
  statusProps: TimelineItemStatusProps;
  /**
   * The current state of the timeline item. State is `active` by default
   */
  state?: "active" | "inactive";
  /**
   * Add some extra information to the timeline item
   */
  children?: React.ReactNode;
}

export interface TimelineProps {
  /**
   * Set an optional maxwidth to restrict the content of timeline items
   */
  maxWidth?: ResponsiveValue<number | string>;
  /**
   * Pass in the timeline items
   */
  children: React.ReactNode;
}
