import { ClassNames } from "@emotion/react";
import * as React from "react";

import { useTheme } from "../theme";
import { omitTypographyStyleProps } from "../typography/appearance";

import { plainButtonStyle } from "./plain";
import type { ButtonRenderProps, PlainButtonStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof PlainButtonStyleProps
>;

export interface PlainLinkProps extends HTMLAttributes, PlainButtonStyleProps {
  href: string;
  children: React.ReactNode;

  /**
   * Customizes the rendering of the underlying link element. This is useful
   * when integrating this component with React frameworks that come with their
   * own router and link component implementations.
   */
  render?: (props: ButtonRenderProps & { href: string }) => React.ReactNode;
}

const PlainLink = React.forwardRef<HTMLAnchorElement, PlainLinkProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const { effect, render, children, ...rest } = props;

    return (
      <ClassNames>
        {({ css, cx }) => {
          const className = cx(
            css(plainButtonStyle(theme, props)),
            props.className
          );
          const forwardedProps = {
            ...omitTypographyStyleProps(rest),
            className,
            children,
          };

          return render ? (
            render(forwardedProps)
          ) : (
            <a {...forwardedProps} ref={ref}>
              {children}
            </a>
          );
        }}
      </ClassNames>
    );
  }
);

export default PlainLink;
