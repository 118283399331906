import type { ResponsiveValue, CSSRulesFunction, SpaceScale } from "../theme";

export interface GutterProps {
  gutterV?: ResponsiveValue<SpaceScale>;
  gutterH?: ResponsiveValue<SpaceScale>;
}

export const gutterStyle: CSSRulesFunction<GutterProps> = (theme, props) => [
  theme.responsive(props.gutterV, (v) => ({
    paddingTop: theme.spacing(v),
    paddingBottom: theme.spacing(v),
  })),
  theme.responsive(props.gutterH, (h) => ({
    paddingLeft: theme.spacing(h),
    paddingRight: theme.spacing(h),
  })),
];
