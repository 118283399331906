import { useTheme, type ColorProp } from "../../theme";

import { asString, CalendarDate } from "./dateHelpers";
import {
  calendarButtonStyle,
  hideButton,
  highlightCalendarButton,
} from "./calendarStyles";
import {
  calendarRangeButtonStyle,
  highlightCalendarRangeButton,
} from "./calendarRangeStyles";

export interface CalendarButtonProps {
  date: CalendarDate;
  disabled?: boolean;
  tabIndex?: number;
  onClick: () => void;
  "aria-label": string;
  "data-autofocus"?: boolean;
  "data-selected"?: boolean;
  children: React.ReactNode;
  hidden?: boolean;
  isHighlighted?: boolean;
  bg?: ColorProp;
  isRangePicker?: boolean;
}

const CalendarButton: React.FC<CalendarButtonProps> = (props) => {
  const { children, hidden, isHighlighted, bg, date, isRangePicker, ...rest } =
    props;
  const { theme } = useTheme();

  return (
    <button
      css={
        isRangePicker
          ? [
              calendarRangeButtonStyle,
              hidden && hideButton,
              isHighlighted && highlightCalendarRangeButton(theme, bg),
            ]
          : [
              calendarButtonStyle,
              hidden && hideButton,
              isHighlighted && highlightCalendarButton(theme, bg),
            ]
      }
      type="button"
      data-date={asString(date)}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
};

export default CalendarButton;
