import * as React from "react";

import { Glyph, Icon } from "../icons";
import {
  CSSRulesFunction,
  useTheme,
  Interpolation,
  TypeScale,
  ResponsiveValue,
  InputGutter,
  inputGutterStyle,
  AccessorySize,
  Overflow,
} from "../theme";

import type { FormFieldStatus } from "./status";
import { resolveStatusColors } from "./status";

interface SelectAppearanceProps {
  status?: FormFieldStatus.Danger;
  disabled?: boolean;
  gutter?: ResponsiveValue<InputGutter>;
  leftAccessory?: React.ReactNode;
  leftAccessorySize?: AccessorySize;
}

type HTMLAttributes = React.SelectHTMLAttributes<HTMLSelectElement>;

export interface SelectProps extends HTMLAttributes, SelectAppearanceProps {}

const leftAccessorySizeTokens = (size?: AccessorySize) => {
  switch (size) {
    case AccessorySize.Sm:
      return {
        dimension: "14px",
        paddingLeft: "32px",
      };
    case AccessorySize.Lg:
      return {
        dimension: "24px",
        paddingLeft: "40px",
      };
    default:
      return {
        dimension: "20px",
        paddingLeft: "36px",
      };
  }
};

const selectContainerStyle: Interpolation = {
  position: "relative",
};

const selectStyle: CSSRulesFunction<SelectAppearanceProps> = (theme, props) => {
  const { gutter, status, disabled, leftAccessory, leftAccessorySize } = props;

  const padding = inputGutterStyle(theme, { size: gutter });

  const statusColors = resolveStatusColors(theme, {
    status: status,
    disabled: disabled,
  });
  const { baseBorderColor, textColor, backgroundColor, focusBorderColor } =
    statusColors;

  return [
    padding,
    {
      display: "block",
      width: "100%",
      border: "none",
      borderRadius: theme.radius(0.5),
      appearance: "none",
      // make room for the icon
      paddingRight: `calc(${theme.spacing(0.75)} + 24px)`,
      ...(leftAccessory
        ? {
            paddingLeft: `calc(${theme.spacing(0.75)} + ${
              leftAccessorySizeTokens(leftAccessorySize).paddingLeft
            })`,
          }
        : {}),
      boxShadow: `inset 0 0 0 1px ${baseBorderColor}`,
      color: textColor,
      fontFamily: "inherit",
      backgroundColor,
      ...theme.tokens.fontSizes[TypeScale.Size_03],
      "&:focus": {
        outline: "none",
        boxShadow: `inset 0 0 0 2px ${focusBorderColor}`,
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: "unset",
      },
    },
  ];
};

const iconStyle: CSSRulesFunction<SelectProps> = (
  theme,
  { status, disabled }
) => {
  const { iconColor } = resolveStatusColors(theme, { status, disabled });
  return {
    position: "absolute",
    right: theme.spacing(0.75),
    top: "50%",
    transform: `translate3d(-6px, -50%, 0)`,
    color: iconColor,
    pointerEvents: "none",
  };
};

const leftSelectAccessoryStyle: CSSRulesFunction<SelectProps> = (
  theme,
  { leftAccessorySize }
) => {
  const { dimension } = leftAccessorySizeTokens(leftAccessorySize);

  return {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: theme.spacing(1.5),
    top: "50%",
    transform: `translate3d(-6px, -50%, 0)`,
    pointerEvents: "none",
    maxHeight: dimension,
    maxWidth: dimension,
    overflow: Overflow.Hidden,
  };
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(function Select(
  props,
  ref
) {
  const { theme } = useTheme();
  const { status, gutter, leftAccessory, leftAccessorySize, ...rest } = props;

  return (
    <div css={selectContainerStyle} data-field-control>
      {React.Children.count(leftAccessory) ? (
        <div css={leftSelectAccessoryStyle(theme, props)}>{leftAccessory}</div>
      ) : null}

      <select css={selectStyle(theme, props)} ref={ref} {...rest} />

      <Icon
        name={Glyph.ChevronDown}
        size="12px"
        css={iconStyle(theme, props)}
      />
    </div>
  );
});

export default Select;
