import type * as React from "react";

import type { Interpolation } from "../theme";

type HTMLAttributes = React.HTMLAttributes<HTMLTableSectionElement>;

export interface TableBodyProps extends HTMLAttributes {
  children?: React.ReactNode;
}

export const tableBodyStyle: Interpolation = {
  "> tr + tr > td": {
    borderTop: "1px solid var(--table-border-color)",
  },

  "> tr > td + td, > tr > th + td, > tr > td + th, > tr > th + th": {
    borderLeft: "1px solid var(--table-border-color)",
  },
};

const TableBody: React.FC<TableBodyProps> = (props) => {
  return <tbody css={tableBodyStyle} {...props} />;
};

export default TableBody;
