import {
  Color,
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  Interpolation,
} from "../../theme";

import type { UploadProps } from ".";

export const vars = {
  containerBg: "--upload-container-bg",
  containerBorder: "--upload-container-border",
};

const uploadColorVars: CSSRulesFunction<UploadProps> = (theme, props) => {
  const isDisabled =
    props.status === "disabled" || props.status === "uploading";
  const light = {
    [vars.containerBg]: isDisabled
      ? theme.color(Color.Greystone_700_A38)
      : theme.color(props.bg ?? ColorPreset.BackgroundLight_03),
    [vars.containerBorder]: isDisabled
      ? theme.color(Color.Greystone_700_A38)
      : theme.color(ColorPreset.BorderOnLight_04),
  };
  const dark = {
    [vars.containerBg]: isDisabled
      ? theme.color(Color.Greystone_700_A16)
      : theme.color(props.bg ?? ColorPreset.BackgroundDark_04),
    [vars.containerBorder]: isDisabled
      ? theme.color(Color.Greystone_700_A16)
      : theme.color(ColorPreset.BorderOnLight_04),
  };

  switch (props.colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

export const uploadStyle: CSSRulesFunction<UploadProps> = (theme, props) => {
  return [
    uploadColorVars(theme, props),
    {
      position: "relative",
      border: `1px solid var(${vars.containerBorder})`,
      borderRadius: theme.radius(1),
      backgroundColor: `var(${vars.containerBg})`,
    },
  ];
};

export const wrapperStyle: Interpolation = {
  opacity: 0,
  zIndex: "-1",
  position: "absolute",
};
