import * as React from "react";

type NodeFunction = (
  child: React.ReactNode,
  index: number,
  arr: React.ReactNode[]
) => React.ReactNode;

export interface InterposeProps {
  node: React.ReactNode | NodeFunction;
  trailing?: boolean;
  children: React.ReactNode;
}

const Interpose: React.FC<InterposeProps> = (props) => {
  const { children, node, trailing } = props;
  const filtered: React.ReactNode[] = React.Children.toArray(children).filter(
    (c) => React.isValidElement(c)
  );

  if (trailing) {
    filtered.push(null);
  }

  return (
    <>
      {filtered.map((c, i, arr) => {
        const key = `Interpose_${i}`;
        if (i === arr.length - 1) {
          return <React.Fragment key={key}>{c}</React.Fragment>;
        }
        const n = node instanceof Function ? node(c, i, arr) : node;

        return (
          <React.Fragment key={key}>
            {c}
            {n}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Interpose;
