import type * as React from "react";

type HTMLAttributes = React.HTMLAttributes<HTMLTableRowElement>;

export interface TableRowProps extends HTMLAttributes {
  children?: React.ReactNode;
}

const TableRow: React.FC<TableRowProps> = (props) => {
  return <tr css={{ textAlign: "start" }} {...props} />;
};

export default TableRow;
