import type * as React from "react";

import {
  CSSRulesFunction,
  ResponsiveValue,
  SpaceScale,
  useTheme,
} from "../theme";

export type ButtonGroupLayout = "inline" | "block";

type alignment = "start" | "end" | "center";
type direction = "row" | "column";

export type ButtonGroupArrangement =
  | `${direction}-${alignment}`
  | `${direction}-${alignment}-reverse`;

export interface ButtonGroupStyleProps {
  /**
   * Sets the layout of the button group container
   */
  layout: ResponsiveValue<ButtonGroupLayout>;

  /**
   * Sets the arrangement of buttons and other elements inside the button group
   */
  arrangement: ResponsiveValue<ButtonGroupArrangement>;

  /**
   * Sets the spacing between buttons and other elements inside the button group
   */
  spacing: ResponsiveValue<SpaceScale>;
}

export interface ButtonGroupProps
  extends Partial<ButtonGroupStyleProps>,
    React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

// Let spacer components like Separator and Space manage their own margins
// instead of overriding it with this component. Those components have a special
// `data-flux-role` html attribute that we can target.
const childSelector =
  "& > :not([data-flux-role=spacer]) + :not([data-flux-role=spacer])";

const buttonGroupStyle: CSSRulesFunction<ButtonGroupStyleProps> = (
  theme,
  props
) => {
  const { arrangement, layout, spacing } = props;
  const gapToken = "--buttongroup-gap";
  const gapV = `var(${gapToken})`;

  return [
    { flexWrap: "wrap" },
    theme.responsive(layout, (l) => ({
      display: l === "inline" ? "inline-flex" : "flex",
    })),
    theme.responsive(spacing, (s) => ({ [gapToken]: theme.spacing(s) })),
    theme.responsive(arrangement, (a) => {
      switch (a) {
        case "row-start":
          return {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            [childSelector]: { margin: `0 0 0 ${gapV}` },
          };
        case "row-start-reverse":
          return {
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "start",
            [childSelector]: { margin: `0 ${gapV} 0 0` },
          };
        case "row-end":
          return {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            [childSelector]: { margin: `0 0 0 ${gapV}` },
          };
        case "row-end-reverse":
          return {
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "end",
            [childSelector]: { margin: `0 ${gapV} 0 0` },
          };
        case "row-center":
          return {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            [childSelector]: { margin: `0 0 0 ${gapV}` },
          };
        case "row-center-reverse":
          return {
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "center",
            [childSelector]: { margin: `0 ${gapV} 0 0` },
          };
        case "column-start":
          return {
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            [childSelector]: { margin: `${gapV} 0 0 0` },
          };
        case "column-start-reverse":
          return {
            flexDirection: "column-reverse",
            alignItems: "start",
            justifyContent: "center",
            [childSelector]: { margin: `0 0 ${gapV} 0` },
          };
        case "column-end":
          return {
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "center",
            [childSelector]: { margin: `${gapV} 0 0 0` },
          };
        case "column-end-reverse":
          return {
            flexDirection: "column-reverse",
            alignItems: "end",
            justifyContent: "center",
            [childSelector]: { margin: `0 0 ${gapV} 0` },
          };
        case "column-center":
          return {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            [childSelector]: { margin: `${gapV} 0 0 0` },
          };
        case "column-center-reverse":
          return {
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center",
            [childSelector]: { margin: `0 0 ${gapV} 0` },
          };
      }
    }),
  ];
};

const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { theme } = useTheme();
  const {
    layout = "block",
    arrangement = "row-start",
    spacing = 0.5,
    ...rest
  } = props;

  return (
    <div
      css={buttonGroupStyle(theme, { layout, arrangement, spacing })}
      {...rest}
    >
      {props.children}
    </div>
  );
};

export default ButtonGroup;
