import { createContext, useContext, useEffect, useMemo, useState } from "react";

import {
  AirgapAPI,
  ConsentChangeEvent,
  TranscendAPI,
  TranscendPurposes,
} from "./types";

import { UserLocale } from "src/technical-integrations/i18n";

interface TranscendCookieContextValue {
  consent: TranscendPurposes;
}

const TranscendCookieContext = createContext<TranscendCookieContextValue>({
  consent: {
    Functional: false,
    Analytics: false,
    Advertising: false,
    SaleOfInfo: false,
  },
});

export const useTranscendConsent = () => useContext(TranscendCookieContext);

interface TranscendContextProviderProps {
  children?: React.ReactNode;
}

const TranscendContextProvider: React.FC<TranscendContextProviderProps> = ({
  children,
}) => {
  const [airgap, setAirgap] = useState<AirgapAPI | undefined>(undefined);
  const [transcend, setTranscend] = useState<TranscendAPI | undefined>(
    undefined
  );
  const isTranscendLoaded = airgap && transcend;
  const [consent, setConsent] = useState<TranscendPurposes>({
    Functional: "Auto",
    Analytics: "Auto",
    Advertising: "Auto",
    SaleOfInfo: "Auto",
  });

  const handleTranscendConsentEvent = (evt: ConsentChangeEvent) => {
    if (evt?.detail) {
      const detail = evt.detail;
      const purposes = detail?.consent?.purposes;
      setConsent({
        Functional: purposes?.Functional ?? "Auto",
        Analytics: purposes?.Analytics ?? "Auto",
        Advertising: purposes?.Advertising ?? "Auto",
        SaleOfInfo: purposes?.SaleOfInfo ?? "Auto",
      });
    }
  };

  useEffect(() => {
    if (!window?.airgap) {
      window.airgap = {
        // I was forced to do the below because we want to allow airgap to overwrite the readyQueue according to their docs
        // https://docs.transcend.io/docs/consent/configuration/creating-your-own-ui#asynchronous-airgap.js-core-init
        // https://docs.transcend.io/docs/consent/reference/react-snippets
        /*eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        readyQueue: [],
        /*eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        ready(callback) {
          this.readyQueue?.push(callback);
        },
        ...(window.airgap || {}),
      };
    }
    if (!window?.transcend) {
      window.transcend = {
        readyQueue: [],
        ready(callback) {
          this.readyQueue?.push(callback);
        },
        ...(window.transcend || {}),
      };
    }

    // Wait for airgap.js core to load, and set it in the React state
    if (window?.airgap?.ready) {
      window.airgap.ready((readyTranscend) => {
        setAirgap(readyTranscend);
      });
    }

    // Wait for Consent Manager UI to load, and set it in the React state
    if (window?.transcend?.ready) {
      window.transcend.ready((readyTranscend) => {
        setTranscend(readyTranscend);
      });
    }
  }, []);

  useEffect(() => {
    /*
      In the event that Transcend has already loaded or consent
      was given in a prior session we should load the users existing consent.
    */
    const locale = ["de-DE", "fr-FR", "es-ES"].includes(
      window.navigator.language as UserLocale
    )
      ? window.navigator.language
      : "en";

    const { getConsent } = window?.airgap || {};
    const { setActiveLocale, showConsentManager } = window?.transcend || {};
    if (
      isTranscendLoaded &&
      !!getConsent &&
      !!setActiveLocale &&
      !!showConsentManager
    ) {
      const transcendConsent = getConsent();
      if (!transcendConsent.confirmed) {
        setActiveLocale(locale).then(() => {
          showConsentManager();
        });
      }

      if (!!window.airgap && window.airgap.addEventListener) {
        const consentPurposes = getConsent()?.purposes;
        setConsent({
          Functional: consentPurposes?.Functional ?? "Auto",
          Analytics: consentPurposes?.Analytics ?? "Auto",
          Advertising: consentPurposes?.Advertising ?? "Auto",
          SaleOfInfo: consentPurposes?.SaleOfInfo ?? "Auto",
        });

        window.airgap?.addEventListener(
          "consent-change",
          handleTranscendConsentEvent
        );
      }
    }

    return () => {
      if (isTranscendLoaded) {
        if (!!window.airgap && window.airgap?.removeEventListener) {
          window.airgap?.removeEventListener(
            "consent-change",
            handleTranscendConsentEvent
          );
        }
      }
    };
  }, [isTranscendLoaded]);

  const contextValue = useMemo(
    () => ({
      consent,
    }),
    [consent]
  );

  return (
    <TranscendCookieContext.Provider value={contextValue}>
      {children}
    </TranscendCookieContext.Provider>
  );
};

export default TranscendContextProvider;
