import * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";
import { isVisibleInViewport } from "../utils/isVisibleInViewport";

import AccordionHeader from "./AccordionHeader";
import AccordionPanel from "./AccordionPanel";
import { useAccordion } from "./accordionState";

export interface AccordionItemProps {
  /**
   * A unique, HTML id used to set up accessibility attributes for the
   * accordion item.
   */
  id: string;

  /**
   * The title to display in the accordion item header
   */
  title: React.ReactNode;

  /**
   * An optional accessory to render at the start of the accordion item's header
   *
   * @param props the open/closed state of the accordion item
   */
  leftAccessory?(props: { open: boolean }): React.ReactNode;

  /**
   * An optional accessory to render at the end of the accordion item's header
   *
   * @param props the open/closed state of the accordion item
   */
  rightAccessory?(props: { open: boolean }): React.ReactNode;

  children: React.ReactNode;
}

const accordionItemStyle: CSSRulesFunction<{
  padStart: boolean;
  padEnd: boolean;
  open: boolean;
}> = (theme, props) => {
  return [
    {
      "&+&": {
        borderTop: "1px solid var(--accordion-separator-color)",
      },
    },

    // The extra padding on the panel body is added if there is a left accessory
    // and/or the toggle is visible. However, on smaller viewports, we don't
    // want this extra padding because horizontal space is limited and we want
    // to make sure there is enough room for content.
    theme.responsive([false, false, true], (enabled) => {
      return {
        "--accordion-panel-pad-start":
          enabled && props.padStart
            ? "calc(var(--accordion-left-accessory-width) + var(--accordion-header-column-gap))"
            : "0px",
        "--accordion-panel-pad-end":
          enabled && props.padEnd
            ? "calc(var(--accordion-header-font-size) + var(--accordion-header-column-gap))"
            : "0px",
      };
    }),
  ];
};

const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const {
    autoScrollIntoView,
    minimumSelected,
    selectedItems,
    toggleItem,
    toggleVisibility,
  } = useAccordion();
  const { theme } = useTheme();
  const { id, leftAccessory, rightAccessory, title } = props;
  const open = !!selectedItems.find((itemId) => itemId === id);
  const headerRef = React.useRef<HTMLButtonElement>(null);
  const headerId = `${id}_header`;
  const toggleEnabled = toggleVisibility === "visible";
  const disabled = selectedItems.length <= minimumSelected && open;

  const renderProps = { open };
  const left = leftAccessory?.(renderProps);
  const right = rightAccessory?.(renderProps);
  const hasLeft = React.Children.count(left) > 0;
  const hasRight = React.Children.count(right) > 0;

  const onAccordionPanelHeightAnimationEnd = () => {
    if (!open) {
      return;
    }

    const headerEl = headerRef.current;

    if (autoScrollIntoView && headerEl && !isVisibleInViewport(headerEl)) {
      headerEl.scrollIntoView({ block: "start" });
    }
  };

  return (
    <div
      css={accordionItemStyle(theme, {
        padStart: hasLeft,
        padEnd: toggleEnabled || hasRight,
        open,
      })}
    >
      <AccordionHeader
        id={headerId}
        itemId={id}
        leftAccessory={hasLeft ? left : null}
        rightAccessory={hasRight ? right : null}
        toggleItem={toggleItem}
        open={open}
        toggleEnabled={toggleEnabled}
        disabled={disabled}
        ref={headerRef}
      >
        {title}
      </AccordionHeader>

      <AccordionPanel
        headerId={headerId}
        id={id}
        onHeightAnimationEnd={onAccordionPanelHeightAnimationEnd}
        open={open}
      >
        {props.children}
      </AccordionPanel>
    </div>
  );
};

export default AccordionItem;
