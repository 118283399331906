import { useControlledState } from "@react-stately/utils";
import * as React from "react";

import { Glyph } from "../icons";
import { useTheme } from "../theme";

import MenuLabel from "./MenuLabel";
import MenuLI from "./MenuLI";
import {
  menuKeyProp,
  MenuLayoutProvider,
  MenuLevelProvider,
  MenuRole,
  useMenuLevel,
  useMenuRole,
  useMenuSelectionState,
} from "./menuState";
import { menuExpanderTriggerStyle } from "./menuStyle";
import type { MenuLabelProps } from "./menuTypes";
import MenuUL from "./MenuUL";

const noop = () => {};

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLUListElement>,
  "role" | "title"
>;

export interface MenuExpanderProps
  extends HTMLAttributes,
    Omit<MenuLabelProps, "rightIcon"> {
  /**
   * When set, disabled the expander's trigger button.
   */
  disabled?: boolean;
  /**
   * Sets an optional id on the expander's trigger button.
   */
  triggerId?: string;
  /**
   * Sets the title show in the expander's trigger button label.
   */
  title: React.ReactNode;
  /**
   * In controlled mode, controls whether or not the expander is open or closed.
   */
  expanded?: boolean;
  /**
   * In uncontrolled mode, sets the initial state of the expander as either open
   * or closed.
   */
  initialExpanded?: boolean;
  /**
   * A callback that is triggered when the expander is toggled.
   *
   * @param value The new state of the expander
   */
  onToggle?(value: boolean | undefined): void;
  children: React.ReactNode;
}

const MenuExpander = React.forwardRef<HTMLButtonElement, MenuExpanderProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const genId = React.useId();
    const {
      title,
      description,
      children,
      id = genId,
      triggerId = `${genId}_trigger`,
      leftIcon,
      "aria-labelledby": inLabels,
      expanded: inExpanded,
      initialExpanded,
      onToggle = noop,
      disabled,
      ...rest
    } = props;
    const [expanded, setExpanded] = useControlledState(
      inExpanded,
      initialExpanded || false,
      onToggle
    );
    const { key, tabIndex } = useMenuSelectionState();
    const level = useMenuLevel();
    const menuRole = useMenuRole();
    const isMenuRole = menuRole === MenuRole.Menu;
    const buttonRole = isMenuRole ? "menuitem" : undefined;
    const currentLevel = Math.max(level + 1, 1);

    let labels = triggerId;
    if (inLabels) {
      labels = `${inLabels} ${labels}`;
    }

    const interactiveProps = {
      tabIndex: isMenuRole ? tabIndex : undefined,
      [menuKeyProp]: isMenuRole ? key : undefined,
    };

    return (
      <MenuLayoutProvider value="row">
        <MenuLI>
          <button
            css={menuExpanderTriggerStyle(theme, { accent: "hidden" })}
            id={triggerId}
            type="button"
            role={buttonRole}
            ref={ref}
            aria-controls={id}
            aria-expanded={!!expanded}
            onClick={() => setExpanded(!expanded)}
            disabled={disabled}
            {...interactiveProps}
          >
            <MenuLabel
              title={title}
              description={description}
              leftIcon={leftIcon}
              rightIcon={expanded ? Glyph.ChevronUp : Glyph.ChevronDown}
            />
          </button>
          <MenuLevelProvider value={currentLevel}>
            <MenuUL
              {...rest}
              id={id}
              role="group"
              aria-labelledby={labels}
              hidden={!expanded}
            >
              {children}
            </MenuUL>
          </MenuLevelProvider>
        </MenuLI>
      </MenuLayoutProvider>
    );
  }
);

export default MenuExpander;
