import { forwardRef } from "react";

import { Box, BoxProps } from "../../layout";
import { Color, ColorPreset, Overflow } from "../../theme";

interface ListWrapperProps extends BoxProps {
  flip?: boolean;

  children: React.ReactNode;
}
export const ListWrapper = forwardRef<HTMLDivElement, ListWrapperProps>(
  ({ flip, children, ...rest }: ListWrapperProps, ref) => (
    <Box
      ref={ref}
      layout="flex"
      flexDirection={flip ? "column-reverse" : "column"}
      bg={Color.White}
      borderRadius={0.5}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_02}
      overflowY={Overflow.Hidden}
      overflowX={Overflow.Hidden}
      css={{ maxHeight: "100%" }}
      {...rest}
    >
      {children}
    </Box>
  )
);
