import {
  Color,
  ColorPreset,
  ColorProp,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  ResponsiveValue,
} from "../../theme";

const calendarSize: ResponsiveValue<"sm" | "md"> = ["sm", "md"];

const vars = {
  maxWidth: "--datepickercalendar-max-width",
  gutterV: "--datepickercalendar-gutterv",
  gutterH: "--datepickercalendar-gutterh",
  spacing: "--datepickercalendar-spacing",
  bg: "--datepickercalendar-bg",
  weekColor: "--datepickercalendar-week-color",
  cellGutterV: "--datepickercalendar-cell-gutterv",
  cellGutterH: "--datepickercalendar-cell-gutterh",
  buttonSize: "--datepickercalendar-button-size",
  buttonColor: "--datepickercalendar-button-color",
  buttonDisabledColor: "--datepickercalendar-button-disabled-color",
  buttonOutline: "--datepickercalendar-button-outline-color",
  buttonHoverBg: "--datepickercalendar-button-hover-bg",
  buttonSelectedBg: "--datepickercalendar-button-selected-bg",
  buttonSelectedDisabledBg: "--datepickercalendar-button-selected-disabled-bg",
  buttonSelectedColor: "--datepickercalendar-button-selected-color",
};

export const calendarStyle: CSSRulesFunction = (theme) => {
  return [
    {
      [vars.spacing]: theme.spacing(1),
      [vars.bg]: theme.color(ColorPreset.BackgroundLight_01),
      [vars.weekColor]: theme.color(ColorPreset.TextOnLight_03),
      [vars.buttonColor]: theme.color(ColorPreset.TextOnLight_01),
      [vars.buttonDisabledColor]: theme.color(Color.Greystone_1400_A38),
      [vars.buttonOutline]: theme.color(ColorPreset.BorderOnLight_01),
      [vars.buttonHoverBg]: theme.color(Color.Greystone_900_A21),
      [vars.buttonSelectedBg]: theme.color(Color.Greystone_DarkMatter),
      [vars.buttonSelectedDisabledBg]: theme.color(Color.Greystone_1400_A8),
      [vars.buttonSelectedColor]: theme.color(ColorPreset.TextOnDark_01),
      [vars.cellGutterV]: theme.spacing(0.25),
      [vars.cellGutterH]: theme.spacing(0.25),
    },
    theme.responsive(calendarSize, (s) => {
      switch (s) {
        case "sm":
          return {
            [vars.maxWidth]: "none",
            [vars.buttonSize]: "32px",
            [vars.gutterV]: theme.spacing(0.5),
            [vars.gutterH]: theme.spacing(0.5),
          };
        case "md":
          return {
            [vars.maxWidth]: "412px",
            [vars.buttonSize]: "40px",
            [vars.gutterV]: theme.spacing(2),
            [vars.gutterH]: theme.spacing(2),
          };
      }
    }),
    {
      background: `var(${vars.bg})`,
      borderRadius: theme.radius(1),
      filter: theme.tokens.elevation[4],
      padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
      maxWidth: `var(${vars.maxWidth})`,
      flex: "1 1 auto",
    },
  ];
};
export const calendarHeaderStyle: Interpolation = {
  display: "grid",
  gridTemplateColumns: "max-content auto max-content",
  alignItems: "center",
  marginBottom: `var(${vars.spacing})`,
};

export const calendarGridStyle: Interpolation = {
  textAlign: "center",
  borderCollapse: "collapse",
  width: "100%",
  th: {
    padding: `0 0 var(${vars.spacing})`,
  },
  td: {
    padding: `var(${vars.cellGutterV}) var(${vars.cellGutterH})`,
  },
  "td,th": {
    "&:first-of-type": { paddingLeft: 0 },
    "&:last-of-type": { paddingRight: 0 },
  },
  "tr:first-of-type > td": { paddingTop: 0 },
  "tr:last-of-type > td": { paddingBottom: 0 },
};

export const calendarWeekHeaderStyle: Interpolation = {
  textDecoration: "none",
  fontWeight: FontWeight.SemiBold,
  color: `var(${vars.weekColor})`,
};

export const calendarButtonStyle: Interpolation = {
  width: `var(${vars.buttonSize})`,
  height: `var(${vars.buttonSize})`,
  borderRadius: `var(${vars.buttonSize})`,
  background: "none",
  margin: 0,
  padding: 0,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "none",
  fontFamily: "inherit",
  lineHeight: "inherit",
  fontWeight: FontWeight.SemiBold,
  color: `var(${vars.buttonColor})`,
  "&:hover": {
    background: `var(${vars.buttonHoverBg})`,
    color: `var(${vars.buttonColor})`,
  },
  "&:focus": {
    background: "none",
    color: `var(${vars.buttonColor})`,
  },
  "&[data-selected=true]": {
    background: `var(${vars.buttonSelectedBg})`,
    color: `var(${vars.buttonSelectedColor})`,
  },
  "&[data-highlighted=true]": {
    "&:focus, &:hover": {
      background: "none",
    },
  },
  "&:disabled": {
    color: `var(${vars.buttonDisabledColor})`,
    background: "none",
    "&:focus, &:hover": {
      background: "none",
    },
    "&[data-selected=true]": {
      background: `var(${vars.buttonSelectedDisabledBg})`,
    },
  },
};

export const hideButton: Interpolation = {
  visibility: "hidden",
};

export const highlightCalendarButton: CSSRulesFunction<
  ColorProp | undefined
> = (theme, props) => {
  return {
    background: props ? theme.color(props) : "none",
    border: props ? `2px solid ${theme.color(props)}` : "none",
    color: `var(${vars.buttonSelectedColor})`,
  };
};
