import { AuthenticationFactorsStatus } from "@gocardless/api/dashboard/types";
import { useAuthenticationFactorList } from "@gocardless/api/dashboard/authentication-factor";

import {
  getAuthenticationFactorId,
  getAuthenticationFactorPhoneNumber,
  getAuthenticationFactorType,
} from "./getAuthenticationFactorId";

export const useCurrentAuthFactor = () => {
  const { data: currentAuthFactor = {}, isLoading } =
    useAuthenticationFactorList({
      is_default: true,
      status: AuthenticationFactorsStatus.Verified,
    });

  const id = getAuthenticationFactorId(currentAuthFactor);
  const factorType = getAuthenticationFactorType(currentAuthFactor);
  const phoneNumber = getAuthenticationFactorPhoneNumber(currentAuthFactor);

  return {
    currentAuthId: id,
    factorType,
    phoneNumber,
    loading: isLoading,
  };
};
