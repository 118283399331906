import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type SwitchProps = Omit<ToggleProps, "type" | "control">;

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  props,
  ref
) {
  const {
    variant = ToggleVariant.Compact,
    colorScheme = ColorScheme.OnLight,
    controlPosition = ToggleControlPosition.Start,
    ...rest
  } = props;
  const switchStyleProps = { variant, colorScheme, controlPosition, ...rest };
  return (
    <Toggle
      {...switchStyleProps}
      control={ToggleControl.Switch}
      type="checkbox"
      ref={ref}
    />
  );
});

export default Switch;
