import * as React from "react";

import MenuLabel from "./MenuLabel";
import MenuLI from "./MenuLI";
import {
  MenuLayoutProvider,
  MenuLevelProvider,
  MenuRole,
  useMenuLevel,
  useMenuRole,
} from "./menuState";
import { menuGroupLabelStyle } from "./menuStyle";
import type { MenuLabelProps } from "./menuTypes";
import MenuUL from "./MenuUL";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLUListElement>,
  "role" | "title"
>;

export interface MenuGroupProps extends HTMLAttributes, MenuLabelProps {
  /**
   * Sets an optional id on the group's label area.
   */
  labelId?: string;
  /**
   * Sets the title to display for a group.
   */
  title: React.ReactNode;
  children: React.ReactNode;
}

const MenuGroup: React.FC<MenuGroupProps> = (props) => {
  const genId = React.useId();
  const {
    title,
    description,
    children,
    labelId = genId,
    leftIcon,
    rightIcon,
    "aria-labelledby": inLabels,
    ...rest
  } = props;
  const level = useMenuLevel();
  const menuRole = useMenuRole();
  const hideDescriptors = menuRole === MenuRole.Menu ? true : undefined;
  const currentLevel = level + 1;

  let labels = labelId;
  if (inLabels) {
    labels = `${inLabels} ${labels}`;
  }

  return (
    <MenuLayoutProvider value="row">
      <MenuLevelProvider value={currentLevel}>
        <MenuLI>
          <div
            id={labelId}
            css={menuGroupLabelStyle}
            aria-hidden={hideDescriptors}
          >
            <MenuLabel
              title={title}
              description={description}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
            />
          </div>
          <MenuUL {...rest} role="group" aria-labelledby={labels}>
            {children}
          </MenuUL>
        </MenuLI>
      </MenuLevelProvider>
    </MenuLayoutProvider>
  );
};

export default MenuGroup;
