import * as React from "react";

import { useTheme } from "../theme";

import {
  TypographyStyleProps,
  omitTypographyStyleProps,
  typographyStyle,
} from "./appearance";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof TypographyStyleProps
>;

export interface CodeProps extends TypographyStyleProps, HTMLAttributes {}

const Code = React.forwardRef<HTMLParagraphElement, CodeProps>(function Code(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitTypographyStyleProps(props);
  const { fontFamily = "monospace" } = props;

  return (
    <code
      css={typographyStyle(theme, { fontFamily, ...props })}
      {...rest}
      ref={ref}
    />
  );
});

export default Code;
