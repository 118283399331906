import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type CheckboxProps = Omit<ToggleProps, "type" | "control">;

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, ref) {
    const {
      variant = ToggleVariant.Compact,
      colorScheme = ColorScheme.OnLight,
      controlPosition = ToggleControlPosition.Start,
      ...rest
    } = props;
    const checkboxStyleProps = {
      variant,
      colorScheme,
      controlPosition,
      ...rest,
    };
    return (
      <Toggle
        {...checkboxStyleProps}
        control={ToggleControl.Check}
        type="checkbox"
        ref={ref}
      />
    );
  }
);

export default Checkbox;
