import type { Interpolation } from "../../theme";

export const autoFillSupport: Interpolation = {
  border: "var(--input-border-width) solid transparent",
  borderRadius: "inherit",
};

const searchStyle: Interpolation = { display: "none" };

export const searchReset: Interpolation = {
  "&[type='search']::-ms-clear": searchStyle,
  "&[type='search']::-ms-reveal": searchStyle,
  "&[type='search']::-webkit-search-decoration": searchStyle,
  "&[type='search']::-webkit-search-cancel-button": searchStyle,
  "&[type='search']::-webkit-search-results-button": searchStyle,
  "&[type='search']::-webkit-search-results-decoration": searchStyle,
};
