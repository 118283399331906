import { IconButton, IconLink } from "../buttons";

import {
  CardButtonVariant,
  CardActionType,
  CardHeaderActionProps,
} from "./cardTypes";
import { convertButtonVariant, sharedButtonProps } from "./cardUtils";

const CardHeaderAction: React.FC<
  CardHeaderActionProps & {
    variant?: CardButtonVariant;
  }
> = (props) => {
  if (props == null) {
    return null;
  }

  const btnVariant = convertButtonVariant(props.variant);

  if (props.control === CardActionType.IconLink) {
    const { buttonVariant, variant, control, ...rest } = props;
    return <IconLink {...rest} {...sharedButtonProps} variant={btnVariant} />;
  } else {
    const { buttonVariant, variant, control, ...rest } = props;
    return <IconButton {...rest} {...sharedButtonProps} variant={btnVariant} />;
  }
};

export default CardHeaderAction;
