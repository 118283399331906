/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from "react";

import { useTheme } from "../theme";

import { AccordionContext, useAccordionManager } from "./accordionState";
import { accordionStyle } from "./style";
import type { AccordionStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof AccordionStyleProps
>;

export interface AccordionProps extends HTMLAttributes, AccordionStyleProps {
  children: React.ReactNode;
}

const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
  const { target, currentTarget } = event;
  const buttons = Array.from(
    currentTarget.querySelectorAll<HTMLButtonElement>(":scope > div > button")
  );
  const targetIndex = buttons.findIndex((el) => el === target);
  const key = event.key;
  if (!buttons.length || targetIndex < 0) {
    return;
  }

  const lastIndex = buttons.length - 1;

  const nextIndex = targetIndex === lastIndex ? 0 : targetIndex + 1;
  const previousIndex = targetIndex === 0 ? lastIndex : targetIndex - 1;

  switch (key) {
    case "ArrowUp":
    case "Up":
      buttons[previousIndex]?.focus();
      break;
    case "ArrowDown":
    case "Down":
      buttons[nextIndex]?.focus();
      break;
    case "Home":
      buttons[0]?.focus();
      break;
    case "End":
      buttons[lastIndex]?.focus();
      break;
  }
};

const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    autoScrollIntoView = true,
    children,
    initialSelected,
    minimumSelected = 1,
    mode,
    onChange,
    outline,
    size,
    toggleVisibility = "visible",
    variant,
    ...rest
  } = props;

  const { theme } = useTheme();
  const [selectedItems, toggleItem] = useAccordionManager(
    mode,
    initialSelected || [],
    { minimumSelected }
  );
  const previousRef = React.useRef<string[]>();

  React.useEffect(() => {
    // We should only call onChange after initial render and when the selected
    // items have changed. We use a previousRef to track changes between current
    // state and previous state.
    const previous = previousRef.current;
    previousRef.current = selectedItems;

    // On initial render this ref is going to be undefined.
    if (previous == null) {
      return;
    }
    if (previous === selectedItems) {
      return;
    }

    if (onChange) {
      onChange(selectedItems);
    }
  }, [previousRef, selectedItems, onChange]);

  return (
    <AccordionContext.Provider
      value={{
        autoScrollIntoView,
        selectedItems,
        toggleItem,
        mode,
        minimumSelected,
        toggleVisibility,
      }}
    >
      <div
        css={accordionStyle(theme, props)}
        data-reading-optimized
        role="presentation"
        {...rest}
        onKeyDown={handleKeyDown}
      >
        {props.children}
      </div>
    </AccordionContext.Provider>
  );
};

export default Accordion;
