import {
  FontWeight,
  type CSSRulesFunction,
  type Interpolation,
  TypeScale,
  Color,
  ResponsiveValue,
} from "../../theme";

const vars = {
  spacing: "--datepicker-range-input-spacing",
  fontSize: "--datepicker-range-input-font-size",
  labelSpacing: "--datepicker-range-input-label-spacing",
};

const calendarSize: ResponsiveValue<"sm" | "md"> = ["sm", "md"];

export const dataRangeInputsStyle: CSSRulesFunction = (theme) => {
  return [
    {
      [vars.spacing]: theme.spacing(1),
      [vars.labelSpacing]: theme.spacing(0.25),
      [vars.fontSize]: theme.tokens.fontSizes[TypeScale.Size_02].fontSize,
    },
    theme.responsive(calendarSize, (s) => {
      switch (s) {
        case "sm":
          return {
            display: "flex",
            flexDirection: "column",
            ">div:first-of-type": {
              marginBottom: theme.spacing(0.5),
              marginRight: 0,
              marginLeft: 0,
            },
          };
        case "md":
          return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ">div:first-of-type": {
              marginRight: theme.spacing(0.5),
              marginLeft: `-${theme.spacing(0.25)}`,
              marginBottom: 0,
            },
          };
      }
    }),
    {
      borderBottom: `1px solid ${theme.color(Color.Greystone_500)}`,
      paddingBottom: theme.spacing(1.5),
    },
  ];
};

export const labelStyle: Interpolation = {
  fontWeight: FontWeight.SemiBold,
  fontSize: `var(${vars.fontSize})`,
  marginBottom: `var(${vars.labelSpacing})`,
  display: "block",
};
