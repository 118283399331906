import { FocusScope } from "@react-aria/focus";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { useControlledState } from "@react-stately/utils";

import {
  FloatingPlacement,
  PlacementStrategy,
  useFloating,
} from "../../hooks/floating";
import { useTapOut } from "../../hooks/pointers";
import { Interpolation, useTheme } from "../../theme";

import type { CalendarRangeProps } from "./CalendarRange";
import CalendarRange from "./CalendarRange";
import DatePickerTrigger from "./DatePickerTrigger";
import { validatePartial } from "./dateHelpers";
import {
  datePickerCalendarStyle,
  datePickerTriggerLayoutStyle,
} from "./datePickerStyle";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLLabelElement>,
  keyof CalendarRangeProps
>;

export interface DateRangePickerProps
  extends HTMLAttributes,
    CalendarRangeProps {
  /**
   * Controls whether or not the calendar trigger and dropdown are rendered.
   */
  position?: "absolute" | "fixed";
  placementStrategy?: PlacementStrategy;
  placement?: FloatingPlacement;
  disabled?: boolean;
  triggerButton?: {
    style?: Interpolation;
    children: React.ReactNode;
  };
}

const noop = () => {};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value = [],
  defaultValue = [],
  highlightedDates,
  onChange,
  triggerButton,
  position = "absolute",
  placement = "bottom-start",
  placementStrategy = "static",
  disabled,
  ...rest
}) => {
  const [propsStartDate, propsEndDate] = value;
  const [propsDefaultStartDate, propsDefaultEndDate] = defaultValue;

  const [startDate, setStartDate] = useControlledState(
    propsStartDate,
    propsDefaultStartDate || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
    noop
  );

  const [endDate, setEndDate] = useControlledState(
    propsEndDate,
    propsDefaultEndDate || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
    noop
  );

  const { theme } = useTheme();
  const [calendarOpen, toggleCalendar] = React.useState(false);
  const { x, y, refs, update } = useFloating({
    open: calendarOpen,
    placementStrategy,
    initialPlacement: placement,
    shouldUpdate: position === "fixed",
  });
  const hitAreaRef = React.useRef<HTMLButtonElement>(null);
  const floatingRef = refs.floating;
  const tapoutRefs = React.useMemo(
    () => [floatingRef, hitAreaRef],
    [floatingRef, hitAreaRef]
  );
  const onTapOut = React.useCallback(() => {
    toggleCalendar(false);
  }, [toggleCalendar]);
  useTapOut(tapoutRefs, onTapOut);

  const newValidatedValue = [];
  const validatedStartDate = startDate ? validatePartial(startDate) : null;
  const validatedEndDate = endDate ? validatePartial(endDate) : null;
  if (validatedStartDate) newValidatedValue.push(validatedStartDate);
  if (validatedEndDate) newValidatedValue.push(validatedEndDate);

  const calendarTop = y == null ? "" : `${y}px`;
  const calendarLeft = x == null ? "" : `${x}px`;

  return (
    <>
      <div ref={refs.setReference} css={datePickerTriggerLayoutStyle(theme)} />
      <div>
        <DatePickerTrigger
          ref={hitAreaRef}
          label={rest.i18n.changeDateButtonLabel}
          onClick={() => toggleCalendar((v) => !v)}
          disabled={disabled}
          aria-expanded={calendarOpen}
          aria-haspopup="dialog"
          triggerButton={triggerButton}
        />

        <CSSTransition
          in={calendarOpen}
          timeout={250}
          classNames="fade"
          unmountOnExit
          mountOnEnter
          onEnter={update}
        >
          <div
            ref={refs.setFloating}
            css={datePickerCalendarStyle(theme, {
              position,
              top: calendarTop,
              left: calendarLeft,
            })}
          >
            <FocusScope contain restoreFocus>
              <CalendarRange
                value={newValidatedValue}
                highlightedDates={highlightedDates}
                onChange={(newDates) => {
                  const [newStartDate, newEndDate] = newDates || [];
                  setStartDate(
                    newStartDate || {
                      day: undefined,
                      month: undefined,
                      year: undefined,
                    }
                  );
                  setEndDate(
                    newEndDate || {
                      day: undefined,
                      month: undefined,
                      year: undefined,
                    }
                  );
                  onChange && onChange(newDates);
                  if (newStartDate && newEndDate) toggleCalendar(false);
                }}
                {...rest}
              />
            </FocusScope>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default DateRangePicker;
