import { forwardRef } from "react";

import { Box, BoxProps } from "../../layout";
import { useTheme } from "../../theme";

interface ListItemWrapperProps extends BoxProps {
  children: React.ReactNode;
}

export const ListItemWrapper = forwardRef<HTMLDivElement, ListItemWrapperProps>(
  ({ children, ...props }, ref) => {
    const { theme } = useTheme();
    return (
      <Box
        ref={ref}
        css={{
          "&:first-of-type": {
            paddingTop: theme.spacing(0.5),
          },
          "&:last-of-type": {
            paddingBottom: theme.spacing(1),
          },
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);
