import { useControlledState } from "@react-stately/utils";
import * as React from "react";

import { useIsomorphicLayoutEffect } from "../hooks/useIsomorphicLayoutEffect";
import { useTheme, ColorScheme } from "../theme";

import { TabItemRole } from "./tabsState";
import {
  tabsListStyle,
  tabsScrollerStyle,
  tabsScrollIndicator,
  tabsStyle,
} from "./tabsStyle";
import type { TabsStyleProps } from "./tabsTypes";

type HTMLAttributes = React.HTMLAttributes<HTMLDivElement>;

export interface TabsProps extends HTMLAttributes, Partial<TabsStyleProps> {
  /**
   * In controlled mode, this option sets the selected tab.
   */
  selected?: string;
  /**
   * In uncontrolled mode, sets the initial or default selected tab when this
   * component is first rendered.
   */
  initialSelected?: string;
  /**
   * A callback that is called when a tab is selected.
   */
  onSelected?: (key: string | undefined) => void;
  children: React.ReactNode;
}

const noop = () => {};

const Tabs: React.FC<TabsProps> = (props) => {
  const {
    colorScheme = ColorScheme.OnLight,
    orientation = "horizontal",
    triggerFontSize,
    spacing = [1, null, 2],
    selected,
    onSelected = noop,
    initialSelected,
    ...rest
  } = props;

  const { theme } = useTheme();
  // @ts-expect-error - Component effectively handles when both selected and initialSelected are undefined; it selects the first tab in this case
  const [selectedKey, setSelectedKey] = useControlledState(
    selected,
    initialSelected,
    onSelected
  );
  const [focused, setFocused] = React.useState(0);
  const [indicatorEnabled, setIndicatorEnabled] = React.useState(false);
  const tabScrollerRef = React.useRef<HTMLDivElement>(null);
  const indicatorRef = React.useRef<HTMLDivElement>(null);

  /**
   * Disable the selection indicator until font loading is complete. Otherwise,
   * the width and positon of the indicator will be incorrect when the desired
   * font loads in.
   */
  React.useEffect(() => {
    if (typeof document === "undefined") {
      return;
    }

    const cb = () => setIndicatorEnabled(true);

    document.fonts.ready.then(cb, cb);
  }, []);

  /**
   * The selection indicators position must be computed by using the position
   * and width of the selected tab.
   */
  useIsomorphicLayoutEffect(() => {
    const updateIndicator = () => {
      const selectedTab = tabScrollerRef.current?.querySelector(
        ":scope > [role=tablist] > [role=tab][aria-selected=true]"
      );

      if (!(selectedTab instanceof HTMLElement) || !indicatorRef.current) {
        return;
      }

      indicatorRef.current.style.width = `${selectedTab.offsetWidth}px`;
      indicatorRef.current.style.transform = `translateX(${selectedTab.offsetLeft}px)`;
    };

    if (!indicatorRef.current || !tabScrollerRef.current) {
      return;
    }

    updateIndicator();

    const resizeObserver = new ResizeObserver(updateIndicator);

    const tabs = tabScrollerRef.current.querySelectorAll(
      ":scope > [role=tablist] > [role=tab]"
    );

    tabs.forEach((tab) => resizeObserver.observe(tab));

    return () => {
      tabs.forEach((tab) => resizeObserver.unobserve(tab));
      resizeObserver.disconnect();
    };
  }, [selectedKey, indicatorEnabled, triggerFontSize, orientation]);

  /**
   * Sets up keyboard shortcuts for this component based on its orientation.
   * The focused tab can be changed using the arrow keys as specified by the
   * aria `tab` role docs: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role
   */
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    let backKey = ["Left", "ArrowLeft"];
    let forwardKey = ["Right", "ArrowRight"];
    if (orientation === "vertical") {
      backKey = ["Up", "ArrowUp"];
      forwardKey = ["Down", "ArrowDown"];
    }

    const goingBack = backKey.indexOf(e.key) >= 0;
    const goingForward = forwardKey.indexOf(e.key) >= 0;

    if (!goingBack && !goingForward) {
      return;
    }

    e.preventDefault();

    const tabs = Array.from(
      e.currentTarget.querySelectorAll(":scope > [role=tab]")
    );
    let nextIndex = tabs.findIndex(
      (tab) => tab.getAttribute("tabindex") === "0"
    );

    if (goingBack) {
      nextIndex = nextIndex > 0 ? nextIndex - 1 : tabs.length - 1;
    } else if (goingForward) {
      nextIndex = nextIndex < tabs.length - 1 ? nextIndex + 1 : 0;
    }

    setFocused(nextIndex);
    const nextTab = tabs[nextIndex];
    if (nextTab instanceof HTMLElement) {
      nextTab.focus();
    }
  };

  // We are going to be rendering `TabItem`s twice as this component:
  // Under one part of the `Tab`s tree, they will be rendered as tab
  // triggers (buttons). Under another, part of the tree they will be rendered
  // as panels with content.
  //
  // Using `TabItemRole.Provider` is a neat little trick to change the render
  // output of `TabItem`s. It means we can keep the API of this component simple
  // and maintain strong accessibility by tightly association a trigger and
  // panel using one component - the `TabItem`.
  const items = React.Children.map(props.children, (item, index) => {
    if (!React.isValidElement(item)) {
      return null;
    }

    const itemProps = {
      focused: index === focused,
      selected: selectedKey,
      onClick: (key: string) => setSelectedKey(key),
    };
    return React.cloneElement(item, itemProps);
  });

  return (
    <div
      css={tabsStyle(theme, {
        colorScheme,
        triggerFontSize,
        orientation,
        spacing,
      })}
      {...rest}
    >
      <div
        ref={tabScrollerRef}
        css={tabsScrollerStyle(theme, orientation)}
        role="presentation"
      >
        {/* disabling this overly pedantic a11y since the code is compliant */}
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          css={tabsListStyle(theme, { orientation, colorScheme })}
          role="tablist"
          onKeyDown={handleKeyDown}
          aria-orientation={orientation}
        >
          <TabItemRole.Provider value="trigger">{items}</TabItemRole.Provider>
        </div>

        <div
          ref={indicatorRef}
          css={tabsScrollIndicator(theme, orientation)}
          role="presentation"
          data-tabs-indicator-ready={indicatorEnabled}
        />
      </div>
      <TabItemRole.Provider value="panel">{items}</TabItemRole.Provider>
    </div>
  );
};

export default Tabs;
