/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { CONSENT_TYPE, ExecutableScript, GenericScript } from "../Scripts";
import { useTrackPath } from "../useTrackPath";

import { getConfig } from "src/common/config";
import { useHandleRouteChange } from "src/hooks/useHandleRouteChange/useHandleRouteChange";
import { TranscendPurposes } from "src/technical-integrations/transcend";

const GA_NOT_LOADED = "window.gtag not available";

export enum GA4Commands {
  Config = "config",
  Set = "set",
  Get = "get",
  Event = "event",
  Consent = "consent",
}

declare global {
  interface Window {
    gtag?: (
      command: GA4Commands,
      eventName: string,
      parameters: object
    ) => void;
  }
}

interface Props {
  consent: TranscendPurposes;
}

export const getRedactedLocation = (url?: string) => {
  const blockList =
    "redirect_uri,client_id,merchant[user][email],merchant[name],merchant[company_name],user[email],loginEmail,r,token,merchant[user_attributes][first_name],merchant[user_attributes][last_name],merchant[user_attributes][email],merchant[name],merchant[account_upgrade_token],_bt,_bk,_bm,_bn,_bg,fbclid,tblci,prefill,prefill[email],prefill[given_name],prefill[family_name],prefill[organisation_name],prefill[country_code]".split(
      ","
    );
  const replaceParamWith = "[REDACTED]";

  const locationURL = decodeURI(url || window.location.href);

  const urlQueryStr = locationURL
    .replace(
      /((\?)|&)([^#&=]+)(?:=([^#&]*))?/gi,
      (input, delim, qmark, key) => {
        if (blockList.indexOf(key) === -1) {
          return input;
        }
        return replaceParamWith
          ? `${delim + key}=${replaceParamWith}`
          : qmark || "";
      }
    )
    .replace(/\?&*$|(\?)&+/, "$1");

  const simpleEmailRegex =
    /([a-zA-Z0-9._-]+(@|%40)[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  const result = urlQueryStr.replace(simpleEmailRegex, "[REDACTED_EMAIL]");

  return result;
};

const useGATracking = () => {
  const ga4 = getConfig().client?.externalMarketing?.googleAnalytics4;

  const gaDisableProperty = `ga-disable-${ga4?.id}`;

  const { shouldTrackPath } = useTrackPath();

  const initGATracking = () => {
    if (!ga4 || !ga4.id) return;

    if (!window.gtag) {
      console.debug(GA_NOT_LOADED);
      return;
    }

    window.gtag(GA4Commands.Config, ga4?.id, {
      anonymyze_ip: true,
      page_location: getRedactedLocation(),
    });
  };

  const toggleTracking = () => {
    if (!ga4 || !ga4.id) return;

    if (!shouldTrackPath()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)[gaDisableProperty] = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)[gaDisableProperty] = false;
    }
  };

  useHandleRouteChange("routeChangeStart", toggleTracking);

  return {
    initGATracking,
    toggleTracking,
  };
};

export const GoogleAnalytics4Script = ({ consent }: Props) => {
  const ga4 = getConfig().client?.externalMarketing?.googleAnalytics4;

  const INITIALIZE_GA_SCRIPT = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  `;

  const [hasInitialised, setHasInitialised] = useState(false);
  const { initGATracking, toggleTracking } = useGATracking();
  const { shouldTrackPath } = useTrackPath();

  const trackingAllowed = ga4?.id && !!consent.Analytics && shouldTrackPath();

  useEffect(() => {
    if (trackingAllowed && !hasInitialised) {
      toggleTracking();
      initGATracking();
      setHasInitialised(true);
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingAllowed, hasInitialised]);

  return trackingAllowed && !hasInitialised ? (
    <>
      <GenericScript
        consentType={CONSENT_TYPE.ANALYTICS}
        src={`https://www.googletagmanager.com/gtag/js?id=${ga4?.id}`}
      />
      <ExecutableScript
        consentType={CONSENT_TYPE.ANALYTICS}
        executableScript={INITIALIZE_GA_SCRIPT}
      />
    </>
  ) : null;
};
