import { useContext } from "react";
import { ga as reactGa } from "react-ga";
import { UAParser } from "ua-parser-js";

import { useTranscendConsent } from "../transcend";

import { sessionTracking } from "./sessionTracking";

import { SegmentContext } from ".";

import { getConfig } from "src/common/config";

export interface DefaultSegmentParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
interface SendEventAbsParams {
  user_language: string;
}

export const useSegmentAbs = () => {
  const { track, identify, group } = useContext(SegmentContext);
  const { browser, device } = new UAParser(navigator.userAgent).getResult();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ga = reactGa() as any;
  const clientId = ga?.getAll?.()[0]?.get("clientId");
  const session_id = sessionTracking.getSession();

  const defaultParams = {
    browser: browser.name,
    device_type: device.type || "Desktop",
    client_id: clientId,
    session_id,
  };

  const hasSegment = !!getConfig()?.client?.segment?.key;
  const { consent } = useTranscendConsent();

  return {
    sendEventAbs: (
      name: string,
      params: SendEventAbsParams & DefaultSegmentParams
    ) =>
      new Promise((resolve) => {
        // Ensure context exists in params
        params.context = params.context || {};
        // Set consent in context
        params.context.consent = { categoryPreferences: consent };

        hasSegment
          ? track(
              name,
              { ...defaultParams, ...params },
              params.context,
              resolve
            )
          : resolve(undefined);
      }),
    sendIdentify: (id: string, params?: DefaultSegmentParams): Promise<void> =>
      new Promise((resolve) =>
        hasSegment ? identify(id, params, resolve) : resolve(undefined)
      ),
    sendGroup: (
      groupId: string,
      traits?: DefaultSegmentParams
    ): Promise<void> =>
      new Promise((resolve) =>
        hasSegment ? group(groupId, traits, resolve) : resolve(undefined)
      ),
  };
};
