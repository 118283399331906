import type {
  TypographyStyleProps,
  Interpolation,
  CSSRulesFunction,
} from "../theme";

export type { TypographyStyleProps };

export const omitTypographyStyleProps = <Props extends TypographyStyleProps>(
  props: Props
): Omit<Props, keyof TypographyStyleProps> => {
  const {
    preset,
    color,
    size,
    textAlign,
    weight,
    textWrap,
    layout,
    spaceAbove,
    spaceBelow,
    fontFamily,
    lineHeight,
    fontStyle,
    decoration,
    emptyVisibility,
    textTransform,
    ...rest
  } = props;
  return rest;
};

const resetStyle: Interpolation = {
  padding: 0,
  margin: 0,
};

/**
 * The Nudge font family, which is used as the "display" font, requires a custom
 * line height that isn't set by the typography scale. Additionally, it requires
 * a separate, custom line-height when used for non-English text to accommodate
 * diacritic marks.
 */
const nudgeLineHeightHack: Interpolation = {
  lineHeight: 0.9,
  "&:not(:lang(en))": { lineHeight: 1 },
};

const appearanceStyle: CSSRulesFunction<TypographyStyleProps> = (
  theme,
  props
) => {
  const presetConf = props.preset
    ? theme.tokens.typePresets[props.preset]
    : undefined;
  const merged = { ...presetConf, ...props };
  const {
    layout,
    color,
    size,
    weight,
    textAlign,
    textWrap,
    spaceAbove,
    spaceBelow,
    fontFamily,
    lineHeight,
    fontStyle: style,
    decoration,
    emptyVisibility,
    textTransform,
  } = merged;

  const layoutStyle = theme.responsive(layout, (l) => ({ display: l }));

  const colorStyle = theme.responsive(color, (c) => ({
    color: theme.color(c),
  }));

  const sizeStyle = theme.responsive(size, (s) => ({
    ...theme.tokens.fontSizes[s],
    ...(fontFamily === "display" ? nudgeLineHeightHack : {}),
  }));

  const weightStyle = theme.responsive(weight, (fontWeight) => ({
    fontWeight,
  }));

  const alignStyle = theme.responsive(textAlign, (value) => ({
    textAlign: value,
  }));

  const wrapStyle = theme.responsive(textWrap, (whiteSpace) => ({
    whiteSpace,
  }));

  const spaceAboveStyle = theme.responsive(spaceAbove, (s) => ({
    marginTop: theme.spacing(s),
  }));
  const spaceBelowStyle = theme.responsive(spaceBelow, (s) => ({
    marginBottom: theme.spacing(s),
  }));

  const fontFamilyStyle = fontFamily
    ? { fontFamily: theme.tokens.fontFamilies[fontFamily] }
    : null;

  const lineHeightStyle = theme.responsive(lineHeight, (scale) => ({
    lineHeight: theme.tokens.fontSizes[scale].lineHeight,
  }));

  const fontStyle = theme.responsive(style, (fs) => ({ fontStyle: fs }));

  const decorationStyle = theme.responsive(decoration, (td) => ({
    textDecoration: td,
  }));

  const textTransformStyle = theme.responsive(textTransform, (tt) => ({
    textTransform: tt,
  }));

  const emptyStyle =
    emptyVisibility === "hidden" ? { "&:empty": { display: "none" } } : null;

  return [
    layoutStyle,
    colorStyle,
    sizeStyle,
    weightStyle,
    alignStyle,
    wrapStyle,
    spaceAboveStyle,
    spaceBelowStyle,
    fontFamilyStyle,
    lineHeightStyle,
    fontStyle,
    decorationStyle,
    emptyStyle,
    textTransformStyle,
  ];
};

export const typographyStyle: CSSRulesFunction<TypographyStyleProps> = (
  theme,
  props
) => [resetStyle, appearanceStyle(theme, props)];
