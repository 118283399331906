import * as React from "react";

import { CSSRulesFunction, TextWrap, useTheme } from "../theme";

import type { TypographyStyleProps } from "./appearance";
import Text from "./Text";

type Format = "literal" | "short";

export enum MoneyTextVariant {
  Default = "default",
  Flat = "flat",
}

export interface MoneyTextProps extends TypographyStyleProps {
  /** The monetary amount to display */
  amount: number;

  /** The 3-character currency code for the given amount */
  currency: string;

  /** The desired locale in which to represent the formatted amount */
  locale: string;

  /**
   * Set the display format to include the literal ISO 4217 currency
   * code after the amount or the currency symbol before the amount
   */
  format?: Format;

  /**
   * Sets a pre-defined styling
   */
  variant?: MoneyTextVariant;
}

const normalParts = new Set<Intl.NumberFormatPartTypes>(["group", "integer"]);

interface CustomNumberFormatPart extends Intl.NumberFormatPart {
  isShort?: boolean;
}

const partStyle: CSSRulesFunction<CustomNumberFormatPart> = (_, props) => {
  const { type, isShort } = props;
  if (normalParts.has(type) || (isShort && type === "currency")) {
    return {};
  }

  return { fontSize: "0.7em", lineHeight: 1 };
};

const languageFromLocale = (locale: string) => locale.split("-")[0];

const CurrencySymbolMap: Record<string, string> = {
  GBP: "£",
  USD: "$",
  EUR: "€",
  DKK: "kr.",
  SEK: "kr",
  AUD: "$",
  NZD: "$",
  CAD: "$",
};

const getShortFormat = (
  amount: Intl.NumberFormatPart[],
  currencyCode: string
) => {
  const currencySymbol = CurrencySymbolMap[currencyCode];

  if (!currencySymbol) {
    throw new Error(
      `Currency code (${currencyCode}) is not supported for short format.`
    );
  }

  const shortSymbol: Intl.NumberFormatPart = {
    type: "currency",
    value: currencySymbol,
  };

  return [shortSymbol, ...amount];
};

const getLiteralFormat = (
  amount: Intl.NumberFormatPart[],
  currencyCode: string
) => {
  const literalCode: Intl.NumberFormatPart = {
    type: "currency",
    value: currencyCode,
  };

  return [...amount, literalCode];
};

const getAmountParts = (parts: Intl.NumberFormatPart[]) => {
  return parts.filter(({ type }) => type !== "currency" && type !== "literal");
};

const getFormattedParts = (
  parts: Intl.NumberFormatPart[],
  format: Format,
  code: string
) => {
  const amount = getAmountParts(parts);

  return format === "literal"
    ? getLiteralFormat(amount, code)
    : getShortFormat(amount, code);
};

const MoneyText: React.FC<MoneyTextProps> = (props) => {
  const { theme } = useTheme();
  const {
    amount,
    currency,
    locale,
    format = "literal",
    variant = MoneyTextVariant.Default,
    ...rest
  } = props;

  const isShort = format === "short";
  const isLiteral = format === "literal";

  const formatter = Intl.NumberFormat(languageFromLocale(locale), {
    style: "currency",
    currencyDisplay: "code",
    currency,
  });

  const whole = `${formatter.format(amount)}`;
  const parts = formatter.formatToParts(amount);
  const formattedParts = getFormattedParts(parts, format, currency);

  return (
    <Text textWrap={TextWrap.NoWrap} aria-label={whole} {...rest}>
      <Text aria-hidden>
        {formattedParts.flatMap((p, index) => {
          const key = `${p.type}_${p.value}_${index}`;
          const style =
            variant === MoneyTextVariant.Flat
              ? {}
              : partStyle(theme, { ...p, isShort });

          // normalize all spaces to use literal space (" ") instead of
          // non-breaking space character where present.
          let node = (
            <span key={key} css={style}>
              {p.value.replace(/\s/g, " ")}
            </span>
          );

          if (p.type === "currency") {
            node = (
              <React.Fragment key={key}>
                {isLiteral && <span css={style}> </span>}
                {node}
                {isShort && <span css={style}> </span>}
              </React.Fragment>
            );
          }

          return node;
        })}
      </Text>
    </Text>
  );
};

export default MoneyText;
