import {
  ShorthandLocale,
  UserLocale,
  useI18n,
} from "../../technical-integrations/i18n";

export const useSupportCentreUrl = () => {
  const [userLocale] = useI18n();

  //The support page offers multiple English language options, so we're setting 'en-gb' as the default.
  const language =
    userLocale === UserLocale.EN_GB
      ? UserLocale.EN_GB.toLowerCase()
      : ShorthandLocale[userLocale];

  const supportCentreUrl = `https://support.gocardless.com/hc/${language}/requests/new?ticket_form_id=134125`;

  return supportCentreUrl;
};
