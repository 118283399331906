import type { Glyph } from "../icons";
import type {
  ButtonSize,
  FocusRing,
  HoverEffect,
  ResponsiveValue,
  TypographyStyleProps,
} from "../theme";

export enum ButtonVariant {
  PrimaryOnLight = "primary-on-light",
  PrimaryOnDark = "primary-on-dark",
  PrimaryWhiteOnDark = "primary-white-on-dark",
  PrimaryAuto = "primary-auto",

  SecondaryOnLight = "secondary-on-light",
  SecondaryOnDark = "secondary-on-dark",
  SecondaryAuto = "secondary-auto",

  AlertOnLight = "alert-on-light",
  AlertOnDark = "alert-on-dark",
  AlertAuto = "alert-auto",

  TextOnLight = "text-on-light",
  TextOnDark = "text-on-dark",
  TextAuto = "text-auto",

  /**
   * @deprecated Inline variants are now deprecated and will be removed in a later
   * version. Please use `<PlainButton />` or `<PlainLink />` instead
   */
  Inline = "inline",
  /**
   * @deprecated Inline variants are now deprecated and will be removed in a later
   * version. Please use `<PlainButton />` or `<PlainLink />` instead
   */
  InlineUnderlined = "inline-underlined",
}

export enum ButtonLayout {
  Inline = "inline",
  Full = "full",
}

export enum ButtonGutter {
  Sm = "sm",
  Md = "md",
}

export interface ButtonStyleProps {
  variant: ButtonVariant;
  size?: ResponsiveValue<ButtonSize>;
  layout?: ResponsiveValue<ButtonLayout>;
  leftIcon?: Glyph;
  rightIcon?: Glyph;
}

export type ShiftH = "right" | "left";
export type ShiftV = "top" | "bottom";
type Shift = `${ShiftV}-${ShiftH}` | ShiftH | ShiftV;

export const omitButtonStyleProps = <Props extends ButtonStyleProps>(
  props: Props
): Omit<Props, keyof ButtonStyleProps> => {
  const { variant, leftIcon, rightIcon, layout, size, ...rest } = props;

  return rest;
};

export interface IconButtonStyleProps {
  icon: Glyph;
  variant:
    | ButtonVariant.PrimaryOnLight
    | ButtonVariant.PrimaryOnDark
    | ButtonVariant.PrimaryWhiteOnDark
    | ButtonVariant.PrimaryAuto
    | ButtonVariant.SecondaryOnLight
    | ButtonVariant.SecondaryOnDark
    | ButtonVariant.SecondaryAuto
    | ButtonVariant.AlertOnLight
    | ButtonVariant.AlertOnDark
    | ButtonVariant.AlertAuto
    | ButtonVariant.TextOnLight
    | ButtonVariant.TextOnDark
    | ButtonVariant.TextAuto;
  /**
   * Customizes the size of the element. This option can be set to a single size
   * value or it can be set to a base size and gutter size. The second variation
   * allows finer control of the intrisic size and padding of an icon button.
   */
  size?: ResponsiveValue<
    ButtonSize | { base: ButtonSize; gutters: ButtonGutter }
  >;
  focusRing?: FocusRing;
  /**
   * Shifts the button in the specified direction by the padding amount
   */
  shift?: Shift;
}

export interface ButtonRenderProps {
  className: string;
  children: React.ReactNode;
}

export interface PlainButtonStyleProps extends TypographyStyleProps {
  /**
   * The visual effect to apply when the user interacts with this component.
   */
  effect?: HoverEffect;

  /**
   * Sets the initial text decoration on the component's text content.
   */
  textDecoration?: "underline" | "none";

  /**
   * @ignore Internal prop to simulate interaction states for testing.
   */
  "data-flux-state"?: "hover";
}
