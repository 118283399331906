import type React from "react";

import type { ResponsiveValue, SpaceScale } from "../theme";
import type { TypographyStyleProps } from "../typography/appearance";

export enum DLLayout {
  Stack = "stack",
  Row = "row",
  RowFixedTerm = "row-fixed-term",
}

export interface DLStyleProps {
  /**
   * Sets the layout of the description list's items
   */
  layout: ResponsiveValue<DLLayout>;
  /**
   * Sets the spacing above the description list
   */
  spaceAbove: ResponsiveValue<SpaceScale>;
  /**
   * Sets the spacing below the description list
   */
  spaceBelow: ResponsiveValue<SpaceScale>;
}

type TypographyProps = Pick<
  TypographyStyleProps,
  | "color"
  | "decoration"
  | "fontFamily"
  | "fontStyle"
  | "preset"
  | "size"
  | "textAlign"
  | "textTransform"
  | "weight"
>;

export interface DTStyleProps extends TypographyProps {
  /**
   * Displays a custom accessory before the term element's contents. This can
   * include buttons, links or icons.
   */
  leftAccessory?: React.ReactNode;
}

export interface DDStyleProps extends TypographyProps {
  /**
   * Displays a custom accessory after the detail element's contents. This can
   * include buttons, links or icons.
   */
  rightAccessory?: React.ReactNode;
}
