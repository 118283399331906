import React from "react";

import { useTheme } from "../../theme";

import type { DateRangeI18n } from "./i18n";
import { dataRangeInputsStyle, labelStyle } from "./dateRangeInputsStyles";
import { calendarPaddingStyle } from "./calendarRangeStyles";
import type { DateInputProps } from "./DateInput";
import DateInput from "./DateInput";

interface DateRangeInputs {
  fromDate: DateInputProps;
  toDate: DateInputProps;
  i18n: DateRangeI18n;
}

const DateRangeInputs = ({ fromDate, toDate, i18n }: DateRangeInputs) => {
  const { theme } = useTheme();
  const genid = React.useId();
  const fromInputId = `from-input-${genid}`;
  const fromLabelId = `from-label-${genid}`;
  const toInputId = `to-input-${genid}`;
  const toLabelId = `to-label-${genid}`;

  return (
    <div css={[dataRangeInputsStyle(theme), calendarPaddingStyle]}>
      <div>
        <label id={fromLabelId} htmlFor={fromInputId} css={labelStyle}>
          {i18n.fromDateLabel || "From"}
        </label>
        <DateInput {...fromDate} id={fromInputId} compact />
      </div>
      <div>
        <label id={toLabelId} htmlFor={toInputId} css={labelStyle}>
          {i18n.toDateLabel || "To"}
        </label>
        <DateInput {...toDate} id={toInputId} compact />
      </div>
    </div>
  );
};

export default DateRangeInputs;
