import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { VariableSizeList } from "react-window";

import type { InputProps } from "../Input";

import type { GetItemProps, GetItemPropsArgs } from "./List.types";
import { getListHeight } from "./List.utils";

enum Direction {
  Up = -1,
  Down = 1,
}

const inactiveIndex = -1;
const firstItemIndex = 0;

interface ListKeyBoardNavArgs<Item> {
  items: Item[];
  onChange?: (item: Item) => void;
  searchInput?: InputProps;
  focussedIndex?: number;
  searchable?: boolean;
  focusListOnMount?: boolean;
  innerVirtualizeListRef?: React.RefObject<VariableSizeList>;
}

export const useListKeyboardNav = <Item>({
  items = [],
  onChange,
  searchInput,
  searchable,
  focusListOnMount,
  innerVirtualizeListRef,
  ...rest
}: ListKeyBoardNavArgs<Item>) => {
  // the ref is applied to the container of the search input
  const searchInputRef = useRef<HTMLInputElement>(null);

  // this ref is applied to very outer the list wrapper
  const listRef = useRef<HTMLDivElement>(null);
  // ref for the header element
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  // create 2 refs that are assigned to the virtual list
  const virtualListRef = useRef<VariableSizeList>(
    innerVirtualizeListRef?.current || null
  );
  const innerListRef = useRef<HTMLDivElement>(null);

  // the focussed index is the index of the item that we have highlighted using
  // keyboard or mouse navigation.
  const [focussedIndex, setFocussedIndex] = useState(
    rest.focussedIndex !== undefined && rest.focussedIndex >= 0
      ? rest.focussedIndex
      : inactiveIndex
  );

  // search input value
  const [searchInputVal, setSearchInputVal] = useState(
    searchInput?.value || ""
  );

  // store the total calculated height of all rendered items in the list
  const [listTotalHeight, setListTotalHeight] = useState(0);

  // determine the index of the last item
  const lastItemIndex = items.length;

  useEffect(() => {
    const height = getListHeight(innerListRef);
    if (height !== listTotalHeight) {
      setListTotalHeight(height);
    }
  }, [items, listTotalHeight]);

  useEffect(() => {
    if (focusListOnMount && !searchable) {
      listRef?.current?.focus({ preventScroll: true });
    }
    if (focusListOnMount && !!searchable && searchInput?.autoFocus !== false) {
      searchInputRef?.current?.focus({ preventScroll: true });
    }
  }, [focusListOnMount, searchInput?.autoFocus, searchable]);

  /**
   * bring the list item with the given index into the viewport incase it's not.
   */
  const bringListItemIntoView = useCallback((index: number) => {
    if (virtualListRef.current?.scrollToItem) {
      virtualListRef.current?.scrollToItem(index, "smart");
    } else {
      (innerListRef.current?.children[index] as HTMLDivElement).scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, []);

  /**
   * get the index of the next active item based on the direction
   * we've passed in
   */
  const getNextActiveIndex = useCallback(
    (direction: Direction): number => {
      const newActiveIndex = focussedIndex + direction;

      if (newActiveIndex < firstItemIndex) {
        return focussedIndex;
      }

      if (newActiveIndex + 1 > lastItemIndex) {
        return focussedIndex;
      }

      return newActiveIndex;
    },
    [focussedIndex, lastItemIndex]
  );

  // handle keyboard navigation
  const handleArrowKeys = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      const isModified = e.metaKey || e.ctrlKey;
      let newActiveIndex = focussedIndex;

      if (e.key === "ArrowUp") {
        e.preventDefault();
        newActiveIndex = isModified
          ? firstItemIndex
          : getNextActiveIndex(Direction.Up);
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        newActiveIndex = isModified
          ? lastItemIndex - 1
          : getNextActiveIndex(Direction.Down);
      }

      if (e.key === "Home") {
        e.preventDefault();
        newActiveIndex = firstItemIndex;
      }

      if (e.key === "End") {
        e.preventDefault();
        newActiveIndex = lastItemIndex - 1;
      }

      if (newActiveIndex !== focussedIndex) {
        setFocussedIndex(newActiveIndex);
        bringListItemIntoView(newActiveIndex);
      }
    },
    [focussedIndex, getNextActiveIndex, lastItemIndex, bringListItemIntoView]
  );

  // handle selection of item using ENTER
  const handleSelect = useCallback(
    (
      e: React.KeyboardEvent<
        HTMLInputElement | HTMLDivElement | HTMLUListElement
      >
    ) => {
      if (e.key === "Enter") {
        e.preventDefault();

        const useItemIndex = focussedIndex;

        const value = items[useItemIndex];

        if (value && onChange) {
          onChange(value);
        }
      }
    },
    [focussedIndex, items, onChange]
  );

  /**
   * All the props that are applied to the search input when the user wants one
   */
  const searchInputProps = useMemo(
    () => ({
      ...searchInput,
      ref: searchInputRef,
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleArrowKeys(e);
        handleSelect(e);
        searchInput?.onKeyDown?.(e);
      },
      value:
        typeof searchInput?.value === "undefined"
          ? searchInputVal
          : searchInput?.value,
      onFocus: () => {
        setFocussedIndex(firstItemIndex);
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setFocussedIndex(firstItemIndex);
        setSearchInputVal(e.target.value);
        searchInput?.onChange?.(e);
        virtualListRef.current?.scrollTo(0);
      },
    }),
    [searchInputVal, handleArrowKeys, handleSelect, searchInput]
  );

  /**
   * All the list props are assigned to the list element that contains
   * the items we want to navigate through
   */
  const listProps = useMemo(() => {
    const activeDescendant =
      focussedIndex !== inactiveIndex ? `item-${focussedIndex + 1}` : undefined;

    return {
      tabIndex: 0,
      ref: listRef,
      role: "listbox",
      "aria-activedescendant": activeDescendant,
      onMouseEnter: () => {
        if (listRef.current) {
          // Only focus on the list itself to activate keyboard navigation
          // if there is no search input in this list.
          if (!searchInputRef.current) {
            listRef.current.focus();
          }
          // if the search input is in the document but not focussed, we
          // focus it, so the user can easily type as well as use keyboard navigation
          if (
            searchInputRef.current &&
            document.activeElement !== searchInputRef.current
          ) {
            searchInputRef.current.focus();
          }
        }

        // if no list item is highlighted at the moment, highlight the first one
        if (focussedIndex === inactiveIndex) {
          setFocussedIndex(firstItemIndex);
        }
      },
      onFocus: () => {
        if (focussedIndex === inactiveIndex) {
          setFocussedIndex(firstItemIndex);
        }
      },
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        // prevent the list from scrolling when pressing space
        if (e.key === "Space") {
          e.preventDefault();
        }
        handleArrowKeys(e);
        handleSelect(e);
      },
    };
  }, [focussedIndex, handleArrowKeys, handleSelect, searchInputRef]);

  /**
   * All the item props are assigned to an individual list item
   */
  const getItemProps = useCallback(
    ({ index, ...userProps }: GetItemPropsArgs<Item>): GetItemProps => {
      const id = userProps.id || `item-${index + 1}`;

      const recalculatedIndex = index;
      return {
        id,
        role: "option",
        "aria-selected": index === focussedIndex,
        onMouseMove: () => {
          if (index !== focussedIndex) {
            setFocussedIndex(index);
          }
        },
        onClick: (e) => {
          e.preventDefault();

          onChange?.(items[recalculatedIndex] as Item);

          if (userProps.onClick) {
            userProps.onClick(items[recalculatedIndex] as Item);
          }

          // if there is a search input and the user clicks on a list item
          // focus on the search input
          if (searchInputRef.current) {
            if (document.activeElement !== searchInputRef.current) {
              searchInputRef.current.focus();
            }
          }
        },
      };
    },
    [items, focussedIndex, onChange]
  );

  return {
    listTotalHeight,
    focussedIndex,
    searchInputProps,
    listProps,
    getItemProps,
    listRef,
    searchInputVal:
      typeof searchInput?.value === "undefined"
        ? searchInputVal
        : searchInput?.value,
    virtualListRef,
    innerListRef,
    headerRef,
    footerRef,
  };
};
