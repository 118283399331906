import { ColorProp, useTheme } from "../../theme";

import { legendStyle, legendWrapperStyle } from "./calendarLegendStyles";

export interface CalendarLegendProps {
  color: ColorProp;
  label: string;
}

const CalendarLegend: React.FC<CalendarLegendProps> = (props) => {
  const { theme } = useTheme();
  const { color, label } = props;

  return (
    <div css={legendWrapperStyle(theme)}>
      <div css={legendStyle(theme, color)} />
      <div>{label}</div>
    </div>
  );
};

export default CalendarLegend;
