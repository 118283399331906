import { memo, useEffect, useRef } from "react";

import { Box } from "../../layout";
import { DynamicListItem } from "../../lists";
import { Color } from "../../theme";

import type {
  DefaultListItemProps,
  GetItemProps,
  GetItemPropsArgs,
  RenderItemProps,
} from "./List.types";
import { getRowHeight, noop } from "./List.utils";
import { ListItemWrapper } from "./ListItemWrapper";

interface ListRowProps<Item> {
  index: number;
  focussedIndex: number;
  searchInputValue: string;
  renderItem?: (props: RenderItemProps<Item>) => React.ReactNode;
  getItemProps: (args: GetItemPropsArgs<Item>) => GetItemProps;
  items: Item[];
  style?: React.CSSProperties;
  onSize?: (index: number, height: number) => void;
  getSize?: (index: number) => number;
}

const ListRowUnmemo = <Item extends DefaultListItemProps>({
  items,
  index,
  renderItem,
  focussedIndex,
  searchInputValue,
  getItemProps,
  style,
  onSize,
  getSize,
}: ListRowProps<Item>) => {
  const item = items[index] as Item;
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current && onSize && getSize) {
      const height = getRowHeight(
        rowRef.current.childNodes[0] as HTMLDivElement
      );

      if (height !== getSize(index) && height !== 0) {
        onSize(index, height);
      }
      const observable = new ResizeObserver(([rowEl]) => {
        const observedHeight = rowEl?.contentRect?.height;
        if (
          observedHeight &&
          observedHeight !== getSize(index) &&
          observedHeight > 0
        ) {
          onSize(index, observedHeight);
        }
      });

      observable.observe(rowRef.current);

      return () => observable.disconnect();
    }
    return noop;
  }, [index, getSize, onSize, rowRef.current]);

  // render custom item
  if (renderItem) {
    return (
      <Box style={style} ref={rowRef}>
        {renderItem({
          item,
          index,
          highlightText: searchInputValue as string,
          isHighlighted: index === focussedIndex,
          ...getItemProps({ index }),
        })}
      </Box>
    );
  }

  const { onClick, ...itemProps } = getItemProps({ index });

  return (
    <ListItemWrapper
      ref={rowRef}
      key={item.value || index}
      gutterH={0.25}
      gutterV={0.5}
      bg={index === focussedIndex ? Color.Brownstone_100 : Color.White}
      style={style}
    >
      <DynamicListItem
        primaryAction={{
          children: item.label || "",
          onClick,
        }}
        {...itemProps}
      />
    </ListItemWrapper>
  );
};

export const ListRow = memo(ListRowUnmemo) as typeof ListRowUnmemo;
