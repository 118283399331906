import type { CalendarLegendProps } from "./CalendarLegend";
import type { CalendarHighlightedDate } from "./CalendarRange";
import type { HighlightedDates } from "./DateInput";
import {
  add,
  startOfWeek,
  type CalendarDate,
  backOneYear,
  forwardOneYear,
  endOfWeek,
  backOneMonth,
  forwardOneMonth,
  compare,
} from "./dateHelpers";

export const getFocusedDate = (
  key: string,
  curr: CalendarDate,
  e: React.KeyboardEvent<HTMLDivElement>
) => {
  switch (key) {
    case "ArrowRight":
      return add(curr, { day: 1 });
    case "ArrowLeft":
      return add(curr, { day: -1 });
    case "ArrowUp":
      return add(curr, { day: -7 });
    case "ArrowDown":
      return add(curr, { day: 7 });
    case "Home":
      return startOfWeek(curr);
    case "End":
      return endOfWeek(curr);
    case "PageUp":
      if (e.shiftKey) {
        return backOneYear(curr);
      } else {
        return backOneMonth(curr);
      }
    case "PageDown":
      if (e.shiftKey) {
        return forwardOneYear(curr);
      } else {
        return forwardOneMonth(curr);
      }
  }
  return curr;
};

export const areEqual = (
  d1: CalendarDate | null | undefined,
  d2: CalendarDate | null | undefined
) => {
  if (!d1 || !d2) return false;

  return compare(d1, d2) === 0;
};

export const isSmaller = (
  d1: CalendarDate | null | undefined,
  d2: CalendarDate | null | undefined
) => {
  if (!d1 || !d2) return false;

  return compare(d1, d2) < 0;
};

export const flattenHighlightedDates = (
  dt: Array<HighlightedDates> | undefined
) => {
  const dates: Array<CalendarHighlightedDate> = [];
  const labels: Array<CalendarLegendProps> = [];
  if (!dt) return { dates, labels };
  dt.forEach((item) => {
    if (item.label) {
      labels.push({
        color: item.color,
        label: item.label,
      });
    }
    item.dates.forEach((dateObj) => {
      dates.push({
        date: dateObj.date,
        disabled: dateObj.disabled,
        color: item.color,
        label: item.label,
      });
    });
  });
  return { dates, labels };
};
