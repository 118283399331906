import React from "react";

const hasListboxParent = (target: Element) => {
  let parent = target.parentElement;

  while (parent) {
    // list and children nodes that clicked within a dialog should not close the dialog
    if (parent.getAttribute("role") === "list") {
      // checking that this list is sitting directly in the body portal
      return parent?.parentElement?.parentElement === document.body;
    }

    parent = parent.parentElement;
  }

  return false;
};

const tappedOut = <T extends Element>(
  ref: React.RefObject<T> | React.RefObject<T>[],
  target: Element
) => {
  if (hasListboxParent(target)) {
    return false;
  }
  const refs = Array.isArray(ref) ? ref : [ref];

  const hit = refs.some((r) => {
    const refEl = r.current;
    if (!refEl) {
      return;
    }

    return refEl.contains(target);
  });
  return !hit;
};

/**
 * @param ref One or more refs to element that should be excluded from tap out
 * detection. For example, this could be a dropdown's trigger AND its panel.
 * @param callback The callback to call when a tap outside the target element
 * occurs.
 */
export const useTapOut = <T extends Element>(
  ref: React.RefObject<T> | React.RefObject<T>[],
  callback: () => void
) => {
  React.useEffect(() => {
    if (
      typeof window === "undefined" ||
      typeof window.document === "undefined"
    ) {
      return;
    }

    const handleMouse = (e: MouseEvent) => {
      const target = e.target;
      if (!target || !(target instanceof Element)) {
        return;
      }

      if (tappedOut(ref, target)) {
        callback();
      }
    };

    const handleKey = (e: KeyboardEvent) => {
      if (e.key !== "Tab" || !document.activeElement) {
        return;
      }
      if (tappedOut(ref, document.activeElement)) {
        callback();
      }
    };

    document.addEventListener("mouseup", handleMouse, true);
    document.addEventListener("keydown", handleKey, true);
    return () => {
      document.removeEventListener("mouseup", handleMouse, true);
      document.removeEventListener("keydown", handleKey, true);
    };
  }, [ref, callback]);
};
