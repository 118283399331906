import * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";

import { boxStyle, AppearanceProps, omitAppearanceProps } from "./Box";

type Attributes<E> = Omit<
  React.HTMLAttributes<E>,
  keyof AppearanceProps | "children"
>;
interface Props extends AppearanceProps, Attributes<HTMLElement> {
  children: React.ReactNode;
}

const mainAreaStyle: CSSRulesFunction<Props> = (theme, props) => [
  boxStyle(theme, props),
  { "&:focus": { boxShadow: "none", outline: 0 } },
];

export const MainArea: React.FC<Props> = (props) => {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);
  return (
    <main css={mainAreaStyle(theme, props)} tabIndex={-1} {...rest}>
      {props.children}
    </main>
  );
};

export const Article = React.forwardRef<HTMLElement, Props>(function Article(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <article css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export const Section = React.forwardRef<HTMLElement, Props>(function Section(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <section css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export const Footer = React.forwardRef<HTMLElement, Props>(function Footer(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <footer css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export const Aside = React.forwardRef<HTMLElement, Props>(function Aside(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <aside css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export const Nav = React.forwardRef<HTMLElement, Props>(function Nav(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <nav css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export const Header = React.forwardRef<HTMLElement, Props>(function Header(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <header css={boxStyle(theme, props)} {...rest} ref={ref} />;
});
