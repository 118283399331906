export interface FormProps
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, "children"> {
  children: React.ReactNode;
}

const Form: React.FC<FormProps> = (props) => {
  return <form {...props} />;
};

export default Form;
