import {
  AlignItems,
  TypeScale,
  type CSSRulesFunction,
  type ColorProp,
} from "../../theme";

const vars = {
  spacing: "--calendarlegend-input-spacing",
  fontSize: "--calendarlegend-font-size",
  labelSpacing: "--calendarlegend-label-spacing",
};

export const legendStyle: CSSRulesFunction<ColorProp> = (theme, color) => {
  return {
    width: theme.spacing(0.5),
    height: theme.spacing(0.25),
    borderRadius: theme.radius(1),
    paddingRight: theme.spacing(0.75),
    paddingLeft: theme.spacing(0.75),
    margin: theme.spacing(0.5),
    backgroundColor: `${theme.color(color)}`,
  };
};

export const legendWrapperStyle: CSSRulesFunction = (theme) => {
  return [
    {
      [vars.spacing]: theme.spacing(1),
      [vars.labelSpacing]: theme.spacing(0.25),
      [vars.fontSize]: theme.tokens.fontSizes[TypeScale.Size_02].fontSize,
    },
    {
      display: "flex",
      marginRight: theme.spacing(0.5),
      alignItems: AlignItems.Center,
      fontSize: `var(${vars.fontSize})`,
      marginTop: theme.spacing(0.25),
    },
  ];
};

export const legendSectionStyle: CSSRulesFunction = (theme) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
  };
};
