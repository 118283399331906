import {
  Color,
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  ResponsiveValue,
  TypeScale,
} from "../theme";

export enum MenuDensity {
  Compact = "compact",
  Regular = "regular",
}

export interface MenuStyleProps {
  /**
   * @ignore
   */
  level: number;
  /**
   * Configures the visual density of the menu. This affects font sizes, weights
   * and general spacing and gutters within the menu.
   */
  density: ResponsiveValue<MenuDensity>;
  /**
   * Sets the color scheme for the menu.
   */
  colorScheme: ColorScheme;
  /**
   * Controls the layout of the first level of items in the menu.
   */
  groupLayout: ResponsiveValue<"row" | "column">;
}

const vars = {
  level: "--menu-level",
  baseOffset: "--menu-base-offset",
  startOffset: "--menu-start-offset",
  gutterV: "--menu-gutterv",
  gutterH: "--menu-gutterH",
  separatorGutterV: "--menu-separator-gutterv",
  separatorGutterH: "--menu-separator-gutterH",

  color: "--menu-color",
  altColor: "--menu-alt-color",
  disabledColor: "--menu-disabled-color",
  separatorColor: "--menu-separator-color",
  outlineColor: "--menu-outline-color",
  outlineOffsetColor: "--menu-outlineoffset-color",

  accentWidth: "--menu-accent-width",
  accentColor: "--menu-accent-color",
  accentDisabledColor: "--menu-accent-disabled-color",

  groupTitleFontSize: "--menu-grouptitle-fontsize",
  groupTitleLineHeight: "--menu-grouptitle-lineheight",
  groupDescFontSize: "--menu-groupdesc-fontsize",
  groupDescLineHeight: "--menu-groupdesc-lineheight",

  buttonWeight: "--menu-button-weight",
  buttonTitleFontSize: "--menu-buttontitle-fontsize",
  buttonTitleLineHeight: "--menu-buttontitle-lineheight",
  buttonDescFontSize: "--menu-buttondesc-fontsize",
  buttonDescLineHeight: "--menu-buttondesc-lineheight",

  labelSpacing: "--menu-label-spacing",
  labelTitleFontSize: "--menu-labeltitle-fontsize",
  labelTitleLineHeight: "--menu-labeltitle-lineheight",
  labelDescFontSize: "--menu-labeldesc-fontsize",
  labelDescLineHeight: "--menu-labeldesc-lineheight",
  labelDescColor: "--menu-labeldesc-color",
};

export const listResetStyle: Interpolation = {
  listStyleType: "none",
  padding: 0,
  margin: 0,
};

export const listItemResetStyle: Interpolation = {
  display: "block",
  width: "100%",
};

const menuColorTokens: CSSRulesFunction<ColorScheme> = (theme, colorScheme) => {
  const light = {
    [vars.color]: theme.color(ColorPreset.TextOnLight_01),
    [vars.altColor]: theme.color(ColorPreset.TextOnLight_03),
    [vars.disabledColor]: theme.color(Color.Greystone_1400_A38),
    [vars.separatorColor]: theme.color(ColorPreset.BorderOnLight_05),
    [vars.accentColor]: theme.color(Color.Greystone_DarkMatter),
    [vars.accentDisabledColor]: theme.color(Color.Greystone_1400_A16),
    [vars.outlineColor]: theme.color(Color.Greystone_DarkMatter),
    [vars.outlineOffsetColor]: theme.color(Color.Brownstone_50),
  };
  const dark = {
    [vars.color]: theme.color(ColorPreset.TextOnDark_01),
    [vars.altColor]: theme.color(ColorPreset.TextOnDark_03),
    [vars.disabledColor]: theme.color(Color.Greystone_700_A38),
    [vars.separatorColor]: theme.color(ColorPreset.BorderOnDark_05),
    [vars.accentColor]: theme.color(Color.Dawn_Base),
    [vars.accentDisabledColor]: theme.color(Color.Greystone_700_A16),
    [vars.outlineColor]: theme.color(Color.White),
    [vars.outlineOffsetColor]: theme.color(Color.Slate_1100),
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

const menuDensityTokens: CSSRulesFunction<MenuStyleProps["density"]> = (
  theme,
  density
) => {
  const ts2 = theme.tokens.fontSizes[TypeScale.Size_02];
  const ts3 = theme.tokens.fontSizes[TypeScale.Size_03];
  const ts5 = theme.tokens.fontSizes[TypeScale.Size_05];
  return theme.responsive(density, (d) => {
    switch (d) {
      case MenuDensity.Compact:
        return {
          [vars.gutterV]: theme.spacing(0.5),
          [vars.gutterH]: theme.spacing(1),
          [vars.separatorGutterV]: theme.spacing(1),
          [vars.separatorGutterH]: theme.spacing(1),
          [vars.labelSpacing]: theme.spacing(0.25),

          [vars.buttonWeight]: FontWeight.Normal,
          [vars.buttonTitleFontSize]: ts2.fontSize,
          [vars.buttonTitleLineHeight]: ts2.lineHeight,
          [vars.buttonDescFontSize]: ts2.fontSize,
          [vars.buttonDescLineHeight]: ts2.lineHeight,

          [vars.groupTitleFontSize]: ts3.fontSize,
          [vars.groupTitleLineHeight]: ts3.lineHeight,
          [vars.groupDescFontSize]: ts2.fontSize,
          [vars.groupDescLineHeight]: ts2.lineHeight,
        };
      case MenuDensity.Regular:
        return {
          [vars.gutterV]: theme.spacing(0.75),
          [vars.gutterH]: theme.spacing(1.5),
          [vars.separatorGutterV]: theme.spacing(1),
          [vars.separatorGutterH]: theme.spacing(1.5),
          [vars.labelSpacing]: theme.spacing(0.5),

          [vars.buttonWeight]: FontWeight.SemiBold,
          [vars.buttonTitleFontSize]: ts3.fontSize,
          [vars.buttonTitleLineHeight]: ts3.lineHeight,
          [vars.buttonDescFontSize]: ts2.fontSize,
          [vars.buttonDescLineHeight]: ts2.lineHeight,

          [vars.groupTitleFontSize]: ts5.fontSize,
          [vars.groupTitleLineHeight]: ts5.lineHeight,
          [vars.groupDescFontSize]: ts2.fontSize,
          [vars.groupDescLineHeight]: ts2.lineHeight,
        };
    }
  });
};

export interface MenuSeparatorStyleProps {
  groupLayout: MenuStyleProps["groupLayout"];
  /**
   * Controls the visibility of the separate across responsive breakpoints.
   */
  visibility: ResponsiveValue<"hidden" | "visible">;
}

export const menuSeparatorStyle: CSSRulesFunction<MenuSeparatorStyleProps> = (
  theme,
  { groupLayout, visibility }
) => {
  return [
    theme.responsive(visibility, (v) => {
      return { display: v === "hidden" ? "none" : "block" };
    }),
    theme.responsive(groupLayout, (gl) => {
      switch (gl) {
        case "row":
          return {
            width: "auto",
            height: "1px",

            margin: `var(${vars.separatorGutterV}) var(${vars.separatorGutterH})`,
            marginLeft: `calc(var(${vars.startOffset}) + var(${vars.separatorGutterH}))`,
          };
        case "column":
          return {
            height: "100%",
            width: "1px",
            margin: 0,
            marginLeft: 0,
          };
      }
    }),
    {
      backgroundColor: `var(${vars.separatorColor})`,
    },
  ];
};

export interface MenuButtonStyleProps {
  /**
   * Shows or hides an accent that is typically used to indicate that a link
   * or button is active.
   */
  accent: "visible" | "hidden";
}

export const menuButtonStyle: CSSRulesFunction<MenuButtonStyleProps> = (
  theme,
  props
) => {
  const accentColor =
    props.accent === "visible" ? `var(${vars.accentColor})` : "transparent";
  const accentDisabledColor =
    props.accent === "visible"
      ? `var(${vars.accentDisabledColor})`
      : "transparent";
  const varOutline = "--menu-buttonoutline-on";

  return {
    [vars.labelTitleFontSize]: `var(${vars.buttonTitleFontSize})`,
    [vars.labelTitleLineHeight]: `var(${vars.buttonTitleLineHeight})`,
    [vars.labelDescFontSize]: `var(${vars.buttonDescFontSize})`,
    [vars.labelDescLineHeight]: `var(${vars.buttonDescLineHeight})`,

    [varOutline]: 0,
    display: "block",
    width: "100%",
    padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
    paddingLeft: `calc(var(${vars.startOffset}) + var(${vars.gutterH}) - var(${vars.accentWidth}))`,
    textAlign: "start",
    background: "none",
    border: "none",
    fontFamily: "inherit",
    fontSize: `var(${vars.buttonTitleFontSize})`,
    lineHeight: `var(${vars.buttonTitleLineHeight})`,
    fontWeight: `var(${vars.buttonWeight})`,
    cursor: "pointer",
    textDecoration: "none",
    color: "inherit",
    borderLeft: `var(${vars.accentWidth}) solid ${accentColor}`,
    boxShadow: `
      0 0 0 calc(var(${varOutline}) * 1px) var(${vars.outlineOffsetColor}),
      0 0 0 calc(var(${varOutline}) * 4px) var(${vars.outlineColor})
    `,
    borderRadius: `calc(var(${varOutline}) * ${theme.radius(0.25)})`,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      [varOutline]: 1,
      outline: "none",
    },
    "&:disabled": {
      [vars.labelDescColor]: `var(${vars.disabledColor})`,
      color: `var(${vars.disabledColor})`,
      borderLeft: `var(${vars.accentWidth}) solid ${accentDisabledColor}`,
      cursor: "not-allowed",
      textDecoration: "none",
    },

    // Only show focus indicators when use keyboard (Tab) for navigation
    ".js-focus-visible &:focus:not(.focus-visible)": {
      [varOutline]: 0,
    },
    "&:focus:not(:focus-visible)": {
      [varOutline]: 0,
    },
  };
};

export const menuExpanderTriggerStyle = menuButtonStyle;

export const menuLabelStyle: Interpolation = {
  display: "flex",
  alignItems: "center",
  "> :not([aria-hidden])": { flex: "1 1 auto" },
  "> * + *": { marginLeft: "8px" },
};

export const menuTitleStyle: Interpolation = {
  display: "block",
  fontSize: `var(${vars.labelTitleFontSize})`,
  lineHeight: `var(${vars.labelTitleLineHeight})`,
};

export const menuDescriptionStyle: Interpolation = {
  display: "block",
  fontSize: `var(${vars.labelDescFontSize})`,
  lineHeight: `var(${vars.labelDescLineHeight})`,
  color: `var(${vars.labelDescColor}, var(${vars.altColor}))`,
  fontWeight: FontWeight.Normal,
  "&:last-of-type:not(:first-of-type)": {
    marginTop: `var(${vars.labelSpacing})`,
  },
};

export const menuLevelStyle = (level: number): Interpolation => {
  return {
    [vars.level]: level,
    [vars.startOffset]: `calc(var(${vars.level}) * var(${vars.baseOffset}))`,
  };
};

export const menuGroupLabelStyle: Interpolation = {
  padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
  paddingLeft: `calc(var(${vars.startOffset}) + var(${vars.gutterH}))`,
  fontWeight: FontWeight.SemiBold,
  [vars.labelTitleFontSize]: `var(${vars.groupTitleFontSize})`,
  [vars.labelTitleLineHeight]: `var(${vars.groupTitleLineHeight})`,
  [vars.labelDescFontSize]: `var(${vars.groupDescFontSize})`,
  [vars.labelDescLineHeight]: `var(${vars.groupDescLineHeight})`,
};

export const menuRootLabelStyle = menuGroupLabelStyle;

export const menuRootListStyle: CSSRulesFunction<MenuStyleProps> = (
  theme,
  props
) => {
  return [
    {
      display: "grid",
      justifyContent: "start",
    },
    theme.responsive(props.groupLayout, (gl) => {
      switch (gl) {
        case "row": {
          return {
            gridAutoFlow: gl,
            gridAutoColumns: "100%",
            "> [role=separator]": { justifySelf: "initial" },
          };
        }
        case "column": {
          return {
            gridAutoFlow: gl,
            gridAutoColumns: "1fr",
            "> [role=separator]": { justifySelf: "center" },
          };
        }
      }
    }),
  ];
};

export const menuRootStyle: CSSRulesFunction<MenuStyleProps> = (
  theme,
  props
) => {
  return [
    menuDensityTokens(theme, props.density),
    menuColorTokens(theme, props.colorScheme),
    menuLevelStyle(props.level),
    {
      [vars.baseOffset]: theme.spacing(0.75),
      [vars.accentWidth]: "4px",
      color: `var(${vars.color})`,
    },
  ];
};
