import type React from "react";

import type { ButtonLayout } from "../../buttons";
import type { ColorProp, ColorScheme } from "../../theme";

export enum UploadStatus {
  Initial = "initial",
  Disabled = "disabled",
  Uploading = "uploading",
}

export enum UploadSelectionMode {
  Single = "single",
  Multiple = "multiple",
}
export interface UploadTranslations {
  /**
   * The translations for the "Choose File" button.
   * If multiple files can be chosen it should be translation for "Choose files"
   */
  chooseFile: React.ReactNode;
  /**
   * The translations for the remove file message
   */
  removeFile: React.ReactNode;
  /**
   * The translations for the uploading label message
   */
  uploadingLabel: React.ReactNode;
}

export interface UploadStyleProps {
  /**
   * Sets the color scheme of the Upload component
   */
  colorScheme: ColorScheme;
  /**
   * Sets the layout of the upload button
   */
  buttonLayout?: ButtonLayout;
}

export interface UploadProps extends UploadStyleProps {
  /**
   * Sets the file types that should be accepted
   */
  accept: string;
  /**
   * Sets wether the files are uploading or have been uploaded and component has file empty or selected
   */
  status?: UploadStatus;
  /**
   * Sets single or multiple file selection
   */
  selectionMode?: UploadSelectionMode;
  /**
   * Sets the description of the upload component
   */
  description?: string;
  /**
   * Sets single or multiple file selection
   */
  bg?: ColorProp;
  /**
   * Pass an array of strings to know what errors have happened within the component
   */
  errors?: string[];
  /**
   * The required translations for the upload component
   * { chooseFile: React.ReactNode, uploadButton: React.ReactNode, noFileChosen: React.ReactNode, removeFile: React.ReactNode, uploadLabel: React.ReactNode }
   */
  translations?: UploadTranslations;
  /**
   * Custom select function to be implemented by the consumer
   */
  onSelectFiles: (files: Array<File>) => void;
  /**
   * The id of the element that describes the upload component
   */
  uploadDescribeby?: string;
}
