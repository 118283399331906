import { FormFieldStatus } from "@gocardless/flux-react";

export const getInputErrorStatus = (value: boolean) => {
  if (value) {
    return FormFieldStatus.Danger;
  }

  return;
};

export const getInputStatus = (isInvalidValue: boolean, value: string) => {
  if (!value) {
    return;
  }

  if (isInvalidValue) {
    return FormFieldStatus.Danger;
  }

  return FormFieldStatus.Success;
};

// We currently don't support these countries and have seen large volumes of SMS abuse from these numbers.
// See incident https://gocardless.slack.com/archives/C02JAFG3KT7
const invalidPhoneCountryCodes = ["+84", "+62"];

const InvalidPhoneRegexValues = invalidPhoneCountryCodes.reduce(
  (acc, curr) => (!acc ? `\\${curr}` : `${acc}|\\${curr}`),
  ""
);

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_PHONE_NUMBER_REGEX = /^\+([0-9]|\s){1,}$/;
export const VALID_OTP_CODE = /^([0-9]{6,6})$/;
export const VALID_OTP_RECOVERY_CODE = /^([0-9a-zA-Z]{16,})$/;
export const VALID_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}$/;
export const INVALID_PHONE_COUNTRY_CODE = new RegExp(
  "^" + InvalidPhoneRegexValues + ".*$",
  "g"
);
export const VALID_SOCIAL_SECURITY_NUMBER_REGEX = /^(\d{3})-?(\d{2})-?(\d{4})$/;
export const INVALID_SOCIAL_SECURITY_NUMBER_REGEX =
  /^(666)-(\d{2})-(\d{4})|(000)-(\d{2})-(\d{4})|(\d{3})-(00)-(\d{4})|(\d{3})-(\d{2})-(0000)$/;
export const VALID_ITIN_INVALID_SOCIAL_SECURITY_NUMBER_REGEX =
  /^(666|9\d{2})-?(\d{2})-?(\d{4})$/;
export const VALID_ITIN_REGEX = /^(9\d{2})-?(\d{2})-?(\d{4})$/;
export const VALID_URL =
  // eslint-disable-next-line no-useless-escape
  /^(https?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const VALID_COLOR =
  /^#(?:[\da-f]{3}){1,2}$|^#(?:[\da-f]{4}){1,2}$|(rgb|hsl)a?\((\s*-?\d+%?\s*,){2}(\s*-?\d+%?\s*)\)|(rgb|hsl)a?\((\s*-?\d+%?\s*,){3}\s*(0|(0?\.\d+)|1)\)/;

export const VALID_AMOUNT = /^\d+(\.\d{1,2})?$/;

export const PASSWORD_MINIMUM_LENGTH = 12;
export const ADMIN_PASSWORD_MINIMUM_LENGTH = 20;

export const passwordLengthValidation = (email: string) =>
  email.includes("@gocardless.com")
    ? ADMIN_PASSWORD_MINIMUM_LENGTH
    : PASSWORD_MINIMUM_LENGTH;

export const invalidGoCardlessEmailSignUp = (email: string) =>
  !email.split("@")[0]?.includes("+") && /@gocardless.com/gim.test(email);

export const followsPasswordUserInfoPolicy = (
  passwordValue: string,
  family_name: string,
  given_name: string,
  email: string
) =>
  !passwordValue.includes(family_name.toLowerCase()) &&
  !passwordValue.includes(given_name.toLowerCase()) &&
  !passwordValue.includes(email.toLowerCase());

export const VALID_CREDITOR_ID = /^CR[a-zA-Z0-9]{12}$|^CR[a-zA-Z0-9]{26}$/;
export const VALID_MANDATE_ID = /^MD[a-zA-Z0-9]{12}$|^MD[a-zA-Z0-9]{26}$/;
export const VALID_CUSTOMER_ID = /^CU[a-zA-Z0-9]{12}$|^CU[a-zA-Z0-9]{26}$/;

export const VALID_SUN = /^([0-9]{6,6})$/;
