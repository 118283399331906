import * as React from "react";

import { Box } from "../layout";
import { LI, UL } from "../lists";
import { JustifyContent, useTheme } from "../theme";

import { dataTableCompactRowStyle, dataTableStyle } from "./styles";
import type {
  DataTableCompactProps,
  DataTableCompactRowProps,
} from "./componentTypes";
import CompactRowRightActions from "./CompactRowRightActions";
import CompactRowBody from "./CompactRowBody";

function DataTableCompactRow<Item extends object>(
  props: DataTableCompactRowProps<Item>
): React.ReactElement {
  const { collapsible, headingsArray, ...rest } = props;
  const [collapsed, setCollapsed] = React.useState(!!collapsible);
  const { theme } = useTheme();
  const extraActions = props.data.compactActions;
  const actionsRight = !!extraActions && !!extraActions.rightActions;

  const rowButtons = !!collapsible || actionsRight;

  const toggleCollapse = () => setCollapsed((state) => !state);

  const pickedHeadings = headingsArray.filter(
    (heading) => !heading.omittedWhenCompact
  );

  const showHeadingsCount =
    collapsible?.alwaysVisibleAmount && collapsible.alwaysVisibleAmount > 0
      ? collapsible.alwaysVisibleAmount
      : 2;

  return (
    <LI
      key={`${props.rowKey}_li`}
      css={dataTableCompactRowStyle(theme, {
        hideTopBorder: props.hideTopBorder,
        hideBottomBorder: props.hideBottomBorder,
      })}
    >
      <Box layout="flex" justifyContent={JustifyContent.SpaceBetween}>
        <CompactRowBody
          {...rest}
          headingsArray={pickedHeadings}
          alwaysVisibleAmount={
            !!collapsible && collapsed ? showHeadingsCount : undefined
          }
        />
        {!!rowButtons && (
          <CompactRowRightActions
            collapsed={collapsed}
            toggleCollapse={toggleCollapse}
            rowKey={props.rowKey}
            collapsible={
              !!collapsible
                ? { labelTranslations: collapsible.labelTranslations }
                : undefined
            }
            actions={!!extraActions ? extraActions.rightActions : undefined}
          />
        )}
      </Box>
      {!!extraActions && !!extraActions.bottomActions && (
        <Box layout="flex" flexWrap="wrap" css={{ gap: theme.spacing(0.5) }}>
          {extraActions.bottomActions.map((action, i) => (
            <span key={`${props.rowKey}_action_bottom_${i}`}>{action}</span>
          ))}
        </Box>
      )}
    </LI>
  );
}

function DataTableCompact<Item extends object>(
  props: DataTableCompactProps<Item>
): React.ReactElement {
  const { theme } = useTheme();
  const {
    headings,
    rows,
    getHeadingStyleProps,
    getCellStyleProps,
    collapsible,
    emptyState,
    hideBottomBorder,
    hideTopBorder,
  } = props;

  if (rows.length === 0 && emptyState) {
    return <Box>{emptyState}</Box>;
  }
  return (
    <UL marker="none" css={dataTableStyle(theme)}>
      {rows.map((row, index, arr) => {
        const rowProps = row.getRowProps();
        const isFirstItem = index === 0;
        const isLastItem = index === arr.length - 1;
        return (
          <DataTableCompactRow
            key={rowProps.key}
            rowKey={rowProps.key}
            data={row}
            headingsArray={headings.cells}
            getHeadingStyleProps={getHeadingStyleProps}
            getCellStyleProps={getCellStyleProps}
            collapsible={collapsible}
            hideTopBorder={hideTopBorder && isFirstItem}
            hideBottomBorder={hideBottomBorder && isLastItem}
          />
        );
      })}
    </UL>
  );
}

export default DataTableCompact;
