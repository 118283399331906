import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { ColorScheme, useTheme } from "../../theme";

import ToggleCheck from "./ToggleCheck";
import { checkboxesToggleStyle } from "./toggleStyles";
import {
  ToggleControl,
  ToggleControlPosition,
  ToggleStyleProps,
  ToggleVariant,
} from "./toggleTypes";

export type CheckboxesToggleState = "mixed" | "all" | "none";

type HTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "type" | "aria-checked" | "role" | "onChange"
>;

export interface CheckboxesToggleProps extends HTMLAttributes {
  /**
   * Sets the layout variant for this component.
   */
  variant?: ToggleVariant.Compact | ToggleVariant.ControlOnly;
  /**
   * Sets the selection state to reflect on this component.
   */
  value: CheckboxesToggleState;
  /**
   * A callback that is invoked when the selection state is changed.
   *
   * @param value The new selection state
   */
  onChange(value: CheckboxesToggleState): void;

  /**
   * The color scheme to use in this component.
   */
  colorScheme?: ColorScheme;

  /**
   * The label to display in this component.
   */
  children: React.ReactNode;
}

const ariaCheckedMapping: Record<
  CheckboxesToggleState,
  React.AriaAttributes["aria-checked"]
> = {
  all: "true",
  none: "false",
  mixed: "mixed",
};
const nextStates: Record<CheckboxesToggleState, CheckboxesToggleState> = {
  all: "none",
  none: "all",
  mixed: "none",
};

const CheckboxesToggle = React.forwardRef<
  HTMLButtonElement,
  CheckboxesToggleProps
>((props, ref) => {
  const {
    value,
    onChange,
    colorScheme = ColorScheme.OnLight,
    variant = ToggleVariant.Compact,
    children,
    ...rest
  } = props;
  const { theme } = useTheme();

  const styleProps: ToggleStyleProps = {
    colorScheme,
    variant,
    control: ToggleControl.Check,
    controlPosition: ToggleControlPosition.Start,
  };

  return (
    <button
      css={checkboxesToggleStyle(theme, styleProps)}
      {...rest}
      type="button"
      role="checkbox"
      aria-checked={ariaCheckedMapping[value]}
      onClick={(e) => {
        onChange(nextStates[value]);
        rest.onClick?.(e);
      }}
      data-field-control
      ref={ref}
    >
      <div>
        <ToggleCheck indeterminate={value === "mixed"} />
      </div>
      {variant === ToggleVariant.ControlOnly ? (
        <VisuallyHidden>{children}</VisuallyHidden>
      ) : (
        <div>{children}</div>
      )}
    </button>
  );
});

export default CheckboxesToggle;
