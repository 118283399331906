import {
  Color,
  ColorPreset,
  ColorProp,
  CSSRulesFunction,
  Elevation,
  FontWeight,
  ResponsiveValue,
  TextAlign,
} from "../theme";

import { BannerVariant } from "./types";

export interface BannerThemingProps {
  /**
   * Configures the background color of the notification
   */
  backgroundColor?: ColorProp;
  /**
   * Configures the appearance of the notification to be either light or dark.
   * This is useful to create enough contrast with different page backgrounds.
   */
  variant: BannerVariant;

  /** Elevations corresponding to various levels of drop-shadows  */
  elevation?: ResponsiveValue<Elevation>;
}

export const bannerActionsLayout: CSSRulesFunction = (theme) => {
  return [
    {
      fontWeight: FontWeight.SemiBold,
    },
    theme.responsive(["block", "block", "flex"], (s) => {
      return {
        display: s,
      };
    }),
    theme.responsive([TextAlign.Center, TextAlign.Center, null], (t) => {
      return {
        textAlign: t,
      };
    }),
  ];
};

export const bannerStyle: CSSRulesFunction<BannerThemingProps> = (
  theme,
  { variant, backgroundColor, elevation }
) => {
  const vl = BannerVariant.Light;
  const vd = BannerVariant.Dark;
  const fb = BannerVariant.FullBleed;

  return [
    {
      [`--banner-${vl}-bg`]: theme.color(Color.White),
      [`--banner-${vl}-fg`]: theme.color(ColorPreset.TextOnLight_01),
      [`--banner-${vd}-bg`]: theme.color(Color.Greystone_DarkMatter),
      [`--banner-${vd}-fg`]: theme.color(ColorPreset.TextOnDark_01),
      [`--banner-${fb}-bg`]: theme.color(ColorPreset.BackgroundLight_03),
      [`--banner-${fb}-fg`]: theme.color(ColorPreset.TextOnLight_01),

      backgroundColor: backgroundColor
        ? theme.color(backgroundColor)
        : `var(--banner-${variant}-bg)`,
      color: `var(--banner-${variant}-fg)`,

      borderRadius: variant === fb ? "unset" : theme.radius(1),

      paddingLeft: [theme.spacing(1.5)],
      paddingRight: [theme.spacing(1.5)],

      width: "100%",
      overflow: "auto",
    },
    elevation == null
      ? {
          filter: variant === fb ? "unset" : theme.tokens.elevation[4],
        }
      : theme.responsive(elevation, (e, themeValue) => ({
          filter: themeValue.tokens.elevation[e],
        })),
    theme.responsive(
      [theme.spacing(1.5), theme.spacing(1.5), theme.spacing(1.5)],
      (s) => {
        return {
          paddingTop: s,
          paddingBottom: s,
        };
      }
    ),
  ];
};
