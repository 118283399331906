import {
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FocusRing,
  Interpolation,
} from "../theme";
import { focusRingVar } from "../theme/cssvariables";
import { focusRingStyle } from "../theme/focusRings";
import { typographyStyle } from "../typography";
import type { TypographyStyleProps } from "../typography/appearance";

import type { BreadcrumbsStyleProps } from "./breadcrumbsTypes";

export const vars = {
  spaceBefore: "--breadcrumbs-spacebefore",
  spaceAfter: "--breadcrumbs-spaceafter",
  staggered: "--breadcrumbs-staggered",
  color: "--breadcrumbs-color",
  separatorColor: "--breadcrumbs-separator-color",
  activeColor: "--breadcrumbs-active-color",
};

export const breadcrumbsSeparatorStyle: Interpolation = {
  margin: `0 var(${vars.spaceAfter}) 0 var(${vars.spaceBefore})`,
  alignSelf: "center",
  userSelect: "none",
  color: `var(${vars.separatorColor})`,
  "li:first-of-type > &": {
    display: "none",
  },
};

export const breadcrumbsLinkStyle: Interpolation = {
  color: "inherit",
  textDecoration: "none",
  boxShadow: `var(${focusRingVar})`,
  "&:hover": {
    textDecoration: "underline",
  },
};

export const breadcrumbsItemStyle: CSSRulesFunction<
  TypographyStyleProps & { level: number }
> = (theme, props) => {
  const { level } = props;
  const s = theme.spacing(1);
  const ml = `calc(var(--breadcrumbs-staggered) * ${level} * ${s})`;
  return [
    typographyStyle(theme, props),
    {
      display: "inline-flex",
      alignItems: "baseline",
      marginLeft: ml,
      "&:last-of-type": {
        color: `var(${vars.activeColor})`,
      },
    },
  ];
};

const breadcrumbsColorSchemeTokens: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  const light = {
    [vars.color]: theme.color(ColorPreset.TextOnLight_02),
    [vars.separatorColor]: theme.color(ColorPreset.IconOnLight_03),
    [vars.activeColor]: theme.color(ColorPreset.TextOnLight_01),
  };

  const dark = {
    [vars.color]: theme.color(ColorPreset.TextOnDark_02),
    [vars.separatorColor]: theme.color(ColorPreset.IconOnDark_03),
    [vars.activeColor]: theme.color(ColorPreset.TextOnDark_01),
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

export const breadcrumbsStyle: CSSRulesFunction<BreadcrumbsStyleProps> = (
  theme,
  props
) => {
  const { layout, colorScheme, ...rest } = props;
  return [
    typographyStyle(theme, rest),
    breadcrumbsColorSchemeTokens(theme, colorScheme),
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme,
      focusSelector: "& li a:focus-visible",
    }),
    {
      [vars.spaceBefore]: theme.spacing(1),
      [vars.spaceAfter]: theme.spacing(1),
      display: "flex",
      flexWrap: "nowrap",
      margin: 0,
      padding: 0,
      listStyleType: "none",
      color: `var(${vars.color})`,
    },
    theme.responsive(layout, (v) => {
      switch (v) {
        case "column":
          return {
            [vars.staggered]: 1,
            [vars.spaceBefore]: 0,
            whiteSpace: "normal",
            flexDirection: v,
            alignItems: "start",
          };
        case "row":
          return {
            [vars.staggered]: 0,
            [vars.spaceBefore]: theme.spacing(1),
            whiteSpace: "nowrap",
            flexDirection: v,
            alignItems: "center",
          };
      }
    }),
  ];
};
