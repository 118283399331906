import {
  ResponsiveValue,
  CSSRulesFunction,
  useTheme,
  ColorProp,
} from "../theme";

import registry, { Glyph } from "./registry";

export type IconProps = {
  /**
   * The glyph to display using this component
   */
  name: Glyph;
  /**
   * The size of the icon. If this is not set, then the icon's size matches the
   * font-size set by parent elements.
   */
  size?: ResponsiveValue<string>;
  /**
   * The color of icon. If this is not set, then the icon's color matches the
   * text color set by parent elements.
   */
  color?: ResponsiveValue<ColorProp>;
  /**
   * Sets vertical alignment of the icon with respect to other inline elements
   * such as text.
   */
  verticalAlign?: ResponsiveValue<"middle" | "baseline">;

  className?: string;
};

const iconStyle: CSSRulesFunction<IconProps> = (theme, props) => [
  {
    display: "inline-block",
    flex: "0 0 auto",
  },
  props.color
    ? theme.responsive(props.color, (c) => ({ color: theme.color(c) }))
    : null,
  theme.responsive(props.size, (s) => ({ width: s, height: s })),
  theme.responsive(props.verticalAlign, (verticalAlign) => ({ verticalAlign })),
];

const Icon: React.FC<IconProps> = (props) => {
  const { theme } = useTheme();
  const { name, size, color, verticalAlign, ...rest } = props;
  const SVG = registry[name];
  return <SVG css={iconStyle(theme, props)} aria-hidden {...rest} />;
};

export default Icon;
