import * as React from "react";

import { useTheme } from "../theme";
import { Shimmer } from "../animations";
import { Box } from "../layout";

import type { DataTableCompactRowProps } from "./componentTypes";
import {
  compactBodyGridStyle,
  dataTableCellStyle,
  dataTableHeaderStyle,
  dataTableHeadingContentStyle,
} from "./styles";
import { getHeaderLabelAndAccessories } from "./DataTable";

type RowBodyProps<Item> = Omit<
  DataTableCompactRowProps<Item>,
  "collapsible" | "omitHeadings" | "extraActions"
> & { alwaysVisibleAmount?: number };

function CompactRowBody<Item extends object>({
  headingsArray,
  alwaysVisibleAmount,
  data,
  rowKey,
  getHeadingStyleProps = () => ({}),
  getCellStyleProps = () => ({}),
}: RowBodyProps<Item>) {
  const { theme } = useTheme();
  return (
    <dl key={`${rowKey}_body_dl`} css={compactBodyGridStyle(theme)}>
      {headingsArray.map((heading, index) => {
        const headingProps = heading.getHeadingProps();

        const { label, leftAccessory, rightAccessory } =
          getHeaderLabelAndAccessories(heading);

        const cellInstance = data.cells.find(
          (cell) => cell.accessor === heading.accessor
        );

        let cellRender: React.ReactNode = null;
        if (cellInstance) {
          cellRender =
            cellInstance.type === "loading" ? (
              <Shimmer />
            ) : (
              cellInstance.render()
            );
        }
        const collapse = !!alwaysVisibleAmount && index >= alwaysVisibleAmount;
        const display = collapse
          ? theme.responsive(["none", null, "block"], (value) => ({
              display: value,
            }))
          : undefined;
        return (
          <React.Fragment key={`${rowKey}_${headingProps.key}_fragment`}>
            <dt
              key={`${rowKey}_${headingProps.key}`}
              css={[
                dataTableHeaderStyle(theme, getHeadingStyleProps(heading)),
                { padding: 0 },
                display,
              ]}
            >
              <Box
                key={`${rowKey}_${headingProps.key}_render`}
                css={[dataTableHeadingContentStyle, { alignItems: "start" }]}
              >
                {leftAccessory}
                {label}
                {rightAccessory}
              </Box>
            </dt>
            <dd
              key={`${rowKey}_${headingProps.key}_value`}
              css={[
                { margin: "0" },
                display,
                theme.responsive([theme.spacing(1), "0"], (mb) => ({
                  marginBottom: mb,
                  ":last-child": { marginBottom: "0" },
                })),
              ]}
            >
              {!!cellInstance && (
                <Box
                  key={`${rowKey}_${headingProps.key}_value_render`}
                  css={[
                    dataTableCellStyle(theme, {
                      cellStyle: getCellStyleProps(cellInstance),
                    }),
                    { padding: "0px" },
                  ]}
                >
                  {cellRender}
                </Box>
              )}
            </dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
}

export default CompactRowBody;
