import { ClassNames } from "@emotion/react";
import * as React from "react";

import { VisuallyHidden } from "../a11y";
import { Icon } from "../icons";
import { ButtonSize, useTheme } from "../theme";

import { iconButtonStyle } from "./styles";
import type { ButtonRenderProps, IconButtonStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLAnchorElement>,
  keyof IconButtonStyleProps
>;

export interface IconLinkProps extends HTMLAttributes, IconButtonStyleProps {
  label: React.ReactNode;

  render?: (props: ButtonRenderProps) => React.ReactNode;
}

const IconLink = React.forwardRef<HTMLAnchorElement, IconLinkProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const {
      icon,
      label,
      size = ButtonSize.Md,
      variant,
      render,
      ...rest
    } = props;
    const wrappedChildren = (
      <>
        <VisuallyHidden>{label}</VisuallyHidden>
        <Icon name={icon} />
      </>
    );

    return (
      <ClassNames>
        {({ css, cx }) => {
          const className = cx(
            css(iconButtonStyle(theme, { ...props, size })),
            props.className
          );
          const forwardedProps = {
            ...rest,
            className,
            children: wrappedChildren,
          };

          return render ? (
            render(forwardedProps)
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a ref={ref} {...forwardedProps} />
          );
        }}
      </ClassNames>
    );
  }
);

export default IconLink;
