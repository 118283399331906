import { CSSRulesFunction, ResponsiveValue, useTheme } from "../theme";

export interface LineClampProps {
  /**
   * Set the line limit for this component after which text is visually clipped
   */
  lines: ResponsiveValue<number>;

  children: React.ReactNode;
}

const noClamp: CSSRulesFunction = () => ({
  WebkitLineClamp: "none",
  overflow: "initial",
});

const lineClampStyle: CSSRulesFunction<LineClampProps> = (theme, { lines }) => [
  {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
  },
  noClamp(theme),
  theme.responsive(lines, (l) => {
    if (l > 0) {
      return {
        WebkitLineClamp: l,
        overflow: "hidden",
      };
    } else {
      return noClamp(theme);
    }
  }),
];

const LineClamp: React.FC<LineClampProps> = (props) => {
  const { theme } = useTheme();
  return <span css={lineClampStyle(theme, props)}>{props.children}</span>;
};

export default LineClamp;
