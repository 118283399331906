import { CSSRulesFunction, useTheme } from "../theme";

type HTMLAttributes = React.HTMLAttributes<HTMLDivElement>;

export interface FieldProps extends Omit<HTMLAttributes, "children"> {
  children: React.ReactNode;
}

const fieldStyle: CSSRulesFunction = (theme) => ({
  "&& > *": {
    marginBottom: theme.spacing(0.25),
  },
  "&& > input, && > select, && > [data-field-control]": {
    margin: theme.spacing([0.5, 0]),
  },
  "&& > :last-child": {
    marginBottom: theme.spacing(0),
  },
});

const Field: React.FC<FieldProps> = (props) => {
  const { theme } = useTheme();
  return <div css={fieldStyle(theme)} {...props} data-field-control />;
};

export default Field;
