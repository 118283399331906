import type { CSSInterpolation } from "@emotion/serialize";

import type {
  Theme,
  DesignTokens,
  SpaceScale,
  ResponsiveValue,
  ColorProp,
  BorderRadiusScale,
} from "./types";
import { Breakpoint } from "./types";
import { makeCSSValueHelper } from "./helpers";

export default class DefaultTheme implements Theme {
  public readonly tokens: DesignTokens;
  public constructor(tokens: DesignTokens) {
    this.tokens = tokens;
  }

  public spacing = makeCSSValueHelper((scale: SpaceScale) => {
    return `${scale * this.tokens.spacingBase}px`;
  });
  public radius = makeCSSValueHelper((scale: BorderRadiusScale) => {
    return `${scale * this.tokens.borderRadiusBase}px`;
  });

  public color = (name: ColorProp): string => this.tokens.colors[name];

  public responsive = <Value>(
    values: ResponsiveValue<Value> | null | undefined,
    interpolate: (value: Value, theme: Theme) => CSSInterpolation
  ): CSSInterpolation => {
    if (values == null) {
      return;
    }
    if (!Array.isArray(values)) {
      return interpolate(values, this);
    }
    const defaultValue = values[0];
    const defaultRules =
      defaultValue == null ? null : interpolate(defaultValue, this);
    const styles: CSSInterpolation = defaultRules ? [defaultRules] : [];

    Object.values(Breakpoint).forEach((bp, i) => {
      const v = values[i + 1];
      if (v == null) {
        return;
      }
      const breakpoint = this.tokens.breakpoints[bp];
      if (!breakpoint) {
        return;
      }
      const mq = `@media (min-width: ${breakpoint})`;
      const rules = interpolate(v, this);
      (styles as unknown[]).push({ [mq]: rules });
    });

    return styles;
  };
}
