import { BannerActionType, type BannerVariant } from "./types";
import BannerAction from "./BannerAction";
import type { BannerActionProps } from "./types";
import { setButtonVariant } from "./utils";

export interface PrimaryActionProps {
  variant: BannerVariant;
  value?: BannerActionProps;
}

const PrimaryAction: React.FC<PrimaryActionProps> = (props) => {
  if (props.value == null) {
    return null;
  }

  const btnVariant = setButtonVariant(
    props.variant,
    BannerActionType.Primary,
    props.value.buttonVariant
  );

  return <BannerAction {...props.value} variant={btnVariant} />;
};

export default PrimaryAction;
