import { Global } from "@emotion/react";

import {
  useTheme,
  CSSRulesFunction,
  TypePreset,
  ColorProp,
  ColorPreset,
} from "./theme";

interface ResetStyleProps {
  bg?: ColorProp;
  color: ColorProp;
}

const resetStyle: CSSRulesFunction<ResetStyleProps> = (theme, props) => ({
  "*": {
    boxSizing: "border-box",
  },
  "html, body": [
    {
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
      backgroundColor: props.bg ? theme.color(props.bg) : undefined,
      color: theme.color(props.color),
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
    },
    theme.responsive(
      theme.tokens.typePresets[TypePreset.Body_02].size,
      (fs) => ({ ...theme.tokens.fontSizes[fs] })
    ),
  ],
  html: {
    textSizeAdjust: "100%",
  },
  body: {
    minHeight: "100vh",
    padding: 0,
    margin: 0,
  },

  // :focus-visible polyfill for hiding focus states when elements are interacted
  // with mouse, but remain visible when tabbing through with the keyboard
  ".js-focus-visible :focus:not(.focus-visible)": {
    outline: "none",
  },
  ":focus:not(:focus-visible)": {
    outline: "none",
  },
});

export interface ResetProps extends Partial<ResetStyleProps> {}

const Reset: React.FC<ResetProps> = (props) => {
  const { theme } = useTheme();
  const { bg, color = ColorPreset.TextOnLight_01 } = props;
  return <Global styles={resetStyle(theme, { bg, color })} />;
};

export default Reset;
