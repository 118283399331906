import { CustomerBankAccount } from "@gocardless/api/dashboard/types";

import { Currency } from "src/common/currencies";
import { useOrganisation } from "src/libraries/organisation/hooks";

// This is here to mirror the current behaviour in ED when selecting IBP
// compatible customer bank accounts
export const IBP_SUPPORTED_BANK_NAMES = [
  "BARCLAYS BANK PLC UK",
  "BARCLAYS BANK PLC",
  "BARCLAYS BANK UK PLC",
  "HALIFAX (A TRADING NAME OF BANK OF SCOTLAND PLC)",
  "HALIFAX",
  "HALIFAX PLC",
  "HSBC BANK PLC",
  "HSBC UK BANK PLC",
  "LLOYDS BANK PLC",
  "LLOYDS BANK PRIVATE BANKING LTD",
  "Revolut Ltd",
  "REVOLUT LTD",
  "MODULR FS LIMITED",
  "MONZO BANK LIMITED",
  "MONZO BANK LTD",
  "NATIONAL WESTMINSTER BANK PLC",
  "NATIONWIDE BUILDING SOCIETY",
  "ROYAL BANK OF SCOTLAND PLC",
  "SANTANDER UK PLC",
  "Santander UK plc",
  "Santander UK",
  "STARLING BANK LIMITED",
  "THE ROYAL BANK OF SCOTLAND PLC",
  "TSB BANK PLC",
  "Bank of Scotland plc",
  "CLYDESDALE BANK PLC",
  "COUTTS and CO",
  "Northern Bank Limited (Trading as Danske Bank)",
  "NORTHERN BANK LIMITED T/A DANSKE BANK",
  "ULSTER BANK LTD",
  "YORKSHIRE BANK (A TRADING NAME OF CLYDESDALE BANK PLC)",
  "YORKSHIRE BANK (CLYDESDALE BANK PLC T/A)",
  "VIRGIN MONEY (CLYDESDALE BANK PLC)",
  "VIRGIN MONEY PLC",
  "NATIONAL WESTMINSTER BANK PLC",
  "NATWEST BANK PLC",
  "First Direct",
  "VIRGIN MONEY (31 CLYDESDALE BANK)",
];

/*
This mirrors the current functionality in enterprise-dashboard which
is used to check if a payment can be made against a customer's bank account.
Once this check is moved to the API, this hook can be replaced with an API call
 */
export const useIsEligibleForIBP = () => {
  const organisation = useOrganisation();
  const organisationHasOBEnabled =
    organisation?.has_open_banking_scheme_enabled;

  return (customerBankAccount: CustomerBankAccount) =>
    !organisationHasOBEnabled
      ? false
      : customerBankAccount.enabled &&
        customerBankAccount.currency === Currency.Gbp &&
        IBP_SUPPORTED_BANK_NAMES.includes(customerBankAccount.bank_name || "");
};
