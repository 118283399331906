import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { useIsomorphicLayoutEffect } from "../../hooks/useIsomorphicLayoutEffect";
import { ColorProp, useTheme } from "../../theme";
import { FormFieldStatus } from "../status";

import CalendarButton from "./CalendarButton";
import { CalendarHeader } from "./CalendarHeader";
import CalendarWeekHeader from "./CalendarWeekHeader";
import { DateInputError } from "./DateInput";
import DateRangeInputs from "./DateRangeInputs";
import {
  calendarGridStyle,
  calendarPaddingStyle,
  calendarRangeStyle,
  calendarStyle,
} from "./calendarRangeStyles";
import {
  areEqual,
  flattenHighlightedDates,
  getFocusedDate,
  isSmaller,
} from "./calendarUtils";
import {
  CalendarDate,
  add,
  asString,
  backOneMonth,
  dateInRange,
  dayInMondayWeek,
  forwardOneMonth,
  fromCalendarDate,
  toCalendarDate,
  validatePartial,
} from "./dateHelpers";
import CalendarRangeFooter from "./CalendarRangeFooter";
import type { DateRangeI18n } from "./i18n";
import CalendarLegend from "./CalendarLegend";
import { legendSectionStyle } from "./calendarLegendStyles";

import type { DateInputProps } from ".";

export interface CalendarHighlightedDate {
  date: CalendarDate;
  color: ColorProp;
  disabled?: boolean;
  label?: string;
}

export interface CalendarRangeProps
  extends Omit<
    DateInputProps,
    "defaultValue" | "value" | "onChange" | "id" | "i18n"
  > {
  /**
   * The internationalized content to use within this component.
   */
  i18n: DateRangeI18n;
  /**
   * A callback to call when the calendar is being closed.
   */
  onClose?(): void;
  /**
   * In uncontrolled mode, sets the default value of this component.
   */
  defaultValue?: CalendarDate[];
  /**
   * In controlled mode, sets the value of this component.
   */
  value?: CalendarDate[];
  /**
   * A callback to call when this component's value changes.
   *
   * @param d The new partial date that was entered
   * @param o Options of onChange callback
   */
  onChange?(d: CalendarDate[] | undefined): void;
}

const CalendarRange: React.FC<CalendarRangeProps> = (props) => {
  const { defaultValue, value, highlightedDates, onClose, ...rest } = props;
  const { theme } = useTheme();
  const [defaultFromDate, defaultToDate] = value || [];

  const { dates, labels } = flattenHighlightedDates(highlightedDates);
  const hasLabel = labels.length > 0;

  const tableRef = React.useRef<HTMLTableElement>(null);
  // When interacting with previous/next month buttons we don't want to
  // automatically move focus to any day buttons in the calendar's grid.
  // Otherwise, users will have tab back to those buttons after they click each
  // one. This ref is used track when users interact with buttons other than the
  // day buttons in the grid and we can skip the focus management logic in the
  // hook below.
  const gridFocusFlagRef = React.useRef(true);
  const todaysDate = toCalendarDate(new Date());

  const [focusedDate, setFocusedDate] = React.useState(
    defaultFromDate || todaysDate
  );
  const [hoverDate, setHoverDate] = React.useState<CalendarDate | null>(null);
  const [fromDate, setFromDate] = React.useState<
    Partial<CalendarDate> | undefined
  >(defaultFromDate);
  const [toDate, setToDate] = React.useState<Partial<CalendarDate> | undefined>(
    defaultToDate
  );

  const validFromDate = validatePartial(fromDate);
  const validToDate = validatePartial(toDate);
  const isToDateBeforeFrom = isSmaller(validToDate, validFromDate);
  const isToDateAfterMax = isSmaller(rest.maxDate, validToDate);
  const isFromDateBeforeMin = isSmaller(validFromDate, rest.minDate);
  const isHoverDateAfterMaxDate = isSmaller(rest.maxDate, hoverDate);
  const canApply =
    validFromDate &&
    validToDate &&
    !isToDateBeforeFrom &&
    !isFromDateBeforeMin &&
    !isToDateAfterMax;

  if (isToDateBeforeFrom && rest.onError) {
    rest.onError(DateInputError.FromDateGreaterThanToDate);
  }

  const focusedStr = asString(focusedDate);

  // Every time we change the focused date using the state hook above, we should
  // focus the corresponding date button.
  useIsomorphicLayoutEffect(() => {
    if (!gridFocusFlagRef.current) {
      gridFocusFlagRef.current = true;
      return;
    }
    const table = tableRef.current;
    if (!table) {
      return;
    }

    const el = table.querySelector(`:scope  [data-date="${focusedStr}"]`);
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.focus();
  }, [tableRef, focusedStr, gridFocusFlagRef]);

  const first = fromCalendarDate({ ...focusedDate, day: 1 });
  const last = fromCalendarDate({
    ...focusedDate,
    month: focusedDate.month + 1,
    day: 0,
  });
  const firstDateInMonth = toCalendarDate(first);
  const lastDateInMonth = toCalendarDate(last);

  let targetWeek: React.ReactNode[] = [];
  const visibleWeeks: React.ReactNode[][] = [targetWeek];
  for (let i = 0; i < lastDateInMonth.day; i++) {
    const d = { ...firstDateInMonth, day: firstDateInMonth.day + i };
    if (i !== 0 && dayInMondayWeek(d) === 0) {
      targetWeek = [];
      visibleWeeks.push(targetWeek);
    }

    const isFocused = areEqual(d, focusedDate);
    const highlighted = dates.find((hd) => areEqual(d, hd.date));

    // Checks for setting border radius for
    // start and end date on hover and selection
    const isFromDate = areEqual(d, validFromDate);
    const isToDate = areEqual(d, validToDate);

    // Checks for highlighting range on hover and selection
    const isHoverDate = areEqual(d, hoverDate);
    const isHoverDateBeforeFrom =
      !toDate && isSmaller(hoverDate, validFromDate);
    const isSelected = isFromDate || isToDate;
    const hightlightOnHover =
      hoverDate &&
      validFromDate &&
      !validToDate &&
      dateInRange({
        min: validFromDate,
        max: isHoverDateAfterMaxDate ? rest.maxDate : hoverDate,
        dt: d,
      });

    const hightlightOnSelection =
      validFromDate &&
      validToDate &&
      dateInRange({
        min: isFromDateBeforeMin ? rest.minDate : validFromDate,
        max: isToDateAfterMax ? rest.maxDate : validToDate,
        dt: d,
      });

    const isDateWithinRange = dateInRange({
      min: rest.minDate,
      max: rest.maxDate,
      dt: d,
    });

    targetWeek.push(
      <td
        role="gridcell"
        key={asString(d)}
        onMouseEnter={() => setHoverDate(d)}
        onMouseLeave={() => setHoverDate(null)}
        data-highlighted={hightlightOnHover || hightlightOnSelection}
        css={calendarRangeStyle({
          hightlightOnSelection,
          isFromDate,
          isToDate,
          isHoverDate,
        })}
      >
        <CalendarButton
          date={d}
          isHighlighted={!!highlighted}
          bg={highlighted?.color}
          tabIndex={isFocused ? 0 : -1}
          data-autofocus={isFocused}
          data-selected={isSelected}
          data-highlighted={hightlightOnSelection && !isFromDate && !isToDate}
          aria-label={rest.i18n.getDateLabel(d)}
          onClick={() => {
            if (
              !validFromDate ||
              isHoverDateBeforeFrom ||
              (validFromDate && validToDate)
            ) {
              setFromDate(d);
              setToDate(undefined);
            } else {
              setToDate(d);
            }
            setFocusedDate(d);
          }}
          disabled={highlighted?.disabled || !isDateWithinRange}
          isRangePicker
        >
          {rest.i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // Pad the first week if there are not enough days in to fill it in the given
  // month
  for (let i = 0; i < dayInMondayWeek(firstDateInMonth); i++) {
    const d = add(firstDateInMonth, { day: -1 * (i + 1) });
    visibleWeeks[0]?.unshift(
      <td key={`startpad-${asString(d)}`} role="gridcell" aria-hidden>
        <CalendarButton
          date={d}
          hidden
          aria-label={rest.i18n.getDateLabel(d)}
          onClick={() => {}}
          isRangePicker
        >
          {rest.i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // Pad the last week if there are not enough days in to fill it in the given
  // month
  for (let i = 0; i < 6 - dayInMondayWeek(lastDateInMonth); i++) {
    const d = add(lastDateInMonth, { day: i + 1 });
    visibleWeeks[visibleWeeks.length - 1]?.push(
      <td key={`endpad-${asString(d)}`} role="gridcell" aria-hidden>
        <CalendarButton
          date={d}
          hidden
          aria-label={rest.i18n.getDateLabel(d)}
          onClick={() => {}}
          isRangePicker
        >
          {rest.i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // The reference for the required keyboard shortcuts can be found on the
  // W3C APG reference for date pickers:
  // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
  const handleRootKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Escape") {
      onClose && onClose();
      return;
    }
  };

  // The reference for the required keyboard shortcuts can be found on the
  // W3C APG reference for date pickers:
  // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
  const handleGridKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const key = e.key;
    setFocusedDate((curr) => getFocusedDate(key, curr, e));
  };

  return (
    // We need to handle keyboard shortcuts as outline in the W3C APG guidelines
    // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
    //
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      css={calendarStyle(theme)}
      role="dialog"
      aria-modal
      onKeyDown={handleRootKeyDown}
      tabIndex={-1}
    >
      <VisuallyHidden>{rest.i18n.instructionsText}</VisuallyHidden>
      <DateRangeInputs
        fromDate={{
          ...rest,
          status: isToDateBeforeFrom ? FormFieldStatus.Danger : rest.status,
          value: fromDate || {},
          onChange: (nfd) => setFromDate(nfd),
        }}
        toDate={{
          ...rest,
          status: isToDateBeforeFrom ? FormFieldStatus.Danger : rest.status,
          value: toDate || {},
          onChange: (ntd) => setToDate(ntd),
        }}
        i18n={rest.i18n}
      />

      <div css={calendarPaddingStyle}>
        <CalendarHeader
          i18n={rest.i18n}
          onPrevious={() => {
            setFocusedDate((d) => backOneMonth(d));
            gridFocusFlagRef.current = false;
          }}
          onNext={() => {
            setFocusedDate((d) => forwardOneMonth(d));
            gridFocusFlagRef.current = false;
          }}
          focusedDate={focusedDate}
          minDate={rest.minDate}
          maxDate={rest.maxDate}
        />
        <table
          ref={tableRef}
          css={calendarGridStyle}
          onKeyDown={handleGridKeyDown}
          role="grid"
        >
          <CalendarWeekHeader i18n={rest.i18n} />
          <tbody>
            {visibleWeeks.map((week, i) => {
              return <tr key={`${asString(firstDateInMonth)}-${i}`}>{week}</tr>;
            })}
          </tbody>
        </table>
        {hasLabel && (
          <div css={legendSectionStyle(theme)}>
            {labels.map((hd) => {
              return (
                <CalendarLegend
                  key={hd.label}
                  color={hd.color}
                  label={hd.label}
                />
              );
            })}
          </div>
        )}
      </div>

      <CalendarRangeFooter
        onChange={props.onChange}
        setFromDate={setFromDate}
        setToDate={setToDate}
        validFromDate={validFromDate!}
        validToDate={validToDate!}
        i18n={props.i18n}
        canApply={canApply}
        onClose={props.onClose}
      />
    </div>
  );
};

export default CalendarRange;
