import * as React from "react";

import {
  useTheme,
  SpaceScale,
  ResponsiveValue,
  AlignItems,
  JustifyContent,
  Elevation,
  CSSValue,
  BorderSize,
  CSSRulesFunction,
  Overflow,
  FlexWrap,
  TypeScale,
  ColorProp,
  BorderRadiusScale,
} from "../theme";
import { makeCSSValueHelper } from "../theme/helpers";

export interface AppearanceProps {
  width?: ResponsiveValue<number | string>;
  minWidth?: ResponsiveValue<number | string>;
  maxWidth?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<string | number>;
  minHeight?: ResponsiveValue<string | number>;
  bg?: ResponsiveValue<ColorProp>;
  color?: ResponsiveValue<ColorProp>;
  elevation?: ResponsiveValue<Elevation>;
  gutterV?: ResponsiveValue<SpaceScale>;
  gutterH?: ResponsiveValue<SpaceScale>;
  spaceAbove?: ResponsiveValue<SpaceScale>;
  spaceBelow?: ResponsiveValue<SpaceScale>;
  spaceBefore?: ResponsiveValue<SpaceScale>;
  spaceAfter?: ResponsiveValue<SpaceScale>;
  flexDirection?: ResponsiveValue<
    "row" | "row-reverse" | "column" | "column-reverse"
  >;
  flexWrap?: ResponsiveValue<FlexWrap>;
  flexGrow?: ResponsiveValue<number>;
  flexShrink?: ResponsiveValue<number>;
  flexBasis?: ResponsiveValue<string>;
  alignItems?: ResponsiveValue<AlignItems>;
  justifyContent?: ResponsiveValue<JustifyContent>;
  alignSelf?: ResponsiveValue<AlignItems>;
  gridArea?: ResponsiveValue<string>;
  gridColumn?: ResponsiveValue<React.CSSProperties["gridColumn"]>;
  gridRow?: ResponsiveValue<React.CSSProperties["gridRow"]>;
  justifySelf?: ResponsiveValue<JustifyContent>;
  layout?: "block" | "inline-block" | "flex" | "inline-flex";
  borderRadius?: ResponsiveValue<CSSValue<BorderRadiusScale>>;
  borderColor?: ResponsiveValue<ColorProp>;
  borderWidth?: ResponsiveValue<CSSValue<BorderSize>>;
  overflowX?: ResponsiveValue<Overflow>;
  overflowY?: ResponsiveValue<Overflow>;
  opacity?: ResponsiveValue<number>;
  fontSize?: ResponsiveValue<TypeScale>;
}

type HTMLProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof AppearanceProps
>;

export interface BoxProps extends AppearanceProps, HTMLProps {
  children?: React.ReactNode | React.ReactNode[];
}

const borderWidthHelper = makeCSSValueHelper(
  (value: BorderSize) => `${value}px`
);

export const boxStyle: CSSRulesFunction<AppearanceProps> = (theme, props) => {
  const width = theme.responsive(props.width, (value) => ({
    width: value,
  }));

  const minWidth = theme.responsive(props.minWidth, (value) => ({
    minWidth: value,
  }));

  const maxWidth = theme.responsive(props.maxWidth, (value) => ({
    maxWidth: value,
  }));

  const height = theme.responsive(props.height, (value) => ({
    height: value,
  }));

  const minHeight = theme.responsive(
    props.minHeight,
    (interpolatedMinHeight) => ({
      minHeight: interpolatedMinHeight,
    })
  );

  const gutterV = theme.responsive(props.gutterV, (value, themeValue) => {
    const px = themeValue.spacing(value);
    return { paddingTop: px, paddingBottom: px };
  });

  const gutterH = theme.responsive(props.gutterH, (value, themeValue) => {
    const px = themeValue.spacing(value);
    return { paddingLeft: px, paddingRight: px };
  });

  const spaceAbove = theme.responsive(props.spaceAbove, (value, themeValue) => {
    return { marginTop: themeValue.spacing(value) };
  });

  const spaceBelow = theme.responsive(props.spaceBelow, (value, themeValue) => {
    return { marginBottom: themeValue.spacing(value) };
  });

  const spaceBefore = theme.responsive(
    props.spaceBefore,
    (value, themeValue) => {
      return { marginLeft: themeValue.spacing(value) };
    }
  );

  const spaceAfter = theme.responsive(props.spaceAfter, (value, themeValue) => {
    return { marginRight: themeValue.spacing(value) };
  });

  const flexDirection = theme.responsive(props.flexDirection, (value) => ({
    flexDirection: value,
  }));

  const flexWrap = theme.responsive(props.flexWrap, (value) => ({
    flexWrap: value,
  }));

  const flexGrow = theme.responsive(props.flexGrow, (v) => ({
    flexGrow: v,
  }));

  const flexShrink = theme.responsive(props.flexShrink, (v) => ({
    flexShrink: v,
  }));

  const flexBasis = theme.responsive(props.flexBasis, (v) => ({
    flexBasis: v,
  }));

  const gridArea = theme.responsive(props.gridArea, (v) => ({ gridArea: v }));
  const gridColumn = theme.responsive(props.gridColumn, (v) => ({
    gridColumn: v,
  }));
  const gridRow = theme.responsive(props.gridRow, (v) => ({
    gridRow: v,
  }));

  const alignItems = theme.responsive(props.alignItems, (v) => ({
    alignItems: v,
  }));

  const justifyContent = theme.responsive(props.justifyContent, (v) => ({
    justifyContent: v,
  }));

  const alignSelf = theme.responsive(props.alignSelf, (v) => ({
    alignSelf: v,
  }));

  const justifySelf = theme.responsive(props.justifySelf, (v) => ({
    justifySelf: v,
  }));

  const elevation = theme.responsive(props.elevation, (e, themeValue) => ({
    filter: themeValue.tokens.elevation[e],
  }));

  const borderRadius = theme.responsive(
    props.borderRadius,
    (r, themeValue) => ({
      borderRadius: themeValue.radius(r),
    })
  );

  const borderWidth = theme.responsive(props.borderWidth, (v) => ({
    borderWidth: borderWidthHelper(v),
    borderStyle: v ? "solid" : "none",
  }));

  const borderColor = theme.responsive(props.borderColor, (c) => ({
    borderColor: theme.color(c),
  }));

  const overflowX = theme.responsive(props.overflowX, (v) => ({
    overflowX: v,
  }));

  const overflowY = theme.responsive(props.overflowY, (v) => ({
    overflowY: v,
  }));

  const backgroundColor = theme.responsive(props.bg, (c) => ({
    backgroundColor: theme.color(c),
  }));

  const color = theme.responsive(props.color, (c) => ({
    color: theme.color(c),
  }));

  const opacity = theme.responsive(props.opacity, (v) => ({
    opacity: v,
  }));

  const fontSize = theme.responsive(props.fontSize, (v) => ({
    ...theme.tokens.fontSizes[v],
  }));

  return [
    { display: props.layout || "block" },
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    backgroundColor,
    color,
    gutterH,
    gutterV,
    spaceAbove,
    spaceBelow,
    spaceBefore,
    spaceAfter,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    flexBasis,
    gridArea,
    gridRow,
    gridColumn,
    alignItems,
    justifyContent,
    alignSelf,
    justifySelf,
    elevation,
    borderRadius,
    borderWidth,
    borderColor,
    overflowX,
    overflowY,
    opacity,
    fontSize,
  ];
};

export const omitAppearanceProps = <Props extends AppearanceProps>(
  props: Props
): Omit<Props, keyof AppearanceProps> => {
  const {
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    bg,
    color,
    gutterH,
    gutterV,
    spaceAbove,
    spaceBelow,
    spaceBefore,
    spaceAfter,
    layout,
    justifyContent,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    flexBasis,
    alignItems,
    alignSelf,
    justifySelf,
    elevation,
    borderColor,
    borderRadius,
    borderWidth,
    overflowX,
    overflowY,
    opacity,
    gridArea,
    gridColumn,
    gridRow,
    fontSize,
    ...rest
  } = props;

  return rest;
};

const Box = React.forwardRef<HTMLDivElement, BoxProps>(function Box(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitAppearanceProps(props);

  return <div css={boxStyle(theme, props)} {...rest} ref={ref} />;
});

export default Box;
