import type * as React from "react";

import { Glyph, Icon } from "../icons";
import { Color, ColorProp } from "../theme";

import { BannerStatus, BannerVariant } from "./types";

export interface BannerStatusIconProps {
  variant: BannerVariant;
  value: BannerStatus;
}

const icons: Record<
  BannerStatus,
  { lightBg: ColorProp; darkBg: ColorProp; glyph: Glyph }
> = {
  [BannerStatus.Info]: {
    lightBg: Color.Info_Base,
    darkBg: Color.Brownstone_50,
    glyph: Glyph.InfoCircle,
  },
  [BannerStatus.Success]: {
    lightBg: Color.Success_Base,
    darkBg: Color.Brownstone_50,
    glyph: Glyph.TickCircle,
  },
  [BannerStatus.Warning]: {
    lightBg: Color.Warning_Base,
    darkBg: Color.Brownstone_50,
    glyph: Glyph.ExclamationCircle,
  },
  [BannerStatus.Alert]: {
    lightBg: Color.Alert_Base,
    darkBg: Color.Brownstone_50,
    glyph: Glyph.SubtractCircle,
  },
  [BannerStatus.Loading]: {
    lightBg: Color.Greystone_DarkMatter,
    darkBg: Color.Brownstone_50,
    glyph: Glyph.Spinner,
  },
};

const BannerStatusIcon: React.FC<BannerStatusIconProps> = (props) => {
  const { lightBg, darkBg, glyph } = icons[props.value];

  return (
    <Icon
      name={glyph}
      color={props.variant === BannerVariant.Dark ? darkBg : lightBg}
      size="24px"
    />
  );
};

export default BannerStatusIcon;
