import { ClassNames } from "@emotion/react";
import * as React from "react";

import { VisuallyHidden } from "../a11y";
import { Icon } from "../icons";
import { ButtonSize, FocusRing, useTheme } from "../theme";

import { iconButtonStyle } from "./styles";
import type { ButtonRenderProps, IconButtonStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof IconButtonStyleProps
>;

export interface IconButtonProps extends HTMLAttributes, IconButtonStyleProps {
  label: React.ReactNode;

  render?: (props: ButtonRenderProps) => React.ReactNode;
  focusRing?: FocusRing;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { theme } = useTheme();
    const {
      icon,
      label,
      size = ButtonSize.Md,
      variant,
      render,
      focusRing,
      type = "button",
      shift,
      ...rest
    } = props;
    const wrappedChildren = (
      <>
        <VisuallyHidden>{label}</VisuallyHidden>
        <Icon name={icon} />
      </>
    );

    return (
      <ClassNames>
        {({ css, cx }) => {
          const className = cx(
            css(iconButtonStyle(theme, { ...props, size })),
            props.className
          );
          const forwardedProps = {
            ...rest,
            type,
            className,
            children: wrappedChildren,
          };

          return render ? (
            render(forwardedProps)
          ) : (
            <button ref={ref} {...forwardedProps} />
          );
        }}
      </ClassNames>
    );
  }
);

export default IconButton;
