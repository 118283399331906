import type { Interpolation } from "../../theme";

import { ControlVar, ToggleControlProps } from "./toggleTypes";

const trackWidth = "36px";
const trackHeight = "20px";
const knobSize = "16px";
const outlineSize = "2px";
const offOffset = outlineSize;
const onOffset = `${trackWidth} - ${knobSize} - ${outlineSize} - calc(var(${ControlVar.On}) * ${outlineSize})`;
const offset = `calc(var(${ControlVar.On}) * (${onOffset}) + ${offOffset})`;

const toggleSwitchStyle: Interpolation = {
  width: trackWidth,
  height: trackHeight,
  borderRadius: trackWidth,
  display: "flex",
  alignItems: "center",
  backgroundColor: `var(${ControlVar.Bg})`,
  boxShadow: `0 0 0 calc(var(${ControlVar.OutlineOn}) * ${outlineSize}) var(${ControlVar.OutlineColor})`,
  transition: "box-shadow 100ms",
  "@media (prefers-reduced-motion: reduce)": {
    transition: "box-shadow 50ms",
  },
  "&:after": {
    content: '""',
    width: knobSize,
    height: knobSize,
    borderRadius: knobSize,
    backgroundColor: `var(${ControlVar.Fg})`,
    transform: `translate3d(${offset}, 0, 0)`,
    transition: "transform 100ms ease-out",
    boxShadow: `0 0 0 ${outlineSize} var(${ControlVar.BorderColor})`,
    "@media (prefers-reduced-motion: reduce)": {
      transition: "transform 50ms ease-out",
    },
  },
  "&:focus-visible:after": {
    boxShadow: `inset 0 0 0 ${outlineSize} var(${ControlVar.BorderColor})`,
  },
};

const ToggleSwitch: React.FC<ToggleControlProps> = () => {
  return <span css={toggleSwitchStyle} role="presentation" />;
};

export default ToggleSwitch;
