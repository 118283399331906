import * as React from "react";

import type { BannerProps } from "./Banner";
import Banner from "./Banner";
import { BannerLeftAccessoryType, BannerStatus } from "./types";

type BaseProps = Omit<
  BannerProps,
  "primaryAction" | "secondaryAction" | "leftAccessory"
>;

export interface ToastProps extends BaseProps {
  /**
   * Configures an optional action to show with the toast message.
   * This action can be either a link or a button.
   */
  action?: BannerProps["secondaryAction"];
  "aria-live"?: BaseProps["aria-live"];
  status: BannerStatus;
}

const Toast = React.forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
  const { action, status, ...rest } = props;

  return (
    <Banner
      aria-live="polite"
      leftAccessory={{ type: BannerLeftAccessoryType.Status, status }}
      {...rest}
      secondaryAction={action}
      ref={ref}
    />
  );
});

export default Toast;
