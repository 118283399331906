import type React from "react";

import { CSSRulesFunction, ResponsiveValue, ZIndex, useTheme } from "../theme";

interface Props {
  mode: ResponsiveValue<
    "static" | "relative" | "absolute" | "fixed" | "sticky"
  >;
  /**
   * **Warning:** Changing this value frequently will cause thrashing around`CSSStyleSheet.insertRule()` and detoriate your application's performance
   */
  top?: ResponsiveValue<number | string>;
  /**
   * **Warning:** Changing this value frequently will cause thrashing around`CSSStyleSheet.insertRule()` and detoriate your application's performance
   */
  bottom?: ResponsiveValue<number | string>;
  /**
   * **Warning:** Changing this value frequently will cause thrashing around`CSSStyleSheet.insertRule()` and detoriate your application's performance
   */
  left?: ResponsiveValue<number | string>;
  /**
   * **Warning:** Changing this value frequently will cause thrashing around`CSSStyleSheet.insertRule()` and detoriate your application's performance
   */
  right?: ResponsiveValue<number | string>;
  zIndex?: ResponsiveValue<ZIndex>;

  children: React.ReactNode;
}
type HTMLAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>;

export interface LayerProps extends HTMLAttributes, Props {}

const layerStyle: CSSRulesFunction<LayerProps> = (theme, props) => [
  theme.responsive(props.mode, (mode) => ({ position: mode })),
  theme.responsive(props.top, (top) => ({ top })),
  theme.responsive(props.bottom, (bottom) => ({ bottom })),
  theme.responsive(props.left, (left) => ({ left })),
  theme.responsive(props.right, (right) => ({ right })),
  theme.responsive(props.zIndex, (z) => ({ zIndex: z })),
];

const Layer: React.FC<LayerProps> = (props) => {
  const { theme } = useTheme();
  const { mode, top, bottom, left, right, zIndex, ...rest } = props;
  return (
    <div css={layerStyle(theme, props)} {...rest}>
      {props.children}
    </div>
  );
};

export default Layer;
