import { FocusScope } from "@react-aria/focus";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import type { Strategy } from "@floating-ui/react-dom";

import { useTapOut } from "../hooks/pointers";
import { ColorScheme, useTheme } from "../theme";

import type {
  TooltipStyleProps,
  TooltipTriggerProps,
  TooltipTriggerRenderProp,
} from "./types";
import TooltipTrigger from "./TooltipTrigger";
import {
  tooltipArrowStyle,
  tooltipHostStyle,
  tooltipRootStyle,
  tooltipShadowStyle,
  tooltipStyle,
  tooltipTitleStyle,
  vars,
} from "./tooltipStyle";
import { useTooltip } from "./hooks";

export interface TooltipProps extends Partial<TooltipStyleProps> {
  /** The id to use for the tooltip element. */
  tooltipId?: string;

  /** Sets a title for the tooltip. */
  title?: React.ReactNode;

  /** Sets the body content of the tooltip. */
  message: React.ReactNode;

  /**
   * Set whether to trigger the tooltip by hovering _in addition to_
   * clicking/focusing. Defaults to hover.
   */
  triggeredBy?: "hover" | "click";

  /**
   * Sets the label of the tooltip's trigger or, if set to a function,
   * customizes the trigger that will be rendered.
   */
  children: React.ReactNode | TooltipTriggerRenderProp;

  /**
   * Used to set position strategy for tooltip. In most of the cases you do not need to use this property.
   */
  positionStrategy?: Strategy;

  /**
   * Used to keep the tooltip open regardless of user interactions. Primarly for
   * the purpose of showcasing this component and visual regression testing.
   *
   * @ignore Used for internal, testing purposes.
   */
  forceOpen?: boolean;
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { theme } = useTheme();
  const genTooltipId = React.useId();
  const {
    tooltipId = genTooltipId,
    title,
    colorScheme = ColorScheme.OnLight,
    triggeredBy = "hover",
    message,
    forceOpen = false,
    placementStrategy = "auto",
    positionStrategy = "fixed",
    children,
  } = props;

  const [dismissed, setDismissed] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const canHover = triggeredBy === "hover";
  const openWithHover = canHover && hovered;
  const open = openWithHover || focused || forceOpen;

  // When the tooltip is dismissed with the Escape key and focus returns to the
  // trigger button, we do no want to reopen the tooltip. Use the state variable
  // to track when the tooltip is explicitly dismissed and skip opening it on
  // focus.
  const arrowRef = React.useRef<HTMLDivElement>(null);
  const {
    x,
    y,
    strategy,
    update,
    refs,
    placement: finalPlacement,
    middlewareData,
  } = useTooltip({
    open,
    initialPlacement: "top",
    arrowRef,
    placementStrategy,
    positionStrategy,
    offsetPadding: 18,
  });

  // Tapping outside of the tooltip should dismiss it.
  useTapOut(refs.floating, () => {
    setFocused(false);
  });

  const handleReveal = () => {
    setHovered(true);
  };
  const handleHide = () => {
    setHovered(false);
  };
  const handleClick = () => {
    setFocused((v) => !v);
  };
  const handleFocus = () => {
    if (dismissed) {
      setDismissed(false);
      return;
    }
    setFocused(true);
  };
  const handleBlur = () => {
    setDismissed(false);
    setFocused(false);
  };
  const handleDismiss = () => {
    setDismissed(true);
    setHovered(false);
    setFocused(false);
  };

  const tooltipNode = (
    // Do not set the role on this div if the tooltip is closed. It makes
    // testing for the presence of a visual tooltip easier in Storybook.
    <div id={tooltipId} role={open ? "tooltip" : undefined} css={tooltipStyle}>
      {title ? <div css={tooltipTitleStyle}>{title}</div> : null}
      {message}
    </div>
  );

  const triggerProps: TooltipTriggerProps = {
    onClick: handleClick,
    "aria-labelledby": tooltipId,
  };
  const triggerNode =
    typeof children === "function" ? (
      children(triggerProps)
    ) : (
      <TooltipTrigger colorScheme={colorScheme} {...triggerProps}>
        {children}
      </TooltipTrigger>
    );

  const tooltipTop = y == null ? "" : `${y}px`;
  const tooltipLeft = x == null ? "" : `${x}px`;

  const arrowY = middlewareData.arrow?.y;
  const arrowX = middlewareData.arrow?.x;
  const arrowTop = arrowY != null ? `${arrowY}px` : "";
  const arrowLeft = arrowX != null ? `${arrowX}px` : "";
  const staticSide =
    {
      top: vars.arrowBottom,
      right: vars.arrowLeft,
      bottom: vars.arrowTop,
      left: vars.arrowRight,
    }[finalPlacement.split("-")[0] || "top"] || "";

  return (
    <div
      role="presentation"
      css={tooltipRootStyle(theme, { colorScheme, placement: finalPlacement })}
      ref={refs.setReference}
      onMouseEnter={canHover ? handleReveal : undefined}
      onMouseLeave={canHover ? handleHide : undefined}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key !== "Escape") {
          return;
        }
        handleDismiss();
      }}
      style={{
        // These must be passed in the `style` prop instead of `css` prop
        // otherwise emotion will potentially generate lots of class names
        // for this element - a performance nightmare.
        [vars.top]: tooltipTop,
        [vars.left]: tooltipLeft,
        [vars.arrowTop]: arrowTop,
        [vars.arrowLeft]: arrowLeft,
        // The arrow is a square rotated by 45 degrees. If we shift half of it
        // behind the tooltip, it will look like an arrow.
        [staticSide]: `calc(-0.5 * var(${vars.arrowSize}))`,
      }}
    >
      {triggerNode}

      {!open ? (
        <div aria-hidden css={tooltipShadowStyle}>
          {tooltipNode}
        </div>
      ) : null}

      <CSSTransition
        in={open}
        timeout={200}
        classNames="tooltip"
        mountOnEnter
        unmountOnExit
        onEnter={update}
        appear
      >
        <div
          role="presentation"
          ref={refs.setFloating}
          css={tooltipHostStyle}
          style={{ position: strategy }}
        >
          <FocusScope restoreFocus>{tooltipNode}</FocusScope>
          <div css={tooltipArrowStyle} role="presentation" ref={arrowRef} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default Tooltip;
