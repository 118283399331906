import { HTTPError } from "@gocardless/api/utils/api";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { ReactNode, useCallback } from "react";

import { useToastNotification } from "src/hooks/useToastNotification";

interface ApiErrorNotificationProps {
  error?: HTTPError;
  title: ReactNode;
  message: ReactNode;
}

export const useAPIErrorNotifications = ({
  error,
  title,
  message,
}: ApiErrorNotificationProps) => {
  const { triggerErrorNotification } = useToastNotification();

  const showErrors = useCallback(async () => {
    if (!error) {
      return;
    }

    // try catch is for 503 or unexplained response which is not in json format
    try {
      const apiErrors = await getErrorsFromErrorResponse(error);

      if (apiErrors && apiErrors.length > 0) {
        apiErrors.forEach(({ message: errMessage }) =>
          triggerErrorNotification({ message: errMessage })
        );
      } else {
        triggerErrorNotification({
          title,
          message,
        });
      }
    } catch {
      triggerErrorNotification({
        title,
        message,
      });
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, title, message]);

  return {
    showErrors,
  };
};
