import {
  BorderRadiusScale,
  Color,
  ColorPreset,
  ColorProp,
  ColorScheme,
  CSSRulesFunction,
  FocusRing,
  FontWeight,
  ResponsiveValue,
  SpaceScale,
  TypeScale,
} from "../theme";
import { focusRingStyle } from "../theme/focusRings";

import {
  AccordionOutline,
  AccordionSize,
  AccordionStyleProps,
  AccordionVariant,
} from "./types";

interface AccordingSizeConfig {
  headerGutterBlock: SpaceScale;
  headerGutterInline: SpaceScale;
  panelGutterBlock: SpaceScale;
  panelGutterInline: SpaceScale;
  borderRadius: BorderRadiusScale;
  headerFontWeight: FontWeight;
  headerTypeScale: TypeScale;
  panelTypeScale: TypeScale;
}

export const sizeMapping: Record<AccordionSize, AccordingSizeConfig> = {
  [AccordionSize.Lg]: {
    headerGutterBlock: 1.5,
    headerGutterInline: 1,
    panelGutterBlock: 2,
    panelGutterInline: 1,
    borderRadius: 1.5,
    headerFontWeight: FontWeight.Normal,
    headerTypeScale: TypeScale.Size_06,
    panelTypeScale: TypeScale.Size_04,
  },
  [AccordionSize.Md]: {
    headerGutterBlock: 1,
    headerGutterInline: 1,
    panelGutterBlock: 1.5,
    panelGutterInline: 1,
    borderRadius: 1,
    headerFontWeight: FontWeight.Normal,
    headerTypeScale: TypeScale.Size_05,
    panelTypeScale: TypeScale.Size_03,
  },
  [AccordionSize.Sm]: {
    headerGutterBlock: 1,
    headerGutterInline: 1,
    panelGutterBlock: 1,
    panelGutterInline: 0.75,
    borderRadius: 1,
    headerFontWeight: FontWeight.Normal,
    headerTypeScale: TypeScale.Size_03,
    panelTypeScale: TypeScale.Size_03,
  },
};

interface AccordingThemeConfig {
  headerColor: ColorProp;
  activeheaderColor: ColorProp;
  bodyColor: ColorProp;
  backgroundColor: ColorProp;
  activeBackgroundColor: ColorProp;
  separatorColor: ColorProp;
}

const solidOnLight: AccordingThemeConfig = {
  headerColor: ColorPreset.TextOnLight_01,
  activeheaderColor: ColorPreset.TextOnLight_01,
  bodyColor: ColorPreset.TextOnLight_02,
  backgroundColor: ColorPreset.BackgroundLight_02,
  activeBackgroundColor: ColorPreset.BackgroundLight_02,
  separatorColor: ColorPreset.BorderOnLight_05,
};

const solidOnDark: AccordingThemeConfig = {
  headerColor: ColorPreset.TextOnDark_01,
  activeheaderColor: ColorPreset.TextOnDark_01,
  bodyColor: ColorPreset.TextOnDark_02,
  backgroundColor: ColorPreset.BackgroundDark_01,
  activeBackgroundColor: ColorPreset.BackgroundDark_01,
  separatorColor: Color.White_A25,
};

const clearOnLight: AccordingThemeConfig = {
  headerColor: ColorPreset.TextOnLight_01,
  activeheaderColor: ColorPreset.TextOnLight_01,
  bodyColor: ColorPreset.TextOnLight_02,
  backgroundColor: Color.Transparent,
  activeBackgroundColor: Color.Transparent,
  separatorColor: ColorPreset.BorderOnLight_05,
};

const clearOnDark: AccordingThemeConfig = {
  headerColor: ColorPreset.TextOnDark_01,
  activeheaderColor: ColorPreset.TextOnDark_01,
  bodyColor: ColorPreset.TextOnDark_02,
  backgroundColor: Color.Transparent,
  activeBackgroundColor: Color.Transparent,
  separatorColor: Color.White_A25,
};

export const variantMapping: Record<AccordionVariant, AccordingThemeConfig> = {
  [AccordionVariant.SolidOnLight]: solidOnLight,
  [AccordionVariant.SolidOnDark]: solidOnDark,
  [AccordionVariant.ClearOnLight]: clearOnLight,
  [AccordionVariant.ClearOnDark]: clearOnDark,

  [AccordionVariant.SolidOnLightDimmed]: {
    ...solidOnLight,
    headerColor: ColorPreset.TextOnLight_03,
  },
  [AccordionVariant.SolidOnDarkDimmed]: {
    ...solidOnDark,
    headerColor: ColorPreset.TextOnDark_03,
  },
  [AccordionVariant.ClearOnLightDimmed]: {
    ...clearOnLight,
    headerColor: ColorPreset.TextOnLight_03,
  },
  [AccordionVariant.ClearOnDarkDimmed]: {
    ...clearOnDark,
    headerColor: ColorPreset.TextOnDark_03,
  },
};

const accordionColorTokens: CSSRulesFunction<AccordionStyleProps> = (
  theme,
  props
) => {
  return [
    theme.responsive(props.variant, (v) => {
      const cfg = variantMapping[v];
      return {
        "--accordion-header-color": theme.color(cfg.headerColor),
        "--accordion-active-header-color": theme.color(cfg.activeheaderColor),
        "--accordion-body-color": theme.color(cfg.bodyColor),
        "--accordion-active-bg": theme.color(cfg.activeBackgroundColor),
        "--accordion-bg": theme.color(cfg.backgroundColor),
        "--accordion-separator-color": theme.color(cfg.separatorColor),
      };
    }),
    {
      "--accordion-outline-color":
        props.outline === AccordionOutline.Solid
          ? "var(--accordion-separator-color)"
          : theme.color(Color.Transparent),
    },
  ];
};

const accordionSizeTokens: CSSRulesFunction<AccordionStyleProps> = (
  theme,
  props
) => {
  return [
    theme.responsive(props.size, (s) => {
      const cfg = sizeMapping[s];
      const radius = theme.radius(cfg.borderRadius);
      const headerBlock = theme.spacing(cfg.headerGutterBlock);
      const headerInline = theme.spacing(cfg.headerGutterInline);
      const panelBlock = theme.spacing(cfg.panelGutterBlock);
      const panelInline = theme.spacing(cfg.panelGutterInline);
      const headerSize = theme.tokens.fontSizes[cfg.headerTypeScale];
      const panelSize = theme.tokens.fontSizes[cfg.panelTypeScale];
      return {
        "--accordion-border-radius": radius,
        "--accordion-header-gutter-block": headerBlock,
        "--accordion-header-gutter-inline": headerInline,
        "--accordion-header-font-size": headerSize.fontSize,
        "--accordion-header-line-height": headerSize.lineHeight,
        "--accordion-panel-gutter-block": panelBlock,
        "--accordion-panel-gutter-inline": panelInline,
        "--accordion-header-font-weight": cfg.headerFontWeight,
        "--accordion-panel-font-size": panelSize.fontSize,
        "--accordion-panel-line-height": panelSize.lineHeight,
      };
    }),
    {
      "--accordion-left-accessory-width": "32px",
      "--accordion-header-column-gap": theme.spacing(1),
    },
  ];
};

export const accordionheaderStyle: CSSRulesFunction = () => {
  return {
    width: "100%",
    display: "grid",
    textAlign: "start",

    border: "none",
    borderBottom: `1px solid var(--accordion-separator-color)`,
    padding:
      "var(--accordion-header-gutter-block) var(--accordion-header-gutter-inline)",
    fontSize: "var(--accordion-header-font-size)",
    lineHeight: "var(--accordion-header-line-height)",

    color: "var(--accordion-header-color)",
    backgroundColor: "var(--accordion-bg)",

    textDecoration: "underline",
    textDecorationColor: "transparent",
    transition: "text-decoration-color 200ms, background-color 200ms",

    "&:hover, &:focus, &:active": {
      textDecorationColor: "currentColor",
    },
  };
};

export const accordionItemStyle: CSSRulesFunction = () => {
  return {
    display: "grid",
    gridTemplateAreas: `
      'accessory-a title accessory-b toggle'
      'separator separator separator separator'
      '. body body body'
    `,
  };
};

const focusRings: CSSRulesFunction<{
  variant: ResponsiveValue<AccordionVariant>;
}> = (theme, { variant }) =>
  theme.responsive(variant, (v) => {
    let colorScheme: ColorScheme;
    if (v.includes("on-dark")) {
      colorScheme = ColorScheme.OnDark;
    } else {
      colorScheme = ColorScheme.Auto;
    }
    return focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme,
      focusSelector: " & [data-accordion-trigger]:focus-visible",
    });
  });

export const accordionStyle: CSSRulesFunction<AccordionStyleProps> = (
  theme,
  props
) => {
  return [
    accordionSizeTokens(theme, props),
    accordionColorTokens(theme, props),
    {
      backgroundColor: "var(--accordion-bg)",
      borderRadius: "var(--accordion-border-radius)",
      border: `1px solid var(--accordion-outline-color)`,
    },
    focusRings(theme, { variant: props.variant }),
  ];
};
