/* eslint-disable deprecation/deprecation */
import { FontWeight, Interpolation } from "../theme";

type HTMLAttributes = Omit<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  "children"
>;

export interface LabelProps extends HTMLAttributes {
  children: React.ReactNode;
}

const labelStyle: Interpolation = {
  fontWeight: FontWeight.SemiBold,
  display: "block",
};

const Label: React.FC<LabelProps> = (props) => {
  return (
    <label css={labelStyle} {...props}>
      {props.children}
    </label>
  );
};

export default Label;
