import TableBody from "./TableBody";
import TableCell from "./TableCell";
import TableContainer from "./TableContainer";
import TableFoot from "./TableFoot";
import TableHead from "./TableHead";
import TableHeadCell from "./TableHeadCell";
import TableRow from "./TableRow";

export { default as TableContainer } from "./TableContainer";
export { default as TableBody } from "./TableBody";
export { default as TableCell } from "./TableCell";
export { default as TableFoot } from "./TableFoot";
export { default as TableHead } from "./TableHead";
export { default as TableHeadCell } from "./TableHeadCell";
export { default as TableRow } from "./TableRow";

export const Table = {
  Container: TableContainer,
  Head: TableHead,
  Body: TableBody,
  Foot: TableFoot,
  Row: TableRow,
  HeadCell: TableHeadCell,
  Cell: TableCell,
};

export type { TableProps } from "./TableContainer";
