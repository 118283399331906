import type * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";
import {
  TypographyStyleProps,
  omitTypographyStyleProps as omitTypographyProps,
  typographyStyle,
} from "../typography/appearance";

import { gutterStyle, GutterProps } from "./appearance";

type HTMLAttributes = Omit<
  React.TdHTMLAttributes<HTMLTableCellElement>,
  "color"
>;

export interface TableCellProps
  extends HTMLAttributes,
    GutterProps,
    Omit<
      TypographyStyleProps,
      "spaceAbove" | "spaceBelow" | "layout" | "emptyVisibility"
    > {
  children?: React.ReactNode;
}

export const tableCellStyle: CSSRulesFunction<TableCellProps> = (
  theme,
  props
) => [
  {
    verticalAlign: "top",
  },
  typographyStyle(theme, { ...props, emptyVisibility: "visible" }),
  gutterStyle(theme, props),
];

const TableCell: React.FC<TableCellProps> = (props) => {
  const { theme } = useTheme();
  const { gutterV = 1, gutterH = 1.5, ...rest } = props;
  const { ...filtered } = omitTypographyProps(props);
  const tableCellStyleProps = { gutterV, gutterH, ...rest };
  return <td css={tableCellStyle(theme, tableCellStyleProps)} {...filtered} />;
};

export default TableCell;
