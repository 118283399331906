import { useEffect, useState } from "react";

type ObservableHTMLElement = HTMLDivElement | HTMLUListElement;

export const noop = () => {};

export const getListHeight = (listRef: React.RefObject<HTMLDivElement>) =>
  Math.round(listRef.current?.clientHeight || 0);

export const getRowHeight = (element: HTMLDivElement) =>
  Math.round(element?.clientHeight || 0);

export const useObserveElementHeight = (
  ref: React.RefObject<ObservableHTMLElement>
) => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      const { offsetHeight } = ref.current;

      if (offsetHeight !== height) {
        setHeight(offsetHeight);
      }

      const observable = new ResizeObserver(([entries]) => {
        const observedHeight = (entries?.target as HTMLElement).offsetHeight;
        if (observedHeight !== height) {
          setHeight(observedHeight);
        }
      });

      observable.observe(ref.current);

      return () => {
        observable.disconnect();
      };
    }
    setHeight(0);
    return noop;
  }, [ref.current, height]);
  return height;
};

interface CalcListHeightParams {
  /**
   * The maximum space that the list has available to render
   */
  heightRestriction?: number;
  /**
   * The minimum list height. The list should not be smaller than this
   */
  minListHeight: number;
  /**
   * The maximum list height. The list should not grow taller than this
   */
  maxListHeight: number;
  /**
   * The actual height of the scrollable area.
   */
  scrollableAreaHeight: number;
  /**
   * The calculated height of the header element
   */
  headerHeight?: number;
  /**
   * The calculated height of the footer element
   */
  footerHeight?: number;
  /**
   * The vertical padding applied to the list scrollable area
   */
  verticalListPadding: number;
  /**
   * If the list is shrinking based on screen size, apply this padding around it
   * so it doesn't stick to the edge
   */
  screenPadding: number;
}

// Calculate the actual height of the list based on various parameters
const calcActualListHeight = ({
  heightRestriction,
  minListHeight,
  maxListHeight,
  verticalListPadding,
  screenPadding,
  headerHeight,
  footerHeight,
}: Omit<CalcListHeightParams, "scrollableAreaHeight">): number => {
  if (!heightRestriction) {
    return maxListHeight - verticalListPadding;
  }

  const staticElementsTotalHeight = (headerHeight ?? 0) + (footerHeight ?? 0);

  // there is no header nor footer
  // Check if the total height of static elements is zero
  if (staticElementsTotalHeight === 0) {
    // If the minimum list height exceeds the height restriction
    if (minListHeight > heightRestriction) {
      // Return the adjusted minimum list height by subtracting vertical padding
      return minListHeight - verticalListPadding;
    }

    // If the maximum list height exceeds the height restriction
    if (maxListHeight > heightRestriction) {
      // Return the adjusted height restriction by subtracting vertical list padding and screen padding
      return heightRestriction - verticalListPadding - screenPadding;
    }

    // If neither the minimum nor maximum list height exceeds the height restriction
    // Return the adjusted maximum list height by subtracting vertical padding
    return maxListHeight - verticalListPadding;
  }

  // else there is header and/or a footer
  // Calculate the modified minimum list height by subtracting the total height of static elements
  const minListHeightModified = minListHeight - staticElementsTotalHeight;
  // Calculate the modified maximum list height by subtracting the total height of static elements
  const maxListHeightModified = maxListHeight - staticElementsTotalHeight;

  // If the modified minimum list height exceeds the height restriction
  if (minListHeightModified > heightRestriction) {
    // Return the adjusted modified minimum list height by subtracting vertical padding
    return minListHeightModified - verticalListPadding;
  }

  // If the modified maximum list height exceeds the adjusted height restriction with static elements taken into account
  if (maxListHeightModified > heightRestriction - staticElementsTotalHeight) {
    // Return the adjusted height restriction with static elements taken into account
    // by subtracting static elements total height, vertical list padding, and screen padding
    return (
      heightRestriction -
      staticElementsTotalHeight -
      verticalListPadding -
      screenPadding
    );
  }

  // If neither the modified minimum nor modified maximum list height exceeds the height restriction
  // Return the adjusted modified maximum list height by subtracting vertical padding
  return maxListHeightModified - verticalListPadding;
};

export const calcListHeight = ({
  scrollableAreaHeight,
  ...args
}: CalcListHeightParams): number => {
  const calculatedListHeight = calcActualListHeight(args);
  if (calculatedListHeight > scrollableAreaHeight) {
    return scrollableAreaHeight;
  }
  return calculatedListHeight;
};
