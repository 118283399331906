import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { useIsomorphicLayoutEffect } from "../../hooks/useIsomorphicLayoutEffect";
import { useTheme } from "../../theme";

import {
  fromCalendarDate,
  toCalendarDate,
  asString,
  compare,
  add,
  forwardOneMonth,
  backOneMonth,
  dayInMondayWeek,
  CalendarDate,
  dateInRange,
} from "./dateHelpers";
import type { DatesI18n } from "./i18n";
import { CalendarHeader } from "./CalendarHeader";
import CalendarButton from "./CalendarButton";
import CalendarWeekHeader from "./CalendarWeekHeader";
import { calendarGridStyle, calendarStyle } from "./calendarStyles";
import {
  areEqual,
  flattenHighlightedDates,
  getFocusedDate,
} from "./calendarUtils";
import type { HighlightedDates } from "./DateInput";
import { legendSectionStyle } from "./calendarLegendStyles";
import CalendarLegend from "./CalendarLegend";

export interface CalendarProps {
  /**
   * An object containing localization data to use in the calendar.
   */
  i18n: DatesI18n;

  /**
   * A callback to call when the calendar is being closed.
   */
  onClose(): void;
  /**
   * A callback to call when a date has been selected.
   * @param date The selected date.
   */
  onSelect(date: CalendarDate): void;

  /**
   * The initial date to start the calendar at. If this is empty, then the
   * present date is used.
   */
  defaultDate?: CalendarDate;
  /**
   * In controlled mode, sets the min date of this component.
   */
  minDate?: CalendarDate;
  /**
   * In controlled mode, sets the max date of this component.
   */
  maxDate?: CalendarDate;
  /**
   * In controlled mode, sets the highlighted dates of this component.
   */
  highlightedDates?: Array<HighlightedDates>;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const {
    i18n,
    defaultDate = toCalendarDate(new Date()),
    onSelect,
    onClose,
    minDate,
    maxDate,
    highlightedDates,
  } = props;
  const { theme } = useTheme();

  const tableRef = React.useRef<HTMLTableElement>(null);
  // When interacting with previous/next month buttons we don't want to
  // automatically move focus to any day buttons in the calendar's grid.
  // Otherwise, users will have tab back to those buttons after they click each
  // one. This ref is used track when users interact with buttons other than the
  // day buttons in the grid and we can skip the focus management logic in the
  // hook below.
  const gridFocusFlagRef = React.useRef(true);

  const [focusedDate, setFocusedDate] = React.useState(defaultDate);
  const focusedStr = asString(focusedDate);

  const { dates, labels } = flattenHighlightedDates(highlightedDates);
  const hasLabel = labels.length > 0;

  // Every time we change the focused date using the state hook above, we should
  // focus the corresponding date button.
  useIsomorphicLayoutEffect(() => {
    if (!gridFocusFlagRef.current) {
      gridFocusFlagRef.current = true;
      return;
    }
    const table = tableRef.current;
    if (!table) {
      return;
    }

    const el = table.querySelector(`:scope  [data-date="${focusedStr}"]`);
    if (!(el instanceof HTMLElement)) {
      return;
    }

    el.focus();
  }, [tableRef, focusedStr, gridFocusFlagRef]);

  const first = fromCalendarDate({ ...focusedDate, day: 1 });
  const last = fromCalendarDate({
    ...focusedDate,
    month: focusedDate.month + 1,
    day: 0,
  });
  const firstDateInMonth = toCalendarDate(first);
  const lastDateInMonth = toCalendarDate(last);

  let targetWeek: React.ReactNode[] = [];
  const visibleWeeks: React.ReactNode[][] = [targetWeek];
  for (let i = 0; i < lastDateInMonth.day; i++) {
    const d = { ...firstDateInMonth, day: firstDateInMonth.day + i };
    if (i !== 0 && dayInMondayWeek(d) === 0) {
      targetWeek = [];
      visibleWeeks.push(targetWeek);
    }

    const isFocused = compare(d, focusedDate) === 0;
    const isSelected = compare(d, defaultDate) === 0;
    const isDateWithinRange = dateInRange({
      min: minDate,
      max: maxDate,
      dt: d,
    });
    const highlighted = dates.find((hd) => areEqual(d, hd.date));

    targetWeek.push(
      <td role="gridcell" key={asString(d)}>
        <CalendarButton
          date={d}
          isHighlighted={!!highlighted}
          bg={highlighted?.color}
          tabIndex={isFocused ? 0 : -1}
          data-autofocus={isFocused}
          data-selected={isSelected}
          aria-label={i18n.getDateLabel(d)}
          onClick={() => {
            onSelect(d);
            setFocusedDate(d);
          }}
          disabled={highlighted?.disabled || !isDateWithinRange}
        >
          {i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // Pad the first week if there are not enough days in to fill it in the given
  // month
  for (let i = 0; i < dayInMondayWeek(firstDateInMonth); i++) {
    const d = add(firstDateInMonth, { day: -1 * (i + 1) });
    visibleWeeks[0]?.unshift(
      <td key={`startpad-${asString(d)}`} role="gridcell" aria-hidden>
        <CalendarButton
          date={d}
          hidden
          aria-label={i18n.getDateLabel(d)}
          onClick={() => {}}
        >
          {i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // Pad the last week if there are not enough days in to fill it in the given
  // month
  for (let i = 0; i < 6 - dayInMondayWeek(lastDateInMonth); i++) {
    const d = add(lastDateInMonth, { day: i + 1 });
    visibleWeeks[visibleWeeks.length - 1]?.push(
      <td key={`endpad-${asString(d)}`} role="gridcell" aria-hidden>
        <CalendarButton
          date={d}
          hidden
          aria-label={i18n.getDateLabel(d)}
          onClick={() => {}}
        >
          {i18n.getDayLabel(d.day)}
        </CalendarButton>
      </td>
    );
  }

  // The reference for the required keyboard shortcuts can be found on the
  // W3C APG reference for date pickers:
  // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
  const handleRootKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Escape") {
      onClose();
      return;
    }
  };

  // The reference for the required keyboard shortcuts can be found on the
  // W3C APG reference for date pickers:
  // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
  const handleGridKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const key = e.key;

    setFocusedDate((curr) => getFocusedDate(key, curr, e));
  };

  return (
    // We need to handle keyboard shortcuts as outline in the W3C APG guidelines
    // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/datepicker-dialog.html
    //
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      css={calendarStyle(theme)}
      role="dialog"
      aria-modal
      onKeyDown={handleRootKeyDown}
      tabIndex={-1}
    >
      <VisuallyHidden>{i18n.instructionsText}</VisuallyHidden>
      <CalendarHeader
        i18n={i18n}
        onPrevious={() => {
          setFocusedDate((d) => backOneMonth(d));
          gridFocusFlagRef.current = false;
        }}
        onNext={() => {
          setFocusedDate((d) => forwardOneMonth(d));
          gridFocusFlagRef.current = false;
        }}
        focusedDate={focusedDate}
        minDate={minDate}
        maxDate={maxDate}
      />
      <table
        ref={tableRef}
        css={calendarGridStyle}
        onKeyDown={handleGridKeyDown}
        role="grid"
      >
        <CalendarWeekHeader i18n={i18n} />
        <tbody>
          {visibleWeeks.map((week, i) => {
            return <tr key={`${asString(firstDateInMonth)}-${i}`}>{week}</tr>;
          })}
        </tbody>
      </table>
      {hasLabel && (
        <div css={legendSectionStyle(theme)}>
          {labels.map((hd) => {
            return (
              <CalendarLegend
                key={hd.label}
                color={hd.color}
                label={hd.label}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Calendar;
