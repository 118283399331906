/* eslint-disable @typescript-eslint/ban-types */
import { Transition, TransitionGroup } from "react-transition-group";

import { Shimmer } from "../animations";
import { PlainButton } from "../buttons";
import { Glyph, Icon } from "../icons";
import { Color, ColorPreset, HoverEffect, useTheme } from "../theme";

import { DataTableAPI, SortDirection } from "./api";
import {
  dataTableCellStyle,
  dataTableCellFadeStyle,
  dataTableHeaderStyle,
  dataTableRowStyle,
  dataTableStyle,
  dataTableHeadingContentStyle,
  dataTableRowLinkStyle,
} from "./styles";
import type { DataTableProps } from "./componentTypes";

const DataTableSortIndicator: React.FC<{ direction?: SortDirection }> = (
  props
) => {
  switch (props.direction) {
    case SortDirection.Ascending:
      return (
        <Icon
          verticalAlign="middle"
          name={Glyph.ArrowUp}
          size="16px"
          color={ColorPreset.IconOnLight_04}
        />
      );
    case SortDirection.Descending:
      return (
        <Icon
          verticalAlign="middle"
          name={Glyph.ArrowDown}
          size="16px"
          color={ColorPreset.IconOnLight_04}
        />
      );
    default:
      return (
        <Icon
          verticalAlign="middle"
          name={Glyph.Swap}
          size="16px"
          color={Color.Greystone_1400_A16}
        />
      );
  }
};

const defaultStyleProps = () => ({});

export function getHeaderLabelAndAccessories<Item>(
  heading: DataTableAPI.HeadingInstance<Item>
) {
  const renderResult = heading.render();

  let label: React.ReactNode = null;
  let leftAccessory: React.ReactNode = null;
  let rightAccessory: React.ReactNode = null;
  if (
    renderResult != null &&
    typeof renderResult === "object" &&
    "label" in renderResult
  ) {
    label = renderResult.label;
    leftAccessory = renderResult.leftAccessory;
    rightAccessory = renderResult.rightAccessory;
  } else {
    label = renderResult;
  }

  return { label, leftAccessory, rightAccessory };
}

function DataTable<Item extends object>(
  props: DataTableProps<Item>
): React.ReactElement {
  const { theme } = useTheme();
  const {
    headings,
    rows,
    getTableProps,
    getHeadingStyleProps = defaultStyleProps,
    getCellStyleProps = defaultStyleProps,
    emptyState,
    hideBottomBorder,
  } = props;

  return (
    <table {...getTableProps()} css={dataTableStyle(theme)}>
      <thead>
        <tr {...headings.getRowProps()} css={dataTableRowStyle()}>
          {headings.cells.map((heading) => {
            const headingProps = heading.getHeadingProps();
            const ariaSort = headingProps["aria-sort"];

            const { label, leftAccessory, rightAccessory } =
              getHeaderLabelAndAccessories(heading);

            let content = label;
            if (heading.canSort) {
              content = (
                <PlainButton
                  effect={HoverEffect.TextDecoration}
                  onClick={heading.toggleSort}
                  aria-pressed={ariaSort != null}
                >
                  {label}
                </PlainButton>
              );
            }
            return (
              <th
                {...headingProps}
                key={headingProps.key}
                css={dataTableHeaderStyle(theme, getHeadingStyleProps(heading))}
              >
                <div css={dataTableHeadingContentStyle}>
                  {heading.canSort ? (
                    <DataTableSortIndicator direction={heading.sorted} />
                  ) : null}
                  {leftAccessory}
                  {content}
                  {rightAccessory}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 && !!emptyState && (
          <tr key="empty_state_row" role="row">
            <td key="empty_0" role="cell" colSpan={headings.cells.length}>
              {emptyState}
            </td>
          </tr>
        )}
        {rows.map((row, index, arr) => {
          const rowProps = row.getRowProps();
          const isLastRow = index === arr.length - 1;

          return (
            <tr
              {...rowProps}
              key={rowProps.key}
              css={dataTableRowStyle(hideBottomBorder && isLastRow)}
            >
              <TransitionGroup
                component={null}
                appear={false}
                enter={true}
                exit={false}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  const renderCellLink = Boolean(
                    rowProps.link && !cell.omitLink
                  );
                  return (
                    <Transition
                      key={cellProps.key}
                      in={cell.type === "data"}
                      timeout={250}
                    >
                      {(state) => {
                        return (
                          <td
                            className={state}
                            {...cellProps}
                            css={[
                              dataTableCellFadeStyle(theme, state),
                              dataTableCellStyle(theme, {
                                cellStyle: getCellStyleProps(cell),
                                removePadding: renderCellLink,
                              }),
                            ]}
                          >
                            {cell.type === "loading" ? (
                              <Shimmer />
                            ) : renderCellLink ? (
                              <a
                                href={rowProps.link}
                                css={dataTableRowLinkStyle(
                                  theme,
                                  getCellStyleProps(cell)
                                )}
                              >
                                {cell.render()}
                              </a>
                            ) : (
                              cell.render()
                            )}
                          </td>
                        );
                      }}
                    </Transition>
                  );
                })}
              </TransitionGroup>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default DataTable;
