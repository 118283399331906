import { useCallback, useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";

import type { InputProps, InternalProps } from "../Input";
import {
  AlignItems,
  ColorPreset,
  CSSRulesFunction,
  inputGutterStyle,
  Overflow,
  TypePreset,
  useTheme,
} from "../../theme";
import { Box, Space } from "../../layout";
import { Shimmer } from "../../animations";
import { FormFieldStatus, resolveStatusColors } from "../status";
import { Glyph, Icon } from "../../icons";
import usePrevious from "../../hooks/usePrevious";

import { useListKeyboardNav } from "./useListKeyboardNav";
import { ListItemsContainer } from "./ListItemContainer";
import { ListWrapper } from "./ListWrapper";
import { ListRow } from "./ListRow";
import { calcListHeight, useObserveElementHeight } from "./List.utils";
import { ListItem } from "./ListItem";
import type { DefaultListItemProps, RenderItemProps } from "./List.types";
import { autoFillSupport, searchReset } from "./List.styles";
import { ListItemWrapper } from "./ListItemWrapper";

const BASE_SIZE = 16;
const MIN_LIST_HEIGHT = 10;
const MAX_LIST_HEIGHT = 25;

// start trying to load more items when the items.length - x comes into view
const LAZY_LOAD_LOOKAHEAD = 2;

export const inputStyle: CSSRulesFunction<InputProps> = (theme, props) => {
  const padding = inputGutterStyle(theme, {
    size: props.gutter,
    adjustmentVar: "--input-border-width",
  });
  const typePreset = theme.tokens.typePresets[TypePreset.Body_02];

  const fontFamily = props.fontFamily
    ? theme.tokens.fontFamilies[props.fontFamily]
    : "inherit";

  return [
    autoFillSupport,
    {
      display: "block",
      width: "100%",
      margin: 0,
      background: "none",
      cursor: props.disabled ? "not-allowed" : "auto",
      fontFamily,
      color: "inherit",
      "&:focus": {
        boxShadow: "none",
        outline: "none",
      },
    },
    padding,
    theme.responsive(typePreset.size, (fs) => {
      const size = theme.tokens.fontSizes[fs];
      const minHeight = `calc(${size.lineHeight} + ${padding} * 2)`;
      return {
        ...size,
        minHeight,
      };
    }),
    searchReset,
  ];
};

const searchInputContainerStyle: CSSRulesFunction<
  InternalProps & { flip: boolean }
> = (theme, props) => {
  const { status, focused, disabled, flip } = props;

  const colors = resolveStatusColors(theme, { status, disabled });
  const { textColor, backgroundColor } = colors;

  const borderWidth = focused ? "2px" : "1px";

  return {
    "--input-border-width": borderWidth,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    backgroundColor,
    color: textColor,
    transition: "box-shadow 150ms",
    borderBottom: flip
      ? "none"
      : `1px solid ${theme.color(ColorPreset.BorderOnLight_02)}`,
    borderTop: !flip
      ? "none"
      : `1px solid ${theme.color(ColorPreset.BorderOnLight_02)}`,
    borderRadius: 0,
    boxShadow: "none",
  };
};

export interface ListProps<Item = DefaultListItemProps> {
  items: Item[];
  renderItem?: (props: RenderItemProps<Item>) => React.ReactNode;
  flip?: boolean;
  maxHeight?: number;
  focussedIndex?: number;
  onClearFilter?: VoidFunction;
  searchInputProps?: InputProps;
  onChange?: (item: Item) => void;
  onRefresh?: VoidFunction;
  emptyMessage?: React.ReactNode;
  loadingCount?: number;
  loading?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
  virtualize?: boolean;
  focusListOnMount?: boolean;
  onLoadNextPage?: VoidFunction;
  loadingNextPage?: boolean;
  status?: FormFieldStatus;
  footer?: (props: {
    flip?: boolean;
    status?: FormFieldStatus;
  }) => React.ReactNode;
}

export const List = <Item extends DefaultListItemProps>({
  flip,
  items,
  virtualize,
  renderItem,
  maxHeight,
  loading,
  loadingCount,
  emptyMessage,
  searchInputProps: userSearchInputProps,
  onChange,
  focussedIndex: defaultFocussedIndex,
  innerRef,
  focusListOnMount,
  onLoadNextPage,
  loadingNextPage,
  status,
  footer,
}: ListProps<Item>) => {
  const prevLoading = usePrevious(loadingNextPage);
  const prevItems = usePrevious<Item[]>(items);
  const searchable = !!Object.keys(userSearchInputProps || {}).length;

  const { theme } = useTheme();
  const {
    listTotalHeight,
    searchInputVal,
    focussedIndex,
    listProps,
    getItemProps,
    searchInputProps,
    virtualListRef,
    innerListRef,
    headerRef,
    footerRef,
  } = useListKeyboardNav<Item>({
    items,
    onChange,
    focussedIndex: defaultFocussedIndex,
    focusListOnMount,
    searchable,
    searchInput: {
      ...userSearchInputProps,
      value: userSearchInputProps?.value,
    },
  });

  // store sizing variables in a ref so they don't get re-calculated
  const listAttributes = useRef({
    minHeight: BASE_SIZE * MIN_LIST_HEIGHT,
    maxHeight: BASE_SIZE * MAX_LIST_HEIGHT,
    verticalPadding: BASE_SIZE,
    screenPadding: BASE_SIZE,
  });

  // get the observed height for the footer and header element
  const headerHeight = useObserveElementHeight(headerRef);
  const footerHeight = useObserveElementHeight(footerRef);

  // calculate the height for the virtualised list
  const calculatedListHeight = calcListHeight({
    heightRestriction: maxHeight,
    scrollableAreaHeight: listTotalHeight,
    minListHeight: listAttributes.current.minHeight,
    maxListHeight: listAttributes.current.maxHeight,
    verticalListPadding: listAttributes.current.verticalPadding,
    screenPadding: listAttributes.current.screenPadding,
    headerHeight,
    footerHeight,
  });

  const inputCssProps = inputStyle(theme, {});
  const inputContainerCssProps = searchInputContainerStyle(theme, {
    flip: flip ?? false,
  });

  // store the height of the list items in a map so the virtual list
  // can calculate the total height and render correctly
  // needs to be in a useCallback so that ListRow React.memo can be effectively memoizing the component
  const sizeMap = useRef<Record<string, number>>({});
  const updateSizeMap = useCallback(
    (index: number, size: number) => {
      if (sizeMap.current[`${index}`] !== Math.round(size)) {
        virtualListRef.current?.resetAfterIndex(index);
        sizeMap.current = { ...sizeMap.current, [index]: Math.round(size) };
      }
    },
    [sizeMap.current, virtualListRef.current]
  );

  // get the size for a list item by index.
  // if there's no calculated height yet we take the very first height and assume that
  // all items have the same height. If no height has been calculated yet we go with 64px
  // which is slightly higher than the standard list item with description.
  const getSize = useCallback(
    (index: number): number => {
      return (
        sizeMap.current[`${index}`] || sizeMap.current["0"] || BASE_SIZE * 4
      );
    },
    [sizeMap.current]
  );

  useEffect(() => {
    if (
      onLoadNextPage &&
      prevLoading &&
      !loadingNextPage &&
      prevItems?.length
    ) {
      const index = prevItems.length;

      if (virtualListRef.current?.scrollToItem) {
        virtualListRef.current?.scrollToItem(index, "smart");
      }
    }
  }, [onLoadNextPage, loadingNextPage, prevLoading, items, prevItems]);

  return (
    <ListWrapper flip={flip} ref={innerRef}>
      {searchable ? (
        <Box
          borderRadius={0}
          borderWidth={0}
          css={inputContainerCssProps}
          ref={headerRef}
          gutterH={0.5}
        >
          <Box layout="flex" alignItems={AlignItems.Center} spaceBefore={0.5}>
            <Icon name={Glyph.Search} />
          </Box>
          <input {...searchInputProps} css={inputCssProps} />
        </Box>
      ) : null}

      {loading && loadingCount && !items.length ? (
        <ListItemsContainer>
          {Array.from({ length: loadingCount ?? 0 }).map((_, i) => (
            <ListItemWrapper key={i.toString()}>
              <ListItem>
                <Shimmer />
              </ListItem>
            </ListItemWrapper>
          ))}
        </ListItemsContainer>
      ) : null}
      {!loading && !items.length && emptyMessage ? (
        <ListItemsContainer>
          <Box gutterH={1} gutterV={1}>
            {emptyMessage}
          </Box>
        </ListItemsContainer>
      ) : null}
      {items.length ? (
        <ListItemsContainer {...listProps} aria-label="listbox">
          {virtualize ? (
            <VariableSizeList
              height={calculatedListHeight}
              width="100%"
              ref={virtualListRef}
              innerRef={innerListRef}
              itemCount={items.length}
              itemSize={getSize}
              style={{
                padding: theme.spacing(0.5),
                position: "relative",
              }}
              onItemsRendered={({ visibleStopIndex }) => {
                const thresholdMet =
                  visibleStopIndex >= items.length - LAZY_LOAD_LOOKAHEAD;

                if (onLoadNextPage && !loadingNextPage && thresholdMet) {
                  onLoadNextPage();
                }
              }}
            >
              {({ index, style }) => (
                <ListRow<Item>
                  style={style}
                  key={items[index]?.value.toString()}
                  onSize={updateSizeMap}
                  getSize={getSize}
                  index={index}
                  renderItem={renderItem}
                  focussedIndex={focussedIndex}
                  searchInputValue={searchInputVal as string}
                  items={items}
                  getItemProps={getItemProps}
                />
              )}
            </VariableSizeList>
          ) : (
            <Box height={calculatedListHeight} overflowY={Overflow.Auto}>
              <Box ref={innerListRef}>
                {items.map((_, index) => (
                  <ListRow<Item>
                    key={index.toString()}
                    index={index}
                    renderItem={renderItem}
                    focussedIndex={focussedIndex}
                    searchInputValue={searchInputVal as string}
                    items={items}
                    getItemProps={getItemProps}
                    style={{
                      paddingBottom:
                        !loadingNextPage && index === items.length - 1
                          ? theme.spacing(0.5)
                          : 0,
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
        </ListItemsContainer>
      ) : null}
      {loadingNextPage ? (
        <Box ref={footerRef} gutterV={0.75} gutterH={0.75} spaceBelow={0.5}>
          <ListItem
            leftAccessory={
              <>
                <Icon name={Glyph.Spinner} size="16px" />
                <Space h={0.25} />
              </>
            }
          >
            <Shimmer height={12} />
          </ListItem>
        </Box>
      ) : null}
      {footer && !loadingNextPage ? (
        <Box ref={footerRef}>{footer({ flip, status })}</Box>
      ) : null}
    </ListWrapper>
  );
};
