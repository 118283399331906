import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { FocusScope } from "@react-aria/focus";

import { useTheme } from "../../theme";
import { useTapOut } from "../../hooks/pointers";
import {
  FloatingPlacement,
  PlacementStrategy,
  useFloating,
} from "../../hooks/floating";

import Calendar from "./Calendar";
import { CalendarDate, validatePartial } from "./dateHelpers";
import DatePickerTrigger from "./DatePickerTrigger";
import {
  datePickerCalendarStyle,
  datePickerTriggerLayoutStyle,
} from "./datePickerStyle";
import type { DatesI18n } from "./i18n";
import type { HighlightedDates } from "./DateInput";

export interface DatePickerAccessoryProps {
  i18n: DatesI18n;
  value: Partial<CalendarDate> | undefined;
  setValue: (v: CalendarDate) => void;
  disabled?: boolean;
  position?: "absolute" | "fixed";
  initialPlacement?: FloatingPlacement;
  placementStrategy?: PlacementStrategy;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  highlightedDates?: Array<HighlightedDates>;
}

const DatePickerAccessory: React.FC<DatePickerAccessoryProps> = ({
  i18n,
  value,
  setValue,
  disabled,
  position = "absolute",
  initialPlacement = "bottom-end",
  placementStrategy = "static",
  minDate,
  maxDate,
  highlightedDates,
}) => {
  const { theme } = useTheme();
  const [calendarOpen, toggleCalendar] = React.useState(false);
  const { x, y, refs, update } = useFloating({
    open: calendarOpen,
    placementStrategy,
    initialPlacement,
    shouldUpdate: position === "fixed",
  });
  const hitAreaRef = React.useRef<HTMLButtonElement>(null);
  const floatingRef = refs.floating;
  const tapoutRefs = React.useMemo(
    () => [floatingRef, hitAreaRef],
    [floatingRef, hitAreaRef]
  );
  const onTapOut = React.useCallback(() => {
    toggleCalendar(false);
  }, [toggleCalendar]);
  useTapOut(tapoutRefs, onTapOut);

  const validated = value ? validatePartial(value) : null;

  const calendarTop = y == null ? "" : `${y}px`;
  const calendarLeft = x == null ? "" : `${x}px`;

  return (
    <>
      <div ref={refs.setReference} css={datePickerTriggerLayoutStyle(theme)} />
      <div>
        <DatePickerTrigger
          ref={hitAreaRef}
          label={i18n.changeDateButtonLabel}
          onClick={() => toggleCalendar((v) => !v)}
          disabled={disabled}
          aria-expanded={calendarOpen}
          aria-haspopup="dialog"
        />
        <CSSTransition
          in={calendarOpen}
          timeout={250}
          classNames="fade"
          unmountOnExit
          mountOnEnter
          onEnter={update}
        >
          <div
            ref={refs.setFloating}
            css={datePickerCalendarStyle(theme, {
              position,
              top: calendarTop,
              left: calendarLeft,
            })}
          >
            <FocusScope contain restoreFocus>
              <Calendar
                defaultDate={validated || undefined}
                i18n={i18n}
                onClose={() => toggleCalendar(false)}
                minDate={minDate}
                highlightedDates={highlightedDates}
                maxDate={maxDate}
                onSelect={(v) => {
                  setValue(v);
                  toggleCalendar(false);
                }}
              />
            </FocusScope>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default DatePickerAccessory;
