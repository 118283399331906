import { calendarWeekHeaderStyle } from "./calendarStyles";
import type { DatesI18n } from "./i18n";

export interface CalendarWeekHeaderProps {
  i18n: DatesI18n;
}

const CalendarWeekHeader: React.FC<CalendarWeekHeaderProps> = ({ i18n }) => {
  const daysOfWeek = i18n.getDaysOfWeek();
  return (
    <thead aria-hidden>
      <tr>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["mon"].full}>
            {daysOfWeek["mon"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["tue"].full}>
            {daysOfWeek["tue"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["wed"].full}>
            {daysOfWeek["wed"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["thu"].full}>
            {daysOfWeek["thu"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["fri"].full}>
            {daysOfWeek["fri"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["sat"].full}>
            {daysOfWeek["sat"].short}
          </abbr>
        </th>
        <th>
          <abbr css={calendarWeekHeaderStyle} title={daysOfWeek["sun"].full}>
            {daysOfWeek["sun"].short}
          </abbr>
        </th>
      </tr>
    </thead>
  );
};

export default CalendarWeekHeader;
