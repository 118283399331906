import type * as React from "react";

import { FontWeight, Interpolation, useTheme } from "../theme";
import { focusRingVar } from "../theme/cssvariables";
import { activeFocusWidth } from "../theme/focusRings";

import { vars } from "./tabsStyle";

export interface TabTriggerProps {
  id: string;
  panelId: string;
  focused?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

const tabTriggerStyle: Interpolation = {
  scrollSnapAlign: "start",
  fontFamily: "inherit",
  border: "none",
  background: "none",
  textAlign: "start",
  margin: 0,
  padding: `var(${activeFocusWidth})`,
  fontSize: `var(${vars.triggerFontSize})`,
  lineHeight: `var(${vars.triggerLineHeight})`,
  fontWeight: FontWeight.SemiBold,
  cursor: "pointer",

  transition: "color 200ms",

  color: `var(${vars.triggerColor})`,
  "&:hover": {
    color: `var(${vars.triggerHoverColor})`,
  },
  "&[aria-selected=true]": {
    color: `var(${vars.triggerSelectedColor})`,
  },
  "&:disabled": {
    color: `var(${vars.triggerColor})`,
    cursor: "unset",
    opacity: 0.5,
  },
};

const TabTrigger: React.FC<TabTriggerProps> = (props) => {
  const { theme } = useTheme();
  const {
    id,
    panelId,
    focused,
    selected = false,
    onClick,
    disabled,
    children,
  } = props;
  return (
    <button
      css={tabTriggerStyle}
      id={id}
      type="button"
      role="tab"
      aria-controls={panelId}
      aria-selected={selected}
      tabIndex={focused ? 0 : -1}
      onClick={onClick}
      disabled={disabled}
    >
      <span
        css={{
          boxShadow: `var(${focusRingVar})`,
          borderRadius: theme.radius(0.25),
        }}
      >
        {children}
      </span>
    </button>
  );
};

export default TabTrigger;
