import { useCapitalIntegrationList } from "@gocardless/api/dashboard/capital-integration";

export const INTEGRATION_NAME = "pipe";

export const usePipeStatus = ({
  shouldMakeRequest,
}: {
  shouldMakeRequest?: boolean;
}) => {
  const { data: capitalIntegrationsResponse, ...apiResponse } =
    useCapitalIntegrationList(shouldMakeRequest);
  const pipeStatus = capitalIntegrationsResponse?.capital_integrations?.find(
    (capitalIntegration) =>
      capitalIntegration?.integration_name === INTEGRATION_NAME
  );

  return {
    pipeStatus,
    ...apiResponse,
  };
};
