import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";

import { useAccessToken } from "src/common/authorisation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const useMultiAccountFeature = () => {
  const [accessToken] = useAccessToken();

  const { data, isLoading } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );

  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_MULTI_ACCOUNT_V2,
  });

  const hasMultiAccountFeature: boolean =
    data?.organisations?.features?.["multi_entity_organisation_tree"] ?? false;

  return {
    hasMultiAccountFeature,
    hasMultiAccountV2Feature: hasMultiAccountFeature && isVariationOn,
    isLoading: isLoading,
  };
};
