import type * as React from "react";

import { useTheme } from "../theme";

import { dlStyle } from "./dlStyle";
import { DLLayout, DLStyleProps } from "./types";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDListElement>,
  keyof DLStyleProps
>;

export interface DLProps extends Partial<DLStyleProps>, HTMLAttributes {
  children: React.ReactNode;
}

const DL: React.FC<DLProps> = (props) => {
  const { theme } = useTheme();
  const {
    layout = DLLayout.Row,
    spaceAbove = 0,
    spaceBelow = 0,
    ...rest
  } = props;

  return (
    <dl css={dlStyle(theme, { layout, spaceAbove, spaceBelow })} {...rest}>
      {props.children}
    </dl>
  );
};

export default DL;
