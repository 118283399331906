import type * as React from "react";

import { useTheme } from "../theme";

import { useMenuLayout } from "./menuState";
import { menuSeparatorStyle, MenuSeparatorStyleProps } from "./menuStyle";

type StyleProps = Omit<MenuSeparatorStyleProps, "groupLayout">;

export interface MenuSeparatorProps extends Partial<StyleProps> {}

const MenuSeparator: React.FC<MenuSeparatorProps> = (props) => {
  const { visibility = "visible" } = props;
  const { theme } = useTheme();
  const groupLayout = useMenuLayout();
  return (
    <li
      role="separator"
      css={menuSeparatorStyle(theme, { groupLayout, visibility })}
    />
  );
};

export default MenuSeparator;
