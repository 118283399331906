import * as React from "react";

import TabPanel from "./TabPanel";
import { TabItemRole } from "./tabsState";
import TabTrigger from "./TabTrigger";

export interface TabItemProps {
  /**
   * An id that is used to derive the ids for the tab trigger and panel and tie
   * them together with accessibility attributes.
   */
  id: string;
  /**
   * The label to use on the tab button (also know as tab trigger).
   */
  label: React.ReactNode;

  /**
   * The contents of the tab panel.
   */
  children: React.ReactNode;

  /**
   * This prop is used to set the disabled state for the tab panel.
   */
  disabled?: boolean;

  /**
   * @ignore This prop is set by the Tabs component.
   */
  onClick?: (key: string) => void;
  /**
   * @ignore This prop is set by the Tabs component.
   */
  focused?: boolean;
  /**
   * @ignore This prop is set by the Tabs component.
   */
  selected?: string;
}

const TabItem: React.FC<TabItemProps> = (props) => {
  const { focused, selected, onClick, disabled } = props;
  const renderRole = React.useContext(TabItemRole);
  const panelId = props.id;
  const triggerId = `${props.id}-trigger`;

  if (renderRole === "trigger") {
    return (
      <TabTrigger
        id={triggerId}
        panelId={panelId}
        focused={focused}
        selected={selected === props.id}
        onClick={() => onClick?.(props.id)}
        disabled={disabled}
      >
        {props.label}
      </TabTrigger>
    );
  } else {
    return (
      <TabPanel
        id={panelId}
        labelId={triggerId}
        selected={selected === props.id}
      >
        {props.children}
      </TabPanel>
    );
  }
};

export default TabItem;
