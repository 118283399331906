import type {
  ColorProp,
  CSSRulesFunction,
  ResponsiveValue,
  SpaceScale,
  TypePreset,
  TypeScale,
} from "../theme";
import { typographyStyle } from "../typography";

export interface LabelledPanelStyleProps {
  /** Sets the background color of the header area. */
  headerBg: ColorProp;

  /** Sets the foreground color of the header area. */
  headerColor: ColorProp;

  /**
   * Sets the border color of the header area.
   */
  headerBorder: ColorProp;

  /**
   * Sets the background color of the body area.
   */
  bodyBg: ColorProp;

  /**
   * Sets the foreground color of the body area.
   */
  bodyColor: ColorProp;

  /**
   * Sets the border color of the body area.
   */
  bodyBorder: ColorProp;

  /** Sets the vertical gutter (padding) of the header and body areas. */
  gutterV: ResponsiveValue<SpaceScale>;

  /** Sets the horizontal gutter (padding) of the header and body areas. */
  gutterH: ResponsiveValue<SpaceScale>;

  /** Sets the visibility of the separator between header and body. */
  separator: "visible" | "hidden";

  /**
   * Set the default type preset of content in the panel.
   */
  typePreset?: TypePreset;

  /**
   * Set the default font size of content in the panel. This prop will override
   * some type settings if set alongside the `typePreset` prop.
   */
  fontSize?: ResponsiveValue<TypeScale>;
}

const vars = {
  gutterV: "--labelledpanel-gutterv",
  gutterH: "--labelledpanel-gutterh",

  headerBg: "--labelledpanel-header-bg",
  headerColor: "--labelledpanel-header-fg",
  headerBorder: "--labelledpanel-header-border",

  bodyBg: "--labelledpanel-body-bg",
  bodyColor: "--labelledpanel-body-fg",
  bodyBorder: "--labelledpanel-body-border",

  borderRadius: "--labelledpanel-borderradius",
};

export const labelledPanelHeaderStyle: CSSRulesFunction<
  LabelledPanelStyleProps
> = (theme, props) => {
  const gh = `var(${vars.gutterH})`;
  const gv = `var(${vars.gutterV})`;
  const r = `var(${vars.borderRadius})`;
  const backgroundColor = `var(${vars.headerBg})`;
  const color = `var(${vars.headerColor})`;
  const borderColor = `var(${vars.headerBorder})`;

  const padding =
    props.separator === "hidden" ? `${gv} ${gh} 0` : `${gv} ${gh}`;
  const borderWidth = props.separator === "hidden" ? "1px 1px 0" : "1px";

  return {
    display: "grid",
    alignItems: "start",
    gap: theme.spacing([1, 1]),
    padding,
    borderRadius: `${r} ${r} 0 0`,
    backgroundColor,
    color,
    borderWidth,
    borderStyle: "solid",
    borderColor,
  };
};

const defaultHeaderLayout: ResponsiveValue<"stack" | "inline"> = [
  "stack",
  null,
  "inline",
];

export const labelledPanelHeaderLayoutStyle: CSSRulesFunction<{
  hasPrimaryAccessory: boolean;
  hasSecondaryAccessory: boolean;
}> = (theme, { hasPrimaryAccessory, hasSecondaryAccessory }) => {
  /**
   * This tedious interpolation builds two versions of grid-template-areas:
   * - A stack layout where accessories are one line and the heading is on the
   *   next line
   * - An inline layout with a heading surrounded by accessories
   *
   * The complicated bit is that we need to omit the areas that don't content
   * in them so the css grid does not allocate an empty column for them.
   */

  const heading = "heading heading";

  const l = hasPrimaryAccessory ? "primary" : "";
  const r = hasSecondaryAccessory ? "secondary" : "";

  const inlineLayout = `"${[l, heading, r].filter(Boolean).join(" ")}"`;
  let inlineTemplateColumns = "auto auto";
  if (hasPrimaryAccessory) {
    inlineTemplateColumns = `max-content ${inlineTemplateColumns}`;
  }
  if (hasSecondaryAccessory) {
    inlineTemplateColumns = `${inlineTemplateColumns} max-content`;
  }

  // the following line will result in one of the following:
  // "primary primary", "secondary secondary", "primary secondary" or ""
  const slots = [l || r, r || l].join(" ").trim();
  const stackLine1 = slots ? `"${slots}" ` : "";
  const stackLayout = `${stackLine1}"${heading}"`;
  const stackTemplateColumns = "1fr 1fr";

  return theme.responsive(defaultHeaderLayout, (layout) => {
    switch (layout) {
      case "inline":
        return {
          gridTemplateAreas: inlineLayout,
          gridTemplateColumns: inlineTemplateColumns,
        };
      default:
        return {
          gridTemplateAreas: stackLayout,
          gridTemplateColumns: stackTemplateColumns,
        };
    }
  });
};

export const labelledPanelHeadingStyle: CSSRulesFunction = (theme) => ({
  display: "grid",
  rowGap: theme.spacing(0.25),
});

export const labelledPanelBodyStyle: CSSRulesFunction<{
  hasHeader: boolean;
}> = (_, { hasHeader }) => {
  const gh = `var(${vars.gutterH})`;
  const gv = `var(${vars.gutterV})`;
  const r = `var(${vars.borderRadius})`;
  const backgroundColor = `var(${vars.bodyBg})`;
  const color = `var(${vars.bodyColor})`;
  const borderColor = `var(${vars.bodyBorder})`;

  return {
    padding: `${gv} ${gh}`,
    borderRadius: hasHeader ? `0 0 ${r} ${r}` : r,
    borderWidth: hasHeader ? "0 1px 1px" : "1px",
    borderStyle: "solid",
    borderColor,
    backgroundColor,
    color,
  };
};

export const labelledPanelStyle: CSSRulesFunction<LabelledPanelStyleProps> = (
  theme,
  props
) => {
  return [
    theme.responsive(props.gutterV, (v) => ({
      [vars.gutterV]: theme.spacing(v),
    })),
    theme.responsive(props.gutterH, (v) => ({
      [vars.gutterH]: theme.spacing(v),
    })),
    typographyStyle(theme, {
      ...(props.fontSize ? { size: props.fontSize } : {}),
      ...(props.typePreset ? { preset: props.typePreset } : {}),
    }),
    {
      [vars.headerBg]: theme.color(props.headerBg),
      [vars.headerColor]: theme.color(props.headerColor),
      [vars.headerBorder]: theme.color(props.headerBorder),
      [vars.bodyBg]: theme.color(props.bodyBg),
      [vars.bodyColor]: theme.color(props.bodyColor),
      [vars.bodyBorder]: theme.color(props.bodyBorder),
      [vars.borderRadius]: theme.radius(1),
    },
  ];
};
