import _ from "lodash";
import Router, { useRouter } from "next/router";
import { stringify, parse } from "query-string";

import { RouteParams, Route, getRoute, isExternal } from "./routes";

import { getUrl, getSite, getFeatureFlag } from "src/common/config";
import { navigateHome } from "src/common/authorisation/session";

export { Route, PublicOnlyRoutes, isExternal, getRoute } from "./routes";

export interface RouteURLParams {
  route: Route;
  routeParams?: RouteParams;
  absolute?: boolean;
  queryParams?: { [key: string]: string | undefined };
  anchorId?: string;
}

const getBaseSite = (route: Route) =>
  isExternal(route) ? getUrl("dashboard") : getSite();

export const getRouteURL = ({
  route,
  routeParams,
  queryParams,
  absolute,
  anchorId,
}: RouteURLParams) => {
  // omits empty query params passed
  const allQueryParams = _.omitBy(
    getQueryParams({ route, routeParams, queryParams }),
    _.isEmpty
  );
  const query = !_.isEmpty(allQueryParams)
    ? "?" + stringify(allQueryParams)
    : "";
  const anchorTag = anchorId ? `#${anchorId}` : "";

  const href = getRoute(route, routeParams) + query + anchorTag;
  const site = getBaseSite(route);
  const url = absolute ? site + href : href;

  // this is to overcome a cypress bug where
  // redirecting to another domain throws a chrome error
  if (getFeatureFlag("enableStubbedRedirects") && isExternal(route)) {
    return `/redirect?url=${url}`;
  }

  return url;
};

export const urlForMobile = (url: string) => {
  if (url.startsWith(`${window.location.origin}/subscription-templates`)) {
    return url.replace(
      "/subscription-templates",
      "/mobile/subscription-templates"
    );
  }
  if (url.startsWith(`${window.location.origin}/customers`)) {
    return url.replace("/customers", "/mobile/customers");
  }
  if (url.startsWith(`${window.location.origin}/payments/paylinks`)) {
    return url.replace("/payments/paylinks", "/mobile/paylinks");
  }
  return url;
};

export const externalNavigation = (href: string, replace = false) => {
  // this is to overcome a cypress bug where
  // redirecting to another domain throws a chrome error
  if (
    getFeatureFlag("enableStubbedRedirects") &&
    // If we are already redirecting we ignore this flag
    !href.includes("/redirect?url=")
  ) {
    window.location.assign(`/redirect?url=${href}`);
    return;
  }

  // check if the URL matches URL origin to prevent attacks like open redirect
  const parsedRedirectURL = new URL(href, window.location.origin);
  const isSafeRedirect =
    !!parsedRedirectURL.origin &&
    parsedRedirectURL.origin === window.location.origin;

  // if its not a safe redirect take the user home
  if (!isSafeRedirect) {
    navigateHome({});
    return;
  }

  if (replace) {
    window.location.replace(href);
  } else {
    window.location.assign(href);
  }
};

// only non empty query params are added to search param of window.location
export const routerPush = (props: RouteURLParams) => {
  if (isExternal(props.route)) {
    externalNavigation(getRouteURL({ ...props, absolute: true }), false);
  } else {
    Router.push(getRouteURL(props));
  }
};

export const routerReplace = (props: RouteURLParams) => {
  if (isExternal(props.route)) {
    externalNavigation(getRouteURL({ ...props, absolute: true }), true);
  } else {
    Router.replace(getRouteURL(props));
  }
};

export const useRouterQuery = (queryKey: string) => {
  const { query, isReady } = useRouter();
  const queryItem = query[queryKey];

  // We have to handle that query can return a string or an array of strings.
  const item = Array.isArray(queryItem) ? queryItem[0] : queryItem;

  return {
    item: item || null,
    // It's possible that the item is not ready yet and empty on first render
    itemReady: isReady,
  };
};

// will check if route is same and merges with existing params else use new params
const getQueryParams = ({
  route,
  routeParams,
  queryParams,
}: RouteURLParams) => {
  const { pathname, search } = location;
  const newPath = getRoute(route, routeParams);
  const routeMatch = pathname === newPath;
  if (!routeMatch) {
    return queryParams;
  }
  const existingQueryParams = parse(search);
  return { ...existingQueryParams, ...queryParams };
};
