import * as React from "react";

import { CSSRulesFunction, useTheme } from "../theme";

import type { ListMarker } from "./appearance";

type HTMLAttributes = React.LiHTMLAttributes<HTMLLIElement>;

export interface LIProps extends HTMLAttributes {
  marker?: ListMarker;
}

const listItemStyle: CSSRulesFunction<LIProps> = (_, props) => ({
  listStyleType: props.marker,
});

const LI = React.forwardRef<HTMLLIElement, LIProps>(function LI(props, ref) {
  const { theme } = useTheme();
  const { marker, ...rest } = props;
  return <li css={listItemStyle(theme, props)} {...rest} ref={ref} />;
});

export default LI;
