import * as React from "react";

import {
  ResponsiveValue,
  CSSRulesFunction,
  useTheme,
  ColorProp,
} from "../theme";

interface AppearanceProps {
  bg?: ResponsiveValue<ColorProp>;
}

type HTMLProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof AppearanceProps
>;

export interface FullWidthProps extends HTMLProps, AppearanceProps {}

const fullWidthStyle: CSSRulesFunction<AppearanceProps> = (theme, props) => [
  {
    marginLeft: "calc(50% - 50vw)",
    marginRight: "calc(50% - 50vw)",
    maxWidth: "unset",
    width: "auto",
  },
  theme.responsive(props.bg, (bg) => ({
    backgroundColor: theme.color(bg),
  })),
];

const FullWidth = React.forwardRef<HTMLDivElement, FullWidthProps>(
  function FullWidth(props, ref) {
    const { theme } = useTheme();
    const { bg, ...rest } = props;
    return <div css={fullWidthStyle(theme, props)} {...rest} ref={ref} />;
  }
);

export default FullWidth;
