import React from "react";

export const useResizeObserver = (
  /** The element to observe. */
  elementRef: React.RefObject<HTMLElement>,
  /** A callback to call on resize. */
  callback: () => void,
  /**
   * An optional list of dependencies to track in the underlying use effect.
   *
   * For example, if a tooltip element is conditionally rendered then elementRef
   * will contain a null reference until some `open` prop is true. Adding the
   * `open` prop to the deps list will setup the resize observer when the
   * tooltip is rendered.
   */
  deps: unknown[] = []
) => {
  React.useEffect(() => {
    if (typeof window === "undefined" || window.document == null) {
      return;
    }
    const el = elementRef.current;
    if (!el) {
      return;
    }
    // If ResizeObserver is not available for a browser then use a traditional
    // window resize event listener
    const handleResize = () => {
      callback();
    };
    if (typeof window.ResizeObserver === "undefined") {
      window.addEventListener("resize", handleResize, false);
      return () => window.removeEventListener("resize", handleResize, false);
    } else {
      const ro = new ResizeObserver((entries) => {
        if (!entries.length) {
          return;
        }
        handleResize();
      });
      ro.observe(el);
      return () => {
        if (!el) {
          return;
        }
        ro.unobserve(el);
      };
    }
  }, [elementRef, callback, ...deps]);
};

interface UseScrollObserverOptions {
  /**
   * Specifies the scrollable element to track.
   */
  scrollReference: "document" | React.RefObject<HTMLElement>;

  /**
   * The callback to call when scrolling.
   */
  callback(): void;

  /**
   * Whether scroll tracking is enabled. This can be used as a performance
   * optimisation that plays nice with the rules of hooks. For example, scroll
   * tracking may need to be disabled when a particular prop or statement
   * evaluates to false.
   */
  enabled: boolean;
}

/**
 * A hook that triggers a callback when the page, or a reference, scrollable
 * element, are scrolled.
 */
export const useScrollObserver = (options: UseScrollObserverOptions) => {
  const { scrollReference, callback, enabled } = options;
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    let reference: Document | HTMLElement | null;
    if (scrollReference === "document") {
      reference = document;
    } else {
      reference = scrollReference.current;
    }

    if (!reference) {
      return;
    }

    const handler = () => {
      callback();
    };
    reference.addEventListener("scroll", handler, { passive: true });
    return () => {
      if (!reference) {
        return;
      }
      reference.removeEventListener("scroll", handler);
    };
  }, [scrollReference, callback, enabled]);
};
