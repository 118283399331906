import React from "react";
import AnimateHeight from "react-animate-height";

import type { Interpolation } from "../theme";

export interface AccordionPanelProps {
  id: string;
  headerId: string;
  open: boolean;
  children: React.ReactNode;
  onHeightAnimationEnd: () => void;
}

const panelBodyStyle: Interpolation = {
  paddingBottom: "var(--accordion-panel-gutter-block)",
  paddingRight:
    "calc(var(--accordion-panel-gutter-inline) +  var(--accordion-panel-pad-end))",
  paddingLeft:
    "calc(var(--accordion-panel-gutter-inline) + var(--accordion-panel-pad-start))",
  fontSize: "var(--accordion-panel-font-size)",
  lineHeight: "var(--accordion-panel-line-height)",
  color: "var(--accordion-body-color)",
};

const AccordionPanel: React.FC<AccordionPanelProps> = (props) => {
  const { open, id, headerId, children, onHeightAnimationEnd } = props;
  const [isClientSide, setIsClientSide] = React.useState(false);
  React.useEffect(() => setIsClientSide(true), []);
  const sharedProps = {
    id: id,
    role: "region",
    "aria-labelledby": headerId,
    "aria-hidden": !open,
  };

  const body = <div css={panelBodyStyle}>{children}</div>;

  if (!isClientSide) {
    return <div {...sharedProps}>{body}</div>;
  }

  return (
    <AnimateHeight
      onHeightAnimationEnd={onHeightAnimationEnd}
      duration={250}
      height={open ? "auto" : 0}
      {...sharedProps}
    >
      {body}
    </AnimateHeight>
  );
};

export default AccordionPanel;
