import { Color, ColorProp, ColorPreset, Theme } from "../theme";

export enum FormFieldStatus {
  Success = "success",
  Danger = "danger",
}

interface StatusColors {
  border: ColorProp;
  focusBorder: ColorProp;
  background: ColorProp;
  icon?: ColorProp;
  text?: ColorProp;
}

const defaultStatusColor: StatusColors = {
  border: ColorPreset.BorderOnLight_02,
  focusBorder: ColorPreset.BorderOnLight_01,
  background: ColorPreset.BackgroundLight_01,
  icon: ColorPreset.IconOnLight_01,
  text: ColorPreset.TextOnLight_01,
};

const disabledStatusColor: StatusColors = {
  border: Color.Greystone_700_A38,
  focusBorder: Color.Greystone_700_A38,
  text: Color.Greystone_1400_A38,
  background: Color.Greystone_1400_A8,
  icon: Color.Greystone_1400_A38,
};

const statusColors: Record<FormFieldStatus, StatusColors> = {
  [FormFieldStatus.Danger]: {
    border: ColorPreset.AlertBorderOnLight,
    focusBorder: ColorPreset.AlertBorderOnLight,
    background: ColorPreset.BackgroundLight_01,
    icon: ColorPreset.AlertIconOnLight,
  },
  [FormFieldStatus.Success]: {
    ...defaultStatusColor,
    border: Color.Success_Base,
    focusBorder: Color.Success_300,
    icon: Color.Success_Base,
  },
};

type ResolvedStatusColors = {
  baseBorderColor: string;
  selectedBorderColor: string;
  focusBorderColor: string;
  textColor?: string;
  backgroundColor?: string;
  iconColor?: string;
};

export const resolveStatusColors = (
  theme: Theme,
  options: {
    status?: FormFieldStatus;
    disabled?: boolean;
  }
): ResolvedStatusColors => {
  const { status, disabled } = options;
  let config = status ? statusColors[status] : defaultStatusColor;
  if (disabled) {
    config = disabledStatusColor;
  }

  const baseBorderColor = theme.color(config.border);
  const selectedBorderColor = theme.color(config.focusBorder);
  const focusBorderColor = selectedBorderColor;
  const textColor = config.text ? theme.color(config.text) : undefined;
  const iconColor = config.icon ? theme.color(config.icon) : undefined;
  const backgroundColor = config.background
    ? theme.color(config.background)
    : undefined;

  return {
    baseBorderColor,
    selectedBorderColor,
    focusBorderColor,
    textColor,
    backgroundColor,
    iconColor,
  };
};
