import type { CSSRulesFunction } from "../theme";
import { typographyStyle } from "../typography/appearance";

import { DDStyleProps, DLLayout, DLStyleProps, DTStyleProps } from "./types";

const vars = {
  templateColumns: "--dl-template-cols",
  rowGap: "--dl-row-gap",
  dtGridColumn: "--dl-dt-grid-col",
  ddGridColumn: "--dl-dd-grid-col",
  dtSpaceBelow: "--dl-dt-space-below",
  ddSpaceBelow: "--dl-dd-space-below",
};

const dlStackLayout: CSSRulesFunction = (theme) => ({
  [vars.templateColumns]: "1fr",
  [vars.rowGap]: 0,
  [vars.dtGridColumn]: "auto",
  [vars.ddGridColumn]: "auto",
  [vars.dtSpaceBelow]: theme.spacing(0.25),
  [vars.ddSpaceBelow]: theme.spacing(1.5),
});

const dlRowLayout: CSSRulesFunction = (theme) => ({
  [vars.templateColumns]: `1fr ${theme.spacing(1.5)} 1fr`,
  [vars.rowGap]: theme.spacing(1),
  [vars.dtGridColumn]: 1,
  [vars.ddGridColumn]: 3,
  [vars.ddSpaceBelow]: 0,
  [vars.dtSpaceBelow]: 0,
});

const dlRowFixedTermLayout: CSSRulesFunction = (theme) => ({
  [vars.templateColumns]: `max-content ${theme.spacing(1.5)} 1fr`,
  [vars.rowGap]: theme.spacing(1),
  [vars.dtGridColumn]: 1,
  [vars.ddGridColumn]: 3,
  [vars.ddSpaceBelow]: 0,
  [vars.dtSpaceBelow]: 0,
});

export const dlStyle: CSSRulesFunction<DLStyleProps> = (theme, props) => {
  return [
    theme.responsive(props.layout, (layout) => {
      switch (layout) {
        case DLLayout.Row:
          return dlRowLayout(theme);
        case DLLayout.RowFixedTerm:
          return dlRowFixedTermLayout(theme);
        default:
          return dlStackLayout(theme);
      }
    }),
    {
      display: "grid",
      alignItems: "baseline",
      overflow: "auto",
      margin: 0,

      gridTemplateColumns: `var(${vars.templateColumns})`,
      rowGap: `var(${vars.rowGap})`,
      "> dt": {
        gridColumn: `var(${vars.dtGridColumn})`,
        marginBottom: `var(${vars.dtSpaceBelow})`,
      },
      "> dd": {
        gridColumn: `var(${vars.ddGridColumn})`,
        marginBottom: `var(${vars.ddSpaceBelow})`,
      },

      "> dd:last-of-type": {
        marginBottom: 0,
      },
    },

    theme.responsive(props.spaceAbove, (v) => ({
      marginTop: theme.spacing(v),
    })),
    theme.responsive(props.spaceBelow, (v) => ({
      marginBottom: theme.spacing(v),
    })),
  ];
};

export const dtStyle: CSSRulesFunction<DTStyleProps> = (theme, props) => {
  return typographyStyle(theme, props);
};

export const ddStyle: CSSRulesFunction<DDStyleProps> = (theme, props) => {
  return [{ margin: 0 }, typographyStyle(theme, props)];
};

export const dlAccessoryStyle: CSSRulesFunction<"left" | "right"> = (
  theme,
  position
) => ({
  marginLeft: position === "right" ? theme.spacing(0.5) : undefined,
  marginRight: position === "left" ? theme.spacing(0.5) : undefined,
});
