import type { Glyph } from "../icons";
import type { ColorScheme, FontFamily, ResponsiveValue } from "../theme";

import type { TagRemoveActionProps } from "./RemoveAction";

export interface TagStyleProps {
  /**
   * Sets the type of tag to render
   */
  variant: TagVariant;
  /**
   * Sets the base color of the tag which along with `colorScheme` define the
   * color of the tag
   */
  color: TagColor;
  /**
   * Sets the color scheme of the tag which along with `color` define the
   * color of the tag
   */
  colorScheme: ColorScheme;
  /**
   * Sets the size of the tag
   */
  size: ResponsiveValue<TagSize>;
  /**
   * Sets the font family of the tag
   */
  fontFamily: Extract<FontFamily, "sans-serif" | "monospace">;
  /**
   * Renders an optional icon before the tag's content
   */
  iconBefore?: Glyph;
  /**
   * Renders an optional icon after the tag's content
   */
  iconAfter?: Glyph;
  /**
   * Configures a remove action that will be performed on the tag component
   */
  removeAction?: TagRemoveActionProps;
}

export enum TagVariant {
  Solid = "solid",
  Tinted = "tinted",
}

export enum TagColor {
  Primary = "primary",
  Neutral = "neutral",
  Success = "success",
  Alert = "alert",
  Warning = "warning",
  Info = "info",
}

export enum TagSize {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}
