import * as React from "react";

import { Box } from "../layout";
import { Breakpoint, useTheme, Theme } from "../theme";

import type { DataTableResponsiveProps } from "./componentTypes";

const useBreakpointLayout = (theme: Theme, breakpoint: Breakpoint) => {
  const [layout, setLayout] = React.useState<"table" | "compact">();

  const mediaQueryChange = (ev: MediaQueryListEvent) => {
    setLayout(ev.matches ? "table" : "compact");
  };

  React.useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(
      `(min-width: ${theme.tokens.breakpoints[breakpoint]})`
    );
    setLayout(mediaQuery.matches ? "table" : "compact");
    mediaQuery.addEventListener("change", mediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", mediaQueryChange);
    };
  }, [breakpoint]);
  return { layout };
};

function DataTableResponsive({
  dataTable,
  dataTableCompact,
  tableLayoutBreakpoint,
  ...rest
}: DataTableResponsiveProps): React.ReactElement {
  const { theme } = useTheme();
  const { layout } = useBreakpointLayout(theme, tableLayoutBreakpoint);
  if (!layout) return <></>;
  return (
    <Box {...rest}>{layout === "table" ? dataTable : dataTableCompact}</Box>
  );
}

export default DataTableResponsive;
