import * as React from "react";

import { Box, Interpose, Space, XYGrid } from "../layout";
import {
  AlignItems,
  JustifyContent,
  JustifyItems,
  TypePreset,
  TypeScale,
  useTheme,
} from "../theme";
import { H4 } from "../typography";

import {
  BannerActionProps,
  BannerCloseActionProps,
  BannerLayout,
  BannerLeftAccessoryProps,
} from "./types";
import { bannerActionsLayout, bannerStyle, BannerThemingProps } from "./styles";
import PrimaryAction from "./PrimaryAction";
import SecondaryAction from "./SecondaryAction";
import CloseAction from "./CloseAction";
import { BannerLeftAccessory } from "./BannerLeftAccessory";
import { getGridTemplateAreas } from "./getGridTemplateAreas";

interface BannerStyleProps extends BannerThemingProps {
  /**
   * The title that will be shown on the notification.
   */
  title?: React.ReactNode;

  /** Configures a close action that will be displayed on the banner. */
  closeAction?: BannerCloseActionProps;

  /**
   * Configures an optional, primary action to show with the toast message.
   * This action can be either a link or a button.
   */
  primaryAction?: BannerActionProps;

  /**
   * Configures an optional, secondary action to show with the toast message.
   * This action can be either a link or a button.
   */
  secondaryAction?: BannerActionProps;

  /** Configures the banner layout. */
  layout?: BannerLayout;

  /** Configures the left accessory of the banner. */
  leftAccessory?: BannerLeftAccessoryProps;
}

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof BannerStyleProps
>;

export interface BannerProps extends HTMLAttributes, BannerStyleProps {}

const omitBannerProps = <Props extends BannerStyleProps>(
  props: Props
): Omit<Props, keyof BannerStyleProps> => {
  const {
    closeAction,
    primaryAction,
    secondaryAction,
    leftAccessory,
    title,
    variant,
    backgroundColor,
    elevation,
    layout,
    ...rest
  } = props;

  return rest;
};

const Banner = React.forwardRef<HTMLDivElement, BannerProps>((props, ref) => {
  const { theme } = useTheme();

  const {
    id,
    title,
    children,
    primaryAction,
    secondaryAction,
    closeAction,
    variant,
    layout,
    leftAccessory,
  } = props;
  const showActions = !!(primaryAction || secondaryAction);

  const role = closeAction ? "alertdialog" : "alert";
  const headingId = id ? `${id}_heading` : "";

  const isCloseable = !!closeAction;
  const closeWidth = closeAction ? "auto" : "";

  const isHorizontal = layout === BannerLayout.Horizontal;
  const hasLeftAccessory = !!leftAccessory;
  const templateColumns = [
    `auto auto`,
    null,
    `max-content 1fr ${isHorizontal ? "max-content" : ""} ${closeWidth}`,
  ];

  const closeActionWrapperAlignItems = [
    AlignItems.Start,
    AlignItems.Start,
    isHorizontal
      ? AlignItems.Center // Align the close button with the action or left accessory
      : AlignItems.Start,
  ];

  return (
    <div
      css={bannerStyle(theme, props)}
      aria-live="off"
      aria-labelledby={headingId}
      {...omitBannerProps(props)}
      ref={ref}
      role={role}
      id={id}
    >
      <XYGrid
        templateAreas={getGridTemplateAreas({
          isCloseable,
          isHorizontal,
          leftAccessoryType: leftAccessory?.type,
          showActions,
        })}
        templateColumns={templateColumns}
        columnGap={hasLeftAccessory ? 1 : 0}
        rowGap={1}
      >
        <Box
          gridArea="body"
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Center}
        >
          <Interpose node={<Space layout="block" v={0.25} />}>
            {title && (
              <H4
                id={headingId}
                preset={TypePreset.Heading_02}
                size={TypeScale.Size_03}
              >
                {title}
              </H4>
            )}
            {children && <Box>{children}</Box>}
          </Interpose>
        </Box>
        {showActions ? (
          <Box gridArea="actions" css={bannerActionsLayout(theme)}>
            <XYGrid
              rowGap={1}
              columnGap={1}
              justifyItems={JustifyItems.Start}
              alignItems={AlignItems.Center}
              templateColumns={["auto", null, "max-content  max-content"]}
            >
              <PrimaryAction value={primaryAction} variant={variant} />
              <SecondaryAction
                value={secondaryAction}
                variant={variant}
                prioritized={!primaryAction}
              />
            </XYGrid>
          </Box>
        ) : null}

        {closeAction ? (
          <Box
            gridArea="close"
            layout="flex"
            justifyContent={JustifyContent.FlexEnd}
            alignItems={closeActionWrapperAlignItems}
          >
            <CloseAction
              label={closeAction.label}
              onClose={closeAction.onClose}
              variant={variant}
              wrapperAlignItems={closeActionWrapperAlignItems}
            />
          </Box>
        ) : null}
        {leftAccessory ? (
          <BannerLeftAccessory
            variant={variant}
            leftAccessory={leftAccessory}
            isVerticallyCentered={isHorizontal}
          />
        ) : null}
      </XYGrid>
    </div>
  );
});

export default Banner;
