import {
  CSSRulesFunction,
  FontWeight,
  TypographyStyleProps,
  useTheme,
} from "../theme";
import {
  omitTypographyStyleProps,
  typographyStyle,
} from "../typography/appearance";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLLegendElement>,
  keyof TypographyStyleProps | "children"
>;

export interface LegendProps extends HTMLAttributes, TypographyStyleProps {
  children: React.ReactNode;
}

const legendStyle: CSSRulesFunction<TypographyStyleProps> = (theme, props) => {
  return [
    {
      fontWeight: FontWeight.SemiBold,
      display: "block",
      padding: 0,
      margin: 0,
    },
    typographyStyle(theme, props),
  ];
};

const Legend: React.FC<LegendProps> = (props) => {
  const { theme } = useTheme();
  return (
    <legend
      css={legendStyle(theme, props)}
      {...omitTypographyStyleProps(props)}
    >
      {props.children}
    </legend>
  );
};

export default Legend;
