import { ButtonGutter, ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { ButtonSize, ColorScheme, FocusRing } from "../theme";

export interface TagRemoveActionProps {
  /**
   * The label to set on the close button. It needs to be defined especially
   * to support assistive technologies like screen readers. This prop accepts
   * localized strings.
   */
  label: React.ReactNode;

  /**
   * A click handler that is triggered when the close button is clicked.
   */
  onClose: () => void;
}

const RemoveAction: React.FC<{
  colorScheme: ColorScheme;
  label: React.ReactNode;
  onClose(): void;
}> = (props) => {
  const { label, onClose: onClick, colorScheme } = props;
  const btnProps = {
    label,
    onClick,
    icon: Glyph.Close,
    size: { base: ButtonSize.Sm, gutters: ButtonGutter.Sm },
  };

  const shift = `calc(0 * var(--btn-gutter-${btnProps.size.base}-${btnProps.size.gutters}))`;
  const margin = `${shift} ${shift} 0 0`;

  const variant =
    colorScheme === ColorScheme.OnDark
      ? ButtonVariant.TextOnLight
      : ButtonVariant.TextOnDark;

  return (
    <IconButton
      {...btnProps}
      css={{ margin }}
      variant={variant}
      focusRing={FocusRing.Inner}
    />
  );
};

export default RemoveAction;
