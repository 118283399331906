import { useMfaEnforcementEnforce } from "@gocardless/api/dashboard/mfa-enforcement";
import { useLocalStorage } from "react-use";

import { useCurrentAuthFactor } from "src/components/routes/Settings/mfa/useCurrentAuthFactor";

export const MFA_DELAYED_ITEM_KEY = "mfaSkippedOnDate";

interface MFAReturnData {
  mfaEnforced: boolean;
  mfaEnforceDueDays: number | undefined;
  mfaEnforcedByOrg: boolean;
  onSkipMFASetup: () => void;
  skipMFASetupScreen: boolean;
  refetchData: () => void;
}

interface UseMFAEnforcementDataType {
  data: MFAReturnData;
  isLoading: false;
}
interface UseMFAEnforcementDataLoadingType {
  data: undefined;
  isLoading: true;
}

type UseMFAEnforcementType =
  | UseMFAEnforcementDataType
  | UseMFAEnforcementDataLoadingType;

export const isMFAEnforcementDataLoaded = (
  props: UseMFAEnforcementType
): props is UseMFAEnforcementDataType => !props.isLoading;

export const showMFAEnforcementDelayedBanner = (
  props: UseMFAEnforcementType
): boolean =>
  !props.data?.mfaEnforced &&
  typeof props.data?.mfaEnforceDueDays === "number" &&
  props.data.mfaEnforceDueDays > 0;

export const useMFAEnforcementData = (): UseMFAEnforcementType => {
  const {
    data,
    isValidating,
    mutate: refetchData,
  } = useMfaEnforcementEnforce();
  const { currentAuthId, loading: authFactorLoading } = useCurrentAuthFactor();

  const hasMFA = !authFactorLoading && !!currentAuthId;
  const mfaLocalStorageItem = localStorage.getItem(MFA_DELAYED_ITEM_KEY);
  const [mfaSkippedOnDate, setMfaDelayedOnDate] = useLocalStorage(
    MFA_DELAYED_ITEM_KEY,
    mfaLocalStorageItem
  );

  const today = new Date().toLocaleDateString();
  const isLoading = (!data?.data && isValidating) || authFactorLoading;
  const mfaEnforced = !!data?.data?.enforce_mfa;
  const mfaEnforceDueDays = data?.data?.mfa_enforcement_due_days;
  const mfaWillBeEnforced = typeof mfaEnforceDueDays === "number";
  const mfaEnforcedByOrg =
    !!data?.data?.enforced_by && data.data.enforced_by !== "gocardless";

  const skipMFASetupScreen =
    // IF: MFA is enforced AND user has already set-up MFA
    (mfaEnforced && hasMFA) ||
    // OR IF: MFA is not enforced AND the user has already bypassed the set-up screen today.
    (!mfaEnforced && mfaWillBeEnforced && mfaSkippedOnDate === today) ||
    // OR IF: MFA will not be enforced (and is finished loading).
    (!mfaEnforced && !mfaWillBeEnforced);

  const handleSkipScreen = () => {
    if (!mfaEnforced) {
      if (mfaWillBeEnforced) {
        setMfaDelayedOnDate(today);
      }

      window.location.replace("/");
    }
  };

  if (isLoading) {
    return {
      data: undefined,
      isLoading: true,
    };
  }

  return {
    data: {
      mfaEnforced,
      mfaEnforceDueDays: mfaEnforceDueDays ?? undefined,
      mfaEnforcedByOrg,
      onSkipMFASetup: handleSkipScreen,
      skipMFASetupScreen,
      refetchData,
    },
    isLoading: false,
  };
};
