import type { Interpolation } from "../theme";

const visibleStyle: Interpolation = {
  display: "unset",
  visibility: "visible",
  opacity: 1,
};
const hidingStyle: Interpolation = {
  display: "unset",
  visibility: "visible",
  opacity: 0,
};
const hiddenStyle: Interpolation = {
  display: "none",
  visibility: "hidden",
  opacity: 0,
};

export const fadeStyle: Interpolation = {
  transition: "opacity 200ms",
  "@media (prefers-reduced-motion: reduce)": {
    transition: "opacity 50ms",
  },

  "&": hiddenStyle,

  "&.fade-enter": hidingStyle,
  "&.fade-enter-active": visibleStyle,
  "&.fade-enter-done": visibleStyle,

  "&.fade-exit": visibleStyle,
  "&.fade-exit-active": hidingStyle,
  "&.fade-exit-done": hiddenStyle,
};
