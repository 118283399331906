import * as React from "react";

import { ResponsiveValue, CSSRulesFunction, useTheme } from "../theme";

type VisibilityValue = "none" | "block" | "inline-block";

type HTMLAttributes = React.HTMLAttributes<HTMLSpanElement>;

export interface VisibilityProps extends HTMLAttributes {
  visible: ResponsiveValue<VisibilityValue>;
}

export const visibilityStyle: CSSRulesFunction<VisibilityProps> = (
  theme,
  props
) => theme.responsive(props.visible, (display) => ({ display }));

const Visibility = React.forwardRef<HTMLSpanElement, VisibilityProps>(
  function Visibility(props, ref) {
    const { theme } = useTheme();
    const { visible, ...rest } = props;
    return <span css={visibilityStyle(theme, props)} {...rest} ref={ref} />;
  }
);

export default Visibility;
