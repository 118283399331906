import type * as React from "react";

import type { Interpolation } from "../theme";

type HTMLAttributes = React.HTMLAttributes<HTMLTableSectionElement>;

export interface TableHeadProps extends HTMLAttributes {
  children?: React.ReactNode;
}

export const tableHeadStyle: Interpolation = {
  "> tr:last-child": {
    "> td, > th": {
      borderBottom: "1px solid var(--table-border-color)",
    },
  },

  "&:last-child > tr:last-child": {
    "> td, > th": {
      borderBottom: "none",
    },
  },

  "> tr > td + td, > tr > th + td, > tr > td + th, > tr > th + th": {
    borderLeft: "1px solid var(--table-border-color)",
  },
};

const TableHead: React.FC<TableHeadProps> = (props) => {
  return <thead css={tableHeadStyle} {...props} />;
};

export default TableHead;
