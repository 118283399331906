import React from "react";

const isInBrowser = () => {
  return (
    typeof window !== "undefined" && typeof window.document !== "undefined"
  );
};

/**
 * When React.useLayoutEffect is invoked during server-side rendering or static
 * generation, it causes a warning to be printed out. This can get noisy so
 * let's instead use React.useEffect when React is running on the server and
 * React.useLayoutEffect on the browser.
 */
export const useIsomorphicLayoutEffect = isInBrowser()
  ? React.useLayoutEffect
  : React.useEffect;
