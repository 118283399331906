import type * as React from "react";

import { PlainButton, PlainLink } from "../../buttons";
import type { PlainButtonStyleProps } from "../../buttons/types";
import { Box, XYGrid } from "../../layout";
import {
  AlignItems,
  ColorPreset,
  FontWeight,
  HoverEffect,
  JustifyContent,
  TypeScale,
  useTheme,
} from "../../theme";
import LI from "../LI";

import { dynamicListItemStyle, plainActionStyle } from "./styles";
import type { DynamicListItemAction, DynamicListItemProps } from "./types";

export const Action = ({ action }: { action: DynamicListItemAction }) => {
  const styleProps: PlainButtonStyleProps = {
    effect: HoverEffect.TextDecoration,
    textDecoration: "underline",
    weight: FontWeight.Bold,
  };
  return "href" in action ? (
    <PlainLink
      data-dynamiclist-item-primary
      {...action}
      {...styleProps}
      css={plainActionStyle}
    />
  ) : (
    <PlainButton
      data-dynamiclist-item-primary
      {...action}
      {...styleProps}
      css={plainActionStyle}
    />
  );
};

const DynamicListItem: React.FC<DynamicListItemProps> = (props) => {
  const { theme } = useTheme();
  const { leadingElement, moreActions, trailingElement } = props;

  const actionsArea = moreActions ? "actions" : "";
  const headerArea = `'lead ${actionsArea || "lead"}'`;
  const leadArea = leadingElement ? "lead" : "";
  const gridAreas = [
    `
      ${leadingElement ? headerArea : ""}
      'body ${!leadingElement && !!actionsArea ? actionsArea : "body"}'
      ${trailingElement ? "'trail trail'" : ""}
    `,
    null,
    `
      '${leadArea} body ${trailingElement ? "trail" : ""} ${actionsArea}'
    `,
  ];
  const leadColumn = leadArea ? "max-content" : "";
  const trailingColumn = trailingElement ? "auto" : "";
  const actionsColumn = actionsArea ? "auto" : "";
  const gridColumns = [
    "1fr auto",
    null,
    `${leadColumn} minmax(0, 1fr) ${trailingColumn} ${actionsColumn}`,
  ];

  const actionsAlign = leadingElement
    ? AlignItems.Center
    : [AlignItems.Start, null, AlignItems.Center];

  return (
    <LI id={props.id} marker="none" css={[dynamicListItemStyle(theme)]}>
      <XYGrid
        templateAreas={gridAreas}
        templateColumns={gridColumns}
        columnGap={1}
        rowGap={1}
      >
        {!!leadingElement && (
          <Box gridArea="lead" layout="flex" alignItems={AlignItems.Center}>
            {leadingElement}
          </Box>
        )}

        <Box
          gridArea="body"
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.Center}
          css={{ gap: theme.spacing(0.25) }}
        >
          <Box
            layout="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems={AlignItems.Baseline}
            css={{ gap: theme.spacing(0.5) }}
          >
            <Action action={props.primaryAction} />
            {!!props.tags &&
              props.tags.map((tag, i) => {
                return <span key={`${props.id}_tag_${i}`}>{tag}</span>;
              })}
          </Box>
          {!!props.children && (
            <Box
              fontSize={TypeScale.Size_02}
              color={ColorPreset.TextOnLight_03}
            >
              {props.children}
            </Box>
          )}
        </Box>

        {!!trailingElement && (
          <Box
            gridArea="trail"
            layout="flex"
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
            fontSize={TypeScale.Size_02}
            color={ColorPreset.TextOnLight_03}
          >
            {trailingElement}
          </Box>
        )}

        {!!moreActions && (
          <Box
            gridArea="actions"
            justifySelf={JustifyContent.End}
            layout="flex"
            alignItems={actionsAlign}
          >
            {moreActions}
          </Box>
        )}
      </XYGrid>
    </LI>
  );
};

export default DynamicListItem;
