import {
  Color,
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  Theme,
} from "../../theme";

import { controlStyle } from "./controlStyle";
import {
  ToggleStyleProps,
  ToggleVariant,
  ToggleControlPosition,
  ControlVar,
  ToggleControl,
} from "./toggleTypes";

enum GeometryVar {
  gutterV = "--toggle-gutterv",
  gutterH = "--toggle-gutterh",
  itemSpacing = "--toggle-item-spacing",
  defaultBorderWidth = "--toggle-default-border-width",
  hoverBorderWidth = "--toggle-hover-border-width",
  focusedBorderWidth = "--toggle-focused-border-width",
  borderRadius = "--toggle-border-radius",
}

enum ColorVar {
  defaultBg = "--toggle-default-bg",
  disabledBg = "--toggle-disabled-bg",

  labelColor = "--toggle-label-color",
  descriptionColor = "--toggle-description-color",

  borderDefaultColor = "--toggle-border-default-color",
  borderFocusColor = "--toggle-border-focus-color",
  borderHoverColor = "--toggle-border-hover-color",
  borderCheckedColor = "--toggle-border-checked-color",
  borderDisabledColor = "--toggle-border-disabled-color",
}

export enum Var {
  bg = "--toggle-bg",
  borderWidth = "--toggle-border-width",
  borderColor = "--toggle-border-color",
  boxShadow = "--toggle-box-shadow",
}

type GeometryConfig = Record<GeometryVar, string | number>;
type ColorConfig = Record<ColorVar, string | number>;

const toggleGeometryStyle: CSSRulesFunction<ToggleVariant> = (
  theme,
  variant
) => {
  const compact: GeometryConfig = {
    [GeometryVar.gutterV]: 0,
    [GeometryVar.gutterH]: 0,
    [GeometryVar.itemSpacing]: theme.spacing(1.5),
    [GeometryVar.borderRadius]: theme.radius(1),
    [GeometryVar.defaultBorderWidth]: 0,
    [GeometryVar.hoverBorderWidth]: 0,
    [GeometryVar.focusedBorderWidth]: 0,
  };

  const emphasized: GeometryConfig = {
    [GeometryVar.gutterV]: theme.spacing(1.5),
    [GeometryVar.gutterH]: theme.spacing(1.5),
    [GeometryVar.itemSpacing]: theme.spacing(1.5),
    [GeometryVar.borderRadius]: theme.radius(1),
    [GeometryVar.defaultBorderWidth]: "1px",
    [GeometryVar.hoverBorderWidth]: "2px",
    [GeometryVar.focusedBorderWidth]: "2px",
  };

  switch (variant) {
    case ToggleVariant.ControlOnly:
    case ToggleVariant.Compact:
      return compact;
    case ToggleVariant.Emphasized:
      return emphasized;
  }
};

type ColorsFunc = (theme: Theme) => ColorConfig | null;
/**
 * Utility function that lazily evaluates color scheme values for each toggle
 * variant.
 *
 * @param light A function that returns a config config for light color scheme
 * @param dark A function that returns a config config for dark color scheme
 * @returns A CSS rules function to generate the necessary color scheme style
 * rules
 */
const configureColors = (
  light: ColorsFunc,
  dark: ColorsFunc
): CSSRulesFunction<ColorScheme> => {
  return (theme, cs) => {
    switch (cs) {
      case ColorScheme.OnLight:
        return light(theme);
      case ColorScheme.OnDark:
        return dark(theme);
      case ColorScheme.Auto:
        return [
          light(theme),
          { "@media (prefers-color-scheme: dark)": dark(theme) },
        ];
    }
  };
};

const toggleColorStyle: CSSRulesFunction<ToggleStyleProps> = (theme, props) => {
  switch (props.variant) {
    case ToggleVariant.Emphasized:
      return configureColors(
        (t) => ({
          [ColorVar.defaultBg]: t.color(ColorPreset.BackgroundLight_03),
          [ColorVar.disabledBg]: t.color(Color.Greystone_700_A38),

          [ColorVar.labelColor]: t.color(ColorPreset.TextOnLight_01),
          [ColorVar.descriptionColor]: t.color(ColorPreset.TextOnLight_02),

          [ColorVar.borderDefaultColor]: t.color(ColorPreset.BorderOnLight_03),
          [ColorVar.borderFocusColor]: t.color(Color.Greystone_DarkMatter),
          [ColorVar.borderCheckedColor]: t.color(Color.Greystone_DarkMatter),
          [ColorVar.borderHoverColor]: t.color(ColorPreset.BorderOnLight_01),
          [ColorVar.borderHoverColor]: t.color(Color.Greystone_1200),
          [ColorVar.borderDisabledColor]: t.color(Color.Greystone_1400_A38),
        }),
        (t) => ({
          [ColorVar.defaultBg]: t.color(ColorPreset.BackgroundDark_03),
          [ColorVar.disabledBg]: t.color(Color.Greystone_700_A16),

          [ColorVar.labelColor]: t.color(ColorPreset.TextOnDark_01),
          [ColorVar.descriptionColor]: t.color(ColorPreset.TextOnDark_02),

          [ColorVar.borderDefaultColor]: t.color(ColorPreset.BorderOnDark_03),
          [ColorVar.borderFocusColor]: t.color(Color.Greystone_50),
          [ColorVar.borderCheckedColor]: t.color(Color.Greystone_50),
          [ColorVar.borderHoverColor]: t.color(Color.Brownstone_400),
          [ColorVar.borderDisabledColor]: t.color(Color.Greystone_700_A38),
        })
      )(theme, props.colorScheme);
    case ToggleVariant.ControlOnly:
    case ToggleVariant.Compact:
      return configureColors(
        (t) => ({
          [ColorVar.defaultBg]: "transparent",
          [ColorVar.disabledBg]: "transparent",

          [ColorVar.labelColor]: t.color(ColorPreset.TextOnLight_01),
          [ColorVar.descriptionColor]: t.color(ColorPreset.TextOnLight_02),

          [ColorVar.borderDefaultColor]: "transparent",
          [ColorVar.borderFocusColor]: "transparent",
          [ColorVar.borderCheckedColor]: "transparent",
          [ColorVar.borderHoverColor]: "transparent",
          [ColorVar.borderDisabledColor]: "transparent",
        }),
        (t) => ({
          [ColorVar.defaultBg]: "transparent",
          [ColorVar.disabledBg]: "transparent",

          [ColorVar.labelColor]: t.color(ColorPreset.TextOnDark_01),
          [ColorVar.descriptionColor]: t.color(ColorPreset.TextOnDark_02),

          [ColorVar.borderDefaultColor]: "transparent",
          [ColorVar.borderFocusColor]: "transparent",
          [ColorVar.borderCheckedColor]: "transparent",
          [ColorVar.borderHoverColor]: "transparent",
          [ColorVar.borderDisabledColor]: "transparent",
        })
      )(theme, props.colorScheme);
  }
};

export const toggleBodyStyle: CSSRulesFunction<
  ToggleStyleProps & {
    hasMedia: boolean;
  }
> = (theme, props) => {
  // We need to dynamically set up the grid areas and template columns based on
  // whether or not there is media being shown and the position of the control.

  const { controlPosition, hasMedia, control } = props;

  const outlineWidth = `calc(var(${ControlVar.OutlineOn}) * 1px)`;

  // For predictable styling, ensure these selectors have the same specificity.
  //
  // Use a tool like Specificity Calculator (https://specificity.keegan.st/) to
  // calculate the specificity of each selector. Replace every instance of '&'
  // with '.blah' e.g. '&&' becomes '.blah.blah' to get a correct calculation.
  const selectors = {
    selectedSelector: "input:checked ~ &&",
    hoverUnselectedSelector: "input:not(:checked) ~ &:hover",
    hoverSelectedSelector: "input:checked ~ &:hover",
    focusSelector: "input:focus-visible ~ &&",
    focusSelectedSelector: "input:checked:focus-visible ~ &&",
    disabledSelector: "input:disabled ~ &&",
    disabledUnselectedSelector: "input:not(:checked):disabled ~ &&",

    // additional selectors to accommodate styling the toggles from the parent
    hoverUnselectedToggleSelector:
      "input:not(:checked) ~ &:hover > div > span[role=presentation]",
    hoverUnselectedDisabledToggleSelector:
      "input:disabled:not(:checked) ~ &:hover > div > span[role=presentation]",
    focusUnselectedToggleSelector:
      "input:focus-visible ~ && > div > span[role=presentation]",
    focusSelectedToggleSelector:
      "input:checked:focus-visible ~ && > div > span[role=presentation]",
    focusUnselectedToggleKnobSelector:
      "input:focus-visible ~ && > div > span[role=presentation]:after",
    focusSelectedToggleKnobSelector:
      "input:checked:focus-visible ~ && > div > span[role=presentation]:after",
  };

  const alignItems =
    hasMedia ||
    controlPosition === ToggleControlPosition.End ||
    control === "switch"
      ? "center"
      : "baseline";

  const areas = [hasMedia ? "media" : null, "content"];
  if (controlPosition === ToggleControlPosition.End) {
    areas.push("control");
  } else {
    areas.unshift("control");
  }
  const gridTemplateAreas = `'${areas.filter(Boolean).join(" ")}'`;

  const templateCols = [hasMedia ? "max-content" : null, "auto"];
  if (controlPosition === ToggleControlPosition.End) {
    templateCols.push("max-content");
  } else {
    templateCols.unshift("max-content");
  }
  const gridTemplateColumns = templateCols.filter(Boolean).join(" ");

  const isSwitch = props.control === ToggleControl.Switch;
  const isRadio = props.control === ToggleControl.Radio;

  return [
    controlStyle(theme, {
      colorScheme: props.colorScheme,
      control: props.control,
      ...selectors,
    }),
    {
      [Var.bg]: `var(${ColorVar.defaultBg})`,
      [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
      [Var.borderColor]: `var(${ColorVar.borderDefaultColor})`,
      [ControlVar.On]: 0,
      [ControlVar.OutlineOn]: isRadio ? 1 : 0,

      [selectors.focusSelector]: {
        [Var.borderColor]: `var(${ColorVar.borderFocusColor})`,
        [Var.borderWidth]: `var(${GeometryVar.focusedBorderWidth})`,
        [ControlVar.OutlineOn]: 1,
      },

      [selectors.selectedSelector]: {
        [Var.borderColor]: `var(${ColorVar.borderCheckedColor})`,
        [ControlVar.On]: 1,
        [ControlVar.OutlineOn]: isRadio ? 6 : 0,
      },

      [`${selectors.hoverSelectedSelector}, ${selectors.hoverUnselectedSelector}`]:
        {
          [Var.borderColor]: `var(${ColorVar.borderHoverColor})`,
          [Var.borderWidth]: `var(${GeometryVar.hoverBorderWidth})`,
        },

      [selectors.disabledSelector]: {
        [Var.bg]: `var(${ColorVar.disabledBg})`,
        [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
        [Var.borderColor]: `var(${ColorVar.borderDisabledColor})`,
      },

      // Only show focus indicators when use keyboard (Tab) for navigation
      ".js-focus-visible input:focus:not(.focus-visible) ~ &": isRadio
        ? {
            [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
          }
        : {
            [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
            [ControlVar.OutlineOn]: 0,
          },
      "input:focus:not(:focus-visible) ~ &": isRadio
        ? {
            [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
          }
        : {
            [Var.borderWidth]: `var(${GeometryVar.defaultBorderWidth})`,
            [ControlVar.OutlineOn]: 0,
          },
    },

    isSwitch
      ? {
          [selectors.focusUnselectedToggleSelector]: {
            [Var.boxShadow]: `
              0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
              0px 0px 0px 5px var(${ControlVar.BorderColor})
            `,
          },

          [selectors.focusUnselectedToggleKnobSelector]: {
            [Var.boxShadow]: `
              0px 0px 0px 2px var(${ControlVar.BorderColor})
            `,
          },

          [selectors.focusSelectedToggleKnobSelector]: {
            [Var.boxShadow]: `
              inset 0px 0px 0px 2px var(${ControlVar.BorderColor})
            `,
          },
        }
      : {
          [selectors.hoverUnselectedToggleSelector]: {
            [Var.boxShadow]: `
              inset 0 0 0 2px var(${ControlVar.BorderColor}),
              0 0 0 ${outlineWidth} var(${ControlVar.OutlineColor})
            `,
          },
          [selectors.hoverUnselectedDisabledToggleSelector]: {
            [Var.boxShadow]: `
              inset 0 0 0 1px var(${ControlVar.BorderColor}),
              0 0 0 ${outlineWidth} var(${ControlVar.OutlineColor})
            `,
          },

          [selectors.focusUnselectedToggleSelector]: {
            [Var.boxShadow]: `
            0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
            0px 0px 0px 5px var(${ControlVar.BorderColor}), 
            inset 0px 0px 0px 1px var(${ControlVar.BorderColor});
          `,
          },

          [selectors.focusSelectedToggleSelector]: {
            [Var.boxShadow]: `
            0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
            0px 0px 0px 5px var(${ControlVar.BorderColor}), 
            inset 0px 0px 0px 1px var(${ControlVar.BorderColor});
          `,
          },
        },

    isRadio && {
      [selectors.focusSelectedToggleSelector]: {
        [Var.boxShadow]: `
            0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
            0px 0px 0px 4px var(${ControlVar.BorderColor}), 
            inset 0px 0px 0px 5px var(${ControlVar.BorderColor});
          `,
      },
    },

    {
      padding: `var(${GeometryVar.gutterV}) var(${GeometryVar.gutterH})`,
      borderRadius: `var(${GeometryVar.borderRadius})`,
      width: "100%",
      display: "inline-grid",
      alignItems,
      gap: `var(${GeometryVar.itemSpacing})`,
      gridTemplateAreas,
      gridTemplateColumns,
      boxShadow: `inset 0 0 0 var(${Var.borderWidth}) var(${Var.borderColor})`,
      backgroundColor: `var(${Var.bg})`,
      cursor: "pointer",
      color: `var(${ColorVar.labelColor})`,
      transition: "box-shadow 200ms",
      "input:disabled ~ &": {
        cursor: "not-allowed",
      },
      [`${selectors.focusUnselectedToggleSelector}, ${selectors.focusSelectedToggleSelector}`]:
        {
          boxShadow: `var(${Var.boxShadow})`,
        },
      [selectors.hoverUnselectedToggleSelector]: {
        boxShadow: `var(${Var.boxShadow})`,
      },
      [selectors.focusUnselectedToggleKnobSelector]: {
        boxShadow: `var(${Var.boxShadow})`,
      },
      [selectors.focusSelectedToggleKnobSelector]: {
        boxShadow: `var(${Var.boxShadow})`,
      },
    },

    props.variant === ToggleVariant.ControlOnly
      ? {
          gap: 0,
          width: "auto",
        }
      : null,
  ];
};

export const toggleInputStyle: Interpolation = {
  width: "1px",
  height: "1px",
  opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
};

export const toggleLabelStyle: Interpolation = {
  fontWeight: FontWeight.SemiBold,
};

export const toggleDescriptionStyle: Interpolation = {
  color: `var(${ColorVar.descriptionColor})`,
};

export const toggleStyle: CSSRulesFunction<ToggleStyleProps> = (
  theme,
  props
) => {
  return [
    toggleGeometryStyle(theme, props.variant),
    toggleColorStyle(theme, props),
    {
      display:
        props.variant === ToggleVariant.ControlOnly ? "inline-block" : "block",
      position: "relative",
    },
  ];
};

export const checkboxesToggleStyle: CSSRulesFunction<ToggleStyleProps> = (
  theme,
  props
) => {
  const selectors = {
    focusSelector: "&:focus-visible",
    selectedSelector: "&[aria-checked=true], &[aria-checked=mixed]",
    disabledSelector: "&:disabled",
    disabledUnselectedSelector: "&:not(:checked):disabled",
    hoverUnselectedSelector: "&:hover[aria-checked=false]",
    hoverSelectedSelector:
      "&:hover[aria-checked=true], &:hover[aria-checked=mixed]",

    focusSelectedSelector: "input:checked:focus ~ &:hover",

    // additional selectors to accommodate styling the toggles from the parent
    focusSelectedToggleSelector:
      "&:focus[aria-checked=true] > div > span[role=presentation], &:focus[aria-checked=mixed] > div > span[role=presentation]",
    focusUnselectedToggleSelector: "&:focus > div > span[role=presentation]",
  };

  return [
    toggleGeometryStyle(theme, props.variant),
    toggleColorStyle(theme, props),
    controlStyle(theme, {
      colorScheme: props.colorScheme,
      control: ToggleControl.Check,
      ...selectors,
    }),
    {
      padding: 0,
      background: "none",
      border: "none",
      display: "inline-grid",
      alignItems: "baseline",
      gridAutoFlow: "column",
      gridTemplate: "max-content auto",
      columnGap: `var(${GeometryVar.itemSpacing})`,
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: FontWeight.SemiBold,
      cursor: "pointer",
      color: `var(${ColorVar.labelColor})`,

      [ControlVar.On]: 0,
      [ControlVar.OutlineOn]: 0,

      [selectors.focusSelector]: {
        [ControlVar.OutlineOn]: 1,
        outline: "none",
      },

      [selectors.selectedSelector]: {
        [ControlVar.On]: 1,
      },

      [selectors.disabledSelector]: {
        cursor: "not-allowed",
      },

      [selectors.focusUnselectedToggleSelector]: {
        [Var.boxShadow]: `
        0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
        0px 0px 0px 5px var(${ControlVar.BorderColor}), 
        inset 0px 0px 0px 1px var(${ControlVar.BorderColor});
      `,
      },

      [selectors.focusSelectedToggleSelector]: {
        [Var.boxShadow]: `
        0px 0px 0px 1px var(${ControlVar.OutlineColor}), 
        0px 0px 0px 5px var(${ControlVar.BorderColor}), 
        inset 0px 0px 0px 1px var(${ControlVar.BorderColor});
      `,
      },

      // Only show focus indicators when use keyboard (Tab) for navigation
      ".js-focus-visible input:focus:not(.focus) ~ &": {
        [ControlVar.OutlineOn]: 0,
      },
      "input:focus-visible:not(:focus) ~ &": {
        [ControlVar.OutlineOn]: 0,
      },

      "&:focus-visible[aria-checked=true] > div > span, &:focus-visible[aria-checked=mixed] > div > span, &:focus-visible > div > span":
        {
          boxShadow: `var(${Var.boxShadow})`,
          backgroundColor:
            props.colorScheme === ColorScheme.OnDark
              ? theme.color(Color.Brownstone_50)
              : theme.color(Color.Greystone_1400),
        },

      "&:focus-visible > div > span[role=presentation]": {
        backgroundColor: "transparent",
      },
    },
  ];
};
