import type { ResponsiveValue, SpaceScale, CSSRulesFunction } from "../theme";

export type ListMarker = "decimal" | "disc" | "circle" | "none";

export interface AppearanceProps {
  marker?: ListMarker;
  spaceAbove?: ResponsiveValue<SpaceScale>;
  spaceBelow?: ResponsiveValue<SpaceScale>;
  spaceBetween?: ResponsiveValue<SpaceScale>;
  gutterH?: ResponsiveValue<SpaceScale>;
}

export const omitAppearanceProps = <Props extends AppearanceProps>(
  props: Props
): Omit<Props, keyof AppearanceProps> => {
  const { marker, spaceAbove, spaceBelow, spaceBetween, gutterH, ...rest } =
    props;
  return rest;
};

export const listStyle: CSSRulesFunction<AppearanceProps> = (theme, props) => {
  const { gutterH, marker, spaceAbove, spaceBelow, spaceBetween } = props;
  return [
    { margin: 0, listStyleType: marker },
    theme.responsive(gutterH, (v) => ({ paddingLeft: theme.spacing(v) })),
    theme.responsive(spaceAbove, (v) => ({ marginTop: theme.spacing(v) })),
    theme.responsive(spaceBelow, (v) => ({
      marginBottom: theme.spacing(v),
    })),
    theme.responsive(spaceBetween, (v) => ({
      "li+li": { marginTop: theme.spacing(v) },
    })),
  ];
};
