import {
  Button,
  ButtonLayout,
  IconButton,
  IconLink,
  Link,
  PlainButton,
  PlainLink,
} from "../buttons";
import { FontWeight, HoverEffect, TypeScale } from "../theme";

import {
  CardButtonVariant,
  CardActionProps,
  CardActionType,
} from "./cardTypes";
import { convertButtonVariant, sharedButtonProps } from "./cardUtils";

export interface FooterActionProps {
  value?: CardActionProps;
  isNarrow?: boolean;
}
const FooterAction: React.FC<FooterActionProps> = (props) => {
  if (props.value == null) {
    return null;
  }

  if (
    props.value.control === CardActionType.PlainButton ||
    props.value.control === CardActionType.PlainLink
  ) {
    return <CardFooterAction {...props.value} />;
  } else {
    return (
      <CardFooterAction
        {...props.value}
        variant={props.value.buttonVariant}
        isNarrow={props.isNarrow}
      />
    );
  }
};

const CardFooterAction: React.FC<
  CardActionProps & { variant?: CardButtonVariant; isNarrow?: boolean }
> = (props) => {
  if (props == null) {
    return null;
  }

  const btnVariant = convertButtonVariant(props.variant);
  const buttonLayout = props.isNarrow
    ? ButtonLayout.Full
    : [ButtonLayout.Full, ButtonLayout.Inline];

  if (props.control === CardActionType.Link) {
    const { buttonVariant, variant, control, layout, isNarrow, ...rest } =
      props;
    return (
      // Unnecessarily noisy lint rule in this instance since we are forwarding
      // props in this components.
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <Link
        {...rest}
        {...sharedButtonProps}
        variant={btnVariant}
        layout={layout || buttonLayout}
      />
    );
  } else if (props.control === CardActionType.IconLink) {
    const { buttonVariant, variant, control, isNarrow, ...rest } = props;
    return <IconLink {...rest} {...sharedButtonProps} variant={btnVariant} />;
  } else if (props.control === CardActionType.IconButton) {
    const { buttonVariant, variant, control, isNarrow, ...rest } = props;
    return <IconButton {...rest} {...sharedButtonProps} variant={btnVariant} />;
  } else if (props.control === CardActionType.PlainButton) {
    const { control, effect, textDecoration, isNarrow, ...rest } = props;
    return (
      <PlainButton
        {...rest}
        weight={FontWeight.SemiBold}
        size={TypeScale.Size_02}
        effect={effect || HoverEffect.TextDecoration}
        textDecoration={textDecoration || "underline"}
      />
    );
  } else if (props.control === CardActionType.PlainLink) {
    const { control, effect, textDecoration, isNarrow, ...rest } = props;
    return (
      <PlainLink
        {...rest}
        weight={FontWeight.SemiBold}
        size={TypeScale.Size_02}
        effect={effect || HoverEffect.TextDecoration}
        textDecoration={textDecoration || "underline"}
      />
    );
  } else {
    const { buttonVariant, variant, control, layout, isNarrow, ...rest } =
      props;
    return (
      <Button
        {...rest}
        {...sharedButtonProps}
        variant={btnVariant}
        layout={layout || buttonLayout}
      />
    );
  }
};

export default FooterAction;
