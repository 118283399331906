import { CSSRulesFunction, useTheme, Color, ZIndex } from "../theme";

export interface SkipLinkProps {
  targetId: string;
  children: React.ReactNode;
}

const skipLinkStyle: CSSRulesFunction = (theme) => ({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  width: "1px",
  margin: "-1px",
  padding: 0,
  overflow: "hidden",
  position: "absolute",

  "&:focus": {
    padding: "1rem",
    position: "fixed",
    top: "10px",
    left: "10px",
    background: theme.color(Color.White),
    zIndex: ZIndex.SkipLink,
    width: "auto",
    height: "auto",
    clip: "auto",
  },
});

const SkipLink: React.FC<SkipLinkProps> = (props) => {
  const { targetId, ...rest } = props;
  const { theme } = useTheme();
  return (
    <a css={skipLinkStyle(theme)} {...rest} href={`#${targetId}`}>
      {props.children}
    </a>
  );
};

export default SkipLink;
