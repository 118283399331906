import {
  DefaultSegmentParams,
  useSegmentAbs,
} from "../segmentAbs/useSegmentAbs";

import { useUser } from "src/queries/user";
import { useAccessToken } from "src/common/authorisation";
import { captureException } from "src/technical-integrations/sentry/sentry";

export interface Segment {
  sendEvent: (name: string, params?: {}) => void;
  sendEventPromise: (name: string, params?: {}) => Promise<void>;
  sendEventPromiseWithTimeout: (name: string, params?: {}) => Promise<unknown>;
  group: (groupId: string, traits?: {}) => Promise<void>;
  identify: (userID: string, traits?: {}) => Promise<void>;
}

const SEND_EVENT_PROMISE_TIMEOUT_LIMIT = 2000;

export const useSegment = (): Segment => {
  const { sendEventAbs, sendGroup, sendIdentify } = useSegmentAbs();
  const [accessToken] = useAccessToken();
  const user = useUser();

  const sendSegmentEvent = async (name: string, params?: {}) => {
    await sendEventAbs(name, {
      organisation_id: accessToken?.links?.organisation,
      source: "dashboard",
      sent_at: new Date().toISOString(),
      user_id: accessToken?.links?.user,
      user_language: user?.language || navigator.language,
      ...params,
    });
  };

  const sendSegmentEventAndReturnPromise = async (name: string, params?: {}) =>
    await sendEventAbs(name, {
      organisation_id: accessToken?.links?.organisation,
      source: "dashboard",
      sent_at: new Date().toISOString(),
      user_id: accessToken?.links?.user,
      user_language: user?.language || navigator.language,
      ...params,
    });

  return {
    sendEvent: (...args) => {
      sendSegmentEvent(...args);
    },

    /**
     * There are times were we need to wait for the segment event to complete
     * before we continue on with something else. In this case the easiest way
     * to achieve that is to wait for the promise to resolve then continue.
     */
    sendEventPromise: (name: string, params?: {}) =>
      Promise.resolve(sendSegmentEvent(name, params)),

    sendEventPromiseWithTimeout: async (name: string, params?: {}) => {
      try {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error("Segment request took too long"));
          }, SEND_EVENT_PROMISE_TIMEOUT_LIMIT);

          sendSegmentEventAndReturnPromise(name, params).then(resolve);
        }).catch((e) => {
          throw e;
        });
      } catch (error) {
        captureException({
          error: error as Error,
        });
      }
    },

    group: async (
      groupId: string,
      traits?: DefaultSegmentParams
    ): Promise<void> => {
      /**
       * If we await this call, it fails silently and causes bugs when users
       * use privacy tools that block Segment
       */
      sendGroup(groupId, traits);
      return;
    },

    identify: async (
      userID: string,
      traits?: DefaultSegmentParams
    ): Promise<void> => {
      /**
       * If we await this call, it fails silently and causes bugs when users
       * use privacy tools that block Segment
       */
      sendIdentify(userID, traits);
      return;
    },
  };
};
