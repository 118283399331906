import { ColorPreset, CSSRulesFunction } from "../../theme";

import type { TimelineItemProps } from "./types";

export const timelineIconSize = "24px";

export const timelineItemStyle: CSSRulesFunction<
  Pick<TimelineItemProps, "state">
> = (theme, { state }) => ({
  position: "relative",
  display: "flex",
  gap: theme.spacing(0.5),
  paddingBottom: theme.spacing(2),
  ":before": {
    content: "' '",
    width: "0px",
    height: `calc(100% - ${timelineIconSize})`,
    position: "absolute",
    top: timelineIconSize,
    left: `calc(${timelineIconSize} / 2)`,
    transform: `translateX(-50%)`,
    borderWidth: "1px",
    borderColor: theme.color(ColorPreset.BorderOnLight_03),
    borderStyle: state === "inactive" ? "dashed" : "solid",
  },
  ":last-child": {
    paddingBottom: "0px",
    ":before": {
      content: "none",
    },
  },
});
