import type { IconButtonStyleProps } from "../buttons/types";
import type { BoxProps } from "../layout";
import type {
  Breakpoint,
  ColorProp,
  ResponsiveValue,
  SpaceScale,
  TextAlign,
  TypographyStyleProps,
} from "../theme";

import type { DataTableAPI } from "./api";

type CellTypographyProps = Pick<
  TypographyStyleProps,
  | "preset"
  | "color"
  | "fontFamily"
  | "fontStyle"
  | "size"
  | "textAlign"
  | "textTransform"
  | "weight"
>;

export enum DataTableTextOverflow {
  Ellipsis = "ellipsis",
  Initial = "initial",
  NoWrap = "nowrap",
}
export interface DataTableCellStyleProps extends CellTypographyProps {
  maxWidth?: ResponsiveValue<string | number>;
  minWidth?: ResponsiveValue<string | number>;
  gutterV?: ResponsiveValue<SpaceScale>;
  gutterH?: ResponsiveValue<SpaceScale>;
  textOverflow?: ResponsiveValue<DataTableTextOverflow>;
  bg?: ColorProp;
  removePadding?: boolean;
}
export interface DataTableHeadingStyleProps {
  textAlign?: ResponsiveValue<TextAlign>;
  textOverflow?: ResponsiveValue<DataTableTextOverflow>;
}

export type GetHeadingStyleProps<Item> = (
  instance: DataTableAPI.HeadingInstance<Item>
) => DataTableHeadingStyleProps;
export type GetCellStyleProps<Item> = (
  instance: DataTableAPI.CellInstance<Item>
) => DataTableCellStyleProps;

export interface DataTableProps<Item> extends DataTableAPI.Instance<Item> {
  /**
   * Customises the styling of the heading cells in the table.
   */
  getHeadingStyleProps?: GetHeadingStyleProps<Item>;
  /**
   * Customises the styling of the data cells in the table.
   */
  getCellStyleProps?: GetCellStyleProps<Item>;
  /**
   * Content to display when there are is no data for the table
   */
  emptyState?: React.ReactNode;
  /**
   * Allows for the hiding of the bottom border on the final row.
   */
  hideBottomBorder?: boolean;
}

export interface RowCollapsibleProps
  extends Pick<Partial<IconButtonStyleProps>, "size" | "variant"> {
  /**
   * The translated labels for the icon button
   */
  labelTranslations: {
    collapse: React.ReactNode;
    expand: React.ReactNode;
  };
  /**
   * The number of the first few headings that will be visible when collapsed.
   * Defaults to the first 2 if no number is passed. If a number below 1 is
   * passed then the first 2 will be shown.
   */
  alwaysVisibleAmount?: number;
}

export interface DataTableCompactProps<Item>
  extends DataTableAPI.Instance<Item> {
  /**
   * Customises the styling of the heading cells in the table.
   */
  getHeadingStyleProps?: GetHeadingStyleProps<Item>;
  /**
   * Customises the styling of the data cells in the table.
   */
  getCellStyleProps?: GetCellStyleProps<Item>;
  /**
   * Should the rows be collapsible
   */
  collapsible?: RowCollapsibleProps;
  /**
   * Content to display when there are is no data for the table
   */
  emptyState?: React.ReactNode;
  /**
   * Allows for the hiding of the top border on the first row.
   */
  hideTopBorder?: boolean;
  /**
   * Allows for the hiding of the bottom border on the final row.
   */
  hideBottomBorder?: boolean;
}

export interface DataTableCompactRowProps<Item> {
  headingsArray: DataTableAPI.HeadingInstance<Item>[];
  data: DataTableAPI.RowInstance<Item>;
  rowKey: string;
  collapsible?: RowCollapsibleProps;
  getHeadingStyleProps?: GetHeadingStyleProps<Item>;
  getCellStyleProps?: GetCellStyleProps<Item>;
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
}

export interface DataTableResponsiveProps extends Omit<BoxProps, "children"> {
  /**
   * The data table component
   */
  dataTable: React.ReactNode;
  /**
   * The data table compact component
   */
  dataTableCompact: React.ReactNode;
  /**
   * What breakpoint or screen width should the table layout be used from.
   */
  tableLayoutBreakpoint: Breakpoint;
}
