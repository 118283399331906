import { ButtonVariant } from "../buttons";

import { BannerActionType, BannerButtonVariant, BannerVariant } from "./types";

const darkThemeButtonVariants = {
  [BannerButtonVariant.Primary]: ButtonVariant.PrimaryOnDark,
  [BannerButtonVariant.Secondary]: ButtonVariant.SecondaryOnDark,
  [BannerButtonVariant.Text]: ButtonVariant.TextOnDark,
  [BannerButtonVariant.Plain]: ButtonVariant.Inline,
};

const lightThemeButtonVariants = {
  [BannerButtonVariant.Primary]: ButtonVariant.PrimaryOnLight,
  [BannerButtonVariant.Secondary]: ButtonVariant.SecondaryOnLight,
  [BannerButtonVariant.Text]: ButtonVariant.TextOnLight,
  [BannerButtonVariant.Plain]: ButtonVariant.Inline,
};

export const setButtonVariant = (
  bannerVariant: BannerVariant,
  actionType: BannerActionType,
  buttonVariant?: BannerButtonVariant
) => {
  switch (bannerVariant) {
    case BannerVariant.Dark:
      if (buttonVariant) {
        return darkThemeButtonVariants[buttonVariant];
      }

      return actionType === BannerActionType.Primary
        ? ButtonVariant.PrimaryOnDark
        : ButtonVariant.Inline;

    case BannerVariant.Light:
      if (buttonVariant) {
        return lightThemeButtonVariants[buttonVariant];
      }

      return actionType === BannerActionType.Primary
        ? ButtonVariant.PrimaryOnLight
        : ButtonVariant.Inline;

    case BannerVariant.FullBleed:
      if (buttonVariant) {
        return lightThemeButtonVariants[buttonVariant];
      }

      return ButtonVariant.Inline;
  }
};
