import type * as React from "react";

import { Glyph, Icon } from "../icons";
import { ColorPreset } from "../theme";
import { Box } from "../layout";

import { BannerVariant } from "./types";

export interface BannerIconProps {
  variant: BannerVariant;
  icon: Glyph;
}

const BannerIcon: React.FC<BannerIconProps> = (props) => {
  return (
    <Box css={{ paddingTop: "2px" }}>
      <Icon
        name={props.icon}
        color={
          props.variant === BannerVariant.Dark
            ? ColorPreset.IconOnDark_01
            : ColorPreset.IconOnLight_01
        }
        size="16px"
      />
    </Box>
  );
};

export default BannerIcon;
