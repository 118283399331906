import { useEffect, useState } from "react";
import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import {
  AvailableCreditorRefundAmountResource,
  CreditorResource,
} from "@gocardless/api/dashboard/types";
import api, { HTTPError } from "@gocardless/api/utils/api";

export const useAvailableCreditorRefundAmountList = (
  creditorId: CreditorResource["id"]
) => {
  const [availableCreditorRefundAmounts, setAvailableCreditorRefundAmounts] =
    useState<AvailableCreditorRefundAmountResource[]>();

  const [error, setError] = useState<HTTPError>();

  const [isLoading, setIsLoading] = useState(false);

  const getAvailableCreditorRefundAmounts = async (
    id: NonNullable<CreditorResource["id"]>
  ) => {
    try {
      setIsLoading(true);

      const response = await api.API.get(
        getEndpointURL(Endpoint.AvailableCreditorRefundAmountList),
        {
          searchParams: { creditor: id },
        }
      );

      const data = await response.json();

      setAvailableCreditorRefundAmounts(data.available_creditor_refund_amounts);
      setError(undefined);
    } catch (err) {
      setError(err as HTTPError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!creditorId) return;

    getAvailableCreditorRefundAmounts(creditorId);
  }, [creditorId]);

  return {
    availableCreditorRefundAmounts,
    error,
    getAvailableCreditorRefundAmounts,
    isLoading,
  };
};
