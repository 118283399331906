import { addMonths, addYears } from "date-fns";

export type CalendarDate = { year: number; month: number; day: number };
export type CalendarDateDelta = Partial<CalendarDate>;

/**
 * Returns the string representation of a calendar date.
 */
export const asString = (d: CalendarDate): string => {
  const pm = `${d.month}`.padStart(2, "0");
  const pd = `${d.day}`.padStart(2, "0");
  return `${d.year}-${pm}-${pd}`;
};

/**
 * Converts a JavaScript `Date` object into a calendar date.
 */
export const toCalendarDate = (d: Date): CalendarDate => {
  return {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
  };
};

/**
 * Returns a JavaScript `Date` object given a calendar date.
 */
export const fromCalendarDate = (d: CalendarDate): Date => {
  return new Date(d.year, d.month - 1, d.day);
};

/**
 * Compares two dates and returns 0 if they are equal, -1 if the first date is
 * before than the second date and 1 if the first date is after than the second
 * date.
 */
export const compare = (a: CalendarDate, b: CalendarDate): number => {
  const dy = a.year - b.year;
  const dm = a.month - b.month;
  const dd = a.day - b.day;

  return dy || dm || dd;
};

/**
 * Adds (or subtracts) a delta value from a given calendar date.
 */
export const add = (
  d: CalendarDate,
  delta: CalendarDateDelta
): CalendarDate => {
  const { year: dy = 0, month: dm = 0, day: dd = 0 } = delta;
  return toCalendarDate(new Date(d.year + dy, d.month + dm - 1, d.day + dd));
};

/**
 * Returns the first day of the week where the given date falls.
 */
export const startOfWeek = (d: CalendarDate): CalendarDate => {
  const offset = dayInMondayWeek(d);
  return toCalendarDate(new Date(d.year, d.month - 1, d.day - offset));
};

/**
 * Returns the last day of the week where the given date falls.
 */
export const endOfWeek = (d: CalendarDate): CalendarDate => {
  const offset = 6 - dayInMondayWeek(d);
  return toCalendarDate(new Date(d.year, d.month - 1, d.day + offset));
};

/**
 * Subtracts one month to the given date and accounts for overflow such that
 * moving back one month from 2020-03-31 results in 2020-02-29.
 */
export const backOneMonth = (d: CalendarDate): CalendarDate => {
  return toCalendarDate(addMonths(fromCalendarDate(d), -1));
};

/**
 * Adds one month to the given date and accounts for overflow such that
 * advancing one month from 2020-01-31 results in 2020-02-28.
 */
export const forwardOneMonth = (d: CalendarDate): CalendarDate => {
  return toCalendarDate(addMonths(fromCalendarDate(d), 1));
};

/**
 * Subtracts one year to the given date and accounts for overflow such that
 * moving back one year from 2020-02-29 results in 2019-02-28.
 */
export const backOneYear = (d: CalendarDate): CalendarDate => {
  return toCalendarDate(addYears(fromCalendarDate(d), -1));
};

/**
 * Adds one year to the given date and accounts for overflow such that advancing
 * one year from 2020-02-29 results in 2021-02-28.
 */
export const forwardOneYear = (d: CalendarDate): CalendarDate => {
  return toCalendarDate(addYears(fromCalendarDate(d), 1));
};

/**
 * Calculates the index of a day in a calendar week that starts on Monday.
 */
export const dayInMondayWeek = (d: CalendarDate): number => {
  const dayInSundayWeek = fromCalendarDate(d).getDay();
  return dayInSundayWeek === 0 ? 6 : dayInSundayWeek - 1;
};

/**
 * Checks that a CalendarDate is valid and doesn't contain overflowing
 * day/month/year combos such as 2022-02-31.
 */
export const validate = (d: CalendarDate): CalendarDate | null => {
  const result = fromCalendarDate(d);

  const isValid =
    result.getFullYear() === d.year &&
    result.getMonth() + 1 === d.month &&
    result.getDate() === d.day;

  return isValid ? d : null;
};

/**
 * Checks that a partial CalendarDate is valid and doesn't contain overflowing
 * day/month/year combos such as 2022-02-31.
 */
export const validatePartial = (
  d: Partial<CalendarDate> | CalendarDate | undefined
): CalendarDate | null => {
  if (!d) return null;

  const { day, month, year } = d;
  if (day == null || month == null || year == null) {
    return null;
  }

  return validate({ day, month, year });
};

/**
 * Checks if the date is within range of min and max date.
 */
export const dateInRange = ({
  min,
  max,
  dt,
}: {
  min?: CalendarDate | null;
  max?: CalendarDate | null;
  dt: CalendarDate;
}) => {
  if (!min && !max) return true;

  // Return false if date is before min date or after max date
  if ((min && compare(dt, min) < 0) || (max && compare(dt, max) > 0)) {
    return false;
  }

  return true;
};
