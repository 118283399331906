import { BannerLeftAccessoryProps, BannerLeftAccessoryType } from "./types";

const getSmGridTemplateAreas = ({
  closeArea,
  showActions,
  leftAccessoryType,
}: {
  closeArea: "close" | "";
  showActions: boolean;
  leftAccessoryType?: BannerLeftAccessoryProps["type"];
}) => {
  return `${
    typeof leftAccessoryType === "undefined"
      ? `'body ${closeArea || "body"}'`
      : `'icon ${closeArea || "icon"}'
    'body body'`
  }
    ${showActions ? "'actions actions'" : ""}`;
};

const getLgHorizontalGridTemplateAreas = ({
  closeArea,
  showActions,
}: {
  closeArea: "close" | "";
  showActions: boolean;
}) => {
  return `
      'icon body ${showActions ? "actions" : "body"} ${closeArea}'
    `;
};

const getLgVerticalGridTemplateAreas = ({
  closeArea,
  showActions,
  leftAccessoryType,
}: {
  closeArea: "close" | "";
  showActions: boolean;
  leftAccessoryType?: BannerLeftAccessoryProps["type"];
}) => {
  if (leftAccessoryType === BannerLeftAccessoryType.Tag) {
    return `
      'icon icon close'
      'body body ${closeArea}'
      ${showActions ? `'actions actions ${closeArea}'` : ""}
    `;
  }

  return `
    'icon body ${closeArea}'
    'icon ${showActions ? "actions" : "body"} ${closeArea}'
  `;
};

export type GetGridTemplateAreasArgs = {
  isCloseable: boolean;
  isHorizontal: boolean;
  leftAccessoryType?: BannerLeftAccessoryProps["type"];
  showActions: boolean;
};

export const getGridTemplateAreas = ({
  isCloseable,
  isHorizontal,
  leftAccessoryType,
  showActions,
}: GetGridTemplateAreasArgs) => {
  const closeArea = isCloseable ? "close" : "";

  return [
    getSmGridTemplateAreas({
      closeArea,
      showActions,
      leftAccessoryType,
    }),
    null,
    isHorizontal
      ? getLgHorizontalGridTemplateAreas({ closeArea, showActions })
      : getLgVerticalGridTemplateAreas({
          closeArea,
          showActions,
          leftAccessoryType,
        }),
  ];
};
