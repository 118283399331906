import { useMemo, useState } from "react";

import { ButtonVariant, IconButton, PlainButton } from "../../buttons";
import { Glyph, Icon } from "../../icons";
import {
  AlignItems,
  ButtonSize,
  Color,
  CSSRulesFunction,
  HoverEffect,
  JustifyContent,
  useTheme,
} from "../../theme";
import { Box } from "../../layout";
import { FormFieldStatus, resolveStatusColors } from "../status";
import type { InternalProps } from "../Input";

import type { DefaultListItemProps } from "./List.types";
import { ListItem } from "./ListItem";

export const triggerContainerStyle: CSSRulesFunction<InternalProps> = (
  theme,
  props
) => {
  const { status, focused, disabled } = props;

  const colors = resolveStatusColors(theme, { status, disabled });
  const { baseBorderColor, focusBorderColor, textColor, backgroundColor } =
    colors;

  let borderWidth = "1px";
  let boxShadowColor = baseBorderColor;
  if (focused) {
    borderWidth = "2px";
    boxShadowColor = focusBorderColor;
  }

  return {
    "--input-border-width": borderWidth,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    backgroundColor,
    color: textColor,
    borderRadius: theme.radius(0.5),
    boxShadow: `0 0 0 var(--input-border-width) ${boxShadowColor}`,
    transition: "box-shadow 150ms",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    "&:focus-visible": {
      outline: "none",
    },
  };
};

export interface RenderValueProps<Item> {
  value: Item;
  disabled?: boolean;
}

export interface TriggerProps<Item> {
  placeholder?: string;
  value?: Item | null;
  loading: boolean;
  setReferenceElement: (instance: HTMLButtonElement | null) => void;
  isOpen: boolean;
  renderValue?: (valueProps: RenderValueProps<Item>) => React.ReactNode;
  onClear: () => void;
  onClick: () => void;
  disabled?: boolean;
  clearable: boolean;
  leftAccessory: React.ReactNode;
  status?: FormFieldStatus;
  id?: string;
}

export const Trigger = <Item extends DefaultListItemProps>({
  clearable,
  placeholder,
  value,
  isOpen,
  loading,
  renderValue,
  onClear,
  leftAccessory,
  setReferenceElement,
  status,
  id,
  ...props
}: TriggerProps<Item>) => {
  const { theme } = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  const renderValueText = useMemo(
    () => () => {
      if (value) {
        if (renderValue) {
          return renderValue({
            value,
            disabled: props.disabled,
          });
        }
        return <ListItem disabled={props.disabled}>{value.label}</ListItem>;
      }

      return <ListItem leftAccessory={leftAccessory}>{placeholder}</ListItem>;
    },
    [loading, value, leftAccessory, placeholder, renderValue, props.disabled]
  );

  const renderAction = useMemo(
    () => () => {
      if (loading) {
        return <Icon name={Glyph.Spinner} />;
      }
      const emptyvalue = !value;
      if (!emptyvalue && isHovering && clearable) {
        return (
          <IconButton
            icon={Glyph.Close}
            size={ButtonSize.Sm}
            label="clear"
            variant={ButtonVariant.TextOnLight}
            data-cy="clear-btn"
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          />
        );
      }

      return (
        <IconButton
          icon={isOpen ? Glyph.ChevronUp : Glyph.ChevronDown}
          label={isOpen ? "close" : "open"}
          variant={ButtonVariant.TextOnLight}
          data-cy="clear-btn"
        />
      );
    },
    [loading, value, isHovering, clearable, isOpen, onClear]
  );

  const statusCss = triggerContainerStyle(theme, {
    status,
    focused: isOpen,
    disabled: props.disabled,
  });

  return (
    <PlainButton
      effect={HoverEffect.TextDecoration}
      ref={setReferenceElement}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      css={statusCss}
      id={id}
      {...props}
    >
      <Box
        bg={Color.White}
        layout="flex"
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
        aria-haspopup="listbox"
        width="100%"
        minHeight={theme.spacing(3)}
      >
        <Box width="100%" maxWidth="calc(100% - 64px)">
          {renderValueText()}
        </Box>

        <Box
          width="64px"
          gutterH={1}
          layout="flex"
          justifyContent={JustifyContent.Center}
        >
          {renderAction()}
        </Box>
      </Box>
    </PlainButton>
  );
};

export default Trigger;
