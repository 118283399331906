import React from "react";
import {
  arrow,
  flip,
  Middleware,
  offset,
  autoUpdate,
  useFloating as useFloatingUI,
  Placement as FloatingUIPlacement,
  Strategy,
} from "@floating-ui/react-dom";

import type { SpaceScale } from "../theme";

import { useResizeObserver, useScrollObserver } from "./geometry";

export type FloatingPlacement = FloatingUIPlacement;
export type PlacementStrategy = "static" | "flip";

const getDocumentBody = () => {
  if (typeof document === "undefined" || typeof document.body === "undefined") {
    return null;
  }
  return document.body;
};

interface UseFloatingOptions {
  open?: boolean;
  arrowRef?: React.RefObject<HTMLElement>;
  initialPlacement: FloatingPlacement;
  placementStrategy: PlacementStrategy;
  positionStrategy?: Strategy;
  /**
   * How far in from the edge of the floating tooltip should the arrow be.
   * This option helps account for a border radius on the floating element.
   */
  arrowPadding?: number;

  /**
   * Sets a displacement amount (like CSS margins) for the floating element.
   */
  offset?: SpaceScale;
  shouldUpdate?: boolean;
}

const placementMiddleware = (
  options: Pick<UseFloatingOptions, "initialPlacement" | "placementStrategy">
): Middleware[] => {
  const { placementStrategy, initialPlacement } = options;
  if (placementStrategy === "static") {
    return [];
  }

  // When deciding the set of allowed placements for a tooltip using flip
  // strategy, we will bias this based on the initial placement. For example,
  // if the initial placement is "top-start" then we will assume we can
  // reposition the tooltip above and below its trigger. This is a sensible
  // default behaviour that tries to avoid having the tooltip cover content to
  // the left or right of its trigger. A similar assumption is made if the
  // initial placement is left-* or right-*.
  const majorEdge = initialPlacement.split("-")[0];
  const fallbackPlacements: FloatingPlacement[] = [];
  if (majorEdge === "left" || majorEdge === "right") {
    fallbackPlacements.push(
      "left",
      "left-start",
      "left-end",
      "right",
      "right-start",
      "right-end"
    );
  } else {
    fallbackPlacements.push(
      "top",
      "top-start",
      "top-end",
      "bottom",
      "bottom-start",
      "bottom-end"
    );
  }

  return [
    flip({
      fallbackStrategy: "bestFit",
      fallbackPlacements,
    }),
  ];
};

export const useFloating = (options: UseFloatingOptions) => {
  const {
    open = false,
    arrowRef,
    initialPlacement,
    placementStrategy,
    arrowPadding,
    offset: offsetScale = 0.5,
    shouldUpdate,
    positionStrategy = "fixed",
  } = options;

  const offsetAmount = offsetScale * 16;

  const arrowMiddleware = arrowRef
    ? [arrow({ element: arrowRef, padding: arrowPadding })]
    : [];

  const result = useFloatingUI({
    strategy: positionStrategy,
    placement: initialPlacement,
    middleware: [
      ...placementMiddleware({ initialPlacement, placementStrategy }),
      ...arrowMiddleware,
      // This is the pixel offset of the tooltip from its reference and needs to
      // be adjust to account for the arrow.
      offset(offsetAmount),
    ],
    whileElementsMounted: shouldUpdate ? autoUpdate : undefined,
  });

  const { update } = result;
  // We need to track the size of the document so we can reposition the tooltip
  // if it changes.
  const documentRef = React.useRef<HTMLElement>(getDocumentBody());
  useResizeObserver(documentRef, update, [open]);
  // We also need to track the browser scroll position to flip the tooltip
  // reposition if needed.
  useScrollObserver({
    scrollReference: "document",
    callback: update,
    enabled: open && placementStrategy === "flip",
  });

  return result;
};
