import {
  Color,
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  TypeScale,
  useTheme,
} from "../theme";

import { FormFieldStatus } from "./status";

interface HintThemingProps {
  /**
   * Adds a title to a group of hints you need to display. This should be used when you want to summarise any mistakes a user has made in a form.
   */
  summaryTitle?: React.ReactNode;
  leftAccessory?: React.ReactNode;
  /**
   * Configures the status indicator that will be shown on the notification.
   */
  status?: FormFieldStatus.Danger;
  /**
   * Configures the appearance of the hint to be either light or dark.
   */
  colorScheme?: ColorScheme;
}
type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLParagraphElement>,
  "children"
>;

export interface HintProps extends HTMLAttributes, HintThemingProps {
  children?: React.ReactNode;
}

export const vars = {
  neutralColor: "--hint-neutral-color",
  dangerColor: "--hint-danger-color",
  accentColor: "--hint-accent-color",

  color: "--hint-color",
  titleColor: "--hint-title-color",
};

const hintColorVars: CSSRulesFunction<ColorScheme> = (theme, cs) => {
  const light = {
    [vars.neutralColor]: theme.color(ColorPreset.TextOnLight_01),
    [vars.dangerColor]: theme.color(ColorPreset.AlertTextOnLight),
    [vars.accentColor]: theme.color(ColorPreset.AlertBorderOnLight),
    [vars.titleColor]: theme.color(ColorPreset.TextOnLight_01),
  };
  const dark = {
    [vars.neutralColor]: theme.color(ColorPreset.TextOnDark_01),
    [vars.dangerColor]: theme.color(ColorPreset.AlertTextOnDark),
    [vars.accentColor]: theme.color(Color.Alert_100),
    [vars.titleColor]: theme.color(ColorPreset.TextOnDark_01),
  };

  switch (cs) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

const hintStyle: CSSRulesFunction<HintThemingProps> = (theme, props) => {
  return [
    hintColorVars(theme, props.colorScheme || ColorScheme.OnLight),
    {
      color:
        props.status === FormFieldStatus.Danger
          ? `var(${vars.dangerColor})`
          : `var(${vars.neutralColor})`,
      display: "flex",
      alignItems: "center",
    },
  ];
};

const hintLeftAccessoryStyle: CSSRulesFunction = (theme) => {
  return {
    marginRight: theme.spacing(0.75),
  };
};

const hintBodyStyle: CSSRulesFunction<HintProps> = (theme, props) => {
  const { status, summaryTitle } = props;

  const fontWeight =
    status === FormFieldStatus.Danger ? FontWeight.SemiBold : FontWeight.Normal;

  const customSpacing = summaryTitle
    ? theme.spacing([1, 1])
    : theme.spacing([0, 1]);

  return {
    padding: status ? customSpacing : undefined,
    position: "relative",
    margin: 0,
    fontWeight,
    ...theme.tokens.fontSizes[TypeScale.Size_02],
    "&:before": {
      content: '""',
      display: status ? "inline" : "none",
      position: "absolute",
      width: "2px",
      height: "100%",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      borderRadius: theme.radius(3),
      backgroundColor: `var(${vars.accentColor})`,
    },
  };
};

const hintTitleStyle: Interpolation = {
  color: `var(${vars.titleColor})`,
  marginTop: "0",
};

const Hint = (props: HintProps) => {
  const {
    children,
    leftAccessory,
    status,
    summaryTitle,
    colorScheme = ColorScheme.OnLight,
    ...rest
  } = props;

  const { theme } = useTheme();

  return (
    <div css={hintStyle(theme, { ...props, colorScheme })}>
      {leftAccessory ? (
        <div css={hintLeftAccessoryStyle(theme)}>{leftAccessory}</div>
      ) : null}
      <div css={hintBodyStyle(theme, props)} {...rest}>
        {summaryTitle && <p css={hintTitleStyle}>{summaryTitle}</p>}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Hint;
