import { ImportResource, ImportsType } from "@gocardless/api/dashboard/types";

import { HomeSchemeEnum } from "src/common/scheme";

export const BULK_CHANGE_BANNER_MANDATES_LIMIT = 30;

export const BulkChangeSchemes = {
  Ach: HomeSchemeEnum.Ach,
  Autogiro: HomeSchemeEnum.Autogiro,
  Bacs: HomeSchemeEnum.Bacs,
  Becs: HomeSchemeEnum.Becs,
  BecsNz: HomeSchemeEnum.BecsNz,
  Betalingsservice: HomeSchemeEnum.Betalingsservice,
  Pad: HomeSchemeEnum.Pad,
  Sepa: HomeSchemeEnum.Sepa,
} as const;

export const EntryFlowContextValues = {
  EntryPointBanner: "Bulk import change banner",
  Unverified: "Unverified",
  ChoosingScheme: "Choosing scheme",
} as const;

export const RequestFlowContextValues = {
  SubmitRequestSummary: "Submit request summary",
  CollectionMethod: "Deed - FM or AM",
  IncorporatedEntity: "Deed - Incorporated Entity",
  UploadingDeed: "Deed - Submitting",
  ContactDetails: "Form - Contact details",
  ProviderDetails: "Form - Provider details",
  SunDetails: "Form - SUN details",
  PartnerIntegration: "Form - Partner",
  MigrationDetails: "Form - Migration details",
  RequestSubmitted: "Form - Request submitted",
} as const;

export const UploadFlowContextValues = {
  SepaMigrationType: "Form - SEPA migration type",
  ConfirmDate: "Upload - Confirm date",
  CollectingData: "Preparing file - Collecting data",
  DownloadCSV: "Preparing file - Download CSV",
  ExportCSV: "Preparing file - Export CSV",
  NotifyCustomer: "Preparing file - Notify customer",
  PreparingFile: "Preparing file",
  Upload2FA: "Upload - 2FA",
  UploadBackendFailed: "Upload - Backend failed",
  UploadFailed: "Upload - Failed",
  UploadFile: "Preparing file - Upload",
  ValidationFile: "Upload - Validation file",
} as const;

export type BulkChangeContextValue =
  | (typeof UploadFlowContextValues)[keyof typeof UploadFlowContextValues]
  | (typeof EntryFlowContextValues)[keyof typeof EntryFlowContextValues]
  | (typeof RequestFlowContextValues)[keyof typeof RequestFlowContextValues];

export type BulkChangeScheme =
  (typeof BulkChangeSchemes)[keyof typeof BulkChangeSchemes];

export interface BulkChangeEntryFormFields {
  scheme: BulkChangeScheme | undefined;
}

export interface SepaBulkChangeFlowFormFields {
  cidType: "gocardless_cid" | "custom_do_not_own" | "custom_own";
  usingUniqueMerchantReference: "true" | "false";
}

export interface BulkChangeFlowProps {
  onContinue: () => void;
}

export const ZENDESK_FORM_URL =
  "https://manage.gocardless.com/forms/zendesk/134125";

export const GENERIC_CUSTOMER_HUB_URL =
  "https://hub.gocardless.com/s/article/Customer-migration-process";

export const getBulkChangeSchemeFromImportType = (
  importType: ImportResource["type"]
) => {
  switch (importType) {
    case ImportsType.BulkChangeAch:
      return BulkChangeSchemes.Ach;
    case ImportsType.BulkChangeAutogiro:
      return BulkChangeSchemes.Autogiro;
    case ImportsType.BulkChangeBacs:
      return BulkChangeSchemes.Bacs;
    case ImportsType.BulkChangeBecs:
      return BulkChangeSchemes.Becs;
    case ImportsType.BulkChangeBecsNz:
      return BulkChangeSchemes.BecsNz;
    case ImportsType.BulkChangeBetalingsservice:
      return BulkChangeSchemes.Betalingsservice;
    case ImportsType.BulkChangePad:
      return BulkChangeSchemes.Pad;
    case ImportsType.BulkChangeSepa:
    case ImportsType.BulkChangeSepaOwnCid:
    case ImportsType.BulkChangeSepaKeepReference:
    case ImportsType.BulkChangeSepaOwnCidKeepReference:
      return BulkChangeSchemes.Sepa;
    default:
      return undefined;
  }
};

export const getImportTypeFromBulkChangeScheme = ({
  scheme,
  cidType,
  usingUniqueMerchantReference,
  customMandateReferencesEnabled,
}: SepaBulkChangeFlowFormFields & {
  scheme: BulkChangeScheme;
  customMandateReferencesEnabled: boolean;
}) => {
  switch (scheme) {
    case BulkChangeSchemes.Ach:
      return ImportsType.BulkChangeAch;
    case BulkChangeSchemes.Autogiro:
      return ImportsType.BulkChangeAutogiro;
    case BulkChangeSchemes.Bacs:
      return ImportsType.BulkChangeBacs;
    case BulkChangeSchemes.Becs:
      return ImportsType.BulkChangeBecs;
    case BulkChangeSchemes.BecsNz:
      return ImportsType.BulkChangeBecsNz;
    case BulkChangeSchemes.Betalingsservice:
      return ImportsType.BulkChangeBetalingsservice;
    case BulkChangeSchemes.Pad:
      return ImportsType.BulkChangePad;
    case BulkChangeSchemes.Sepa: {
      switch (cidType) {
        case "gocardless_cid":
        case "custom_do_not_own": {
          if (
            customMandateReferencesEnabled &&
            usingUniqueMerchantReference === "true"
          )
            return ImportsType.BulkChangeSepaKeepReference;

          return ImportsType.BulkChangeSepa;
        }
        case "custom_own": {
          if (
            customMandateReferencesEnabled &&
            usingUniqueMerchantReference === "true"
          )
            return ImportsType.BulkChangeSepaOwnCidKeepReference;

          return ImportsType.BulkChangeSepaOwnCid;
        }
      }
    }
  }
};
