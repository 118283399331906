import { forwardRef } from "react";

import { Glyph, Icon } from "../../icons";
import { Box } from "../../layout";
import {
  AlignItems,
  Color,
  ColorPreset,
  FontWeight,
  JustifyContent,
  TypePreset,
} from "../../theme";
import { Text } from "../../typography";

export interface ListItemProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "leftAccessory" | "color"
  > {
  children: React.ReactNode;
  isSelected?: boolean;
  isHighlighted?: boolean;
  description?: string;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  disabled?: boolean;
  highlightText?: string;
}

const escapeRegExpString = (input?: string): string =>
  // eslint-disable-next-line no-useless-escape
  (input ?? "").replace(/([\[\\\^\$\.\|\?\*\+\(\)\]])/g, "\\$1");
export const getHighlightedText = (
  text: React.ReactNode,
  highlight: string
) => {
  const parsedText = text === undefined ? "" : text?.toString() ?? "";
  if (!parsedText.trim() || !highlight?.trim()) {
    return parsedText;
  }

  const rx = escapeRegExpString(highlight);

  // Split on highlight term and include term into parts, ignore case
  const parts = parsedText.split(new RegExp(`(${rx})`, "gi"));
  return parts.map((part, i) => {
    const isHighlighted = part.toLowerCase() === highlight.toLowerCase();
    return (
      <Text
        key={i.toString()}
        weight={isHighlighted ? FontWeight.SemiBold : FontWeight.Normal}
        color={ColorPreset.TextOnLight_01}
      >
        {part}
      </Text>
    );
  });
};

export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      children,
      leftAccessory,
      rightAccessory,
      description,
      highlightText = "",
      isSelected,
      isHighlighted,
      ...rest
    }: ListItemProps,
    ref
  ) => (
    <Box
      bg={isHighlighted ? ColorPreset.BackgroundLight_03 : Color.Transparent}
      ref={ref}
      gutterH={1}
      gutterV={0.5}
      borderRadius={0.25}
      style={{ width: "100%" }}
      {...rest}
    >
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
      >
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          width="100%"
        >
          <Box layout="flex" alignItems={AlignItems.FlexStart}>
            {leftAccessory}
          </Box>
          <Box
            layout="inline-block"
            flexGrow={1}
            spaceBefore={leftAccessory ? 0.5 : 0}
            spaceAfter={leftAccessory ? 0.5 : 0}
            maxWidth="100%"
            minWidth={6}
          >
            {typeof children === "string" ? (
              <Text>{getHighlightedText(children, highlightText)}</Text>
            ) : (
              children
            )}
          </Box>
          {isSelected ? (
            <Icon name={Glyph.TickSmall} color={ColorPreset.TextOnLight_01} />
          ) : null}
          <Box layout="flex" alignItems={AlignItems.FlexStart}>
            {rightAccessory}
          </Box>
        </Box>
      </Box>
      {description ? (
        <Box width="100%">
          {typeof description === "string" ? (
            <Text preset={TypePreset.Body_01}>
              {getHighlightedText(description, highlightText)}
            </Text>
          ) : (
            description
          )}
        </Box>
      ) : null}
    </Box>
  )
);
