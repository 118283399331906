import { useControlledState } from "@react-stately/utils";
import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { Glyph, Icon } from "../../icons";
import { ColorPreset, ColorProp, useTheme } from "../../theme";
import { FormFieldStatus } from "../status";

import { CalendarDate, dateInRange, validatePartial } from "./dateHelpers";
import DateInputSegment from "./DateInputSegment";
import {
  dateInputAccessoriesStyle,
  dateInputGroupStyle,
  dateInputStyle,
} from "./dateInputStyle";
import type { DateInputStyleProps } from "./dateInputTypes";
import type { DateFormat, DatesI18n } from "./i18n";

type HTMLAttributes = Omit<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  | "htmlFor"
  | "for"
  | "defaultValue"
  | "onChange"
  | "value"
  | "children"
  | "onError"
  | keyof DateInputStyleProps
>;

export enum DateInputError {
  InvalidDate = "invalid-date",
  DateNotInRange = "date-not-in-range",
  FromDateGreaterThanToDate = "from-date-greater-than-to-date",
}

export type HighlightedDates = {
  dates: Array<{ date: CalendarDate; disabled?: boolean }>;
  color: ColorProp;
  label?: string;
};

export interface OnChangeOptions {
  error?: DateInputError;
}

export interface DateInputProps extends HTMLAttributes, DateInputStyleProps {
  /**
   * An optional id to attach to the first input of this component. This is
   * useful for correlating an external <label> element with this component.
   */
  id?: string;
  /**
   * The internationalized content to use within this component.
   */
  i18n: DatesI18n;
  /**
   * The date format that will control the input order in this component.
   */
  format: DateFormat;
  /**
   * An optional right accessory to place within this component.
   */
  rightAccessory?: React.ReactNode;
  /**
   * The ids of elements that act as labels for this component. These ids are
   * attached to each input field. To make this component accessible, it's
   * recommended to include the id of a `<label>` element that labels it.
   */
  "aria-labelledby"?: string;

  /**
   * The ids of elements that act as descriptions for this component. This may
   * include hints about date formatting or validation errors.
   */
  "aria-describedby"?: string;

  /**
   * In controlled mode, sets the value of this component.
   */
  value?: Partial<CalendarDate>;
  /**
   * In uncontrolled mode, sets the default value of this component.
   */
  defaultValue?: Partial<CalendarDate>;
  /**
   * A callback to call when this component's value changes.
   *
   * @param d The new partial date that was entered
   * @param o Options of onChange callback
   */
  onChange?(d: Partial<CalendarDate> | undefined, o?: OnChangeOptions): void;
  /**
   * A callback to call when error in the input.
   *
   * @param e The error type
   */
  onError?(e: DateInputError | undefined): void;
  /**
   * In controlled mode, sets the min date of this component.
   */
  minDate?: CalendarDate;
  /**
   * In controlled mode, sets the max date of this component.
   */
  maxDate?: CalendarDate;
  /**
   * In controlled mode, sets the highlighted dates of this component.
   */
  highlightedDates?: Array<HighlightedDates>;
}

const noop = () => {};

const caps: Record<keyof CalendarDate, { min: number; max: number }> = {
  day: { min: 0, max: 31 },
  month: { min: 0, max: 12 },
  year: { min: 0, max: 9999 },
};

const DateInput = React.forwardRef<HTMLLabelElement, DateInputProps>(
  (props, ref) => {
    const {
      id,
      i18n,
      disabled,
      format,
      status,
      rightAccessory,
      value: inValue,
      defaultValue: inDefault = {
        day: undefined,
        month: undefined,
        year: undefined,
      },
      minDate,
      maxDate,
      compact,
      onError,
      onChange: inOnChange = noop,
      "aria-labelledby": labelIds,
      "aria-describedby": externalDescriptionIds,
      ...rest
    } = props;

    const [errorStatus, setErrorStatus] = React.useState(status);

    const separator = i18n.separator || "/";
    const descriptionId = React.useId();
    const { theme } = useTheme();
    const [value, setValue] = useControlledState(
      inValue,
      inDefault,
      inOnChange
    );

    const groupRef = React.useRef<HTMLDivElement>(null);

    const cd = value && value.day != null ? `${value.day}` : "";
    const cm = value && value.month != null ? `${value.month}` : "";
    const cy = value && value.year != null ? `${value.year}` : "";

    React.useEffect(() => {
      const error = validateDate(value);

      /**
       * Set the input status to danger if,
       * 1. there is error
       * 2. or user has explicitly asked for it
       **/
      setErrorStatus((error || status) && FormFieldStatus.Danger);

      if (onError) onError(error);
    }, [value, status, minDate, maxDate]);

    const validateDate = (dt: Partial<CalendarDate> | undefined) => {
      if (dt?.year && dt?.day && dt?.month) {
        const isDateWithinRange = dateInRange({
          min: minDate,
          max: maxDate,
          dt: { ...dt } as CalendarDate,
        });

        if (!isDateWithinRange) return DateInputError.DateNotInRange;
      } else if (dt?.year || dt?.day || dt?.month) {
        return DateInputError.InvalidDate;
      }

      return;
    };

    const changeHandler =
      (field: keyof CalendarDate) => (v: string | undefined) => {
        if (v == null) {
          return;
        }

        const parsed = v ? parseInt(v, 10) : undefined;
        if (v != null && Number.isNaN(parsed)) {
          return;
        }

        const next: Partial<CalendarDate> = value ? { ...value } : {};
        const cap = caps[field];
        const capped =
          parsed == null
            ? undefined
            : Math.min(cap.max, Math.max(cap.min, parsed));

        next[field] = capped;

        const error = validateDate(next);

        setValue(next, { error });
      };

    const handleFinish = () => {
      const group = groupRef.current;
      if (group == null || typeof document === "undefined") {
        return;
      }

      if (document.activeElement == null) {
        return;
      }

      const inputs = Array.from(group.querySelectorAll(":scope input"));
      const activeIndex = inputs.indexOf(document.activeElement);
      if (activeIndex < 0) {
        return;
      }

      const nextIndex =
        activeIndex === inputs.length - 1 ? -1 : activeIndex + 1;
      const nextEl = inputs[nextIndex];
      if (!(nextEl instanceof HTMLElement)) {
        return;
      }

      nextEl.focus();
    };

    const dayInput = (
      <DateInputSegment
        value={cd}
        onChange={changeHandler("day")}
        onFinished={handleFinish}
        mode="day"
        key="day"
        disabled={disabled}
        placeholder={i18n.dayInputPlaceholder}
        label={i18n.dayInputLabel}
        aria-labelledby={labelIds}
        aria-invalid={errorStatus === FormFieldStatus.Danger}
        aria-describedby={`${descriptionId} ${externalDescriptionIds}`}
        compact={compact}
      />
    );
    const monthInput = (
      <DateInputSegment
        value={cm}
        onChange={changeHandler("month")}
        onFinished={handleFinish}
        mode="month"
        key="month"
        disabled={disabled}
        placeholder={i18n.monthInputPlaceholder}
        label={i18n.monthInputLabel}
        aria-labelledby={labelIds}
        aria-invalid={errorStatus === FormFieldStatus.Danger}
        aria-describedby={`${descriptionId} ${externalDescriptionIds}`}
        compact={compact}
      />
    );
    const yearInput = (
      <DateInputSegment
        value={cy}
        onChange={changeHandler("year")}
        onFinished={handleFinish}
        mode="year"
        key="year"
        disabled={disabled}
        placeholder={i18n.yearInputPlaceholder}
        label={i18n.yearInputLabel}
        aria-labelledby={labelIds}
        aria-invalid={errorStatus === FormFieldStatus.Danger}
        aria-describedby={`${descriptionId} ${externalDescriptionIds}`}
        compact={compact}
      />
    );
    const sep1 = (
      <span key="sep1" aria-hidden>
        {separator}
      </span>
    );
    const sep2 = (
      <span key="sep2" aria-hidden>
        {separator}
      </span>
    );

    let nodes: React.ReactNode[] = [];
    switch (format) {
      case "dd-mm-yyyy":
        nodes = [dayInput, sep1, monthInput, sep2, yearInput];
        break;
      case "mm-dd-yyyy":
        nodes = [monthInput, sep1, dayInput, sep2, yearInput];
        break;
      case "yyyy-mm-dd":
        nodes = [yearInput, sep1, monthInput, sep2, dayInput];
        break;
    }

    // Add the id prop to the first input in this component. That way if there
    // is a label with htmlFor set to that id, it can be clicked to focus the
    // first input.
    const first = nodes[0];
    const attrs: React.HTMLAttributes<unknown> = { id };
    if (React.isValidElement(first)) {
      nodes[0] = React.cloneElement(first, attrs);
    }

    const isDanger = errorStatus === FormFieldStatus.Danger;
    const validated = value ? validatePartial(value) : null;

    return (
      <label
        ref={ref}
        data-field-control
        role="group"
        aria-disabled={disabled}
        css={dateInputStyle(theme, { disabled, status: errorStatus, compact })}
        aria-describedby={externalDescriptionIds}
        {...rest}
      >
        {validated ? (
          <VisuallyHidden
            id={descriptionId}
            role="presentation"
            aria-live="polite"
            aria-labelledby={labelIds}
          >
            {i18n.getSelectedDateLabel(validated)}
          </VisuallyHidden>
        ) : null}
        <div css={dateInputGroupStyle} ref={groupRef}>
          {nodes}
        </div>
        {!compact ? (
          <div css={dateInputAccessoriesStyle}>
            {isDanger ? (
              <Icon
                name={Glyph.Close}
                color={ColorPreset.AlertIconOnLight}
                size="12px"
              />
            ) : null}
            {rightAccessory}
          </div>
        ) : null}
      </label>
    );
  }
);

export default DateInput;
