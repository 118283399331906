import * as React from "react";
import { createPortal } from "react-dom";

const BodyPortal: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [portalEl, setPortalEl] = React.useState<HTMLDivElement | null>();

  React.useEffect(() => {
    if (!portalEl) {
      const newPortalEl = document.createElement("div");
      document.body.appendChild(newPortalEl);
      setPortalEl(newPortalEl);
    }

    return () => {
      portalEl && document.body.removeChild(portalEl);
    };
  }, [portalEl]);

  return portalEl ? createPortal(children, portalEl) : null;
};

export default BodyPortal;
