import * as React from "react";

import { ColorScheme, useTheme } from "../theme";
import { useIsomorphicLayoutEffect } from "../hooks/useIsomorphicLayoutEffect";

import {
  MenuDensity,
  menuRootLabelStyle,
  menuRootListStyle,
  menuRootStyle,
  MenuStyleProps,
} from "./menuStyle";
import {
  menuKeyProp,
  MenuLayoutProvider,
  MenuRole,
  MenuRoleProvider,
  MenuSelectionProvider,
  useMenuKeyboardHandler,
  useMenuLevel,
} from "./menuState";
import MenuLabel from "./MenuLabel";
import MenuUL from "./MenuUL";

type StyleProps = Omit<Partial<MenuStyleProps>, "level">;
type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLUListElement>,
  keyof StyleProps | "title"
>;

export interface MenuRootProps extends StyleProps, HTMLAttributes {
  /**
   * Sets an optional id on the menu's label area.
   */
  labelId?: string;
  /**
   * Sets the title show in the menu's label area.
   */
  title?: React.ReactNode;
  /**
   * Adds additonal, descriptive content to the menu's label area.
   */
  description?: React.ReactNode;
  /**
   * Sets the semantic role of the menu as either an interactive, actions menu
   * or a pure navigation list.
   */
  role: MenuRole;
  children: React.ReactNode;
}

const MenuRoot: React.FC<MenuRootProps> = (props) => {
  const genLabelId = React.useId();
  const {
    labelId = genLabelId,
    density = MenuDensity.Regular,
    colorScheme = ColorScheme.OnLight,
    groupLayout = "row",
    role: inRole,
    title,
    description,
    ...rest
  } = props;
  const { theme } = useTheme();
  const rootRef = React.useRef<HTMLUListElement>(null);
  const level = useMenuLevel();
  const [selected, setSelected] = React.useState<string | null>(null);
  const handleKeyDown = useMenuKeyboardHandler({
    setSelected,
    inboundHandler: props.onKeyDown,
  });
  // This hook acts both as focus state initializer.
  // It marks the first focusable menu item as selected if there is no active
  // selection.
  useIsomorphicLayoutEffect(() => {
    const rootEl = rootRef.current;
    if (!(rootEl instanceof HTMLElement)) {
      return;
    }

    const firstEl = rootEl.querySelector(`[${menuKeyProp}]:not(:disabled)`);
    const selectedEl = rootEl.querySelector(
      `[${menuKeyProp}="${selected}"]:not(:disabled)`
    );
    const el = selectedEl || firstEl;
    if (!(el instanceof HTMLElement)) {
      return;
    }

    setSelected(el.getAttribute(menuKeyProp));
  }, [rootRef, selected]);

  const styleProps = {
    level: Math.max(level, 0),
    density,
    colorScheme,
    groupLayout,
  };

  const isMenuRole = inRole === MenuRole.Menu;
  const role = isMenuRole ? inRole : undefined;

  const node = (
    <div role="presentation" css={menuRootStyle(theme, styleProps)}>
      <MenuRoleProvider value={inRole}>
        <MenuLayoutProvider value={groupLayout}>
          {title ? (
            <div id={labelId} css={menuRootLabelStyle}>
              <MenuLabel title={title} description={description} />
            </div>
          ) : null}

          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <MenuUL
            css={menuRootListStyle(theme, styleProps)}
            {...rest}
            onKeyDown={isMenuRole ? handleKeyDown : props.onKeyDown}
            role={role}
            ref={rootRef}
          >
            {props.children}
          </MenuUL>
        </MenuLayoutProvider>
      </MenuRoleProvider>
    </div>
  );

  return isMenuRole ? (
    <MenuSelectionProvider value={selected}>{node}</MenuSelectionProvider>
  ) : (
    node
  );
};

export default MenuRoot;
