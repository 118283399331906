import { keyframes } from "@emotion/react";

import { Color, CSSRulesFunction, TypeScale } from "../theme";

import type { ShimmerProps } from "./Shimmer";

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
`;

export const shimmerStyle: CSSRulesFunction<ShimmerProps> = (theme, props) => {
  const width = theme.responsive(props.width, (value) => ({
    width: value,
  }));

  const minWidth = theme.responsive(props.minWidth, (value) => ({
    minWidth: value,
  }));

  const maxWidth = theme.responsive(props.maxWidth, (value) => ({
    maxWidth: value,
  }));

  const height = theme.responsive(props.height, (value) => ({
    height: value,
  }));

  const minHeight = theme.responsive(
    props.minHeight,
    (interpolatedMinHeight) => ({
      minHeight: interpolatedMinHeight,
    })
  );

  const spaceAbove = theme.responsive(props.spaceAbove, (value, themeValue) => {
    return { marginTop: themeValue.spacing(value) };
  });

  const spaceBelow = theme.responsive(props.spaceBelow, (value, themeValue) => {
    return { marginBottom: themeValue.spacing(value) };
  });

  const spaceBefore = theme.responsive(
    props.spaceBefore,
    (value, themeValue) => {
      return { marginLeft: themeValue.spacing(value) };
    }
  );

  const spaceAfter = theme.responsive(props.spaceAfter, (value, themeValue) => {
    return { marginRight: themeValue.spacing(value) };
  });

  const borderRadius = theme.responsive(
    props.borderRadius,
    (r, themeValue) => ({
      borderRadius: themeValue.radius(r),
    })
  );

  return [
    {
      animation: `${shimmer} 2s ease infinite forwards`,
      border: "none",
      padding: 0,
      margin: 0,
      borderCollapse: "collapse",
      background: theme.color(Color.Greystone_300),
      backgroundImage: `linear-gradient(to right, ${theme.color(
        Color.Greystone_300
      )} 0%, ${theme.color(Color.Greystone_200)} 20%, ${theme.color(
        Color.Greystone_300
      )} 40%, ${theme.color(Color.Greystone_300)} 100%)`,
      backgroundRepeat: "no-repeat",
      ...theme.tokens.fontSizes[TypeScale.Size_02],
      borderRadius: 4,
      backgroundSize: "1000px 100%",
      width: "100%",
      height: theme.tokens.fontSizes[TypeScale.Size_02].lineHeight,
    },
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    spaceAbove,
    spaceBelow,
    spaceBefore,
    spaceAfter,
    borderRadius,
  ];
};
