import type * as React from "react";

import { ColorPreset, useTheme } from "../theme";

import {
  labelledPanelBodyStyle,
  labelledPanelHeaderLayoutStyle,
  labelledPanelHeaderStyle,
  labelledPanelHeadingStyle,
  labelledPanelStyle,
  LabelledPanelStyleProps,
} from "./labelledPanelStyle";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLElement>,
  keyof LabelledPanelStyleProps
>;

export interface LabelledPanelProps
  extends HTMLAttributes,
    Partial<LabelledPanelStyleProps> {
  /** Content that will be displayed in the heading area of the panel */
  heading?: React.ReactNode;

  /**
   * Content that will be displayed in after the heading the header area of the
   * panel
   */
  description?: React.ReactNode;

  /** Optional primary accessory shown next to the heading */
  primaryAccessory?: React.ReactNode;

  /** Optional secondary accessory shown next to the heading */
  secondaryAccessory?: React.ReactNode;

  children: React.ReactNode;
}

const LabelledPanel: React.FC<LabelledPanelProps> = (props) => {
  const {
    heading,
    description,
    primaryAccessory,
    secondaryAccessory,
    headerBg = ColorPreset.BackgroundLight_01,
    headerColor = ColorPreset.TextOnLight_01,
    headerBorder = props.headerBg || ColorPreset.BorderOnLight_04,
    bodyBg = ColorPreset.BackgroundLight_01,
    bodyColor = ColorPreset.TextOnLight_01,
    bodyBorder = ColorPreset.BorderOnLight_04,
    gutterV = [1, null, 1.5],
    gutterH = [1, null, 1.5],
    fontSize,
    typePreset,
    separator = "visible",
    children,
    ...rest
  } = props;
  const { theme } = useTheme();

  const hasHeader = !!(heading || description);

  const styleProps = {
    headerBg,
    headerColor,
    headerBorder,
    bodyBg,
    bodyColor,
    bodyBorder,
    gutterV,
    gutterH,
    fontSize,
    typePreset,
    separator,
    hasHeader,
  };

  return (
    <div css={labelledPanelStyle(theme, styleProps)} {...rest}>
      {hasHeader ? (
        <div
          css={[
            labelledPanelHeaderStyle(theme, styleProps),
            labelledPanelHeaderLayoutStyle(theme, {
              hasPrimaryAccessory: !!primaryAccessory,
              hasSecondaryAccessory: !!secondaryAccessory,
            }),
          ]}
        >
          {primaryAccessory ? (
            <div css={{ gridArea: "primary" }}>{primaryAccessory}</div>
          ) : null}

          <div
            css={[labelledPanelHeadingStyle(theme), { gridArea: "heading" }]}
          >
            {heading ? <div>{heading}</div> : null}
            {description ? <div>{description}</div> : null}
          </div>

          {secondaryAccessory ? (
            <div
              css={{
                gridArea: "secondary",
                justifySelf: primaryAccessory ? "end" : undefined,
              }}
            >
              {secondaryAccessory}
            </div>
          ) : null}
        </div>
      ) : null}
      <div css={labelledPanelBodyStyle(theme, styleProps)}>{children}</div>
    </div>
  );
};

export default LabelledPanel;
