import { useCallback, useEffect, useState } from "react";
import {
  AlignItems,
  Box,
  Color,
  Glyph,
  Icon,
  PlainButton,
  PlainLink,
  Text,
  Tooltip,
} from "@gocardless/flux-react";
import { ExportResource, ExportsStatus } from "@gocardless/api/dashboard/types";
import { useExportSelf } from "@gocardless/api/dashboard/export";
import { useInterval } from "react-use";
import { HTTPError } from "@gocardless/api/utils";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import ExportsError from "./ExportsError";
import { onExportUpdateFunc } from "./ExportCentreConnect";
import { ExportItemRow } from "./ExportItemRow";

import { useMultiAccountFeature } from "src/components/routes/Accounts/hooks/useMultiAccountFeature";
import { useSupportCentreUrl } from "src/common/hooks/useSupportCentreUrl";

export const formatExportFileName = (id?: string, exportType?: string) =>
  `${exportType}-export-${id}.csv`;

const POLLING_INTERVAL = 5000;

const useExportStatusMap = ({
  error,
  id,
}: {
  error?: HTTPError;
  id?: ExportResource["id"];
}) => {
  const { hasMultiAccountFeature } = useMultiAccountFeature();

  const supportUrl = useSupportCentreUrl();

  const { i18n } = useLingui();

  return {
    [ExportsStatus.Successful]: (
      <Icon name={Glyph.Tick} color={Color.Success_Base} size="13px" />
    ),
    [ExportsStatus.Pending]: (
      <Icon name={Glyph.Spinner} color={Color.Success_Base} size="13px" />
    ),
    [ExportsStatus.Processing]: (
      <Icon name={Glyph.Spinner} color={Color.Success_Base} size="13px" />
    ),
    [ExportsStatus.Unsuccessful]: (
      <Icon name={Glyph.Close} color={Color.Alert_Base} size="13px" />
    ),
    error: (
      <Tooltip
        message={
          hasMultiAccountFeature &&
          error?.response.status === HTTPStatusCode.NotFound ? (
            <Trans>
              <Text layout="block" spaceBelow={0.5}>
                There was an error retrieving this export.
              </Text>
              To access this file, please switch to the account from which it
              was originally exported. If the issue persists,{" "}
              <PlainLink
                textDecoration="underline"
                href={supportUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                contact support
              </PlainLink>
              .
            </Trans>
          ) : (
            <Trans>
              There was an error retrieving this export. Try exporting again and
              if the issue persists,{" "}
              <PlainLink
                textDecoration="underline"
                href={supportUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                contact support
              </PlainLink>
              .
            </Trans>
          )
        }
        positionStrategy="fixed"
      >
        {(triggerProps) => (
          <PlainButton
            {...triggerProps}
            aria-label={i18n._(
              t({ message: `Failure details about export ${id ?? ""}` })
            )}
          >
            <Icon name={Glyph.ExclamationOutline} />
          </PlainButton>
        )}
      </Tooltip>
    ),
  };
};

export const ExportStatus = ({
  status,
  error,
  id,
}: {
  error?: HTTPError;
  status?: ExportsStatus;
  id?: ExportResource["id"];
}) => {
  const stateMapping = useExportStatusMap({ error, id });

  if (error) {
    return (
      <Box
        gutterV={0}
        gutterH={0.5}
        layout="flex"
        alignItems={AlignItems.Center}
      >
        {stateMapping.error}
      </Box>
    );
  }

  return (
    <Box gutterV={0} gutterH={0.5}>
      {status && stateMapping[status]}
    </Box>
  );
};

const isExportFinished = (exportsStatus: ExportsStatus) =>
  exportsStatus === ExportsStatus.Successful ||
  exportsStatus === ExportsStatus.Unsuccessful;

interface ExportCentreBodyItemProps {
  totalFailures?: number;
  id: string;
  onExportUpdate: onExportUpdateFunc;
  isCentreOpen: boolean;
}

const ExportsCentreExportRowItem: React.FC<ExportCentreBodyItemProps> = (
  props
) => {
  const { totalFailures, id, onExportUpdate, isCentreOpen } = props;
  const { data, error, mutate } = useExportSelf(id);
  const [exportStatus, setExportStatus] = useState<ExportsStatus>(
    ExportsStatus.Pending
  );

  const exportResource = data?.exports;

  const refetchExport = useCallback(() => {
    if (!isExportFinished(exportStatus)) {
      mutate();
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus]);

  useEffect(() => {
    if (exportResource?.status) {
      setExportStatus(exportResource.status);
    }
  }, [exportResource?.status, setExportStatus]);

  useEffect(() => {
    if (exportResource) {
      onExportUpdate(exportResource);
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus]);

  const shouldStopPolling = isExportFinished(exportStatus) || !!error;

  useInterval(refetchExport, shouldStopPolling ? null : POLLING_INTERVAL);

  return (
    <Box>
      {isCentreOpen ? (
        <Box
          gutterH={2}
          gutterV={0.75}
          layout="flex"
          alignItems={AlignItems.Center}
        >
          <ExportItemRow
            key={id}
            {...props}
            {...exportResource}
            error={error}
          />

          {["unsuccessful", "expired"].includes(exportStatus) &&
          totalFailures === 1 ? (
            <ExportsError />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default ExportsCentreExportRowItem;
