import * as React from "react";

import {
  useTheme,
  Interpolation,
  CSSRulesFunction,
  FontFamily,
  TypePreset,
  ResponsiveValue,
  InputGutter,
  inputGutterStyle,
} from "../theme";
import { Glyph, Icon } from "../icons";

import { FormFieldStatus, resolveStatusColors } from "./status";

interface InputAppearanceProps {
  status?: FormFieldStatus;
  fontFamily?: FontFamily;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  gutter?: ResponsiveValue<InputGutter>;
}

type HTMLAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  keyof InputAppearanceProps
>;

interface InteractionState {
  focused?: boolean;
  disabled?: boolean;
}

export interface InternalProps extends InputAppearanceProps, InteractionState {}

const searchStyle: Interpolation = { display: "none" };
const searchReset: Interpolation = {
  "&[type='search']::-ms-clear": searchStyle,
  "&[type='search']::-ms-reveal": searchStyle,
  "&[type='search']::-webkit-search-decoration": searchStyle,
  "&[type='search']::-webkit-search-cancel-button": searchStyle,
  "&[type='search']::-webkit-search-results-button": searchStyle,
  "&[type='search']::-webkit-search-results-decoration": searchStyle,
};

// Autofill styling by browsers and password managers involves setting a custom
// background on filled inputs. Since the input container uses an inset shadow
// to simulate a solid border, this background color will overlap the inset
// shadow and hide it which disrupts this components appearance. We can overcome
// this by setting a transparent border on the underlying input that will shrink
// it and the impact of the custom background. The border radius will smooth out
// the input's corners since it will match its parents border radius.
const autoFillSupport: Interpolation = {
  border: "var(--input-border-width) solid transparent",
  borderRadius: "inherit",
};

const inputStyle: CSSRulesFunction<InternalProps> = (theme, props) => {
  const padding = inputGutterStyle(theme, {
    size: props.gutter,
    adjustmentVar: "--input-border-width",
  });
  const typePreset = theme.tokens.typePresets[TypePreset.Body_02];

  const fontFamily = props.fontFamily
    ? theme.tokens.fontFamilies[props.fontFamily]
    : "inherit";

  return [
    autoFillSupport,
    {
      display: "block",
      width: "100%",
      margin: 0,
      background: "none",
      cursor: props.disabled ? "not-allowed" : "auto",
      fontFamily,
      color: "inherit",
      "&:focus": {
        boxShadow: "none",
        outline: "none",
      },
    },
    padding,
    theme.responsive(typePreset.size, (fs) => {
      const size = theme.tokens.fontSizes[fs];
      const minHeight = `calc(${size.lineHeight} + ${padding} * 2)`;
      return {
        ...size,
        minHeight,
      };
    }),
    searchReset,
  ];
};

const inputContainerStyle: CSSRulesFunction<InternalProps> = (theme, props) => {
  const { status, focused, disabled } = props;

  const colors = resolveStatusColors(theme, { status, disabled });
  const { baseBorderColor, focusBorderColor, textColor, backgroundColor } =
    colors;

  let borderWidth = "1px";
  let boxShadowColor = baseBorderColor;
  if (focused) {
    borderWidth = "2px";
    boxShadowColor = focusBorderColor;
  }

  return {
    "--input-border-width": borderWidth,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    backgroundColor,
    color: textColor,
    borderRadius: theme.radius(0.5),
    boxShadow: `0 0 0 var(--input-border-width) ${boxShadowColor}`,
    transition: "box-shadow 150ms",
  };
};

const inputAccessoryStyle: Interpolation = {
  display: "flex",
  alignItems: "center",
};

const leftInputAccessoryStyle: CSSRulesFunction = (theme) => {
  return [
    inputAccessoryStyle,
    {
      "> svg": { margin: theme.spacing([0, 0, 0, 1]) },
    },
  ];
};
const rightInputAccessoryStyle: CSSRulesFunction = (theme) => {
  return [
    inputAccessoryStyle,
    {
      "> svg": { margin: theme.spacing([0, 1, 0, 0]) },
    },
  ];
};

const inputStatusIconStyle: CSSRulesFunction<InternalProps> = (
  theme,
  props
) => {
  const { iconColor } = resolveStatusColors(theme, {
    status: props.status,
    disabled: props.disabled,
  });

  if (props.disabled) {
    return { display: "none" };
  }

  return {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(1)}`,
    color: iconColor,
  };
};

export interface InputProps extends InputAppearanceProps, HTMLAttributes {
  children?: never;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref
) {
  const { theme } = useTheme();
  const { status, leftAccessory, rightAccessory, type, gutter, ...rest } =
    props;
  const { onFocus, onBlur, disabled } = props;

  const [focused, setFocused] = React.useState(false);
  const handleFocus = React.useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(
    (e) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );
  const handleBlur = React.useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(
    (e) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const styleProps = { ...props, focused, disabled };
  const statusIconCSS = inputStatusIconStyle(theme, styleProps);

  return (
    <div css={inputContainerStyle(theme, styleProps)} data-field-control>
      {React.Children.count(leftAccessory) ? (
        <div css={leftInputAccessoryStyle(theme)}>{leftAccessory}</div>
      ) : null}

      <input
        css={inputStyle(theme, styleProps)}
        type={type}
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={ref}
      />

      {status === FormFieldStatus.Success && (
        <div css={statusIconCSS} aria-hidden>
          <Icon size="10px" name={Glyph.Tick} />
        </div>
      )}

      {status === FormFieldStatus.Danger && (
        <div css={statusIconCSS} aria-hidden>
          <Icon size="10px" name={Glyph.Close} />
        </div>
      )}

      {React.Children.count(rightAccessory) ? (
        <div css={rightInputAccessoryStyle(theme)}>{rightAccessory}</div>
      ) : null}
    </div>
  );
});

export default Input;
