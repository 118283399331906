import { Box } from "../layout";
import { AlignItems } from "../theme";

import BannerIcon from "./BannerIcon";
import BannerStatusIcon from "./BannerStatusIcon";
import BannerTag from "./BannerTag";
import {
  BannerLeftAccessoryProps,
  BannerLeftAccessoryType,
  BannerVariant,
} from "./types";

const getBannerLeftAccessoryContent = (
  bannerVariant: BannerVariant,
  leftAccessory: BannerLeftAccessoryProps
) => {
  switch (leftAccessory.type) {
    case BannerLeftAccessoryType.Icon:
      return <BannerIcon variant={bannerVariant} icon={leftAccessory.name} />;

    case BannerLeftAccessoryType.Status:
      return (
        <BannerStatusIcon
          variant={bannerVariant}
          value={leftAccessory.status}
        />
      );

    case BannerLeftAccessoryType.Tag:
      return <BannerTag variant={bannerVariant} text={leftAccessory.text} />;
  }
};

export function BannerLeftAccessory({
  isVerticallyCentered,
  leftAccessory,
  variant,
}: {
  isVerticallyCentered: boolean;
  leftAccessory: BannerLeftAccessoryProps;
  variant: BannerVariant;
}) {
  return (
    <Box
      layout="flex"
      alignItems={
        isVerticallyCentered
          ? AlignItems.Center
          : [AlignItems.FlexEnd, null, AlignItems.FlexStart]
      }
      gridArea="icon"
      aria-hidden
    >
      {getBannerLeftAccessoryContent(variant, leftAccessory)}
    </Box>
  );
}
