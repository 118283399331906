import * as React from "react";

import type { Interpolation } from "../theme";

const style: Interpolation = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  width: "1px",
  margin: "0 -1px -1px 0",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
};

export type VisuallyHiddenProps = React.HTMLAttributes<HTMLSpanElement>;

const VisuallyHidden = React.forwardRef<HTMLSpanElement, VisuallyHiddenProps>(
  function VisuallyHidden(props, ref) {
    return <span css={style} {...props} ref={ref} />;
  }
);

export default VisuallyHidden;
