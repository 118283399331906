import type { ColorScheme, ResponsiveValue } from "../theme";
export enum ProgressBarVariant {
  Segmented = "segmented",
  Solid = "solid",
}

export interface ProgressBarStyleProps {
  /**
   * Sets the current value of the progress bar
   */
  value: number;
  /**
   * Sets the maximum value of the progress
   */
  max: number;
  /**
   * Sets the type of progress bar to display
   */
  variant: ProgressBarVariant;
  /**
   * Sets the colour of the progress bar
   */
  color?: ProgressBarColor;
  /**
   * Sets the color scheme of the tag which along with `color` define the
   * color of the progress bar
   */
  colorScheme: ColorScheme;
  /**
   * Controls the border radius that is applied to elements of the progress bar
   * such as its edges or its segments.
   */
  borderRadius?: ResponsiveValue<0 | 0.5>;
}

export enum ProgressBarColor {
  Primary = "primary",
  Info = "info",
}
