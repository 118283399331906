import * as React from "react";

import { useTheme, TypeScale } from "../theme";

import {
  TypographyStyleProps,
  typographyStyle,
  omitTypographyStyleProps,
} from "./appearance";

type HTMLProps = Omit<React.HTMLAttributes<HTMLHeadingElement>, "color">;

type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface HeadingProps extends HTMLProps, TypographyStyleProps {}

const createHeadingComponent = (
  Element: HeadingElement,
  defaultSize: TypeScale
) => {
  const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
    function Heading(props, ref) {
      const { theme } = useTheme();
      // Set a default size if size or preset props are set
      const size = props.size || (props.preset ? undefined : defaultSize);
      const rest = omitTypographyStyleProps(props);

      return (
        <Element
          css={typographyStyle(theme, {
            ...props,
            ...(size == null ? {} : { size }),
          })}
          {...rest}
          ref={ref}
        >
          {props.children}
        </Element>
      );
    }
  );

  Heading.displayName = Element.toUpperCase();

  return Heading;
};

export const H1 = createHeadingComponent("h1", TypeScale.Size_11);
export const H2 = createHeadingComponent("h2", TypeScale.Size_08);
export const H3 = createHeadingComponent("h3", TypeScale.Size_07);
export const H4 = createHeadingComponent("h4", TypeScale.Size_06);
export const H5 = createHeadingComponent("h5", TypeScale.Size_05);
export const H6 = createHeadingComponent("h6", TypeScale.Size_04);
