import {
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  FocusRing,
} from "../theme";
import { activeFocusWidth, focusRingStyle } from "../theme/focusRings";

import type { TabsStyleProps } from "./tabsTypes";

export const vars = {
  triggersSpacing: "--tabs-triggers-spacing",
  triggersGap: "--tabs-triggers-gap",
  triggersTrackSize: "--tabs-triggers-track-size",
  triggersTrackBg: "--tabs-triggers-track-bg",
  triggerGutterV: "--tabs-trigger-gutterv",
  triggerGutterH: "--tabs-trigger-gutterh",
  triggerFontSize: "--tabs-trigger-font-size",
  triggerLineHeight: "--tabs-trigger-line-height",
  triggerColor: "--tabs-trigger-color",
  triggerHoverColor: "--tabs-trigger-hover-color",
  triggerSelectedColor: "--tabs-trigger-selected-color",
};

const tabsColorSchemeTokens: CSSRulesFunction<ColorScheme> = (theme, cs) => {
  const lightScheme = {
    [vars.triggersTrackBg]: theme.color(ColorPreset.BorderOnLight_05),
    [vars.triggerColor]: theme.color(ColorPreset.TextOnLight_03),
    [vars.triggerHoverColor]: theme.color(ColorPreset.TextOnLight_01),
    [vars.triggerSelectedColor]: theme.color(ColorPreset.TextOnLight_01),
  };
  const darkScheme = {
    [vars.triggersTrackBg]: theme.color(ColorPreset.BorderOnDark_05),
    [vars.triggerColor]: theme.color(ColorPreset.TextOnDark_03),
    [vars.triggerHoverColor]: theme.color(ColorPreset.TextOnDark_01),
    [vars.triggerSelectedColor]: theme.color(ColorPreset.TextOnDark_01),
  };

  switch (cs) {
    case ColorScheme.OnLight:
      return lightScheme;
    case ColorScheme.OnDark:
      return darkScheme;
    case ColorScheme.Auto:
      return [
        lightScheme,
        { "@media (prefers-color-scheme: dark)": darkScheme },
      ];
  }
};

export const tabsScrollerStyle: CSSRulesFunction<
  TabsStyleProps["orientation"]
> = (theme, orientation) => {
  return [
    { overflow: "auto", flex: "0 0 auto" },
    theme.responsive(orientation, (o) => {
      switch (o) {
        case "horizontal":
          return {
            boxShadow: `inset 0 calc(-1 * var(${vars.triggersTrackSize})) 0 0 var(${vars.triggersTrackBg})`,
            margin: `0 0 var(${vars.triggersSpacing})`,
          };
        case "vertical":
          return {
            boxShadow: "none",
            margin: `0 var(${vars.triggersSpacing}) 0 0`,
          };
      }
    }),
  ];
};

export const tabsScrollIndicator: CSSRulesFunction<
  TabsStyleProps["orientation"]
> = (theme, orientation) => {
  return [
    {
      height: `var(${vars.triggersTrackSize})`,
      width: 0,
      backgroundColor: `var(${vars.triggerSelectedColor})`,
      transition: "transform 200ms, width 200ms",
      "@media (prefers-reduced-motion: reduce)": {
        transitionDuration: "50ms, 50ms",
      },
    },
    theme.responsive(orientation, (o) => {
      switch (o) {
        case "horizontal":
          return {
            display: "block",
          };
        case "vertical":
          return {
            display: "none",
          };
      }
    }),
  ];
};

export const tabsListStyle: CSSRulesFunction<
  Pick<TabsStyleProps, "orientation" | "colorScheme">
> = (theme, { orientation, colorScheme }) => {
  return [
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme,
      focusSelector: "& [role='tab']:focus-visible",
    }),
    {
      position: "relative",
      display: "grid",
      gap: `var(${vars.triggersGap})`,
    },
    theme.responsive(orientation, (o) => {
      switch (o) {
        case "horizontal":
          return {
            gridAutoFlow: "column",
            gridAutoColumns: "max-content",
            marginBottom: `calc(${theme.spacing(
              0.75
            )} - var(${activeFocusWidth}))`,
          };
        case "vertical":
          return {
            gridAutoFlow: "row",
            gridAutoRows: "max-content",
            marginBottom: 0,
          };
      }
    }),
  ];
};

export const tabsStyle: CSSRulesFunction<TabsStyleProps> = (theme, props) => {
  const o = props.orientation;

  return [
    tabsColorSchemeTokens(theme, props.colorScheme),
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme: props.colorScheme,
      focusSelector: "& [role='tabpanel']:focus-visible",
    }),
    {
      [vars.triggersTrackSize]: "3px",
      [vars.triggersGap]: theme.spacing(1),
      [vars.triggerGutterV]: theme.spacing(0.75),
      [vars.triggerGutterH]: 0,
      [vars.triggerFontSize]: "inherit",
      [vars.triggerLineHeight]: "inherit",

      display: "flex",
      flexDirection: o === "vertical" ? "row" : "column",
      overflow: o === "vertical" ? "auto" : "initial",
    },
    theme.responsive(props.triggerFontSize, (fs) => {
      const value = theme.tokens.fontSizes[fs];
      return {
        [vars.triggerFontSize]: value.fontSize,
        [vars.triggerLineHeight]: value.lineHeight,
      };
    }),
    theme.responsive(props.spacing, (s) => {
      return {
        [vars.triggersSpacing]: theme.spacing(s),
      };
    }),
  ];
};
