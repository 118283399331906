import type * as React from "react";

import { Shimmer } from "../../animations";
import { useTheme } from "../../theme";
import UL from "../UL";
import LI from "../LI";

import { dynamicListItemStyle, dynamicListStyle } from "./styles";
import type { DynamicListProps } from "./types";

const DynamicList: React.FC<DynamicListProps> = (props) => {
  const { theme } = useTheme();
  const { border = "top-bottom", placeholderCount, loading } = props;

  const shimmers = Array(placeholderCount || 5).fill(
    <LI marker="none" css={[dynamicListItemStyle(theme)]}>
      <Shimmer />
    </LI>
  );
  return (
    <UL marker="none" gutterH={0} css={dynamicListStyle(theme, { border })}>
      {loading ? shimmers : props.children}
    </UL>
  );
};

export default DynamicList;
