import { BannerActionProps, BannerActionType, BannerVariant } from "./types";
import BannerAction from "./BannerAction";
import { setButtonVariant } from "./utils";

export interface SecondaryActionProps {
  variant: BannerVariant;
  value?: BannerActionProps;
  /**
   * If there isn't a primary action set then increase the prominance of this
   * action.
   */
  prioritized?: boolean;
}

const SecondaryAction: React.FC<SecondaryActionProps> = (props) => {
  if (props.value == null) {
    return null;
  }

  const btnVariant = setButtonVariant(
    props.variant,
    BannerActionType.Secondary,
    props.value.buttonVariant
  );

  return <BannerAction {...props.value} variant={btnVariant} />;
};

export default SecondaryAction;
