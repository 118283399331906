import { useRouter } from "next/router";

import { SegmentAbs } from "../segmentAbs";

import { useAccessToken } from "src/common/authorisation";
import { PublicOnlyRoutesWithoutSegment } from "src/common/routing/routes";

export const Segment: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [details] = useAccessToken();
  const router = useRouter();

  // We don't want to load Segment on sign-in page as it generates extra aIDs
  const isIgnoredRoute = PublicOnlyRoutesWithoutSegment.includes(router.route);

  return isIgnoredRoute ? (
    <>{children}</>
  ) : (
    <SegmentAbs details={details}>{children}</SegmentAbs>
  );
};
