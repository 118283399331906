import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useControlledState } from "@react-stately/utils";

import type { InputProps } from "../Input";
import type { FormFieldStatus } from "../status";
import { useTooltip } from "../../tooltip/hooks";
import { useTapOut } from "../../hooks/pointers";
import { useKeyDown } from "../../hooks/useKeyDown";
import usePrevious from "../../hooks/usePrevious";
import BodyPortal from "../../BodyPortal";
import { useTheme, ZIndex } from "../../theme";
import { Box } from "../../layout";

import { ListItem } from "./ListItem";
import type { DefaultListItemProps, RenderItemProps } from "./List.types";
import { List, ListProps } from "./List";
import { ListWrapper } from "./ListWrapper";
import { RenderValueProps, Trigger } from "./Trigger";
import { noop } from "./List.utils";

export interface SelectInputProps<Item = DefaultListItemProps>
  extends Omit<ListProps<Item>, "flip" | "renderItem"> {
  /**
   * should the selectInput be disabled
   */
  disabled?: boolean;
  /**
   * can reset the selected item
   */
  clearable?: boolean;
  /**
   * placeholder message for the trigger, true by default
   */
  placeholder?: string;
  /**
   * is in loading state
   */
  loading?: boolean;
  /**
   * Number of shimmers displayed when in loading state (if no items yet returned only)
   */
  loadingCount?: number;
  /**
   * message displayed when no results
   */
  emptyMessage?: React.ReactNode;
  /**
   * selected Items
   */
  value?: Item | null;
  /**
   * default selected Item
   */
  defaultValue?: Item;
  /**
   * id of the selectInput
   */
  id?: string;
  /**
   * should the list of options render in a portal or as an absolute div
   * Typically in a modal you should prefer the `false` option to avoid incorrect positioning
   */
  usePortal?: boolean;
  /**
   * callback fn whenever choosing an option
   */
  onChange?: (item?: Item | null) => void;
  /**
   * callback whenever the open state changes
   */
  onClose?: () => void;
  /**
   * customize the trigger rendering
   */
  renderValue?: (valueProps: RenderValueProps<Item>) => React.ReactNode;
  /**
   * customize the listitem rendering
   */
  renderItem?: (itemProps: RenderItemProps<Item>) => React.ReactNode;
  /**
   * Icon to be prefixed to placeholder
   */
  leftAccessory?: React.ReactNode;
  /**
   * items to pass in the list props
   */
  items: Item[];
  /**
   * props to pass to the search input
   */
  searchInputProps?: InputProps;
  /**
   * status of the trigger field
   */
  status?: FormFieldStatus;
  /**
   * max height of the list
   */
  maxHeight?: number;
  /**
   * virtualize the list, if not provided, the list will be virtualized from 100 items retrieved
   */
  virtualize?: boolean;
  /**
   * pass a footer component to the bottom of the list, contains the flip and status context as a props
   */
  footer?: (props: {
    flip?: boolean;
    status?: FormFieldStatus;
  }) => React.ReactNode;
}

export const SelectInput = <Item extends DefaultListItemProps>({
  loading,
  placeholder,
  disabled,
  clearable = true,
  renderValue,
  items = [],
  value: inValue,
  defaultValue: inDefault,
  loadingCount,
  emptyMessage,
  onChange: inOnChange = noop,
  searchInputProps,
  renderItem,
  onClose,
  usePortal,
  leftAccessory,
  status,
  id,
  ...props
}: SelectInputProps<Item>) => {
  const { theme } = useTheme();

  const [value, setValue] = useControlledState(
    inValue as unknown,
    inDefault || null,
    inOnChange
  );

  const [isOpen, setIsOpen] = useState(false);

  const prevIsOpen = usePrevious(isOpen);

  const {
    floatingStyles,
    refs,
    placement: finalPlacement,
  } = useTooltip({
    open: isOpen,
    initialPlacement: "bottom",
    placementStrategy: "flip",
    positionStrategy: "absolute",
    sameWidth: true,
  });

  const closeCallout = useCallback(() => {
    setIsOpen(false);
  }, []);

  useTapOut(refs.floating, closeCallout);
  useKeyDown("Escape", closeCallout);
  useKeyDown("Tab", closeCallout);

  useEffect(() => {
    // was open but now is closed
    if (prevIsOpen && !isOpen) {
      onClose?.();
      if (refs.reference.current) {
        // preventing from scrolling when the dropdown is open
        (refs.reference.current as HTMLElement).focus({ preventScroll: true });
      }
    }
  }, [prevIsOpen, isOpen, refs.reference, onClose]);

  const Portal = useMemo(
    () => (usePortal ? BodyPortal : Fragment),
    [usePortal]
  );

  return (
    <>
      <Trigger<Item>
        clearable={clearable}
        value={value as Item}
        placeholder={placeholder}
        disabled={disabled}
        loading={!!loading}
        isOpen={isOpen}
        setReferenceElement={refs.setReference}
        renderValue={renderValue}
        onClear={() => {
          setValue(null);
          closeCallout();
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        leftAccessory={leftAccessory}
        status={status}
        id={id}
      />
      {isOpen ? (
        <Portal>
          <ListWrapper
            role="list"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: usePortal ? ZIndex.Dialog + 1 : 1,
              border: "none",
            }}
          >
            <List<Item>
              focusListOnMount
              loadingCount={loadingCount || 5}
              emptyMessage={emptyMessage}
              flip={finalPlacement === "top"}
              items={items}
              loading={loading}
              onChange={(item: Item) => {
                setValue(item);
                closeCallout();
              }}
              status={status}
              searchInputProps={searchInputProps}
              renderItem={(listItemProps) => {
                const itemProps = {
                  ...listItemProps,
                  isSelected:
                    (value as Item)?.value === listItemProps.item.value,
                };

                if (renderItem) {
                  return renderItem(itemProps);
                }
                const isFirstItem = listItemProps.index === 0;

                return (
                  <Box
                    key={listItemProps.item.value}
                    css={{
                      paddingTop: isFirstItem ? theme.spacing(0.5) : 0,
                    }}
                    gutterH={0.5}
                  >
                    <ListItem {...itemProps}>
                      {listItemProps.item.label}
                    </ListItem>
                  </Box>
                );
              }}
              {...props}
            />
          </ListWrapper>
        </Portal>
      ) : null}
    </>
  );
};

export default SelectInput;
