import type { CSSRulesFunction } from "../theme";

import { alertTokens } from "./alert";
import { primaryTokens } from "./primary";
import { secondaryTokens } from "./secondary";
import { textTokens } from "./text";
import { ButtonVariant, ButtonStyleProps } from "./types";

export const buttonVariantTokenStyle: CSSRulesFunction<
  Pick<ButtonStyleProps, "variant">
> = (theme, { variant }) => {
  switch (variant) {
    case ButtonVariant.PrimaryOnLight:
    case ButtonVariant.PrimaryOnDark:
    case ButtonVariant.PrimaryWhiteOnDark:
    case ButtonVariant.PrimaryAuto:
      return primaryTokens(theme);
    case ButtonVariant.SecondaryOnLight:
    case ButtonVariant.SecondaryOnDark:
    case ButtonVariant.SecondaryAuto:
      return secondaryTokens(theme);
    case ButtonVariant.AlertOnLight:
    case ButtonVariant.AlertOnDark:
    case ButtonVariant.AlertAuto:
      return alertTokens(theme);
    case ButtonVariant.TextOnLight:
    case ButtonVariant.TextOnDark:
    case ButtonVariant.TextAuto:
      return textTokens(theme);
    case ButtonVariant.Inline:
    case ButtonVariant.InlineUnderlined:
      return null;
  }
};
