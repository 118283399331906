import { Color, ColorScheme, CSSRulesFunction } from "../../theme";

import { ControlVar, ToggleControl } from "./toggleTypes";

interface ControlStyleProps {
  colorScheme: ColorScheme;
  control: ToggleControl;
  focusSelector: string;
  focusSelectedSelector: string;
  selectedSelector: string;
  disabledSelector: string;
  disabledUnselectedSelector: string;
  hoverSelectedSelector: string;
  hoverUnselectedSelector: string;
}

export const controlStyle: CSSRulesFunction<ControlStyleProps> = (
  theme,
  props
) => {
  const {
    control,
    colorScheme,
    focusSelector,
    focusSelectedSelector,
    disabledSelector,
    disabledUnselectedSelector,
    hoverSelectedSelector,
    hoverUnselectedSelector,
    selectedSelector,
  } = props;
  const isSwitch = control === ToggleControl.Switch;
  const isRadio = control === ToggleControl.Radio;
  const isCheck = control === ToggleControl.Check;

  const light = {
    [ControlVar.Bg]: isSwitch
      ? theme.color(Color.Greystone_Base)
      : "transparent",
    [ControlVar.Fg]: theme.color(Color.Brownstone_50),
    [ControlVar.OutlineColor]: isSwitch
      ? theme.color(Color.Brownstone_1400)
      : "transparent",
    [ControlVar.BorderColor]: isSwitch
      ? theme.color(Color.Brownstone_1400)
      : theme.color(Color.Greystone_DarkMatter),

    [selectedSelector]: {
      [ControlVar.Bg]: theme.color(Color.Greystone_DarkMatter),
      [ControlVar.Fg]: theme.color(Color.Brownstone_50),
      [ControlVar.BorderColor]: theme.color(Color.Greystone_DarkMatter),
    },

    [focusSelector]: {
      [ControlVar.BorderColor]: isSwitch
        ? theme.color(Color.Brownstone_1400)
        : theme.color(Color.Greystone_DarkMatter),
      [ControlVar.OutlineColor]: theme.color(Color.White),
    },

    [hoverUnselectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Greystone_500)
        : "transparent",
      [ControlVar.OutlineColor]: isRadio
        ? "transparent"
        : theme.color(Color.Greystone_1200),
      [ControlVar.BorderColor]: theme.color(Color.Greystone_1200),
    },

    [hoverSelectedSelector]: {
      [ControlVar.Bg]: theme.color(Color.Greystone_1200),
      [ControlVar.OutlineColor]: theme.color(Color.Greystone_1200),
      [ControlVar.BorderColor]: theme.color(Color.Greystone_1200),
    },

    [disabledSelector]: {
      [ControlVar.Fg]: theme.color(
        isCheck
          ? Color.Greystone_1400_A38
          : isRadio
          ? Color.Greystone_1400_A16
          : Color.White
      ),
      [ControlVar.Bg]: theme.color(Color.Greystone_1400_A8),
      [ControlVar.BorderColor]: isRadio
        ? theme.color(Color.Greystone_1400_A8)
        : "transparent",
    },
    [disabledUnselectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Greystone_1400_A8)
        : "transparent",
      [ControlVar.BorderColor]: isSwitch
        ? "transparent"
        : theme.color(Color.Greystone_1400_A8),
    },
  };
  const dark = {
    [ControlVar.Bg]: isSwitch
      ? theme.color(Color.Greystone_1100)
      : isRadio
      ? "transparent"
      : theme.color(Color.Slate_900),
    [ControlVar.Fg]: theme.color(Color.Greystone_DarkMatter),
    [ControlVar.BorderColor]: theme.color(Color.Greystone_50),
    [ControlVar.OutlineColor]: "transparent",

    [selectedSelector]: {
      [ControlVar.Bg]: theme.color(Color.Greystone_50),
      [ControlVar.Fg]: theme.color(Color.Greystone_DarkMatter),
      [ControlVar.BorderColor]: isCheck
        ? theme.color(Color.Greystone_DarkMatter)
        : theme.color(Color.Greystone_50),
    },

    [focusSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Greystone_1100)
        : "transparent",
      [ControlVar.BorderColor]: theme.color(Color.Greystone_50),
      [ControlVar.OutlineColor]: theme.color(Color.Slate_1100),
    },

    [focusSelectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.White)
        : isRadio
        ? "transparent"
        : theme.color(Color.Greystone_50),
      [ControlVar.BorderColor]: theme.color(Color.Greystone_50),
      [ControlVar.OutlineColor]: theme.color(Color.Slate_1100),
    },

    [hoverUnselectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Greystone_1300)
        : theme.color(Color.Slate_900),
      [ControlVar.OutlineColor]: isRadio
        ? "transparent"
        : theme.color(Color.Brownstone_400),
      [ControlVar.BorderColor]: theme.color(Color.Brownstone_400),
    },

    [hoverSelectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Brownstone_700)
        : theme.color(Color.Brownstone_400),
      [ControlVar.OutlineColor]: theme.color(Color.Brownstone_400),
      [ControlVar.BorderColor]: theme.color(Color.Brownstone_400),
    },

    [disabledSelector]: {
      [ControlVar.Fg]: theme.color(Color.Greystone_700_A38),
      [ControlVar.Bg]: theme.color(Color.Greystone_700_A16),
      [ControlVar.BorderColor]: isRadio
        ? theme.color(Color.Greystone_700_A16)
        : "transparent",
    },
    [disabledUnselectedSelector]: {
      [ControlVar.Bg]: isSwitch
        ? theme.color(Color.Greystone_700_A16)
        : "transparent",
      [ControlVar.BorderColor]: isSwitch
        ? "transparent"
        : theme.color(Color.Greystone_700_A16),
    },
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};
