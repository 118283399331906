import { Glyph, Icon } from "../../icons";
import type { Interpolation } from "../../theme";

import { ControlVar, ToggleControlProps } from "./toggleTypes";

const outlineWidth = `calc(var(${ControlVar.OutlineOn}) * 1px)`;

const toggleCheckStyle: Interpolation = {
  width: "24px",
  height: "24px",
  display: "flex",
  borderRadius: "4px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `
  inset 0 0 0 1px var(${ControlVar.BorderColor}),
  0 0 0 ${outlineWidth} var(${ControlVar.OutlineColor})
  `,
  color: `var(${ControlVar.Fg})`,
  backgroundColor: `var(${ControlVar.Bg})`,

  transition: "box-shadow 100ms, background 100ms, color 100ms",
  "@media (prefers-reduced-motion: reduce)": {
    transition: "box-shadow 50ms, background 50ms, color 50ms",
  },
  "> svg": {
    opacity: `var(${ControlVar.On})`,
    transition: "opacity 100ms",
    "@media (prefers-reduced-motion: reduce)": {
      transition: "opacity 50ms",
    },
  },
};

const ToggleCheck: React.FC<ToggleControlProps> = ({ indeterminate }) => {
  return (
    <span css={toggleCheckStyle} role="presentation">
      <Icon size="12px" name={indeterminate ? Glyph.Subtract : Glyph.Tick} />
    </span>
  );
};

export default ToggleCheck;
