import * as React from "react";

import { VisuallyHidden } from "../../a11y";
import { PlainButton } from "../../buttons";
import { Icon, Glyph } from "../../icons";
import { Interpolation, useTheme } from "../../theme";

import { datePickerTriggerStyle } from "./datePickerStyle";

export interface DatePickerTriggerProps {
  disabled?: boolean;
  onClick: () => void;
  label: React.ReactNode;
  triggerButton?: {
    style?: Interpolation;
    children: React.ReactNode;
  };
}

const DatePickerTrigger = React.forwardRef<
  HTMLButtonElement,
  DatePickerTriggerProps
>((props, ref) => {
  const { theme } = useTheme();
  const { onClick, label, triggerButton } = props;

  return (
    <PlainButton
      ref={ref}
      onClick={onClick}
      disabled={props.disabled}
      css={datePickerTriggerStyle(theme, props)}
    >
      {triggerButton?.children ? (
        triggerButton?.children
      ) : (
        <>
          <Icon name={Glyph.Calendar} size="14px" />
          <VisuallyHidden>{label}</VisuallyHidden>
        </>
      )}
    </PlainButton>
  );
});

export default DatePickerTrigger;
