import {
  AvailableDebitSchemeEnum as Scheme,
  DetailsScheme,
} from "@gocardless/api/dashboard/types";
import { HomeSchemeEnum } from "@gocardless/api/staff/types";
import { Glyph } from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
export { HomeSchemeEnum } from "@gocardless/api/staff/types";
export { AvailableDebitSchemeEnum as Scheme } from "@gocardless/api/dashboard/types";
import { t } from "@lingui/macro";

import { Currency } from "./currencies";

export type AllScheme = Scheme | HomeSchemeEnum | DetailsScheme;

export const schemeToZoneName = (): Record<AllScheme, string> => ({
  [Scheme.Ach]: i18n._(t({ id: "United States", message: "United States" })),
  [Scheme.Autogiro]: i18n._(t({ id: "Sweden", message: "Sweden" })),
  [Scheme.Bacs]: i18n._(t({ id: "United Kingdom", message: "United Kingdom" })),
  [Scheme.Becs]: i18n._(t({ id: "Australia", message: "Australia" })),
  [Scheme.BecsNz]: i18n._(t({ id: "New Zealand", message: "New Zealand" })),
  [Scheme.Betalingsservice]: i18n._(t({ id: "Denmark", message: "Denmark" })),
  [Scheme.Pad]: i18n._(t({ id: "Canada", message: "Canada" })),
  [Scheme.SepaCore]: i18n._(t({ id: "Eurozone", message: "Eurozone" })),
  [HomeSchemeEnum.Sepa]: i18n._(t({ id: "Eurozone", message: "Eurozone" })),
  [DetailsScheme.SepaCor1]: i18n._(t({ id: "Eurozone", message: "Eurozone" })),
  [HomeSchemeEnum.FasterPayments]: i18n._(
    t({ id: "United Kingdom", message: "United Kingdom" })
  ),
  [HomeSchemeEnum.SepaCreditTransfer]: i18n._(
    t({ id: "Eurozone", message: "Eurozone" })
  ),
  [HomeSchemeEnum.SepaInstantCreditTransfer]: i18n._(
    t({ id: "Eurozone", message: "Eurozone" })
  ),
  [HomeSchemeEnum.PayTo]: i18n._(t({ id: "Australia", message: "Australia" })),
});

export const schemeToShortZoneName: Record<AllScheme, string> = {
  [Scheme.Ach]: `US`,
  [Scheme.Autogiro]: `Sweden`,
  [Scheme.Bacs]: `UK`,
  [Scheme.Becs]: `Australia`,
  [Scheme.BecsNz]: `New Zealand`,
  [Scheme.Betalingsservice]: `Denmark`,
  [Scheme.Pad]: `Canada`,
  [Scheme.SepaCore]: `Eurozone`,
  [HomeSchemeEnum.Sepa]: `Eurozone`,
  [DetailsScheme.SepaCor1]: `Eurozone`,
  [HomeSchemeEnum.FasterPayments]: `UK`,
  [HomeSchemeEnum.SepaCreditTransfer]: `Eurozone`,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: `Eurozone`,
  [HomeSchemeEnum.PayTo]: `Australia`,
};

export const schemeToCurrency: Record<AllScheme, Currency> = {
  [Scheme.Ach]: Currency.Usd,
  [Scheme.Autogiro]: Currency.Sek,
  [Scheme.Bacs]: Currency.Gbp,
  [Scheme.Becs]: Currency.Aud,
  [Scheme.BecsNz]: Currency.Nzd,
  [Scheme.Betalingsservice]: Currency.Dkk,
  [Scheme.Pad]: Currency.Cad,
  [Scheme.SepaCore]: Currency.Eur,
  [HomeSchemeEnum.Sepa]: Currency.Eur,
  [DetailsScheme.SepaCor1]: Currency.Eur,
  [HomeSchemeEnum.FasterPayments]: Currency.Gbp,
  [HomeSchemeEnum.SepaCreditTransfer]: Currency.Eur,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: Currency.Eur,
  [HomeSchemeEnum.PayTo]: Currency.Aud,
};

export const currencyToInstantScheme: Record<
  Currency,
  HomeSchemeEnum | undefined
> = {
  [Currency.Eur]: HomeSchemeEnum.SepaInstantCreditTransfer,
  [Currency.Gbp]: HomeSchemeEnum.FasterPayments,
  [Currency.Usd]: undefined,
  [Currency.Sek]: undefined,
  [Currency.Aud]: undefined,
  [Currency.Nzd]: undefined,
  [Currency.Dkk]: undefined,
  [Currency.Cad]: undefined,
};

export const currencyToScheme: Record<Currency, Scheme> = {
  [Currency.Usd]: Scheme.Ach,
  [Currency.Sek]: Scheme.Autogiro,
  [Currency.Gbp]: Scheme.Bacs,
  [Currency.Aud]: Scheme.Becs,
  [Currency.Nzd]: Scheme.BecsNz,
  [Currency.Dkk]: Scheme.Betalingsservice,
  [Currency.Cad]: Scheme.Pad,
  [Currency.Eur]: Scheme.SepaCore,
};

export const schemeToCountryIconGlyph: Record<AllScheme, Glyph> = {
  [Scheme.Ach]: Glyph.FlagUS,
  [Scheme.Autogiro]: Glyph.FlagSE,
  [Scheme.Bacs]: Glyph.FlagUK,
  [Scheme.Becs]: Glyph.FlagAU,
  [Scheme.BecsNz]: Glyph.FlagNZ,
  [Scheme.Betalingsservice]: Glyph.FlagDK,
  [Scheme.Pad]: Glyph.FlagCA,
  [Scheme.SepaCore]: Glyph.FlagEU,
  [HomeSchemeEnum.Sepa]: Glyph.FlagEU,
  [DetailsScheme.SepaCor1]: Glyph.FlagEU,
  [HomeSchemeEnum.FasterPayments]: Glyph.FlagUK,
  [HomeSchemeEnum.SepaCreditTransfer]: Glyph.FlagEU,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: Glyph.FlagEU,
  [HomeSchemeEnum.PayTo]: Glyph.FlagAU,
};

export const schemeToDisabledCountryIconGlyph: Record<AllScheme, Glyph> = {
  [Scheme.Ach]: Glyph.FlagUSDisabled,
  [Scheme.Autogiro]: Glyph.FlagSEDisabled,
  [Scheme.Bacs]: Glyph.FlagUKDisabled,
  [Scheme.Becs]: Glyph.FlagAUDisabled,
  [Scheme.BecsNz]: Glyph.FlagNZDisabled,
  [Scheme.Betalingsservice]: Glyph.FlagDKDisabled,
  [Scheme.Pad]: Glyph.FlagCADisabled,
  [Scheme.SepaCore]: Glyph.FlagEUDisabled,
  [HomeSchemeEnum.Sepa]: Glyph.FlagEUDisabled,
  [DetailsScheme.SepaCor1]: Glyph.FlagEUDisabled,
  [HomeSchemeEnum.FasterPayments]: Glyph.FlagUKDisabled,
  [HomeSchemeEnum.SepaCreditTransfer]: Glyph.FlagEUDisabled,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: Glyph.FlagEUDisabled,
  [HomeSchemeEnum.PayTo]: Glyph.FlagAUDisabled,
};

export const schemeToIsDebitScheme: Record<AllScheme, boolean> = {
  [Scheme.Ach]: true,
  [Scheme.Autogiro]: true,
  [Scheme.Bacs]: true,
  [Scheme.Becs]: true,
  [Scheme.BecsNz]: true,
  [Scheme.Betalingsservice]: true,
  [Scheme.Pad]: true,
  [Scheme.SepaCore]: true,
  [HomeSchemeEnum.Sepa]: true,
  [DetailsScheme.SepaCor1]: true,
  [HomeSchemeEnum.FasterPayments]: false,
  [HomeSchemeEnum.SepaCreditTransfer]: false,
  [HomeSchemeEnum.SepaInstantCreditTransfer]: false,
  [HomeSchemeEnum.PayTo]: false,
};

// TODO: This case statement won't compile, as we don't have all the
// HomeSchemeEnum values available to map into Scheme. Once we do, we can
// uncomment it- but we don't use this right now, so perhaps it's unnecessary?
/*
 * export const sunSchemeToScheme: Record<HomeSchemeEnum, Scheme> = {
 *   [HomeSchemeEnum.Ach]: Scheme.Ach,
 *   [HomeSchemeEnum.Autogiro]: Scheme.Autogiro,
 *   [HomeSchemeEnum.Bacs]: Scheme.Bacs,
 *   [HomeSchemeEnum.Becs]: Scheme.Becs,
 *   [HomeSchemeEnum.BecsNz]: Scheme.BecsNz,
 *   [HomeSchemeEnum.Betalingsservice]: Scheme.Betalingsservice,
 *   [HomeSchemeEnum.Pad]: Scheme.Pad,
 *   [HomeSchemeEnum.Sepa]: Scheme.SepaCore,
 *   [HomeSchemeEnum.FasterPayments]: Scheme.FasterPayments, // TODO
 * };
 */
