import {
  Color,
  ColorScheme,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  TypeScale,
  ZIndex,
} from "../theme";

import type { TooltipStyleProps } from "./types";

export const vars = {
  gutterV: "--tooltip-gutterv",
  gutterH: "--tooltip-gutterH",
  bg: "--tooltip-bg",
  color: "--tooltip-color",
  borderRadius: "--tooltip-border-radius",
  elevation: "--tooltip-elevation",
  position: "--tooltip-position",
  top: "--tooltip-top",
  left: "--tooltip-left",

  bodyFontSize: "--tooltip-body-fontsize",
  bodyLineHeight: "--tooltip-body-lineheight",
  titleFontSize: "--tooltip-title-fontsize",
  titleLineHeight: "--tooltip-title-lineheight",
  titleSpacing: "--tooltip-title-spacing",

  arrowSize: "--tooltip-arrow-size",
  arrowTop: "--tooltip-arrow-top",
  arrowRight: "--tooltip-arrow-right",
  arrowBottom: "--tooltip-arrow-bottom",
  arrowLeft: "--tooltip-arrow-left",
  arrowTransform: "--tooltip-arrow-transform",
};

const visibleStyle: Interpolation = {
  visibility: "visible",
  opacity: 1,
};
const hidingStyle: Interpolation = {
  visibility: "visible",
  opacity: 0,
};
const hiddenStyle: Interpolation = {
  visibility: "hidden",
  opacity: 0,
};

export const tooltipHostStyle: Interpolation = {
  position: "absolute",
  top: `var(${vars.top})`,
  left: `var(${vars.left})`,
  width: "max-content",
  maxWidth: "300px",
  zIndex: ZIndex.Tooltip,
  filter: `var(${vars.elevation})`,

  transition: "opacity 200ms",
  "@media (prefers-reduced-motion: reduce)": {
    transition: "opacity 50ms",
  },

  "&": hiddenStyle,

  "&.tooltip-enter": hidingStyle,
  "&.tooltip-enter-active": visibleStyle,
  "&.tooltip-enter-done": visibleStyle,

  "&.tooltip-exit": visibleStyle,
  "&.tooltip-exit-active": hidingStyle,
  "&.tooltip-exit-done": hiddenStyle,
};

export const tooltipStyle: Interpolation = {
  padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
  backgroundColor: `var(${vars.bg})`,
  color: `var(${vars.color})`,
  borderRadius: `var(${vars.borderRadius})`,
  fontSize: `var(${vars.bodyFontSize})`,
  lineHeight: `var(${vars.bodyLineHeight})`,
  fontWeight: FontWeight.Normal,
};

export const tooltipShadowStyle: Interpolation = {
  display: "none",
};

export const tooltipTitleStyle: Interpolation = {
  fontWeight: FontWeight.SemiBold,
  fontSize: `var(${vars.titleFontSize})`,
  lineHeight: `var(${vars.titleLineHeight})`,
  marginBottom: `var(${vars.titleSpacing})`,
};

export const tooltipArrowStyle: Interpolation = {
  width: `var(${vars.arrowSize})`,
  height: `var(${vars.arrowSize})`,
  position: "absolute",
  top: `var(${vars.arrowTop})`,
  right: `var(${vars.arrowRight})`,
  bottom: `var(${vars.arrowBottom})`,
  left: `var(${vars.arrowLeft})`,
  borderRadius: "1px",
  transform: `var(${vars.arrowTransform})`,

  transformOrigin: "center",
  zIndex: -1,
  background: `var(${vars.bg})`,
};

const tooltipColorTokens: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  const light = {
    [vars.bg]: theme.color(Color.Greystone_DarkMatter),
    [vars.color]: theme.color(Color.Brownstone_50),
  };
  const dark = {
    [vars.bg]: theme.color(Color.White),
    [vars.color]: theme.color(Color.Greystone_DarkMatter),
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

export const tooltipRootStyle: CSSRulesFunction<TooltipStyleProps> = (
  theme,
  props
) => {
  const bfs = theme.tokens.fontSizes[TypeScale.Size_02];
  const tfs = theme.tokens.fontSizes[TypeScale.Size_03];
  return [
    { all: "initial", boxSizing: "border-box" },
    tooltipColorTokens(theme, props.colorScheme),
    {
      [vars.gutterV]: theme.spacing(1),
      [vars.gutterH]: theme.spacing(1),
      [vars.borderRadius]: theme.radius(1),
      [vars.elevation]: theme.tokens.elevation[2],
      [vars.arrowSize]: "8px",
      [vars.arrowTransform]: "rotate(45deg)",

      [vars.bodyFontSize]: bfs.fontSize,
      [vars.bodyLineHeight]: bfs.lineHeight,
      [vars.titleFontSize]: tfs.fontSize,
      [vars.titleLineHeight]: tfs.lineHeight,
      [vars.titleSpacing]: theme.spacing(0.25),
    },
    {
      display: "inline-block",
      position: "relative",
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
    },
  ];
};
