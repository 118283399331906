import { Color, ColorScheme, CSSRulesFunction, FocusRing } from "./types";
import { focusRingVar } from "./cssvariables";

interface FocusRingArgs {
  variant: FocusRing;
  colorScheme: ColorScheme;
  focusSelector: string;
}

const focusWidth = {
  inset: "2px",
  thin: "1px",
  heavy: "4px",
};

export const activeFocusWidth = "--active-focus-width";

const heavyFocusRing: CSSRulesFunction<FocusRingArgs> = (theme, args) => {
  const { colorScheme, focusSelector } = args;

  const totalFocusWidth = `calc(${focusWidth.heavy} + ${focusWidth.thin})`;
  const onLightFocusRing = {
    [activeFocusWidth]: totalFocusWidth,
    [focusSelector]: {
      outline: "none",
      [focusRingVar]: `0 0 0 ${focusWidth.thin} ${theme.color(
        Color.Brownstone_50
      )}, 0 0 0 ${focusWidth.heavy} ${theme.color(Color.Greystone_DarkMatter)}`,
    },
  };

  const onDarkFocusRing = {
    [activeFocusWidth]: totalFocusWidth,
    [focusSelector]: {
      outline: "none",
      [focusRingVar]: `0 0 0 ${focusWidth.thin} ${theme.color(
        Color.Slate_1100
      )}, 0 0 0 ${focusWidth.heavy} ${theme.color(Color.Brownstone_50)}`,
    },
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return onLightFocusRing;
    case ColorScheme.OnDark:
      return onDarkFocusRing;
    case ColorScheme.Auto:
      return [
        onLightFocusRing,
        { "@media (prefers-color-scheme: dark)": onDarkFocusRing },
      ];
  }
};

const innerFocusRing: CSSRulesFunction<FocusRingArgs> = (theme, args) => {
  const { colorScheme, focusSelector } = args;

  const onLightFocusRing = {
    [activeFocusWidth]: `${focusWidth.inset}`,
    [focusSelector]: {
      outline: "none",
      [focusRingVar]: `inset 0 0 0 ${focusWidth.inset} ${theme.color(
        Color.Greystone_DarkMatter
      )}`,
    },
  };

  const onDarkFocusRing = {
    [activeFocusWidth]: `${focusWidth.inset}`,
    [focusSelector]: {
      outline: "none",
      [focusRingVar]: `inset 0 0 0 ${focusWidth.inset} ${theme.color(
        Color.Brownstone_50
      )}`,
    },
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return onLightFocusRing;
    case ColorScheme.OnDark:
      return onDarkFocusRing;
    case ColorScheme.Auto:
      return [
        onLightFocusRing,
        { "@media (prefers-color-scheme: dark)": onDarkFocusRing },
      ];
  }
};

export const focusRingStyle: CSSRulesFunction<FocusRingArgs> = (
  theme,
  props
) => {
  switch (props.variant) {
    case FocusRing.Heavy:
      return heavyFocusRing(theme, props);
    case FocusRing.Inner:
      return innerFocusRing(theme, props);
  }
};
