import {
  Color,
  CSSRulesFunction,
  FontWeight,
  TextAlign,
  ColorPreset,
  Interpolation,
} from "../theme";
import { typographyStyle } from "../typography";

import {
  DataTableTextOverflow,
  type DataTableCellStyleProps,
  type DataTableHeadingStyleProps,
} from "./componentTypes";

const vars = {
  borderColor: "--datatable-border-color",
  headingColor: "--datatable-heading-color",
  headingItemSpacing: "--datatable-item-spacing",
  rowHoverBg: "--datatable-row-hover-bg",
  rowFocusedOutline: "--datatable-row-focused-outline",
};

export const dataTableStyle: CSSRulesFunction = (theme) => {
  return [
    {
      [vars.headingColor]: theme.color(ColorPreset.TextOnLight_03),
      [vars.headingItemSpacing]: theme.spacing(0.5),
      [vars.borderColor]: theme.color(ColorPreset.BorderOnLight_04),
      [vars.rowHoverBg]: theme.color(Color.Brownstone_700_A12),
      [vars.rowFocusedOutline]: theme.color(ColorPreset.BorderOnLight_01),
    },
    {
      border: "none",
      padding: 0,
      margin: 0,
      borderCollapse: "collapse",
      width: "100%",
      position: "relative",
      "thead tr:hover": {
        backgroundColor: "unset",
      },
    },
  ];
};

export const dataTableHeaderStyle: CSSRulesFunction<
  DataTableHeadingStyleProps
> = (
  theme,
  { textAlign = TextAlign.Left, textOverflow = DataTableTextOverflow.NoWrap }
) => {
  return [
    theme.responsive(textAlign, (val) => ({ textAlign: val })),
    {
      padding: theme.spacing(1),
      fontWeight: FontWeight.Normal,
      color: `var(${vars.headingColor})`,
      whiteSpace: "nowrap",
    },
    theme.responsive(textOverflow, (to) => {
      switch (to) {
        case DataTableTextOverflow.NoWrap:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "nowrap",
          };
        case DataTableTextOverflow.Ellipsis:
          return {
            textOverflow: to,
            overflow: "hidden",
            whiteSpace: "nowrap",
          };
        default:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "initial",
          };
      }
    }),
  ];
};

export const dataTableHeadingContentStyle: Interpolation = {
  display: "inline-grid",
  alignItems: "center",
  gridAutoFlow: "column",
  gap: `var(${vars.headingItemSpacing})`,
};

export const dataTableCellFadeStyle: CSSRulesFunction<
  "entering" | "exiting" | "entered" | "exited" | "unmounted"
> = (_, state) => {
  switch (state) {
    case "exiting":
    case "entering":
      return { opacity: 0 };
    default:
      return { opacity: 1 };
  }
};

export const dataTableCellStyle: CSSRulesFunction<{
  cellStyle: DataTableCellStyleProps;
  removePadding?: boolean;
}> = (theme, { cellStyle, removePadding }) => {
  const {
    maxWidth,
    minWidth,
    textOverflow,
    bg,
    gutterH = 1,
    gutterV = 1,
    ...rest
  } = cellStyle;
  return [
    typographyStyle(theme, rest),
    {
      backgroundColor: bg ? theme.color(bg) : undefined,
      transition: "opacity 250ms",
    },
    theme.responsive(textOverflow, (to) => {
      switch (to) {
        case DataTableTextOverflow.NoWrap:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "nowrap",
          };
        case DataTableTextOverflow.Ellipsis:
          return {
            textOverflow: to,
            overflow: "hidden",
            whiteSpace: "nowrap",
          };
        default:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "initial",
          };
      }
    }),
    theme.responsive(maxWidth, (mw) => ({ maxWidth: mw })),
    theme.responsive(minWidth, (mw) => ({ minWidth: mw })),
    ...(!removePadding
      ? [
          theme.responsive(gutterV, (v) => ({
            paddingTop: theme.spacing(v),
            paddingBottom: theme.spacing(v),
          })),
        ]
      : []),
    ...(!removePadding
      ? [
          theme.responsive(gutterH, (v) => ({
            paddingLeft: theme.spacing(v),
            paddingRight: theme.spacing(v),
          })),
        ]
      : []),
  ];
};

export const dataTableRowStyle = (
  hideBottomBorder?: boolean
): Interpolation => ({
  borderBottom: hideBottomBorder ? "" : `1px solid var(${vars.borderColor})`,
  ":hover": {
    backgroundColor: `var(${vars.rowHoverBg})`,
  },
  ":hover[tabindex]": {
    cursor: "pointer",
  },
  ":focus-visible": {
    outline: "none",
    boxShadow: `inset 0 0 0 2px var(${vars.rowFocusedOutline})`,
  },
  transition: "background-color 150ms ease-in-out",
});

export const dataTableRowLinkStyle: CSSRulesFunction<
  DataTableCellStyleProps
> = (theme, props) => {
  const { textOverflow, gutterH = 1, gutterV = 1 } = props;
  return [
    {
      color: "inherit",
      display: "block",
      textDecoration: "none",
      outline: "none",
      width: "100%",
    },
    theme.responsive(gutterV, (v) => ({
      paddingTop: theme.spacing(v),
      paddingBottom: theme.spacing(v),
    })),
    theme.responsive(gutterH, (v) => ({
      paddingLeft: theme.spacing(v),
      paddingRight: theme.spacing(v),
    })),
    theme.responsive(textOverflow, (to) => {
      switch (to) {
        case DataTableTextOverflow.NoWrap:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "nowrap",
          };
        case DataTableTextOverflow.Ellipsis:
          return {
            textOverflow: to,
            overflow: "hidden",
            whiteSpace: "nowrap",
          };
        default:
          return {
            textOverflow: "initial",
            overflow: "initial",
            whiteSpace: "initial",
          };
      }
    }),
  ];
};

export const dataTableCompactRowStyle: CSSRulesFunction<{
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
}> = (theme, { hideTopBorder, hideBottomBorder }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  padding: `${theme.spacing(1.5)} 0px`,
  borderTop: hideTopBorder ? "" : `1px solid var(${vars.borderColor})`,
  ":last-child": {
    borderBottom: hideBottomBorder ? "" : `1px solid var(${vars.borderColor})`,
  },
});

export const compactBodyGridStyle: CSSRulesFunction = (theme) => [
  {
    flex: "auto",
    display: "grid",
    margin: "0",
  },
  theme.responsive(["0", theme.spacing(1)], (gap) => ({ gap })),
  theme.responsive(
    ["1fr", "max-content 1fr", "repeat(2, max-content 1fr)"],
    (columns) => ({
      gridTemplateColumns: columns,
    })
  ),
];
