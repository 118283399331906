import * as React from "react";

import { Glyph, Icon } from "../icons";
import {
  Color,
  ColorPreset,
  CSSRulesFunction,
  FontFamily,
  InputGutter,
  inputGutterStyle,
  ResponsiveValue,
  TypePreset,
  useTheme,
} from "../theme";

import { FormFieldStatus } from "./status";

export enum TextAreaResize {
  Auto = "auto",
  None = "none",
}

interface TextAreaThemingProps {
  status?: FormFieldStatus;
  fontFamily?: FontFamily;
  gutter?: ResponsiveValue<InputGutter>;
  /**
   * Controls the height of the text area.
   * When set to auto, the height grows automatically as the text overflows. It can grow up to 450px max-height.
   */
  resize?: TextAreaResize;
}

interface InteractionState {
  focused?: boolean;
  disabled?: boolean;
}

interface InternalProps extends TextAreaThemingProps, InteractionState {}

const textAreaStatusColors: CSSRulesFunction = (theme) => {
  const vs = FormFieldStatus.Success;
  const vd = FormFieldStatus.Danger;

  return {
    [`--textarea-${vs}`]: theme.color(Color.Success_300),
    [`--textarea-${vd}`]: theme.color(ColorPreset.AlertBorderOnLight),
  };
};

const textAreaContainerStyle: CSSRulesFunction<InternalProps> = (
  theme,
  props
) => {
  const { status, focused, disabled } = props;
  const borderWidth = focused ? "2px" : "1px";
  let boxShadow = `inset 0 0 0 ${borderWidth} ${theme.color(
    ColorPreset.BorderOnLight_02
  )}`;
  let backgroundColor = theme.color(ColorPreset.BackgroundLight_01);
  let color = theme.color(ColorPreset.TextOnLight_01);

  if (status) {
    boxShadow = `inset 0 0 0 ${borderWidth} var(--textarea-${status})`;
  }

  if (focused && !status) {
    boxShadow = `inset 0 0 0 ${borderWidth} ${theme.color(
      ColorPreset.BorderOnLight_01
    )}`;
  }

  if (disabled) {
    boxShadow = `inset 0 0 0 ${borderWidth} ${theme.color(
      Color.Greystone_700_A38
    )}`;
    backgroundColor = theme.color(Color.Greystone_1400_A8);
    color = theme.color(Color.Greystone_1400_A38);
  }
  return [
    {
      position: "relative",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "stretch",
      padding: `${borderWidth} 0`,
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
      color,
      boxShadow,
      borderRadius: theme.spacing(0.5),
      backgroundColor,
    },
  ];
};

const textAreaStyle: CSSRulesFunction<InternalProps> = (theme, props) => {
  const padding = inputGutterStyle(theme, { size: props.gutter });

  const typePreset = theme.tokens.typePresets[TypePreset.Body_02];

  const { disabled, fontFamily, resize } = props;
  const fontFamilyProp = fontFamily
    ? theme.tokens.fontFamilies[fontFamily]
    : "inherit";
  return [
    padding,
    theme.responsive(typePreset.size, (fs) => {
      return theme.tokens.fontSizes[fs];
    }),
    {
      display: "block",
      width: "100%",
      margin: 0,
      background: "none",
      paddingRight: theme.spacing(1.5),
      cursor: disabled ? "not-allowed" : "auto",
      borderWidth: 0,
      fontFamily: fontFamilyProp,
      color: "inherit",
      resize: resize ? "none" : "vertical",
      maxHeight: "450px",
      "&:focus": {
        boxShadow: "none",
        outline: "none",
      },
    },
  ];
};

const rightStatusIconCSS: CSSRulesFunction<InternalProps> = (theme, props) => {
  const { status, disabled } = props;

  let color = status ? `var(--textarea-${status})` : "inherit";

  if (disabled) {
    color = theme.color(Color.Greystone_700_A38);
  }

  return {
    color,
    display: "inline-block",
    position: "absolute",
    right: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  };
};

type HTMLAttributes = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  keyof TextAreaThemingProps | "cols"
>;

export interface TextAreaProps extends TextAreaThemingProps, HTMLAttributes {
  children?: never;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    const { theme } = useTheme();
    const [focused, setFocused] = React.useState(false);

    const { status, onBlur, onFocus, resize, gutter, ...rest } = props;
    const { disabled } = props;

    const styleProps = { ...props, disabled, focused };

    const textAreaCSS = [
      textAreaStatusColors(theme),
      textAreaStyle(theme, styleProps),
    ];
    const rightStatusCSS = [
      textAreaStatusColors(theme),
      rightStatusIconCSS(theme, styleProps),
    ];

    const textAreaContainerCSS = [
      textAreaStatusColors(theme),
      textAreaContainerStyle(theme, styleProps),
    ];

    function handleOnInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
      // we need this to measure the initial scroll height of the element
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";

      props.onInput?.(e);
    }

    const handleFocus = React.useCallback<
      React.FocusEventHandler<HTMLTextAreaElement>
    >(
      (e) => {
        setFocused(true);
        if (onFocus) {
          onFocus(e);
        }
      },
      [onFocus]
    );

    const handleBlur = React.useCallback<
      React.FocusEventHandler<HTMLTextAreaElement>
    >(
      (e) => {
        setFocused(false);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur]
    );

    return (
      <div css={textAreaContainerCSS} data-field-control>
        <textarea
          css={textAreaCSS}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows={4}
          ref={ref}
          {...rest}
          onInput={resize === "auto" ? handleOnInput : rest.onInput}
        />

        {status === FormFieldStatus.Success && (
          <div css={rightStatusCSS} aria-hidden>
            <Icon size="10px" name={Glyph.Tick} />
          </div>
        )}

        {status === FormFieldStatus.Danger && (
          <div css={rightStatusCSS} aria-hidden>
            <Icon size="10px" name={Glyph.Close} />
          </div>
        )}
      </div>
    );
  }
);

export default TextArea;
