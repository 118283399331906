import type { ColorScheme } from "../../theme";

export enum ToggleControl {
  Check = "check",
  Radio = "radio",
  Switch = "switch",
}

export enum ToggleVariant {
  Emphasized = "emphasized",
  Compact = "compact",
  ControlOnly = "control-only",
}

export enum ToggleControlPosition {
  Start = "start",
  End = "end",
}

export interface ToggleStyleProps {
  /**
   * Sets the layout variant to use for this component.
   */
  variant: ToggleVariant;
  /**
   * Sets the visual indicator to display for this component.
   */
  control: ToggleControl;
  /**
   * Sets the position of the visual indicator with respect to the body content.
   */
  controlPosition: ToggleControlPosition;
  /**
   * Sets the color scheme for this component.
   */
  colorScheme: ColorScheme;
}

export interface ToggleControlProps {
  indeterminate?: boolean;
}

export enum ControlVar {
  On = "--toggle-control-on",
  Bg = "--toggle-control-bg",
  Fg = "--toggle-control-fg",
  OutlineOn = "--toggle-control-outline-on",
  OutlineColor = "--toggle-control-outline-color",
  BorderColor = "--toggle-control-border-color",
}
