import type * as React from "react";

type HTMLAttributes = React.HTMLAttributes<HTMLTableSectionElement>;

export interface TableFootProps extends HTMLAttributes {
  children?: React.ReactNode;
}

const TableFoot: React.FC<TableFootProps> = (props) => {
  return <tfoot {...props} />;
};

export default TableFoot;
