import type { Interpolation } from "../../theme";

import { ControlVar, ToggleControlProps } from "./toggleTypes";

const outlineWidth = `calc(var(${ControlVar.OutlineOn}) * 1px)`;

const toggleRadioStyle: Interpolation = {
  width: "24px",
  height: "24px",
  display: "flex",
  borderRadius: "24px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `
  inset 0 0 0 ${outlineWidth} var(${ControlVar.BorderColor})
  `,
  color: `var(${ControlVar.Fg})`,
  transition: "box-shadow 100ms, background 100ms, color 100ms",
  "@media (prefers-reduced-motion: reduce)": {
    transition: "box-shadow 50ms, background 50ms, color 50ms",
  },
};

const ToggleRadio: React.FC<ToggleControlProps> = () => {
  return <span css={toggleRadioStyle} role="presentation" />;
};

export default ToggleRadio;
