/* eslint-disable react/forbid-elements */

type HTMLAttributes = Omit<
  React.OptionHTMLAttributes<HTMLOptionElement>,
  "label"
>;

export interface OptionProps extends HTMLAttributes {
  children: string | number;
}

const Option = (props: OptionProps): React.ReactElement => {
  return <option {...props} />;
};

export default Option;
