import {
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  Interpolation,
  ZIndex,
} from "../theme";

import type { DropdownStyleProps } from "./dropdownTypes";

export const vars = {
  maxWidth: "--dropdown-maxwidth",
  top: "--dropdown-top",
  left: "--dropdown-left",
  bg: "--dropdown-bg",
  color: "--dropdown-color",
  elevation: "--dropdown-elevation",
  borderRadius: "--dropdown-border-radius",
  gutterV: "--dropdown-gutterv",
  gutterH: "--dropdown-gutterh",
};

export const dropdownPanelStyle: Interpolation = {
  width: "max-content",
  maxWidth: `var(${vars.maxWidth})`,
  padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
  backgroundColor: `var(${vars.bg})`,
  color: `var(${vars.color})`,
  filter: `var(${vars.elevation})`,
  borderRadius: `var(${vars.borderRadius})`,
  top: `var(${vars.top})`,
  left: `var(${vars.left})`,
  zIndex: ZIndex.Dropdown,
};

const dropdownColorTokens: CSSRulesFunction<ColorScheme> = (theme, cs) => {
  const light = {
    [vars.bg]: theme.color(ColorPreset.BackgroundLight_01),
    [vars.color]: theme.color(ColorPreset.TextOnLight_01),
  };
  const dark = {
    [vars.bg]: theme.color(ColorPreset.BackgroundDark_01),
    [vars.color]: theme.color(ColorPreset.TextOnDark_01),
  };
  switch (cs) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

export const dropdownStyle: CSSRulesFunction<DropdownStyleProps> = (
  theme,
  props
) => {
  const transformation =
    props.transformRemoval === true ? "none" : "translate3d(0,0,0)";
  return [
    dropdownColorTokens(theme, props.colorScheme),

    theme.responsive(props.gutterV, (value) => ({
      [vars.gutterV]: theme.spacing(value),
    })),
    theme.responsive(props.gutterH, (value) => ({
      [vars.gutterH]: theme.spacing(value),
    })),
    theme.responsive(props.maxWidth, (maxWidth) => ({
      [vars.maxWidth]: maxWidth,
    })),
    {
      [vars.elevation]: theme.tokens.elevation[2],
      [vars.borderRadius]: theme.radius(1),

      position: "relative",
      display: "inline-block",
      // On Chrome, the dropdown root, or rather the containing block, is not
      // being picked up correctly. The containing block is what the floating
      // element will be positioned against. When the floating element used
      // fixed positioning there are different rules for finding the containing
      // block [1]. On Firefox and Safari, these rules appear to be observed
      // correctly, but not on Chrome. There are some old issues reported on
      // Popper.js and Floating UI that refer to this. The workaround to make
      // this work consistently is to set a transform value on dropdown's root
      // element that hosts the trigger - there are other ways to do this
      // covered on MDN [1].
      //
      // Tracked this down by stepping through the isContainingBlock function
      // (via getContainingBlock) in floating-ui [2]. Also a commenter hinted at
      // this solution in a GitHub issue [3].
      //
      // [1]: https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block
      // [2]: https://github.com/floating-ui/floating-ui/blob/70e1fd5e8ac927b3612a7ba2390bd57d5eceee30/packages/dom/src/utils/is.ts#L47-L64
      // [3]: https://github.com/floating-ui/floating-ui/issues/1035
      transform: transformation,
    },
  ];
};
