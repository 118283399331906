import type React from "react";

import {
  Color,
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  Interpolation,
  ResponsiveValue,
  ZIndex,
  BorderRadiusScale,
} from "../theme";

export enum SidebarLayout {
  FloatingLeft = "floating-left",
  FloatingRight = "floating-right",
  StaticLeft = "static-left",
}

export interface SidebarAppearanceProps {
  /**
   * Sets the color scheme for the component.
   */
  colorScheme: ColorScheme;

  /**
   * Sets the layout for the component within the page.
   */
  layout: ResponsiveValue<SidebarLayout>;

  /**
   * If set, applies the border radius to the outside edge of the
   * sidebar (the right-hand-side of the sidebar if it is positioned on
   * the left, or the left-hand-side of the sidebar if it is positioned
   * on the right)
   */
  borderRadius?: BorderRadiusScale;

  /**
   * The width of the sidebar's content area
   *
   * @ignore
   */
  contentWidth: ResponsiveValue<React.CSSProperties["width"]>;
}

export const vars = {
  contentWidth: "--sidebar-content-width",
  defaultDisplay: "--sidebar-default-display",
  bg: "--sidebar-bg",
  color: "--sidebar-color",
  borderColor: "--sidebar-border-color",
  enterTransitions: "--sidebar-enter-transitions",
  exitTransitions: "--sidebar-exit-transitions",
  enterTransform: "--sidebar-enter-transform",
  exitTransform: "--sidebar-exit-transform",
  closeSpacing: "--sidebar-close-spacing",
  closeDisplay: "--sidebar-close-display",
  backdropDisplay: "--sidebar-backdrop-display",
  backdropBg: "--sidebar-backdrop-bg",
  borderLeftRadius: "--border-left-radius",
  borderRightRadius: "--border-right-radius",
};

export const sidebarColorSchemeTokens: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  const light = {
    [vars.bg]: theme.color(ColorPreset.BackgroundLight_01),
    [vars.color]: theme.color(ColorPreset.TextOnLight_01),
    [vars.borderColor]: theme.color(ColorPreset.BorderOnLight_02),
    [vars.backdropBg]: theme.color(Color.Greystone_1400_A50),
  };

  const dark = {
    [vars.bg]: theme.color(ColorPreset.BackgroundDark_02),
    [vars.color]: theme.color(ColorPreset.TextOnDark_01),
    [vars.borderColor]: theme.color(ColorPreset.BorderOnDark_02),
    [vars.backdropBg]: theme.color(Color.Greystone_1400_A50),
  };

  switch (colorScheme) {
    case ColorScheme.OnLight:
      return light;
    case ColorScheme.OnDark:
      return dark;
    case ColorScheme.Auto:
      return [light, { "@media (prefers-color-scheme: dark)": dark }];
  }
};

const sidebarLayoutStyle: CSSRulesFunction<{
  layout?: ResponsiveValue<SidebarLayout>;
  borderRadius?: BorderRadiusScale;
}> = (theme, { layout = SidebarLayout.StaticLeft, borderRadius = 0 }) => {
  return theme.responsive(layout, (v) => {
    switch (v) {
      case SidebarLayout.FloatingLeft:
        return {
          minHeight: "auto",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          [vars.borderLeftRadius]: "0px",
          [vars.borderRightRadius]: theme.radius(borderRadius),
          [vars.closeSpacing]: theme.spacing([1, 0, 0, 1]),
          [vars.closeDisplay]: "block",
          [vars.backdropDisplay]: "block",
          [vars.enterTransform]: "translate3d(0, 0, 0)",
          [vars.exitTransform]: "translate3d(-100%, 0, 0)",
          [vars.enterTransitions]:
            "transform 200ms, filter 200ms, opacity 200ms 200ms",
          [vars.exitTransitions]:
            "transform 200ms, filter 200ms, opacity 100ms",
          "@media (prefers-reduced-motion: reduce)": {
            [vars.enterTransitions]:
              "transform 50ms, filter 50ms, opacity 50ms",
            [vars.exitTransitions]: "transform 50ms, filter 50ms, opacity 50ms",
          },

          "&.slide-enter, &.slide-enter-done": {
            display: "grid",
          },
          "&.slide-exit, &.slide-exit-active": {
            display: "grid",
          },
          "&.slide-exit-done": {
            display: `var(${vars.defaultDisplay})`,
          },
        };
      case SidebarLayout.FloatingRight:
        return {
          minHeight: "auto",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          [vars.borderLeftRadius]: theme.radius(borderRadius),
          [vars.borderRightRadius]: "0px",
          justifyContent: "end",
          [vars.closeSpacing]: theme.spacing([1, 1, 0, 0]),
          [vars.closeDisplay]: "block",
          [vars.backdropDisplay]: "block",
          [vars.enterTransform]: "translate3d(0, 0, 0)",
          [vars.exitTransform]: "translate3d(100%, 0, 0)",
          [vars.enterTransitions]:
            "transform 200ms, filter 200ms, opacity 200ms 200ms",
          [vars.exitTransitions]:
            "transform 200ms, filter 200ms, opacity 100ms",
          "@media (prefers-reduced-motion: reduce)": {
            [vars.enterTransitions]:
              "transform 50ms, filter 50ms, opacity 50ms",
            [vars.exitTransitions]: "transform 50ms, filter 50ms, opacity 50ms",
          },

          "&.slide-enter, &.slide-enter-done": {
            display: "grid",
          },
          "&.slide-exit, &.slide-exit-active": {
            display: "grid",
          },
          "&.slide-exit-done": {
            display: `var(${vars.defaultDisplay})`,
          },
        };
      case SidebarLayout.StaticLeft:
        return {
          minHeight: "100vh",
          height: "100%",
          position: "relative",
          top: 0,
          left: 0,
          [vars.borderLeftRadius]: "0px",
          [vars.borderRightRadius]: theme.radius(borderRadius),
          filter: "none",
          [vars.closeSpacing]: theme.spacing([1, 0, 0, 1]),
          [vars.closeDisplay]: "none",
          [vars.backdropDisplay]: "none",
          [vars.enterTransform]: "none",
          [vars.exitTransform]: "none",
          [vars.enterTransitions]: "none",
          [vars.exitTransitions]: "none",
          "@media (prefers-reduced-motion: reduce)": {
            [vars.enterTransitions]: "none",
            [vars.exitTransitions]: "none",
          },
          display: "grid",
          "&.slide-enter, &.slide-enter-done": {
            display: "grid",
          },
          "&.slide-exit, &.slide-exit-active": {
            display: "grid",
          },
          "&.slide-exit-done": {
            display: "grid",
          },
        };
    }
  });
};

export const sidebarContentStyle: Interpolation = {
  width: `var(${vars.contentWidth})`,
  overflow: "auto",
  backgroundColor: `var(${vars.bg})`,
  color: `var(${vars.color})`,
  borderBottomLeftRadius: `var(${vars.borderLeftRadius})`,
  borderTopLeftRadius: `var(${vars.borderLeftRadius})`,
  borderBottomRightRadius: `var(${vars.borderRightRadius})`,
  borderTopRightRadius: `var(${vars.borderRightRadius})`,
  transform: `var(${vars.enterTransform})`,
  ".slide-enter > &": {
    transition: `var(${vars.enterTransitions})`,
    transform: `var(${vars.exitTransform})`,
  },
  ".slide-enter-active > &, .slide-enter-done > &": {
    transition: `var(${vars.enterTransitions})`,
    transform: `var(${vars.enterTransform})`,
  },
  ".slide-exit > &": {
    transition: `var(${vars.exitTransitions})`,
    transform: `var(${vars.enterTransform})`,
  },
  ".slide-exit-active > &, .slide-exit-done > &": {
    transition: `var(${vars.exitTransitions})`,
    transform: `var(${vars.exitTransform})`,
  },
};

export const sidebarCloseStyle: (
  layout: SidebarAppearanceProps["layout"]
) => Interpolation = (layout) => ({
  margin: `var(${vars.closeSpacing})`,
  display: `var(${vars.closeDisplay})`,
  alignSelf: "start",
  order: layout === SidebarLayout.FloatingRight ? -1 : 1,

  opacity: 1,

  ".slide-enter > &": {
    transition: `var(${vars.enterTransitions})`,
    opacity: 0,
  },
  ".slide-enter-active > &, .slide-enter-done > &": {
    transition: `var(${vars.enterTransitions})`,
    opacity: 1,
  },
  ".slide-exit > &": {
    transition: `var(${vars.exitTransitions})`,
    opacity: 1,
  },
  ".slide-exit-active > &, .slide-exit-done > &": {
    transition: `var(${vars.exitTransitions})`,
    opacity: 0,
  },
});

export const sidebarBackdropStyle: Interpolation = {
  zIndex: -1,
  display: `var(${vars.backdropDisplay})`,
  background: `var(${vars.backdropBg})`,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",

  ".slide-enter > &": {
    transition: `var(${vars.enterTransitions})`,
    filter: "opacity(0)",
  },
  ".slide-enter-active > &, .slide-enter-done > &": {
    transition: `var(${vars.enterTransitions})`,
    filter: "opacity(1)",
  },
  ".slide-exit > &": {
    transition: `var(${vars.exitTransitions})`,
    filter: "opacity(1)",
  },
  ".slide-exit-active > &, .slide-exit-done > &": {
    transition: `var(${vars.exitTransitions})`,
    filter: "opacity(0)",
  },
};

export const sidebarStyle: CSSRulesFunction<
  SidebarAppearanceProps & { open?: boolean }
> = (theme, props) => {
  return [
    sidebarColorSchemeTokens(theme, props.colorScheme),
    {
      [vars.defaultDisplay]: props.open ? "grid" : "none",
      width: "100%",
      zIndex: ZIndex.Sidebar,
      display: `var(${vars.defaultDisplay})`,
      gridAutoColumns: "auto",
      gridAutoFlow: "column",
      justifyContent: "start",
    },
    theme.responsive(props.contentWidth, (cw) => ({ [vars.contentWidth]: cw })),
    sidebarLayoutStyle(theme, props),
  ];
};
