import * as React from "react";

import {
  useTheme,
  AlignItems,
  JustifyContent,
  ResponsiveValue,
  CSSRulesFunction,
  SpaceScale,
} from "../theme";

type HTMLProps = React.HTMLAttributes<HTMLElement>;

export enum GridSemantics {
  Default = "default",
  UnorderedList = "unordered-list",
  OrderedList = "ordered-list",
}

export const GridContext = React.createContext({
  semantics: GridSemantics.Default,
});

export interface GridProps extends HTMLProps {
  /** Determines semantic HTML elements to use for the Grid's elements */
  semantics?: GridSemantics;
  direction?: ResponsiveValue<"row" | "row-reverse">;
  alignItems?: ResponsiveValue<AlignItems>;
  justifyContent?: ResponsiveValue<JustifyContent>;
  flexWrap?: ResponsiveValue<"wrap" | "wrap-reverse">;

  /** Horizontal margin between columns */
  gutterH?: ResponsiveValue<SpaceScale>;

  /** Vertical margin between rows. Not applied to the bottom, after the Grid */
  gutterV?: ResponsiveValue<SpaceScale>;
}

const gutterStyle: CSSRulesFunction<GridProps> = (theme, { gutterH }) =>
  theme.responsive(
    gutterH == null ? theme.tokens.gridGutters : gutterH,
    (value) => ({
      margin: `0 calc(-1 * ${theme.spacing(value)} / 2)`,
      "> *": {
        padding: `0 calc(${theme.spacing(value)} / 2)`,
      },
    })
  );

const spacingStyle: CSSRulesFunction<GridProps> = (theme, props) =>
  theme.responsive(props.gutterV, (v) => ({
    marginBottom: `calc(-1 * ${theme.spacing(v)})`,
    "> *": {
      marginBottom: theme.spacing(v),
    },
  }));

const layoutStyle: CSSRulesFunction<GridProps> = (theme, props) => [
  { display: "flex" },
  theme.responsive(props.direction, (v) => ({ flexDirection: v })),
  theme.responsive(props.justifyContent, (v) => ({ justifyContent: v })),
  theme.responsive(props.alignItems, (v) => ({ alignItems: v })),
  theme.responsive(props.flexWrap || "wrap", (v) => ({ flexWrap: v })),
];

const semanticsStyle: CSSRulesFunction<GridProps> = (_, props) => {
  switch (props.semantics) {
    case GridSemantics.OrderedList:
    case GridSemantics.UnorderedList:
      return { listStyle: "none", padding: 0 };
    default:
      return null;
  }
};

const gridStyle: CSSRulesFunction<GridProps> = (theme, props) => [
  layoutStyle(theme, props),
  gutterStyle(theme, props),
  spacingStyle(theme, props),
  semanticsStyle(theme, props),
];

const tags: Record<GridSemantics, React.ElementType> = {
  [GridSemantics.Default]: "div",
  [GridSemantics.OrderedList]: "ol",
  [GridSemantics.UnorderedList]: "ul",
};

const Grid = React.forwardRef<HTMLElement, GridProps>(function Grid(
  props,
  ref
) {
  const { theme } = useTheme();
  const {
    direction,
    justifyContent,
    alignItems,
    gutterH,
    gutterV,
    flexWrap,
    semantics = GridSemantics.Default,
    ...rest
  } = props;

  const Tag = tags[semantics];

  return (
    <GridContext.Provider value={{ semantics }}>
      <Tag css={gridStyle(theme, props)} {...rest} ref={ref}>
        {props.children}
      </Tag>
    </GridContext.Provider>
  );
});

export default Grid;
