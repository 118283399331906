import { createContext, useEffect } from "react";
import useCookie from "react-use/lib/useCookie";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import { getConfig, Environment, isEnvironment } from "src/common/config";
import { AccessToken } from "src/common/authorisation/types";
import { CookieStorage } from "src/common/constants/storage";
import { SignInApiErrors } from "src/components/routes/SignIn/useSignIn";

interface SegmentAbsProps {
  details?: AccessToken;
  children: React.ReactNode;
}

let analytics = new AnalyticsBrowser();

/**
 * AnalyticsBrowser is bizarre - it implements PromiseLike and has some statically defined methods and some dynamically
 * defined methods. We've spent hours trying to make it play nicely with Jest's mocking features and it won't, so are
 * resorting to good old-fashioned dependency injection.
 */
export function __injectAnalyticsBrowserForTesting(ab: AnalyticsBrowser) {
  analytics = ab;
}

export const SegmentContext = createContext<AnalyticsBrowser>(analytics);

export const SegmentAbs: React.FC<SegmentAbsProps> = ({
  children,
  details,
}) => {
  const {
    client: { segment },
  } = getConfig();

  const {
    data: userDetails,
    error,
    isLoading,
  } = useUserShowSelf(details !== undefined && details !== null) || {};

  // for some reason this code is runned 3 times, and in first 2 user is undefined, and exist in 3rd, but identify reuqest is send only once during first run.
  const user = userDetails?.users;
  const errorMessage = error?.message;

  const [webAnonymousID] = useCookie(CookieStorage.WebAnonymousID);

  const userId = details?.links?.user;
  const organisationId = details?.links?.organisation;
  const creditorId = details?.links?.creditor;

  useEffect(() => {
    // The load method asynchronously initializes the AnalyticsBrowser instance, including adding scripts for third
    // party trackers like Google Tag Manager to the DOM.
    // It can fail, for example if the user has an ad/privacy blocker on, and those scripts fail to load.
    // We swallow these errors silently because the instance has a "feature" we can make use of; further calls to
    // tracking methods won't do anything and also won't throw, which is our desired behaviour.

    const destinationPrivacyRules = {
      production: {
        Advertising: [
          "Bing Ads",
          "Facebook Pixel",
          "Google AdWords New",
          "LinkedIn Insights Tag",
          "Twitter Ads",
          "Criteo",
        ],
        Analytics: [
          "Actions Amplitude",
          "FullStory Classic",
          "Google Analytics 4 Web",
        ],
      },
      staging: {
        Advertising: ["Bing Ads", "Criteo"],
        Analytics: ["Actions Amplitude", "Google Analytics 4 Web"],
      },
    };
    const allowedDestinations: Record<string, boolean> = { All: true };
    const rules =
      destinationPrivacyRules[
        isEnvironment(Environment.Production)
          ? Environment.Production
          : Environment.Staging
      ];
    if (window.airgap?.getConsent) {
      const consent = window.airgap?.getConsent().purposes;
      if (consent?.Analytics === false) {
        rules?.Analytics.forEach(
          (destination) => (allowedDestinations[destination] = false)
        );
      }
      // Check exact match so we don't accept "Auto" as permission to track advertising
      if (consent?.Advertising !== true) {
        rules?.Advertising.forEach(
          (destination) => (allowedDestinations[destination] = false)
        );
      }
    }

    // obfuscate helps to reduce impact on tracking by ad blockers
    analytics
      .load(
        { writeKey: segment.key },
        { obfuscate: true, integrations: allowedDestinations }
      )
      .catch(() => {});
    if (webAnonymousID) {
      analytics.setAnonymousId(webAnonymousID);
    }
  }, [segment.key, webAnonymousID]);
  useEffect(() => {
    if (errorMessage?.toLowerCase() === SignInApiErrors.Unauthorized) {
      resetSegmentAnalytics();
      return;
    }

    const canTrack = userId && organisationId;
    if (!isLoading && canTrack) {
      analytics.identify(userId, {
        org_id: organisationId,
        organisation_id: organisationId,
        creditor_id: creditorId,
        // even if user_id is not a name of user in ZD, it still should be there, and segment will match account by email.
        name: user
          ? user.given_name + (user.family_name ? " " + user.family_name : "")
          : userId,
        email: user ? user.email : "",
      });
      analytics.group(organisationId, {
        userId: userId,
        name: organisationId,
        deleted: "false",
      });
    }
  }, [userId, organisationId, creditorId, user, errorMessage, isLoading]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};

export const resetSegmentAnalytics = (): void => {
  // This method performs synchronous work. The AnalyticsBrowser type is weird because the entire class implements
  // PromiseLike, and because most of its methods return `this` it looks like you should await the result.
  // If load() succeeded inside the useEffect of SegmentAbs, this call synchronously resets the instance's state.
  // If load() failed, there's no state to reset and this succeeds.
  analytics.reset();
};
