/* eslint-disable @typescript-eslint/no-namespace */
import * as React from "react";

import { DataTableAPI, SortDirection, SortMode } from "./api";

//#region Memoization-friendly defaults
const defaultGetTableProps = (): DataTableAPI.TableProps => ({
  role: "grid",
  "aria-readonly": true,
});

const defaultGetHeadingsRowProps = (): DataTableAPI.RowProps => ({
  key: "headings_row",
  role: "row",
});

const defaultComparator = <Item, Accessor extends keyof Item>(
  a: Item[Accessor],
  b: Item[Accessor]
): -1 | 0 | 1 => {
  return a === b ? 0 : a < b ? -1 : 1;
};
//#endregion

function isPlaceholderItem<Item>(
  item: Item | DataTableAPI.PlaceholderItem
): item is DataTableAPI.PlaceholderItem {
  return (
    !!item &&
    typeof item === "object" &&
    "loading" in item &&
    item.loading === true
  );
}

const nextSortDirection = (
  current?: SortDirection
): SortDirection | undefined => {
  switch (current) {
    case SortDirection.Ascending:
      return SortDirection.Descending;
    case SortDirection.Descending:
      return undefined;
    case undefined:
      return SortDirection.Ascending;
  }
};

function nextSortState<Item>(
  mode: SortMode | undefined,
  state: DataTableAPI.SortState<Item>,
  selected: keyof Item
): DataTableAPI.SortState<Item> {
  if (!mode || mode === SortMode.Controlled) {
    return state;
  }

  const currentIndex = state.findIndex(([a]) => selected === a);
  const currentValue = currentIndex > -1 ? state[currentIndex] : undefined;
  const nextDirection = nextSortDirection(currentValue?.[1]);

  let nextValue: [keyof Item, SortDirection] | undefined = undefined;
  if (nextDirection) {
    nextValue = [selected, nextDirection];
  }

  const nextState = [...state];
  if (!nextValue) {
    nextState.splice(currentIndex, 1);
    return nextState;
  }

  if (mode === SortMode.Single) {
    return [nextValue];
  }

  if (currentIndex > -1) {
    nextState[currentIndex] = nextValue;
  } else {
    nextState.push(nextValue);
  }
  return nextState;
}

function sortRows<Item>(
  rows: DataTableAPI.RowInstance<Item>[],
  headings: DataTableAPI.Heading<Item>[],
  sorts: DataTableAPI.SortState<Item>
) {
  type Sorter = [keyof Item, DataTableAPI.SortFunc<Item>, SortDirection];
  const sorters = sorts.map(([accessor, direction]): Sorter | undefined => {
    const heading = headings.find((h) => h.accessor === accessor);
    if (!heading) {
      return;
    }
    const { comparator = defaultComparator } = heading;
    return [heading.accessor, comparator, direction];
  });

  if (!sorters.length) {
    return;
  }

  rows.sort((a, b) => {
    if (isPlaceholderItem(a.item)) {
      return 1;
    }
    if (isPlaceholderItem(b.item)) {
      return -1;
    }

    if (a.item)
      for (let i = 0; i < sorters.length; i++) {
        const sorter = sorters[i];
        if (!sorter) {
          continue;
        }
        const [accessor, func, dir] = sorter;
        const out = func(a.item[accessor], b.item[accessor]);
        if (out !== 0) {
          return dir === SortDirection.Descending ? -out : out;
        }
      }

    return 0;
  });
}

const noop = () => {};

interface DataTableState<Item> {
  activeSort: DataTableAPI.SortState<Item>;
}

type DataTableAction<Item> =
  | {
      type: "SORT";
      payload: {
        accessor: keyof Item;
        mode: SortMode;
      };
    }
  | { type: "RESET_SORT"; payload: DataTableAPI.SortState<Item> };

function makeReducer<Item>() {
  const reducer: React.Reducer<DataTableState<Item>, DataTableAction<Item>> = (
    state: DataTableState<Item>,
    action: DataTableAction<Item>
  ): DataTableState<Item> => {
    switch (action.type) {
      case "RESET_SORT":
        return { ...state, activeSort: action.payload };
      case "SORT":
        return {
          ...state,
          activeSort: nextSortState(
            action.payload.mode,
            state.activeSort,
            action.payload.accessor
          ),
        };
      default:
        return state;
    }
  };
  return reducer;
}

export function useDataTable<Item>(
  options: DataTableAPI.UseDataTableOptions<Item>
): DataTableAPI.Instance<Item> {
  const {
    headings,
    data,
    sorting,
    activeSort: inputActiveSort,
    getItemId,
    getRowLink,
    cellRenderers,
    headingRenderers,
    onControlledSort = noop,
    onRowClick,
    omitCompactHeadings,
    omitColumnLinks,
    compactActions,
  } = options;

  const reducer = React.useMemo(() => makeReducer<Item>(), []);

  const [state, dispatch] = React.useReducer(reducer, {
    activeSort: inputActiveSort || [],
  });

  const { activeSort } = state;
  React.useEffect(() => {
    if (sorting === SortMode.Controlled) {
      dispatch({ type: "RESET_SORT", payload: inputActiveSort || [] });
    }
  }, [sorting, inputActiveSort, dispatch]);

  //#region Build HeadingsRowInstance
  const headingRow = React.useMemo<
    DataTableAPI.HeadingsRowInstance<Item>
  >(() => {
    const cells = headings.map((h) => {
      const { accessor, sorting: headingSort, label } = h;
      const sortIndex = activeSort.findIndex(([a]) => accessor === a);
      const sorted = sortIndex >= 0 ? activeSort[sortIndex]?.[1] : undefined;
      const canSort = sorting != null && headingSort !== "disabled";
      const heading: DataTableAPI.HeadingInstance<Item> = {
        accessor,
        canSort,
        sorted,
        toggleSort: () => {
          if (!canSort) {
            return;
          }

          switch (sorting) {
            case SortMode.Controlled:
              onControlledSort(accessor);
              return;
            case SortMode.Single:
            case SortMode.Multi:
              dispatch({
                type: "SORT",
                payload: { accessor, mode: sorting },
              });
              return;
          }
        },
        render() {
          const found = headingRenderers?.[accessor];
          return found ? found({ label }) : label;
        },
        getHeadingProps() {
          return {
            key: `${String(accessor)}`,
            role: "columnheader",
            scope: "col",
            "aria-sort":
              sorted == null
                ? undefined
                : sorted === SortDirection.Descending
                ? "descending"
                : "ascending",
          };
        },
        omittedWhenCompact:
          !!omitCompactHeadings && omitCompactHeadings.includes(accessor),
      };
      return heading;
    });
    return { cells, getRowProps: defaultGetHeadingsRowProps };
  }, [headings, activeSort, sorting, dispatch, onControlledSort]);
  //#endregion

  //#region Build rows
  const rows = React.useMemo(() => {
    const allRows = data.map((item) => {
      const itemId = isPlaceholderItem(item) ? item.id : getItemId(item);
      const itemLink =
        !isPlaceholderItem(item) && getRowLink ? getRowLink(item) : "";

      const onClick =
        onRowClick && !isPlaceholderItem(item)
          ? () => onRowClick(item)
          : undefined;

      const cells = headings.map(
        ({ accessor }, index): DataTableAPI.CellInstance<Item> => {
          if (isPlaceholderItem(item)) {
            return {
              type: "loading",
              accessor,
              omitLink: false,
              getCellProps: () => ({
                key: `loading_${String(accessor)}_${index}`,
                role: "gridcell",
              }),
            };
          }

          const value = item[accessor];
          const omitLink = Boolean(omitColumnLinks?.includes(accessor));
          const enableCustomClick = Boolean(itemLink) && omitLink;

          return {
            type: "data",
            accessor,
            value,
            item,
            omitLink: omitLink,
            getCellProps: () => ({
              key: `${String(accessor)}_${index}`,
              role: "gridcell",
              onClick: enableCustomClick ? onClick : undefined,
            }),
            render() {
              const renderCell = cellRenderers?.[accessor];
              if (renderCell) {
                return renderCell({ value, item });
              }

              return <>{value}</>;
            },
          };
        }
      );

      const tabIndex = onClick ? 0 : undefined;

      let compactActionsRendered:
        | DataTableAPI.CompactRowInstanceActions
        | undefined = undefined;
      if (compactActions && !isPlaceholderItem(item)) {
        compactActionsRendered = {
          rightActions: compactActions.rightActions
            ? compactActions.rightActions(item)
            : undefined,
          bottomActions: compactActions.bottomActions
            ? compactActions.bottomActions(item)
            : undefined,
        };
      }
      const rowInstance: DataTableAPI.RowInstance<Item> = {
        cells,
        item,
        getRowProps: () => ({
          role: "row",
          key: itemId,
          onClick: itemLink ? undefined : onClick,
          tabIndex,
          link: itemLink,
        }),
        compactActions: compactActionsRendered,
      };

      return rowInstance;
    });

    if (sorting && sorting !== SortMode.Controlled) {
      sortRows(allRows, headings, activeSort);
    }

    return allRows;
  }, [
    headings,
    data,
    cellRenderers,
    getItemId,
    activeSort,
    sorting,
    onRowClick,
  ]);
  //#endregion

  return {
    activeSort,
    rows,
    headings: headingRow,
    getTableProps: defaultGetTableProps,
  };
}
