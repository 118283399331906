import * as React from "react";

import type { Theme, DesignTokens } from "./types";
import DefaultTheme from "./DefaultTheme";
import { defaultDesignTokens } from "./tokens";

export type ThemeContextValue = { theme: Theme };

const ThemeContext = React.createContext<ThemeContextValue>({
  theme: new DefaultTheme(defaultDesignTokens),
});

export interface ThemeProviderProps {
  tokens?: DesignTokens;

  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  tokens = defaultDesignTokens,
  children,
}) => {
  const value = React.useMemo(
    () => ({ theme: new DefaultTheme(tokens) }),
    [tokens]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextValue => React.useContext(ThemeContext);
