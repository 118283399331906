import * as React from "react";

import { ColorScheme } from "../theme";

import {
  Toggle,
  ToggleControl,
  ToggleControlPosition,
  ToggleProps,
  ToggleVariant,
} from "./toggles";

export type RadioProps = Omit<ToggleProps, "type" | "control">;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(function Radio(
  props,
  ref
) {
  const {
    variant = ToggleVariant.Compact,
    colorScheme = ColorScheme.OnLight,
    controlPosition = ToggleControlPosition.Start,
    ...rest
  } = props;
  const radioStyleProps = { variant, colorScheme, controlPosition, ...rest };
  return (
    <Toggle
      {...radioStyleProps}
      control={ToggleControl.Radio}
      type="radio"
      ref={ref}
    />
  );
});

export default Radio;
