import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { FactorType } from "@gocardless/api/dashboard/types";
import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  Dialog,
  Field,
  Glyph,
  H2,
  Interpose,
  Space,
  TypePreset,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  P,
} from "@gocardless/flux-react";

import { TwoFactorAuthFormFields } from "../TwoFAContextProvider";
import { ToTranslate } from "../i18n";

import { getInputErrorStatus, VALID_OTP_CODE } from "src/utils/inputValidation";
import { useCurrentAuthFactor } from "src/components/routes/Settings/mfa/useCurrentAuthFactor";
import { useTriggerAuthFactor } from "src/components/routes/Settings/mfa/useTriggerAuthFactor";
import TwoFAImage from "src/assets/png/bulk-change-2fa.png";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";

export interface TwoFADialogConfig {
  title?: React.ReactNode;
  description?: React.ReactNode;
  submitButtonText?: React.ReactNode;
  cancelButtonText?: React.ReactNode;
  image?: {
    src: string;
    alt: string;
  };
}

const TwoFADialogVariants = {
  BulkChange: "bulkChange",
  MultiAccount: "multiAccount",
  updateUser: "updateUser",
} as const;

export type TwoFADialogVariant =
  (typeof TwoFADialogVariants)[keyof typeof TwoFADialogVariants];

export interface TwoFADialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  requiresPassword?: boolean;
  requiresOtpCode?: boolean;
  variant: TwoFADialogVariant;
  config?: Partial<TwoFADialogConfig | null>;
}

const DEFAULT_CONFIGS: Record<TwoFADialogVariant, TwoFADialogConfig> = {
  bulkChange: {
    title: (
      <H2
        color={ColorPreset.TextOnLight_01}
        id="bulkChange2FADialogHeader"
        size={3}
      >
        <Trans>Two-Factor Authentication</Trans>
      </H2>
    ),
    description: (
      <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        <Trans>
          To grant access to the error file containing confidential information,
          please verify your identity by entering your Two-Factor Authentication
          code.
        </Trans>
      </P>
    ),
    submitButtonText: <Trans>Download</Trans>,
    cancelButtonText: <Trans id="Cancel">Cancel</Trans>,
    image: {
      src: TwoFAImage,
      alt: "",
    },
  },
  multiAccount: {
    title: (
      <H2
        color={ColorPreset.TextOnLight_01}
        id="multiAccount2FADialogHeader"
        size={3}
      >
        <Trans>Two-Factor Authentication</Trans>
      </H2>
    ),
    description: (
      <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        <Trans>
          To access to this account, please verify your identity by entering
          your two-factor authentication code.
        </Trans>
      </P>
    ),
    submitButtonText: <Trans id="Continue">Continue</Trans>,
    cancelButtonText: <Trans id="Cancel">Cancel</Trans>,
    image: {
      src: TwoFAImage,
      alt: "",
    },
  },
  updateUser: {
    title: (
      <H2
        color={ColorPreset.TextOnLight_01}
        id="updateUser2FADialogHeader"
        size={3}
      >
        <Trans>Two-Factor Authentication</Trans>
      </H2>
    ),
    description: (
      <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        <ToTranslate>
          To make changes to this user, please verify your identity by entering
          your two-factor authentication code.
        </ToTranslate>
      </P>
    ),
    submitButtonText: <Trans id="submit">Submit</Trans>,
    cancelButtonText: <Trans id="Cancel">Cancel</Trans>,
    image: {
      src: TwoFAImage,
      alt: "",
    },
  },
};

export const TwoFADialog: React.FC<TwoFADialogProps> = ({
  open,
  onClose,
  onSubmit,
  requiresPassword,
  requiresOtpCode,
  isSubmitting,
  variant,
  config,
}) => {
  const { currentAuthId, factorType } = useCurrentAuthFactor();
  const { triggerMFA } = useTriggerAuthFactor(currentAuthId);

  const {
    formState: { isDirty, isValid },
    register,
  } = useFormContext<TwoFactorAuthFormFields>();

  const { sendEvent } = useSegment();

  useEffect(() => {
    if (open && factorType === FactorType.Sms) {
      triggerMFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, factorType]);

  useSendPageViewEvent(TrackingEvent.TWOFA_DIALOG_VIEWED, {
    project: variant,
  });

  const showErrorState = isDirty && !isValid;
  const finalConfig = { ...DEFAULT_CONFIGS[variant], ...config };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit();
    sendEvent(TrackingEvent.TWOFA_DIALOG_SUBMIT_CTA_CLICKED, {
      project: variant,
    });
  };

  const handleClose = () => {
    onClose();
    sendEvent(TrackingEvent.TWOFA_DIALOG_CANCEL_CTA_CLICKED, {
      project: variant,
    });
  };

  return (
    <Dialog
      as="form"
      onSubmit={handleSubmit}
      open={open}
      aria-labelledby={`${variant}2FADialogHeader`}
      header={finalConfig.title}
      footer={
        <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
          <Button
            disabled={!isValid || isSubmitting}
            layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
            rightIcon={isSubmitting ? Glyph.Spinner : undefined}
            size={ButtonSize.Md}
            type="submit"
            variant={ButtonVariant.PrimaryOnLight}
          >
            {finalConfig.submitButtonText}
          </Button>
          <Button
            layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
            size={ButtonSize.Md}
            variant={ButtonVariant.TextOnLight}
            onClick={handleClose}
          >
            {finalConfig.cancelButtonText}
          </Button>
        </ButtonGroup>
      }
    >
      <Interpose node={<Space v={1.5} />}>
        {finalConfig.image && (
          <Image
            src={finalConfig.image.src}
            alt={finalConfig.image.alt}
            width={504}
            height={216}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        )}
        {finalConfig.description}
        <Interpose node={<Space v={2} />}>
          {requiresPassword && (
            <Field>
              <Label htmlFor="current_password_mfa">
                <Trans>Enter your current password</Trans>
              </Label>
              <Space v={0.5} />
              <Input
                id="current_password_mfa"
                type="password"
                autoComplete="currentPassword"
                {...register("currentPassword", {
                  required: requiresPassword,
                })}
              />
            </Field>
          )}
          {requiresOtpCode && (
            <Field>
              <Label htmlFor="otp_code">
                <Trans id="settings.user.mfa.sms.dialog.2fa-setup.enter-code.label">
                  Enter authentication code
                </Trans>
              </Label>
              <Input
                id="otp_code"
                autoComplete="off"
                {...register("otpCode", {
                  required: requiresOtpCode,
                  pattern: VALID_OTP_CODE,
                  minLength: 6,
                  maxLength: 6,
                })}
                status={getInputErrorStatus(showErrorState)}
                style={{
                  letterSpacing: "14px",
                  textAlign: "center",
                  fontSize: "24px",
                }}
              />
              {showErrorState && (
                <Hint status={FormFieldStatus.Danger}>
                  <Trans>Please enter a valid authentication code.</Trans>
                </Hint>
              )}
            </Field>
          )}
        </Interpose>
      </Interpose>
    </Dialog>
  );
};
