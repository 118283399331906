import { ExportResource } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ButtonLayout,
  Color,
  JustifyContent,
  PlainLink,
  Text,
} from "@gocardless/flux-react";
import { HTTPError } from "@gocardless/api/utils";

import {
  ExportStatus,
  formatExportFileName,
} from "./ExportsCentreExportRowItem";

interface ExportItemRowProps extends ExportResource {
  error?: HTTPError;
}

export const ExportItemRow: React.FC<ExportItemRowProps> = ({
  id,
  export_type: exportType,
  status,
  download_url: downloadUrl,
  error,
}) => {
  const fileName = (
    <Text color={error?.message ? Color.Greystone_1400_A38 : undefined}>
      {formatExportFileName(id, exportType)}
    </Text>
  );

  return (
    <Box
      layout="flex"
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
    >
      <Text fontFamily="monospace" size={2}>
        {downloadUrl ? (
          <PlainLink
            href={downloadUrl}
            layout={ButtonLayout.Inline}
            data-testid={`export-download-link-${id}`}
          >
            {fileName}
          </PlainLink>
        ) : (
          fileName
        )}
      </Text>
      <ExportStatus status={status} error={error} id={id} />
    </Box>
  );
};
