import * as React from "react";

import { useTheme } from "../theme";

import {
  TypographyStyleProps,
  typographyStyle,
  omitTypographyStyleProps,
} from "./appearance";

type HTMLProps = Omit<React.HTMLAttributes<HTMLSpanElement>, "color">;

export interface TextProps extends HTMLProps, TypographyStyleProps {}

const Text = React.forwardRef<HTMLSpanElement, TextProps>(function Text(
  props,
  ref
) {
  const { theme } = useTheme();
  const rest = omitTypographyStyleProps(props);

  return <span css={typographyStyle(theme, props)} {...rest} ref={ref} />;
});

export default Text;
