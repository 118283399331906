import type * as React from "react";

import { Icon } from "../../icons";
import { Box } from "../../layout";
import {
  Color,
  ColorPreset,
  ColorProp,
  FontWeight,
  TypePreset,
  useTheme,
} from "../../theme";
import { H5 } from "../../typography";

import { TimelineItemProps, TimelineItemStatus } from "./types";
import { timelineIconSize, timelineItemStyle } from "./styles";

const statusColor: Record<TimelineItemStatus, ColorProp> = {
  [TimelineItemStatus.Alert]: Color.Alert_300,
  [TimelineItemStatus.Neutral]: ColorPreset.IconOnLight_02,
  [TimelineItemStatus.Success]: Color.Success_300,
  [TimelineItemStatus.Warning]: Color.Warning_300,
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  title,
  state,
  statusProps,
  dateTime,
  children,
}) => {
  const { theme } = useTheme();
  return (
    <Box css={timelineItemStyle(theme, { state })}>
      <div>
        <Icon
          name={statusProps.icon}
          color={statusColor[statusProps.status]}
          size={timelineIconSize}
        />
      </div>
      <Box
        layout="flex"
        flexDirection="column"
        color={
          state === "inactive"
            ? ColorPreset.TextOnLight_03
            : ColorPreset.TextOnLight_01
        }
        css={{ gap: theme.spacing(0.5), overflowWrap: "break-word" }}
      >
        <H5 preset={TypePreset.Body_03} weight={FontWeight.SemiBold}>
          {title}
        </H5>
        {!!children && <Box>{children}</Box>}
        <Box>
          {dateTime.date}
          {!!dateTime.time && <>, {dateTime.time}</>}
        </Box>
      </Box>
    </Box>
  );
};

export default TimelineItem;
