import type * as React from "react";

import { MenuRole, useMenuLevel, useMenuRole } from "./menuState";
import { listItemResetStyle, menuLevelStyle } from "./menuStyle";

type HTMLAttributes = Omit<React.LiHTMLAttributes<HTMLLIElement>, "role">;

export interface MenuLIProps extends HTMLAttributes {
  children: React.ReactNode;
}

const MenuLI: React.FC<MenuLIProps> = (props) => {
  const menuRole = useMenuRole();
  const role = menuRole === MenuRole.Menu ? "presentation" : undefined;
  const level = useMenuLevel();

  return (
    <li
      css={[listItemResetStyle, menuLevelStyle(Math.max(0, level))]}
      {...props}
      role={role}
      data-level={level}
    />
  );
};

export default MenuLI;
