import { Color, CSSRulesFunction, HoverEffect } from "../theme";
import { typographyStyle } from "../typography/appearance";

import type { PlainButtonStyleProps } from "./types";

export const plainButtonStyle: CSSRulesFunction<PlainButtonStyleProps> = (
  theme,
  props
) => {
  const { textDecoration, effect, ...rest } = props;

  return [
    {
      display: "inline-block",
      appearance: "none",
      padding: 0,
      margin: 0,
      background: "none",
      border: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
      textAlign: "inherit",
      cursor: "pointer",
      color: "inherit",
      textDecoration: textDecoration ? textDecoration : "none",
    },
    typographyStyle(theme, rest),
    effect === HoverEffect.Lift
      ? {
          transition: "filter 200ms",
          "&:hover, &:focus:focus-visible, &[data-flux-state=hover]": {
            filter: `drop-shadow(0 2px 6px ${theme.color(
              Color.Greystone_1400_A38
            )})`,
          },
          "&:active, &:disabled": { filter: "none" },
          "@media (prefers-reduced-motion: reduce)": {
            "&:active, &:disabled": { filter: "none" },
          },
        }
      : null,

    effect === HoverEffect.TextDecoration
      ? {
          transition: "text-decoration-color 200ms",
          textDecoration: "underline",
          textDecorationColor:
            textDecoration === "underline" ? "currentColor" : "transparent",
          "*": { textDecoration: "inherit" },
          "&:hover, &:focus:focus-visible, &:active, &[data-flux-state=hover]":
            {
              textDecoration: "underline",
              textDecorationColor:
                textDecoration === "underline" ? "transparent" : "currentColor",
            },
          "&:disabled": { textDecoration: "none" },
        }
      : null,

    {
      "&&:disabled": {
        filter: "saturate(20%) opacity(0.6)",
        cursor: "not-allowed",
      },
    },
  ];
};
