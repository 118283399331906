import type * as React from "react";

import { Icon } from "../icons";

import {
  menuDescriptionStyle,
  menuLabelStyle,
  menuTitleStyle,
} from "./menuStyle";
import type { MenuLabelProps as BaseProps } from "./menuTypes";

type HTMLAttributes = Omit<React.HTMLAttributes<HTMLSpanElement>, "title">;

export interface MenuLabelProps extends BaseProps, HTMLAttributes {
  title: React.ReactNode;
}

const MenuLabel: React.FC<MenuLabelProps> = (props) => {
  const { title, description, leftIcon, rightIcon, ...rest } = props;
  return (
    <span role="presentation" {...rest}>
      <span css={menuLabelStyle} role="presentation">
        {leftIcon ? <Icon name={leftIcon} /> : null}

        <span css={menuTitleStyle}>{title}</span>

        {rightIcon ? <Icon name={rightIcon} /> : null}
      </span>

      {description ? (
        <span css={menuDescriptionStyle}>{description}</span>
      ) : null}
    </span>
  );
};

export default MenuLabel;
