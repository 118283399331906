import * as Sentry from "@sentry/browser";

import { getAccessToken } from "../../common/authorisation";

import { getConfig } from "src/common/config";

const sentryOptions: Sentry.BrowserOptions = {
  whitelistUrls: getConfig().client.sentry.whitelist,
  dsn: getConfig().client.sentry.dsn,
  environment: getConfig().shared.environment,
  release: getConfig().shared.buildId,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  // We do this cause our E2E on CI runs against a production
  // build but we don't want it sending sentries events.
  enabled: getConfig().client.sentry.enabled,
  ignoreErrors: [
    // This is an issue with Next.js which does not affect the user experience.
    // See https://github.com/vercel/next.js/issues/43088
    "Invariant: attempted to hard navigate to the same URL",
    // Sentry MERCHANT-DASHBOARD-7VK
    // Highest-firing sentry for merchant dashboard, but doesn't seem to be affecting user experience, and is explicitly ignored in cypress tests
    "ResizeObserver loop completed with undelivered notifications",
  ],
  beforeSend: (event) => (!hasBannedOriginFilename(event) ? event : null),
  denyUrls: getConfig().client.sentry.deny,
};

// When we're developing locally
//
// TODO: Disabled for now, as it's breaking tslint
/*
if (process.env.NODE_ENV === "development") {
  // Don't actually send the errors to Sentry
  sentryOptions.beforeSend = () => null;

  // Instead, dump the errors to the console
  sentryOptions.integrations = [
    new SentryIntegrations.Debug({
      // Trigger DevTools debugger instead of using console.log
      debugger: false,
    }),
  ];
}
*/

Sentry.init(sentryOptions);

export default Sentry;

interface ErrorOptions {
  errorInfo: React.ErrorInfo;
}

interface ExceptionData {
  error: Error;
  options?: ErrorOptions;
  tag?: [string, string];
}

export const captureException = ({ error, options, tag }: ExceptionData) => {
  const { errorInfo } = options || {};
  Sentry.withScope((scope) => {
    scope.setTag("sub_environment", getConfig().shared.role);
    scope.setTag("application", getConfig().shared.name);

    const accessToken = getAccessToken();

    if (accessToken) {
      scope.setTag("organisation_id", accessToken.links?.organisation || "");
      scope.setTag("user_id", accessToken.links?.user || "");
    }

    if (tag) {
      scope.setTag(`gc-${tag[0]}`, tag[1]);
    }

    if (error.message) {
      // De-duplication currently doesn't work correctly browser errors
      // so we force de-duplication by error message if it is present
      // https://docs.sentry.io/data-management/event-grouping/sdk-fingerprinting/?platform=javascript
      scope.setFingerprint([error.message]);
    }

    if (errorInfo) {
      /**
       * This expects a loosely typed Record<string, string>, and won't accept an interface without an index signature.
       * Rather than change the interface of errorInfo, we can just spread it into a new object.
       * */
      scope.setExtras({ ...errorInfo });
    }

    return Sentry.captureException(error);
  });
};

/**
 * List of banned origins that should not be sent to Sentry
 *
 * Sentry _should_ "filter out errors known to be caused by browser extensions",
 * however certain chrome internals may still cause issues.
 *
 * This allows us to ban error origins that are known to be problematic
 */
const bannedOriginFilename: string[] = ["chrome://", "chrome-extension://"];

/**
 * Checks if frame origin filename is banned
 *
 * @param {Sentry.Event} - Sentry event
 * @returns {boolean} true if frame origin filename is banned
 */
const hasBannedOriginFilename = (event: Sentry.Event) => {
  const values = event.exception?.values || [];
  return values.reduce((valuesCheck, value) => {
    const [firstFrame] = value.stacktrace?.frames || [];
    const isBannedFilename = bannedOriginFilename.some((str) =>
      Boolean(firstFrame?.filename?.includes(str))
    );
    return isBannedFilename || valuesCheck;
  }, false);
};
