import type * as React from "react";

import { Box } from "../layout";
import { TabItem, TabItemProps, Tabs, TabsProps } from "../navigation";
import {
  ColorPreset,
  ColorScheme,
  CSSRulesFunction,
  Interpolation,
  TypeScale,
  useTheme,
} from "../theme";

export interface CodeSnippetProps extends Omit<TabItemProps, "label"> {
  /** The name of the programming for the corresponding snippet */
  language: React.ReactNode;
}

const codePanelStyle: Interpolation = { overflow: "auto" };

export const CodeSnippet: React.FC<CodeSnippetProps> = (props) => {
  const { language, ...rest } = props;
  return (
    <TabItem {...rest} label={language}>
      <div css={codePanelStyle}>{rest.children}</div>
    </TabItem>
  );
};

export interface CodeSnippetsProps extends Omit<TabsProps, "spacing"> {
  /** Optional content to include at the end of the code snippets component. */
  footer?: React.ReactNode;
}

const codeSnippetsStyle: CSSRulesFunction<ColorScheme> = (
  theme,
  colorScheme
) => {
  const lightScheme = {
    color: theme.color(ColorPreset.TextOnLight_01),
    backgroundColor: theme.color(ColorPreset.BackgroundLight_01),
  };
  const darkScheme = {
    color: theme.color(ColorPreset.TextOnDark_01),
    backgroundColor: theme.color(ColorPreset.BackgroundDark_01),
  };

  // Your eyes aren't tricking you. They are flipped around intentionally
  // except for Auto.
  switch (colorScheme) {
    case ColorScheme.OnLight:
      return darkScheme;
    case ColorScheme.OnDark:
      return lightScheme;
    case ColorScheme.Auto:
      return [
        lightScheme,
        { "@media (prefers-color-scheme: dark)": darkScheme },
      ];
  }
};

const CodeSnippets: React.FC<CodeSnippetsProps> = (props) => {
  const { theme } = useTheme();
  const {
    triggerFontSize = TypeScale.Size_03,
    footer,
    colorScheme = ColorScheme.OnLight,
    ...rest
  } = props;

  let flipped = ColorScheme.Auto;
  switch (colorScheme) {
    case ColorScheme.OnLight:
      flipped = ColorScheme.OnDark;
      break;
    case ColorScheme.OnDark:
      flipped = ColorScheme.OnLight;
      break;
  }

  return (
    <Box
      gutterV={2}
      gutterH={[1.5, null, 2]}
      css={codeSnippetsStyle(theme, colorScheme)}
      borderRadius={1}
    >
      <Tabs
        {...rest}
        triggerFontSize={triggerFontSize}
        colorScheme={flipped}
        spacing={1.5}
      />
      {footer ? <Box spaceAbove={1}>{footer}</Box> : null}
    </Box>
  );
};

export default CodeSnippets;
