/* eslint-disable */
const jwt = require("jsonwebtoken");

export const stopHidingZendesk = function () {
  const h = document.getElementsByTagName("head")[0];
  const s = document.createElement("script");
  s.id = "ze-visible";
  h.appendChild(s);
};

const setConversationTags = function () {
  if (window?.zE) {
    window.zE("messenger:set", "conversationTags", ["dashboard_chat_confirm"]);
  }
};

export const hideZendesk = () => {
  if (window?.zE) {
    window.zE("messenger", "hide");
  }
};

export const showZendesk = () => {
  if (window?.zE) {
    window.zE("messenger", "show");
  }
};

export const openZendesk = () => {
  if (window?.zE) {
    window.zE("messenger", "open");
  }
};

export const logoutUser = () => {
  if (window?.zE) {
    window.zE("messenger", "logoutUser");
    hideZendesk();
  }
};

export const loadZendeskScript = (addId, credentials) => {
  const w = window;
  const d = document;
  const l = function () {
    const snipet = document.getElementById("ze-snippet");
    if (!snipet) {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.id = "ze-snippet";
      s.src = `https://static.zdassets.com/ekr/snippet.js?key=${addId}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      s.onload = () => {
        hideZendesk();
      };
    }
  };
  if (document.readyState === "complete") {
    l();
    document.addEventListener("showZendesk", stopHidingZendesk);
    document.addEventListener("showZendesk", () => {
      setConversationTags();
    });
  } else if (w.attachEvent) {
    w.attachEvent("onload", l);
  } else {
    w.addEventListener("load", l, false);
  }
};
