import { FocusScope } from "@react-aria/focus";
import * as React from "react";
import { CSSTransition } from "react-transition-group";

import { fadeStyle } from "../animations/transitions";
import { useFloating } from "../hooks/floating";
import { useTapOut } from "../hooks/pointers";
import { useIsomorphicLayoutEffect } from "../hooks/useIsomorphicLayoutEffect";
import { ColorScheme, useTheme } from "../theme";
import BodyPortal from "../BodyPortal";

import { dropdownPanelStyle, dropdownStyle, vars } from "./dropdownStyle";
import type { DropdownStyleProps } from "./dropdownTypes";

type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof DropdownStyleProps | "children"
>;

export interface DropdownTriggerProps {
  onClick: () => void;
  "aria-controls": string;
  "aria-haspopup": boolean;
  "aria-expanded": boolean;
}

export interface DropdownRenderProps {
  close(): void;
}

export interface DropdownProps
  extends HTMLAttributes,
    Partial<DropdownStyleProps> {
  /**
   * An optional, external reference which the dropdown will be positioned
   * against instead of the trigger button.
   */
  reference?: HTMLElement | null;

  /**
   * Renders the trigger that toggles the dropdown.
   */
  trigger: (props: DropdownTriggerProps) => React.ReactNode;

  /**
   * The id of the element whose presence is controlled by this dropdown. If
   * this is not provided, then the dropdown panel's id will be used.
   */
  controls?: string;

  /**
   * The content of the dropdown's panel. This can also be a function that
   * returns the content and is passed some of the dropdown's functionality to
   * invoke.
   */
  children: React.ReactNode | ((props: DropdownRenderProps) => React.ReactNode);

  /**
   * Used to keep the dropdown open regardless of user interactions. Primarly
   * for the purpose of showcasing this component and visual regression testing.
   *
   * @ignore Used for internal, testing purposes.
   */
  forceOpen?: boolean;
  positionOverride?: boolean;
  /**
   * Dropdown panel will be attached to the body. This will help panel to
   * appear always on top the other elements.
   * invoke.
   */
  appendToBody?: boolean;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    maxWidth = "300px",
    gutterV = 1,
    gutterH = 0.5,
    trigger,
    children,
    controls: inControlsId,
    placement = "bottom-start",
    placementStrategy = "flip",
    colorScheme = ColorScheme.OnLight,
    forceOpen = false,
    reference: externalReference,
    offset,
    positionOverride = true,
    appendToBody = false,
    ...rest
  } = props;
  const [openState, setOpen] = React.useState(false);
  const open = openState || forceOpen;
  const { theme } = useTheme();
  const genControlsId = React.useId();
  const { x, y, strategy, update, refs } = useFloating({
    open,
    initialPlacement: placement,
    placementStrategy,
    positionStrategy:
      positionOverride === true || appendToBody ? "absolute" : "fixed",
    offset,
  });
  const hitAreaRef = React.useRef<HTMLDivElement>(null);
  const floatingRef = refs.floating;
  const tapoutRefs = React.useMemo(
    () => [floatingRef, hitAreaRef],
    [floatingRef, hitAreaRef]
  );

  const close = () => setOpen(false);

  // Tapping outside of the tooltip should dismiss it.
  useTapOut(tapoutRefs, () => {
    close();
  });

  useIsomorphicLayoutEffect(() => {
    if (externalReference == null) {
      return;
    }

    refs.setReference(externalReference);
  }, [refs.setReference, externalReference]);

  const controlsId = inControlsId || genControlsId;

  const triggerProps = {
    onClick: () => setOpen((v) => !v),
    "aria-controls": controlsId,
    "aria-haspopup": true,
    "aria-expanded": open,
  };

  const dropdownTop = y == null ? "" : `${y}px`;
  const dropdownLeft = x == null ? "" : `${x}px`;

  const menuNode =
    typeof children === "function" ? children({ close }) : children;

  const transformRemoval =
    positionOverride || appendToBody === true ? true : false;

  const style = dropdownStyle(theme, {
    maxWidth,
    gutterV,
    gutterH,
    placement,
    placementStrategy,
    colorScheme,
    transformRemoval,
  });

  const positionStyle = {
    [vars.top]: dropdownTop,
    [vars.left]: dropdownLeft,
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      css={style}
      {...rest}
      onKeyDown={(e) => {
        props.onKeyDown?.(e);
        if (e.isDefaultPrevented()) {
          return;
        }

        if (e.key !== "Escape") {
          return;
        }
        close();
      }}
      ref={
        externalReference
          ? undefined
          : (refs.reference as React.LegacyRef<HTMLDivElement>)
      }
      style={positionStyle}
    >
      <div ref={hitAreaRef}>{trigger(triggerProps)}</div>

      <DropdownWrapper appendToBody={appendToBody}>
        <CSSTransition
          in={open}
          timeout={200}
          classNames="fade"
          onEnter={update}
          appear
        >
          <>
            <div
              id={genControlsId}
              ref={refs.setFloating}
              css={[appendToBody ? style : {}, dropdownPanelStyle, fadeStyle]}
              style={{
                position: strategy,
                ...(appendToBody ? positionStyle : {}),
              }}
              data-dropdown-open={open}
            >
              {open ? (
                // eslint-disable-next-line jsx-a11y/no-autofocus
                <FocusScope restoreFocus autoFocus>
                  {menuNode}
                </FocusScope>
              ) : (
                menuNode
              )}
            </div>
          </>
        </CSSTransition>
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = ({
  children,
  appendToBody,
}: {
  children: React.ReactNode;
  appendToBody: boolean;
}) => {
  if (!appendToBody) return <>{children}</>;

  return <BodyPortal>{children}</BodyPortal>;
};

export default Dropdown;
