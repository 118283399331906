import { Color, CSSRulesFunction, ZIndex } from "../../theme";

import type { DatePickerTriggerProps } from "./DatePickerTrigger";

export const datePickerCalendarStyle: CSSRulesFunction<{
  position: "absolute" | "fixed";
  top?: string;
  left?: string;
}> = (theme, { position, top, left }) => {
  return {
    position,
    top,
    left,
    zIndex: ZIndex.Dropdown,
    marginTop: theme.spacing(0.5),
    display: "flex",
    justifyContent: "end",
    "&.fade-enter": {
      opacity: 0,
      transform: "translate3d(0, -8px, 0)",
    },
    "&.fade-enter-active": {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
      transition: "opacity 250ms, transform 250ms",
    },
    "&.fade-enter-done": {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },

    "&.fade-exit": {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
    "&.fade-exit-active": {
      opacity: 0,
      transform: "translate3d(0, -8px, 0)",
      transition: "opacity 250ms, transform 250ms",
    },
  };
};

export const datePickerTriggerStyle: CSSRulesFunction<
  DatePickerTriggerProps
> = (theme, props) => {
  return [
    {
      marginRight: `calc(-1 * ${theme.spacing(0.75)})`,
      padding: theme.spacing(0.75),
      display: "inline-flex",
      alignItems: "center",
      borderRadius: theme.radius(2),
      "&:focus, &:hover": {
        outline: "none",
        background: theme.color(Color.Greystone_700_A38),
      },
      "&:disabled": {
        background: "none",
      },
    },
    props.triggerButton?.style,
  ];
};

export const datePickerTriggerLayoutStyle: CSSRulesFunction = (_theme) => {
  return {
    width: "42px",
    height: "30px",
    position: "absolute",
    zIndex: -1,
  };
};
