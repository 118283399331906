import type { ColorScheme } from "..";
import { Color, ColorPreset, CSSRulesFunction, TypePreset } from "../theme";
import { typographyStyle } from "../typography/appearance";

import type { ProgressBarColor, ProgressBarStyleProps } from "./types";

export const vars = {
  trackV: "--progressbar-track-bg",
  fillV: "--progressbar-fill-color",
  labelV: "--progressbar-label-color",
};

type ProgressBarTheme = `${ProgressBarColor}-${ColorScheme}`;

export const progressBarColorTokens: CSSRulesFunction<ProgressBarTheme> = (
  theme,
  progressBarTheme
) => {
  const primaryOnLightColor = theme.color(Color.Greystone_DarkMatter);
  const primaryOnDarkColor = theme.color(Color.Dawn_700);

  const infoOnLightColor = theme.color(Color.Info_300);
  const infoOnDarkColor = theme.color(Color.Info_100);

  const lightTrack = theme.color(Color.Greystone_1400_A16);
  const lightFill =
    progressBarTheme === "primary-on-light"
      ? primaryOnLightColor
      : infoOnLightColor;
  const lightLabel = theme.color(ColorPreset.TextOnLight_01);

  const darkTrack = theme.color(Color.White_A25);
  const darkFill =
    progressBarTheme === "primary-on-dark"
      ? primaryOnDarkColor
      : infoOnDarkColor;
  const darkLabel = theme.color(ColorPreset.TextOnDark_02);
  switch (progressBarTheme) {
    case "primary-on-light":
    case "info-on-light":
      return {
        [vars.trackV]: lightTrack,
        [vars.fillV]: lightFill,
        [vars.labelV]: lightLabel,
      };
    case "primary-on-dark":
    case "info-on-dark":
      return {
        [vars.trackV]: darkTrack,
        [vars.fillV]: darkFill,
        [vars.labelV]: darkLabel,
      };
    case "primary-auto":
      return {
        [vars.trackV]: lightTrack,
        [vars.fillV]: primaryOnLightColor,
        [vars.labelV]: lightLabel,
        "@media (prefers-color-scheme: dark)": {
          [vars.trackV]: darkTrack,
          [vars.fillV]: primaryOnDarkColor,
          [vars.labelV]: darkLabel,
        },
      };
    case "info-auto":
      return {
        [vars.trackV]: lightTrack,
        [vars.fillV]: infoOnLightColor,
        [vars.labelV]: lightLabel,
        "@media (prefers-color-scheme: dark)": {
          [vars.trackV]: darkTrack,
          [vars.fillV]: infoOnDarkColor,
          [vars.labelV]: darkLabel,
        },
      };
  }
};

export const progressBarStyle: CSSRulesFunction<ProgressBarStyleProps> = (
  theme,
  props
) => {
  const tagTheme = `${props.color}-${props.colorScheme}` as ProgressBarTheme;

  return [
    progressBarColorTokens(theme, tagTheme),
    typographyStyle(theme, { preset: TypePreset.Code_02 }),
    {
      color: `var(${vars.labelV})`,
    },
  ];
};
