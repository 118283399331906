import type * as React from "react";

import { Icon } from "../icons";
import { ColorScheme, useTheme } from "../theme";

import RemoveAction from "./RemoveAction";
import { tagStyle } from "./tagStyle";
import { TagSize, TagStyleProps, TagColor, TagVariant } from "./types";

type HTMLAttributes = Omit<React.HTMLAttributes<HTMLSpanElement>, "color">;

export interface TagProps extends HTMLAttributes, Partial<TagStyleProps> {
  children: React.ReactNode;
}

const Tag: React.FC<TagProps> = (props) => {
  const { theme } = useTheme();
  const {
    children,
    removeAction,
    size = TagSize.Md,
    color = TagColor.Neutral,
    colorScheme = ColorScheme.OnLight,
    variant = TagVariant.Solid,
    iconAfter,
    iconBefore,
    fontFamily = "sans-serif",
    ...rest
  } = props;

  return (
    <span
      css={tagStyle(theme, {
        size,
        color,
        colorScheme,
        variant,
        fontFamily,
      })}
      {...rest}
    >
      {iconBefore ? <Icon size="10px" name={iconBefore} /> : null}
      <span>{children}</span>
      {iconAfter ? <Icon size="10px" name={iconAfter} /> : null}
      {removeAction ? (
        <RemoveAction
          colorScheme={colorScheme}
          label={removeAction?.label}
          onClose={removeAction?.onClose}
        />
      ) : null}
    </span>
  );
};

export default Tag;
