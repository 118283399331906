import {
  Color,
  ColorPreset,
  ColorProp,
  CSSRulesFunction,
  FontWeight,
  Interpolation,
  ResponsiveValue,
} from "../../theme";

const calendarSize: ResponsiveValue<"sm" | "md"> = ["sm", "md"];

const vars = {
  maxWidth: "--daterangepickercalendar-max-width",
  gutterV: "--daterangepickercalendar-gutterv",
  gutterH: "--daterangepickercalendar-gutterh",
  spacing: "--daterangepickercalendar-spacing",
  bg: "--daterangepickercalendar-bg",
  weekColor: "--daterangepickercalendar-week-color",
  cellGutterV: "--daterangepickercalendar-cell-gutterv",
  cellGutterH: "--daterangepickercalendar-cell-gutterh",
  buttonSize: "--daterangepickercalendar-button-size",
  buttonColor: "--daterangepickercalendar-button-color",
  buttonDisabledColor: "--daterangepickercalendar-button-disabled-color",
  buttonHoverBg: "--daterangepickercalendar-button-hover-bg",
  buttonRangeHoverBg: "--daterangepickercalendar-button-range-hover-bg",
  buttonSelectedBg: "--daterangepickercalendar-button-selected-bg",
  buttonSelectedDisabledBg:
    "--daterangepickercalendar-button-selected-disabled-bg",
  buttonSelectedColor: "--daterangepickercalendar-button-selected-color",
};

export const calendarStyle: CSSRulesFunction = (theme) => {
  return [
    {
      [vars.spacing]: theme.spacing(1),
      [vars.bg]: theme.color(ColorPreset.BackgroundLight_01),
      [vars.weekColor]: theme.color(ColorPreset.TextOnLight_03),
      [vars.buttonColor]: theme.color(ColorPreset.TextOnLight_01),
      [vars.buttonDisabledColor]: theme.color(Color.Greystone_1400_A38),
      [vars.buttonHoverBg]: theme.color(ColorPreset.BackgroundLight_03),
      [vars.buttonRangeHoverBg]: theme.color(ColorPreset.BackgroundLight_03),
      [vars.buttonSelectedBg]: theme.color(Color.Greystone_DarkMatter),
      [vars.buttonSelectedDisabledBg]: theme.color(Color.Greystone_1400_A8),
      [vars.buttonSelectedColor]: theme.color(ColorPreset.TextOnDark_01),
      [vars.cellGutterV]: theme.spacing(0.5),
      [vars.cellGutterH]: theme.spacing(0.5),
    },
    theme.responsive(calendarSize, (s) => {
      switch (s) {
        case "sm":
          return {
            [vars.maxWidth]: "280px",
            [vars.buttonSize]: "32px",
            [vars.gutterV]: theme.spacing(1),
            [vars.gutterH]: theme.spacing(1.5),
          };
        case "md":
          return {
            [vars.maxWidth]: "354px",
            [vars.buttonSize]: "40px",
            [vars.gutterV]: theme.spacing(1.5),
            [vars.gutterH]: theme.spacing(2),
          };
      }
    }),
    {
      background: `var(${vars.bg})`,
      borderRadius: theme.radius(1),
      filter: theme.tokens.elevation[4],
      maxWidth: `var(${vars.maxWidth})`,
      flex: "1 1 auto",
    },
  ];
};

export const calendarPaddingStyle: Interpolation = {
  padding: `var(${vars.gutterV}) var(${vars.gutterH})`,
};

export const calendarFooterButtonStyle: CSSRulesFunction = (theme) => {
  return [
    {
      borderTop: `1px solid ${theme.color(Color.Greystone_500)}`,
      textAlign: "right",
    },
    calendarPaddingStyle,
  ];
};

export const calendarRangeStyle = ({
  hightlightOnSelection,
  isFromDate,
  isToDate,
  isHoverDate,
}: {
  hightlightOnSelection?: boolean | null;
  isFromDate?: boolean | null;
  isToDate?: boolean | null;
  isHoverDate?: boolean | null;
}) => {
  return [
    isFromDate
      ? {
          borderTopLeftRadius: "100%",
          borderBottomLeftRadius: "100%",
        }
      : {},
    (!hightlightOnSelection && isHoverDate) || isToDate
      ? {
          borderTopRightRadius: "100%",
          borderBottomRightRadius: "100%",
        }
      : {},
  ];
};

export const calendarHeaderStyle: Interpolation = {
  display: "grid",
  gridTemplateColumns: "max-content auto max-content",
  alignItems: "center",
  marginBottom: `var(${vars.spacing})`,
};

export const calendarGridStyle: Interpolation = {
  textAlign: "center",
  borderCollapse: "separate",
  borderSpacing: `0 var(${vars.cellGutterV})`,
  th: {
    padding: `0 0 var(${vars.spacing})`,
  },
  td: {
    "&[data-highlighted=true]": {
      background: `var(${vars.buttonRangeHoverBg})`,
    },
  },
  width: `var(${vars.buttonSize})`,
  height: `var(${vars.buttonSize})`,

  "td,th": {
    "&:first-of-type": { paddingLeft: 0 },
    "&:last-of-type": { paddingRight: 0 },
  },
  "tr:first-of-type > td": { paddingTop: 0 },
  "tr:last-of-type > td": { paddingBottom: 0 },
};

export const calendarWeekHeaderStyle: Interpolation = {
  textDecoration: "none",
  fontWeight: FontWeight.SemiBold,
  color: `var(${vars.weekColor})`,
};

export const calendarRangeButtonStyle: Interpolation = {
  width: `var(${vars.buttonSize})`,
  height: `var(${vars.buttonSize})`,
  borderRadius: `var(${vars.buttonSize})`,
  background: "none",
  margin: 0,
  padding: 0,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "none",
  fontFamily: "inherit",
  lineHeight: "inherit",
  fontWeight: FontWeight.SemiBold,
  color: `var(${vars.buttonColor})`,
  "&:hover": {
    background: `var(${vars.buttonHoverBg})`,
    color: `var(${vars.buttonColor})`,
  },
  "&[data-selected=true]": {
    background: `var(${vars.buttonSelectedBg})`,
    color: `var(${vars.buttonSelectedColor})`,
  },
  "&[data-highlighted=true]": {
    "&:focus, &:hover": {
      background: "none",
    },
  },
  "&:disabled": {
    color: `var(${vars.buttonDisabledColor})`,
    background: "none",
    "&:focus, &:hover": {
      background: "none",
    },
    "&[data-selected=true]": {
      background: `var(${vars.buttonSelectedDisabledBg})`,
    },
  },
};

export const hideButton: Interpolation = {
  visibility: "hidden",
};

export const highlightCalendarRangeButton: CSSRulesFunction<
  ColorProp | undefined
> = (theme, props) => {
  return {
    background: props ? theme.color(props) : "none",
    border: props ? `2px solid ${theme.color(props)}` : "none",
    color: `var(${vars.buttonSelectedColor})`,
  };
};
