import { CSSRulesFunction, FontWeight, Interpolation } from "../theme";

import { roundedButtonSizeTokens } from "./rounded";
import { ButtonLayout, ButtonVariant, ButtonStyleProps } from "./types";

const inlineStyle: Interpolation = {
  textDecoration: "underline",
  textDecorationColor: "transparent",
  fontWeight: FontWeight.SemiBold,
  "&:hover, &:active": {
    textDecorationColor: "currentcolor",
  },
  "&:disabled": {
    textDecorationColor: "transparent",
    opacity: 0.6,
    cursor: "not-allowed",
  },
};

const inlineUnderlinedStyle: Interpolation = {
  textDecoration: "underline",
  fontWeight: "inherit",
  "&:hover, &:active": {
    textDecorationColor: "transparent",
  },
  "&:disabled": {
    textDecorationColor: "currentColor",
    opacity: 0.6,
    cursor: "not-allowed",
  },
};

export const inlineButtonStyle: CSSRulesFunction<ButtonStyleProps> = (
  theme,
  props
) => {
  const { layout = ButtonLayout.Inline, size, variant } = props;
  return [
    {
      appearance: "none",
      padding: 0,
      margin: 0,
      background: "none",
      border: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      textAlign: "inherit",
      cursor: "pointer",
      transition: "text-decoration-color 200ms",
      color: "inherit",
    },

    // Respect the value of the size prop if it is set and set the button size
    // based on the tokens used by rounded buttons since they seem sensible.
    // If the size prop is not set then continue to inherit the font-size and
    // line-height set by parent elements.
    roundedButtonSizeTokens(theme),
    theme.responsive(size, (v) => {
      return {
        fontSize: `var(--btn-font-size-${v})`,
        lineHeight: `var(--btn-line-height-${v})`,
      };
    }),
    theme.responsive(layout, (v) => {
      if (v === ButtonLayout.Full) {
        return { display: "block", width: "100%" };
      } else {
        return { display: "inline", width: "auto" };
      }
    }),

    variant === ButtonVariant.Inline ? inlineStyle : inlineUnderlinedStyle,
  ];
};
