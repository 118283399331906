import { CSSRulesFunction, useTheme } from "../theme";

type HTMLAttributes = React.FieldsetHTMLAttributes<HTMLFieldSetElement>;

export interface FieldSetProps extends Omit<HTMLAttributes, "children"> {
  children: React.ReactNode;
}

const fieldSetStyle: CSSRulesFunction = (theme) => ({
  display: "block",
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,

  "&& > *": {
    marginBottom: theme.spacing(0.25),
  },
  "&& > input, && > select, && > [data-field-control]": {
    margin: theme.spacing([1.5, 0]),
  },
  "&& > :last-child": {
    marginBottom: theme.spacing(0),
  },
});

const FieldSet: React.FC<FieldSetProps> = (props) => {
  const { theme } = useTheme();
  return <fieldset css={fieldSetStyle(theme)} role="group" {...props} />;
};

export default FieldSet;
