import * as React from "react";

import { ButtonVariant, IconButton } from "../buttons";
import { Glyph } from "../icons";
import { ButtonSize } from "../theme";

import Input, { InputProps } from "./Input";

export type PasswordInputProps = Omit<InputProps, "type" | "rightAccessory"> & {
  showButtonLabel: React.ReactNode;
  hideButtonLabel: React.ReactNode;
};

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props, ref) {
    const [showPassword, setShowPassword] = React.useState(false);
    const type = showPassword ? "text" : "password";

    const { showButtonLabel, hideButtonLabel, ...rest } = props;

    return (
      <Input
        ref={ref}
        {...rest}
        type={type}
        rightAccessory={
          <IconButton
            variant={ButtonVariant.TextOnLight}
            icon={showPassword ? Glyph.ViewHidden : Glyph.View}
            label={showPassword ? hideButtonLabel : showButtonLabel}
            onClick={() => setShowPassword((v) => !v)}
            size={ButtonSize.Md}
          />
        }
      />
    );
  }
);

export default PasswordInput;
