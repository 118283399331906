import { Color, CSSRulesFunction, useTheme, ZIndex } from "../theme";

export interface OverlayProps {}

const overlayStyle: CSSRulesFunction = (theme) => {
  return {
    overflow: "auto",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.color(Color.Greystone_1400_A90),
    zIndex: ZIndex.Dialog,
    transition: "opacity 250ms",
    opacity: 1,
    ".slide-enter &": {
      display: "block",
      opacity: 0,
    },
    ".slide-enter-active &": {
      opacity: 1,
    },
    ".slide-enter-done &": {
      opacity: 1,
      display: "block",
    },
    ".slide-exit &": {
      display: "block",
      opacity: 1,
    },
    ".slide-exit-active &": {
      opacity: 0,
    },
    ".slide-exit-done &": {
      opacity: 0,
      display: "none",
    },
  };
};

const Overlay: React.FC<OverlayProps> = () => {
  const { theme } = useTheme();
  return <div css={overlayStyle(theme)} aria-hidden />;
};

export default Overlay;
